import { AlertTypes, ApiKey, WebUrl, ApiUrl, SessionKey } from "../util/Constant.js";
import ApiEngine from "../util/ApiEngine.js";
import { isNull } from "lodash";


export const _WALLET_INFO = "walletInfo";
export const _LATEST_GAME = "latestGame";
export const _NEW_DOWNLINE_MEMBER = "newDownlineMember";
export const _PROMOTION = "promotion";
export const _UNCLAIMED = "unclaimed";
export const _DOWNLINE_SALE = "downlineSale";
export const _HELP_CENTRE_CATEGORY = "helpCentreCategory";
export const _REWARD_INFO = "rewardInfo";
export const _REDEMPTION_HISTORY = "redemptionHistory";

export const walletInfoFunction = () => {
  return async (dispatch) => {
    let walletJson = await ApiEngine.get(ApiUrl._API_GET_WALLET_INFO);
    if (walletJson[ApiKey._API_SUCCESS_KEY]) {
      let data = walletJson[ApiKey._API_DATA_KEY];
      var nonSeamless = data.filter(i => i.productId != 0);

      dispatch({
        type: _WALLET_INFO,
        data: nonSeamless
      });
    };
  };
}

export const latestGameFunction = (e) => {
  return async (dispatch) => {
    let latestGameJson = await ApiEngine.get(ApiUrl._API_GET_LATEST_GAME);
    if (latestGameJson[ApiKey._API_SUCCESS_KEY]) {
      dispatch({
        type: _LATEST_GAME,
        data: latestGameJson[ApiKey._API_DATA_KEY]
      });
    };
  };
}

export const newDownlineMemberFunction = () => {
  return async (dispatch) => {
    var responseJson = await ApiEngine.get(
      ApiUrl._API_GET_TODAY_NEW_DOWNLINES_MEMBER
    );
    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      dispatch({
        type: _NEW_DOWNLINE_MEMBER,
        data: responseJson[ApiKey._API_DATA_KEY]
      });
    };
  };
}

export const promotionFuntion = () => {
  return async (dispatch) => {
    let apiUrl = ApiUrl._API_GET_USER_BANNER + "?isDesktop=true&isPopup=false&languageCode=" +
      (localStorage.getItem(SessionKey._LANGUAGE) && localStorage.getItem(SessionKey._LANGUAGE) != 'undefined' ?
        localStorage.getItem(SessionKey._LANGUAGE) : 'id');

    let responseJson = await ApiEngine.get(apiUrl);
    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      dispatch({
        type: _PROMOTION,
        data: responseJson[ApiKey._API_DATA_KEY]["promoBanners"]
      });
    };
  };
}

export const unclaimedFunction = () => {
  return async (dispatch) => {
    var unClimedJson = await ApiEngine.get(ApiUrl._API_GET_MEMBER_SHAREHOLDER_UNCLAIM);
    if (unClimedJson[ApiKey._API_SUCCESS_KEY]) {
      var claim = 0;
      if (unClimedJson[ApiKey._API_DATA_KEY]["totalclaimable"] != null) {
        claim = unClimedJson[ApiKey._API_DATA_KEY]["totalclaimable"]
      } else {
        claim = 0;
      }

      dispatch({
        type: _UNCLAIMED,
        data: claim
      });
    };
  };
}

export const downlineSaleFunction = (username, url, startDate, endDate) => {
  
  return async (dispatch) => {
    var responseJson = await ApiEngine.get(`${ApiUrl._API_GET_MEMBER_SHAREHOLDER_DETAIL}?&startdate=${startDate}&enddate=${endDate}&username=${username}`);

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      let data = responseJson[ApiKey._API_DATA_KEY]["shareholderData"];

      let grandTotal = { sales: 0, vsales: 0, netProfit: 0 };
      let summaryGrandTotal = 0;

      data["downlineData"].map((e, i) => {
        grandTotal.sales += e.grossSales;
        grandTotal.vsales += e.validTurnover;
        grandTotal.netProfit += e.profit;
        grandTotal.netProfit += e.settlement;

      });

      data["summaryData"].map((summary, index) => {
        summaryGrandTotal += summary["netProfit"];
      });

      dispatch({
        type: _DOWNLINE_SALE,
        data: { summaryGrandTotal: summaryGrandTotal, netProfit: grandTotal.netProfit, vsales: grandTotal.vsales }
      });
    };
  };
}

export const helpCentreCategory = (list) => {
  return async (dispatch) => {

    dispatch({
      type: _HELP_CENTRE_CATEGORY,
      data: list.filter((x) => !isNull(x.publishedAt))
    });
  };
}

export const rewardInfoFunction = (selectedLanguage) => {
  return async (dispatch) => {
    var responseJson = await ApiEngine.get(
      ApiUrl._API_GET_PRODUCT_CATEGORY_DESKTOP +
      "?selectedLanguage=" +
      selectedLanguage
    );

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      var categoryData = responseJson[ApiKey._API_DATA_KEY].filter(x => x['title'] !== "V.Sports");
      let grandTotalReward = 0;

      let categoryTotal = {};

      var responseJson1 = await ApiEngine.get(
        ApiUrl._API_GET_CATEGORY_REWARD_RATES + "?all=true"
      );

      if (responseJson1[ApiKey._API_SUCCESS_KEY])
      {
        let data = responseJson1[ApiKey._API_DATA_KEY];
        let tempObj = {};

        data.map((rewardData, index) => {
          if (!tempObj[rewardData["date"]]) {
            tempObj[rewardData["date"]] = { totalAmount: 0, totalAmountClaimable: 0 };
          }
          if (!tempObj[rewardData["date"]][rewardData["categoryTitle"]]) {
            tempObj[rewardData["date"]][rewardData["categoryTitle"]] = { totalAmount: 0, totalAmountClaimable: 0 };
          }
          if (!tempObj[rewardData["date"]][rewardData["categoryTitle"]][rewardData["providerName"]]) {
            tempObj[rewardData["date"]][rewardData["categoryTitle"]][rewardData["providerName"]] = { productImage: rewardData["productImage"], rate: rewardData["rewardRate"] };
          }
          tempObj[rewardData["date"]].totalAmount += rewardData["amount"];
          tempObj[rewardData["date"]][rewardData["categoryTitle"]].totalAmount += rewardData["amount"];
          if (rewardData["claimed"] !== "True") {
            tempObj[rewardData["date"]].totalAmountClaimable += rewardData["amount"];
            tempObj[rewardData["date"]][rewardData["categoryTitle"]].totalAmountClaimable += rewardData["amount"];
            grandTotalReward += rewardData["amount"];

            if (!categoryTotal[rewardData["categoryTitle"]]) {
              categoryTotal[rewardData["categoryTitle"]] =
              {
                category: rewardData["categoryTitle"],
                totalAmount: rewardData["amount"]
              };
            }
            else if (categoryTotal[rewardData["categoryTitle"]]) {
              categoryTotal[rewardData["categoryTitle"]].totalAmount += rewardData["amount"];
            }
            else if (categoryTotal[rewardData["categoryTitle"]]) {
              categoryTotal[rewardData["categoryTitle"]] =
              {
                category: rewardData["categoryTitle"],
                totalAmount: rewardData["amount"]
              };
            }
          }
          
        })
      }

      dispatch({
        type: _REWARD_INFO,
        data: { gameCategoryData: categoryTotal, categoryData: categoryData, grandTotalReward: grandTotalReward }
      });
    }

  };
}

export const redemptionHistoryFunction = () => {
  return async (dispatch) => {

    let responseJson = await ApiEngine.get(ApiUrl._API_GET_LATEST_REDEMPTION_HISTORY);

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      let data = responseJson[ApiKey._API_DATA_KEY];

      dispatch({
        type: _REDEMPTION_HISTORY,
        data: data
      });
    }

    
  };
}