import React, { useState, useEffect } from "react";
import "react-simple-keyboard/build/css/index.css";
import ApiEngine from "../../util/ApiEngine";
import { ApiKey, ApiUrl } from "../../util/Constant";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import moment from "moment";
import ContentHeader from "components/content/header";

const Richwin = (props) => {
  var { t } = useTranslation();
  var _history = useHistory();
  var _location = useLocation();


  return (
    <>
      <section id="richwin-section" style={{ backgroundImage: `url(${"/img/richwin.png"})`, }}>
        <div className="richwin-box">
          <div className="">
            <h2 className="mb-4 text-center">RICHWIN <span>admin</span></h2>
            <form>
              <div className="mb-4">
                <input
                  id="username"
                  key="username"
                  type="text"
                  name="username"
                  className="form-white-input input-bg-white"
                  placeholder="Username"
                />
              </div>
              <div className="mb-4">
                <input
                  id="password"
                  key="password"
                  type="password"
                  name="password"
                  className="form-white-input input-bg-white"
                  placeholder="Password"
                />
              </div>
              <div className="recaptcha-text mb-4">
                4  4  5  8  9
              </div>
              <div className="">
                <input
                  id="recaptcha"
                  key="recaptcha"
                  type="text"
                  name="recaptcha"
                  className="form-white-input input-bg-white"
                  placeholder="Please enter captcha"
                />
              </div>
              <hr className="richwin-hr" />
              <button className="btn white-long-btn">Login</button>
            </form>
          </div>
        </div>
      </section>
    </>
  );
};
export default Richwin;
