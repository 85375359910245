import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import "react-simple-keyboard/build/css/index.css";
import { ApiUrl, ApiKey, WebUrl, DEFAULT_PAGE_SIZE } from "../../util/Constant";
import Sidebar from "components/sidebar";
import { useTranslation } from "react-i18next";
import ApiEngine from "../../util/ApiEngine";
import {
  showResponseMessage,
  setBusy,
  setIdle,
  setPageSize,
} from "../../redux/AppAction";
import { numberWithCurrencyFormat } from "../../util/Util";
import ContentHeader from "components/content/header";
import RightInformation from "../../components/custom/RightInformation";
import Breadcrumb from "components/custom/Breadcrumb";
import Input from "../../components/custom/Input";
import { useForm, Controller } from "react-hook-form";
import { AsyncPaginate } from "react-select-async-paginate";


const EditDownlineDetails = (props) => {
  var _history = useHistory();
  var { t } = useTranslation();
  var _dispatch = useDispatch();
  var _location = useLocation();

  const [downlineData, setDownlineData] = useState({});
  const [products, setProducts] = useState([]);
  const [nickname, setNickname] = useState("");
  //const [latestGame, setLatestGame] = useState({});
  const [selectedCategory, setSelectedCategory] = useState(
    {
      "label": "All",
      "value": "-1"
    }
  );

  const {
    register: registerInternalTransfer,
    handleSubmit: handleSubmitInternalTransfer,
    errors: errorsInternalTransfer,
    control: controlInternalTransfer,
    setValue: setValueInternalTransfer
  } = useForm();

  useEffect(() => {
    if (_location["state"]) {
      init();
      _dispatch(setPageSize(_location["scrollIndex"]));
    } else {
      _history.goBack();
    }
  }, [_location["state"]]);

  async function init() {
    _dispatch(setBusy());
    try {
      var responseJson = await ApiEngine.get(
        `${ApiUrl._API_GET_MEMBER_DETAILS_BY_ID}?memberId=${_location["state"]["id"]}`
      );

      if (!responseJson[ApiKey._API_SUCCESS_KEY]) {
        throw responseJson[ApiKey._API_MESSAGE_KEY];
      }

      setDownlineData(responseJson[ApiKey._API_DATA_KEY]);

      responseJson = await ApiEngine.get(
        `${ApiUrl._API_GET_ASSIGNED_MEMBER_PRODUCT}?memberId=${_location["state"]["id"]}&ignoreAccountProductStatus=true`
      );

      if (!responseJson[ApiKey._API_SUCCESS_KEY]) {
        throw responseJson[ApiKey._API_MESSAGE_KEY];
      }

      setProducts(responseJson[ApiKey._API_DATA_KEY].products);
      setNickname(responseJson[ApiKey._API_DATA_KEY].nickname);
    } catch (errorMessage) {
      _dispatch(
        showResponseMessage(false, responseJson[ApiKey._API_MESSAGE_KEY])
      );
    } finally {
      _dispatch(setIdle());
    }
  }

  const breadcrumb = [
    {
      value: 'DOWNLINE',
      active: false,
      link: WebUrl._URL_DOWNLINE
    },
    {
      value: 'PRODUCT_COMM_RATE',
      active: true,
      link: WebUrl._URL_PROGRESS_COMM_RATE
    },
  ];

  const _SELECT_STYLES = {
    menu: (styles, state) => ({
      ...styles,
      //padding: '0px',
      //background: 'rgba(0, 0, 32)',
      //color: 'white',
      position: 'absolute !important',
      zIndex: 2,
      background: '#2a2255 !important',
      fontSize: '12px !important'
    }),
    control: (styles, state) => ({
      ...styles,
      //background: 'linear-gradient(0deg, rgba(25, 35, 47, 0.1), rgba(25, 35, 47, 0.1)), linear-gradient(271.54deg, rgba(51, 105, 255, 0.12) -18.33%, rgba(27, 42, 84, 0.3) 100%)',
      //height: '45px',
      //fontSize: '14px',
      borderRadius: '50px',
      boxShadow: '0px 3px 17px 0px rgba(0,0,0,.1)',
      height: '35px',
      background: 'transparent',
      border: '0px',
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        //background: 'rgba(0, 0, 32)',
        //color: 'white',
        //boxShadow: '0px 4px 7px rgba(0, 0, 0, 0.3)'
      };
    },
    singleValue: styles => ({
      ...styles,
      //paddingLeft: '1rem',
      //fontSize: '14px',
      //fontWeight: 'bold',
      //color: 'white'
      paddingLeft: '1.2rem',
      fontSize: '12px',
      color: '#3369ff',
      fontWeight: 'bold'
    }),
    placeholder: styles => ({
      ...styles,
      paddingLeft: '1rem',
      fontSize: '14px',
      color: '#FFF'
    }),
    input: styles => ({
      ...styles,
      margin: '2px',
      color: '#fff',
      paddingLeft: '10px'
    })
  }

  async function getCategoryList(search, loadOptions, { page }) {
    var responseJson = await ApiEngine.get(`${ApiUrl._API_GET_PRODUCT_CATEGORY_SEARCH}?start=${(page - 1) * DEFAULT_PAGE_SIZE}&count=${DEFAULT_PAGE_SIZE}&keyword=${search}`);

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      let data = responseJson[ApiKey._API_DATA_KEY].data;
      formattedData = data.map((m) => { return { label: m.title, value: m.id } });
      var formattedData;
      return {
        options: formattedData,
        hasMore: formattedData.length == DEFAULT_PAGE_SIZE,
        additional: {
          page: page + 1
        }
      };
    }

  }

  //useEffect(() => {
  //  getLatestGame();
  //},[]);

  //const getLatestGame = async () => {
  //  var latestGameJson = await ApiEngine.get(ApiUrl._API_GET_LATEST_GAME);

  //  if (latestGameJson[ApiKey._API_SUCCESS_KEY]) {
  //    setLatestGame(latestGameJson[ApiKey._API_DATA_KEY]);
  //  }
  //}
  return (
    <>
      <section className="section-padding" id="downline-section">
        <Breadcrumb data={breadcrumb} />
        <div className="with-information-container">
          <div className="left">
            <h3>{t("PRODUCT_COMM_RATE")}</h3>
            <div id="downline-search-box-main" className="mt-4">
              <Controller
                className="product-category-dropdown"
                control={controlInternalTransfer}
                errors={errorsInternalTransfer}
                name="category"
                render={({ onChange, value }) => {
                  return (
                    <AsyncPaginate
                      styles={_SELECT_STYLES}
                      placeholder={t("SEARCH_BY_CATEGORY")}
                      debounceTimeout={250}
                      loadOptions={getCategoryList}
                      additional={{
                        page: 1,
                      }}
                      value={selectedCategory}
                      onChange={(e) => { onChange(e.value); setSelectedCategory(e); }}
                      noOptionsMessage={() => t("NO_OPTIONS")}
                      loadingMessage={() => t("LOADING...")}
                    />
                  )
                }}
                rules={{ required: "SEARCH_BY_CATEGORY" }} />
            </div>
            <div className="downline-edit-container mt-4" id="downline-edit">
              {selectedCategory.label === "All" ? (
                products
                  .filter((x) => !x["useGroupRate"])
                  .map((product, index) => {
                    return (
                      <div key={"products" + index}>
                        <table
                          id="commrate"
                          className="table table-borderless table-description"
                        >
                          <tbody>
                            <tr>
                              <th className="downline-prod-name">{product["productName"]}</th>
                              <td className="text-center"></td>
                            </tr>
                            <tr>
                              <th>{t("BONUS_RATE")} (%)</th>
                              <td className="text-center">
                                {numberWithCurrencyFormat(
                                  product["bonus"],
                                  2
                                )}
                              </td>
                            </tr>
                            <tr>
                              <th>{t("COMM_RATE")} (%)</th>
                              <td className="text-center">
                                {numberWithCurrencyFormat(
                                  product["commission"],
                                  2
                                )}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    );
                  })) : (
                products
                  .filter((x) => !x["useGroupRate"] && x.categoryName === selectedCategory.label)
                  .map((product, index) => {
                    return (
                      <div key={"products" + index}>
                        <table
                          id="commrate"
                          className="table table-borderless table-description"
                        >
                          <tbody>
                            <tr>
                              <th className="downline-prod-name">{product["productName"]}</th>
                              <td className="text-center"></td>
                            </tr>
                            <tr>
                              <th>{t("BONUS_RATE")} (%)</th>
                              <td className="text-center">
                                {numberWithCurrencyFormat(
                                  product["bonus"],
                                  2
                                )}
                              </td>
                            </tr>
                            <tr>
                              <th>{t("COMM_RATE")} (%)</th>
                              <td className="text-center">
                                {numberWithCurrencyFormat(
                                  product["commission"],
                                  2
                                )}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    );
                  })
              )}
              {products.filter((x) => x["useGroupRate"])["length"] > 0 && (
                <div className="row groupRates">
                  <div style={{ width: "100%" }}>
                    <div>
                      <label className="col-6 col-form-label">
                        {t("PRODUCT_NAME")}
                      </label>
                      <span>{t("SLOT_GAMES")}</span>
                    </div>
                    <div>
                      <label className="col-6 col-form-label">
                        {t("COMM_RATE")} (%)
                      </label>
                      <span>
                        {
                          products.filter((x) => x["useGroupRate"])[0][
                          "commission"
                          ]
                        }
                      </span>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="right">
            <RightInformation
              // info={true}
              // infoTitle="Wallet Info"
              // infoData={walletInformation}
              // recent={true}
              // recentTitle="Recent Transaction"
              // recentData={recentTransaction}
              games={true}
              //gamesTitle="LATEST_GAME"
              //gamesData={latestGame}
              promotion={true}
            />
          </div>
        </div>





        {/* <div className="container">
          <div className="row">
            <div className="col-3" id="account-menu">
              <Sidebar activePage={"DOWNLINE"} />
            </div>
            <div className="col-9">
              <ContentHeader
                enableBackButton
                title={t("PRODUCT_COMM_RATE")}
              />
              <hr className="right-title-hr" />
              <div className="template-box" id="downline-edit">
                <div className="downline-edit-container">
                  {products
                    .filter((x) => !x["useGroupRate"])
                    .map((product, index) => {
                      return (
                        <div key={"products" + index}>
                          <table
                            id="commrate"
                            className="table table-borderless table-description"
                          >
                            <tbody>
                              <tr>
                                <th>{t("PRODUCT_NAME")}</th>
                                <td className="text-center">
                                  {product["productName"]}
                                </td>
                              </tr>
                              <tr>
                                <th>{t("BONUS_RATE")} (%)</th>
                                <td className="text-center">
                                  {numberWithCurrencyFormat(
                                    product["bonus"],
                                    2
                                  )}
                                </td>
                              </tr>
                              <tr>
                                <th>{t("COMM_RATE")} (%)</th>
                                <td className="text-center">
                                  {numberWithCurrencyFormat(
                                    product["commission"],
                                    2
                                  )}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          {index + 1 < products["length"] && (
                            <div className="border-bot-black"></div>
                          )}
                        </div>
                      );
                    })}
                  {products.filter((x) => x["useGroupRate"])["length"] > 0 && (
                    <div className="row groupRates">
                      <div style={{ width: "100%" }}>
                        <div>
                          <label className="col-6 col-form-label">
                            {t("PRODUCT_NAME")}
                          </label>
                          <span>{t("SLOT_GAMES")}</span>
                        </div>
                        <div>
                          <label className="col-6 col-form-label">
                            {t("COMM_RATE")} (%)
                          </label>
                          <span>
                            {
                              products.filter((x) => x["useGroupRate"])[0][
                                "commission"
                              ]
                            }
                          </span>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </section>
    </>
  );
};
export default EditDownlineDetails;
