import React, { useState, useEffect, useRef } from "react";
import ContentHeader from "components/content/header";
import RightInformation from "../../components/custom/RightInformation";
import { walletInformation, recentTransaction, latestGames } from "../../config/game-configs";
import Breadcrumb from "components/custom/Breadcrumb";
import { map } from "lodash";
import Input from "../../components/custom/Input";
import { useTranslation } from "react-i18next";
import Icon from "@mdi/react";
import { WebUrl, ApiUrl, ApiKey } from "../../util/Constant";
import ApiEngine from "../../util/ApiEngine";
import { stringIsNullOrEmpty, numberWithCurrencyFormat, createMultiPartFormBody } from "../../util/Util";
import { Accordion } from "react-bootstrap";
import DynamicLoadTable from "../../components/custom/DynamicLoadTable";
import { useDispatch, useSelector } from "react-redux";
import moment from 'moment';
import { useHistory } from "react-router-dom";
import {
  setBusy,
  setIdle, showResponseMessage, setDownlineSearchText, setDownlineQuery, setPageSize, setY
} from "../../redux/AppAction";
import { Modal, ModalBody, ModalHeader } from "reactstrap";

const Shareholder = (props) => {

    var _userData = useSelector((state) => state.authState.userData);
    const _dispatch = useDispatch();
    var url_string = window.location;
    var url = new URL(url_string);
    const [currentActiveTab, setCurrentActiveTab] = useState(0);
    var { t } = useTranslation();
    const [searchText, setSearchText] = useState("");
    let _savedPageSize = useSelector(state => state.appState.pageSize);
    let _savedQuery = useSelector(state => state.appState.savedQuery);
    let _savedData = useSelector(state => state.appState.savedText);

    const [displayDataIndex, setDisplayDataIndex] = useState(-1);
    var _history = useHistory();
    const [addShareholder, setAddShareholder] = useState(false);
    const [activeOptionsList, setActiveOptionsList] = useState([]);
    const [member, setMember] = useState({});
    const [hideSummary, setHideSummary] = useState(false);
    const [share, setShare] = useState(0);
    const [shareholderIndex, setShareholderIndex] = useState("");
    const [isAssignNewSH, setIsAssignNewSH] = useState(false);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [displayData, setDisplayData] = useState(true);
    //const [latestGame, setLatestGame] = useState({});
    const [totalUnClaimable, setTotalUnClaimable] = useState("");
    const [processedGrandTotal, setProcessedGrandTotal] = useState({});
    const [companyGrandTotal, setCompanyGrandTotal] = useState("");

    const breadcrumb = [
        {
          value: 'VIP_EVENT',
          active: false,
          link: WebUrl._URL_VIP +
            "?GAzobE73Vse4nr8WpufA=" +
            _userData.hideSummary
        },
        {
          value: 'SHAREHOLDER',
          active: true,
          link: WebUrl._URL_SHAREHOLDER +
            "&startDate=" +
            url.searchParams.get("startDate") +
            "&endDate=" +
            url.searchParams.get("endDate") +
            "&GAzobE73Vse4nr8WpufA=" +
            hideSummary
        },
      ];

    const tabHeadings = [
        {
            title: "SHAREHOLDER",
            tab_id: 0,
        },
        {
            title: "NON_SHAREHOLDERS",
            tab_id: 1,
        },
    ];

  useEffect(() => {
    _dispatch(setDownlineQuery(ApiUrl._API_GET_USER_MEMBER_LIST  + "?directUserOnly=true"));
    _dispatch(setPageSize(10));
    _dispatch(setY(0));
  }, []);


    function getText(e) {
      _dispatch(setDownlineSearchText(e.target.value));
      _dispatch(setDownlineQuery(ApiUrl._API_GET_USER_MEMBER_LIST + "?keyword=" + e.target.value + "&directUserOnly=true"));
      _dispatch(setPageSize(10));
      _dispatch(setY(0));
    }


    const handleEditShBtn = async (downline, index, e) => {
      _dispatch(setBusy());
      e.preventDefault();
      let responseJson = await ApiEngine.get(ApiUrl._API_GET_MEMBER_DETAILS_BY_ID + "?memberId=" + downline['id'] + "&shareholder=true");
      if (responseJson[ApiKey._API_SUCCESS_KEY]) {
        let editingDownlineData = responseJson[ApiKey._API_DATA_KEY];
        let tempOptionsList = [];
        for (var i = editingDownlineData["minShare"]; i <= editingDownlineData["maxShare"]; i += 5) {
          tempOptionsList.push(i.toFixed(2));
        }
        setActiveOptionsList(tempOptionsList);

        setMember(responseJson[ApiKey._API_DATA_KEY]);
        setHideSummary(responseJson[ApiKey._API_DATA_KEY]["hideSummary"]);
        if (responseJson[ApiKey._API_DATA_KEY]['isShareHolder'] !== undefined) {
          setShare(responseJson[ApiKey._API_DATA_KEY]['sharePercent']);
        }
        else {
          setShare(responseJson[ApiKey._API_DATA_KEY]['maxShare']);
        }

        setShareholderIndex(index);
        setIsAssignNewSH(responseJson[ApiKey._API_DATA_KEY]['isShareHolder'] === undefined);
      
      }
      _dispatch(setIdle());
    }

    const handleSubmitForm = () => {
      if (isAssignNewSH) {
        setShowConfirmation(true);
      }
      else {
        submitForm();
      }
    }

    const submitForm = async () => {
      _dispatch(setBusy());
      let params =
      {
        "memberId": member.id,
        "share": share,
        "hideSummary": hideSummary
      };

      let responseJson = await ApiEngine.post(
        ApiUrl._API_UPDATE_SHAREHOLDER,
        createMultiPartFormBody(params)
      );

      _dispatch(setY(0));
      setDisplayData(false);
      setDisplayDataIndex(-1);
      setTimeout(() => setDisplayData(true), 1);
      setShowConfirmation(false);
      setCurrentActiveTab(0);
      _dispatch(setIdle());
    }

    //useEffect(() => {
    //  getLatestGame();
    //  getUnClaimed();
    //}, []);

    //const getLatestGame = async () => {
    //  var latestGameJson = await ApiEngine.get(ApiUrl._API_GET_LATEST_GAME);

    //  if (latestGameJson[ApiKey._API_SUCCESS_KEY]) {
    //    setLatestGame(latestGameJson[ApiKey._API_DATA_KEY]);
    //  }
    //}

    //const getUnClaimed = async () => {
    //  var unClimedJson = await ApiEngine.get(ApiUrl._API_GET_MEMBER_SHAREHOLDER_UNCLAIM);

    //  if (unClimedJson[ApiKey._API_SUCCESS_KEY]) {
    //    setTotalUnClaimable(unClimedJson[ApiKey._API_DATA_KEY]["totalclaimable"]);
    //  }
    //}

    //useEffect(() => {
    //  getDownlineSales(_userData["username"]);
    //}, []);

    //async function getDownlineSales(username) {
    //  _dispatch(setBusy());
    //  var responseJson = await ApiEngine.get(`${ApiUrl._API_GET_MEMBER_SHAREHOLDER_DETAIL}?&startdate=${url.searchParams.get("startDate")}&enddate=${url.searchParams.get("endDate")}&username=${username}`);

    //  if (responseJson[ApiKey._API_SUCCESS_KEY]) {
    //    let data = responseJson[ApiKey._API_DATA_KEY]["shareholderData"];

    //    let grandTotal = { sales: 0, vsales: 0, netProfit: 0 };
    //    let summaryGrandTotal = 0;

    //    data["downlineData"].map((e, i) => {
    //      grandTotal.sales += e.grossSales;
    //      grandTotal.vsales += e.validTurnover;
    //      grandTotal.netProfit += e.profit;
    //      grandTotal.netProfit += e.settlement;

    //    });

    //    data["summaryData"].map((summary, index) => {
    //      summaryGrandTotal += summary["netProfit"];
    //    });

    //    setProcessedGrandTotal(grandTotal);
    //    setCompanyGrandTotal(summaryGrandTotal);
    //  }
    //  else {
    //    _dispatch(showResponseMessage(false, responseJson[ApiKey._API_MESSAGE_KEY]));
    //  }
    //  _dispatch(setIdle());
    //}

    return (
        <>
            <section className="section-padding" id="shareholder-section">
                <Breadcrumb
                    data={breadcrumb}
                    // escButtonOnClick={() => window.history.go(-1)}
                />
                <div className="with-information-container">
                    <div className="left">
                        <div className="custom-two-header-tab-container">
                            {tabHeadings &&
                                tabHeadings.length &&
                                map(tabHeadings, (item, index) => {
                                return (
                                    <div 
                                    key={"shareholder-tab-" + index}
                                    onClick={() => { setCurrentActiveTab(item.tab_id);  }}
                                    className={currentActiveTab == item.tab_id ? 'active' : ''}
                                    >
                                        {t(item.title)}
                                    </div>
                                );
                                })
                            }
                        </div>
                        <div id="downline-search-box-main" className="mt-4 mb-4">
                            <Input placeholder={t("ALIAS_NAME_PHONE")} defaultValue={_savedData} onInput={(e) => { getText(e) }}
                            inputGroupType="append" inputGroupContent={<img src="/img/version3/search-blue.png" alt="search" />} />
                        </div>

                        {displayData && 
                          <Accordion className="downline-accordion">

                            <DynamicLoadTable pageSize={_savedPageSize} apiQuery={_savedQuery} searchEnabled={true} autoscrollEnabled={true} render={(downline, i) => {
                              return (
                                <>
                                  {currentActiveTab == 0 && downline["isShareHolder"] == 1 &&
                                    <>
                                      <Accordion.Item
                                        key={i}
                                        eventKey={i}
                                        className="downline-main-boxes"
                                      >
                                        <Accordion.Header
                                          onClick={() => {
                                            if (displayDataIndex === i) {
                                              setDisplayDataIndex(-1);
                                            }
                                            else {
                                              setDisplayDataIndex(i);
                                            }
                                          }}>
                                          <div className="text-left">
                                            <div style={{ fontWeight:'bold' }}>{downline['username']} </div>
                                            <div className="font11 mt-1">{downline['phoneNumber']}</div>
                                          </div>
                                          <div className="hide-summary-box">
                                            {downline['hideSummary'] == true &&
                                              <div><img src="/img/version3/hide-summary.png" alt="hide" /></div>
                                            }
                                            <i className={"fas fa-chevron-" + (displayDataIndex === i ? "down" : "right")}></i>
                                          </div>
                                        </Accordion.Header>
                                        <Accordion.Body>
                                          <div className="vip-event-downline-data">
                                            <div className="shareholder-data shareholder-date">
                                              <div className="data-left">{t("SHAREHOLDERDATE")}</div>
                                              <div className="data-right">{moment(downline.shareHolderDate).format("YYYY-MM-DD")}</div>
                                            </div>
                                            <div className="shareholder-data shareholder-percent">
                                              <div className="data-left">{t("SHAREPERCENT")}</div>
                                              <div className="data-right">{downline.sharePercent}%</div>
                                            </div>
                                            <div className="edit-sh-btn"
                                            onClick={(e) => _history.push(WebUrl._URL_SHAREHOLDER_EDIT + "?memberId=" + downline.id +
                                              "&startDate=" +
                                              url.searchParams.get("startDate") +
                                              "&endDate=" +
                                              url.searchParams.get("endDate") +
                                              "&GAzobE73Vse4nr8WpufA=" +
                                              hideSummary)}
                                            >
                                              {t("EDITSHAREHOLDER")}
                                            </div>
                                          </div>
                                        </Accordion.Body>
                                      </Accordion.Item>
                                    </>
                                  }

                                  {currentActiveTab == 1 && downline["isShareHolder"] == false &&
                                    <>
                                      <Accordion.Item
                                        key={i}
                                        eventKey={i}
                                        className="downline-main-boxes"
                                      >
                                        <Accordion.Header>
                                          <div className="text-left">
                                             <div style={{ fontWeight:'bold' }}>{downline['username']} </div>
                                            <div className="font11 mt-1">{downline['phoneNumber']}</div>
                                          </div>
                                          <div className="hide-summary-box">
                                            <div className="edit-sh-btn" onClick={(e) => { setAddShareholder(true); handleEditShBtn(downline, i, e) }}>
                                              {t("ASSIGN_AS_SHAREHOLDER")}
                                            </div>
                                          </div>
                                        </Accordion.Header>
                                        {addShareholder &&
                                          <Accordion.Body>
                                            <div className="vip-event-downline-data">
                                              <div className="shareholder-data shareholder-date">
                                                <div className="data-left">{t("SHAREHOLDERDATE")}</div>
                                                <div className="data-right">{moment().format("YYYY-MM-DD")}</div>
                                              </div>
                                              <div className="shareholder-data shareholder-percent">
                                                <div className="data-left">
                                                  <div>{t("SHAREPERCENT")}</div>
                                                  <div className="sh-min-max">{t("MIN") + ":" + member.minShare + "%," + t("MAX") + ":" + member.maxShare + "%"}</div>
                                                </div>
                                                <div className="data-right sh-select-box">
                                                  <select className="sh-select" onChange={(e) => { setShare(e.target.value); }}>
                                                    <option value={share} disabled selected hidden>{numberWithCurrencyFormat(share, 2, true) + "%"}</option>
                                                    {activeOptionsList &&
                                                      activeOptionsList.length > 0 &&
                                                      activeOptionsList.map((optionValue, index) => {
                                                        return (
                                                          <option key={index} value={optionValue}>{optionValue + "%"}</option>
                                                        )
                                                      })
                                                    }
                                                  </select>
                                                </div>
                                              </div>
                                              <div className="shareholder-data shareholder-hide-summary" onClick={() => setHideSummary(!hideSummary)}>
                                                <div className="hide-summary-icon">
                                                  {hideSummary ? (
                                                    <img src="/img/version3/hide-summary.png" alt="hide" />
                                                  ) : (
                                                    <img src="/img/version3/eye-blue.png" alt="show" />
                                                  )}
                                                </div>
                                                <div>
                                                  {t("HIDE_SUMMARY")}
                                                </div>
                                              </div>
                                              <div className="edit-sh-btn" >
                                                <button className="btn custom-btn w-100" onClick={() => handleSubmitForm()}>{t("SAVE")}</button>
                                              </div>
                                            </div>
                                          </Accordion.Body>
                                        }
                                      </Accordion.Item>
                                    </>
                                  }
                                </>
                              );
                            }}
                            />
                          </Accordion>
                          }
                        <Modal
                          isOpen={showConfirmation}
                          id="modal-small-custom"
                          fade={true}
                          toggle={() => {
                            setShowConfirmation(false);
                          }}
                          centered
                        >
                          <ModalBody>
                            <div className="text-center">
                              <div className="confirm-div">
                                <img
                                  className="cop-code"
                                  src="/img/version3/error.svg"
                                  alt="error"
                                />
                                <div>{t(
                                  "CONFIRM_APPOINT_AS_SHAREHOLDER", { username: member["username"] }
                                )}</div>
                                <div className="confirm-btn-div">
                                  <button type="submit" className="btn custom-btn" style={{ background: '#FF334B' }} onClick={() => setShowConfirmation(false)}>{t("CANCEL")}</button>
                                  <button type="submit" className="btn custom-btn" onClick={() => { submitForm();  }}>{t("CONFIRM")}</button>

                                </div>
                              </div>
                            </div>
                          </ModalBody>
                        </Modal>
                    </div>
                    <div className="right">
                        <RightInformation
                            companySummary={true}
                            //companySummaryGrandTotal={companyGrandTotal}
                            //companySummaryNetProfit={processedGrandTotal.netProfit}
                            //companySummaryTurnover={processedGrandTotal.vsales}
                            shareHolderTotalUnclaimed={true}
                            //shareHolderTotalUnclaimedTitle="TOTAL_UNCLAIMED"
                            //shareHolderTotalUnclaimedDetails={totalUnClaimable}
                            games={true}
                            //gamesTitle="LATEST_GAME"
                            //gamesData={latestGame}
                            promotion={true}
                        />
                    </div>
                </div>
            </section>
        </>
    );
};

export default Shareholder;
