export const _SET_GAME_CATEGORIES = "setGameCategories";
export const _SET_GAME_PROVIDERS = "setGameProviders";
export const _GAME_QUERY = "setGameQueryCalled";
export const _GAME_SEARCH = "setGameSearchTextCalled";

export const setGameCategories = ({ gameCategories }) => {
  return (dispatch) => {
    dispatch({
      type: _SET_GAME_CATEGORIES,
      gameCategories,
    });
  };
};

export const setGameProviders = ({ gameProviders }) => {
  return (dispatch) => {
    dispatch({
      type: _SET_GAME_PROVIDERS,
      gameProviders,
    });
  };
};

export const setGameQuery = (e) => {
  return async (dispatch) => {
    dispatch({
      type: _GAME_QUERY,
      data: e,
    });
  };
};

export const setGameSearchText = (e) => {
  return async (dispatch) => {
    dispatch({
      type: _GAME_SEARCH,
      data: e,
    });
  };
};
