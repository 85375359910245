import React, { useState, useEffect } from "react";
import "react-simple-keyboard/build/css/index.css";
import { WebUrl } from "../../util/Constant";
import { useHistory } from "react-router-dom";
import GameMenu from "components/game-list/game-menu";
import ApiEngine from "../../util/ApiEngine";
import {
  ApiKey,
  ApiUrl,
  SessionKey,
  LanguageOption,
} from "../../util/Constant";
import { useDispatch, useSelector } from "react-redux";
import {
  showResponseMessage,
  setBusy,
  setIdle,
} from "../../redux/AppAction.js";
import parse from "html-react-parser";
import { useTranslation } from "react-i18next";
import ContentHeader from "components/content/header";

const About = (props) => {
  const { t, i18n } = useTranslation();
  const _history = useHistory();
  const [title, setTitle] = useState("");
  const [html, setHtml] = useState("");
  // const [lang, setLang] = useState("");
  const [displayGameMenu, setDisplayGameMenu] = useState(true);


  var _dispatch = useDispatch();

  useEffect(() => {
    init();
    // setLang(
    //   LanguageOption.find(
    //     (x) => x.code === localStorage.getItem(SessionKey._LANGUAGE)
    //   ).languageId
    // );
  }, [
    (localStorage.getItem(SessionKey._LANGUAGE) && localStorage.getItem(SessionKey._LANGUAGE) != 'undefined') ?
      (LanguageOption.find(
        (x) => x.code === localStorage.getItem(SessionKey._LANGUAGE)) != undefined ?

        LanguageOption.find(
          (x) => x.code === localStorage.getItem(SessionKey._LANGUAGE)
        ).languageId : LanguageOption[0].languageId) : LanguageOption[0].languageId

    // LanguageOption.find(
    //   (x) => x.code === localStorage.getItem(SessionKey._LANGUAGE)
    // ).languageId,
  ]);

  useEffect(() => {
    setDisplayGameMenu(false);
    setTimeout(() => setDisplayGameMenu(true), 1);
  }, [(localStorage.getItem(SessionKey._LANGUAGE) && localStorage.getItem(SessionKey._LANGUAGE) != 'undefined' ?
    localStorage.getItem(SessionKey._LANGUAGE) : 'en')]);

  useEffect(() => {
    init();
  }, [window.location.pathname]);

  async function init() {
    _dispatch(setBusy());

    var url_string = window.location;
    var url = new URL(url_string);
    var page = url.pathname.split("/")[2];
    let apiUrl =
      ApiUrl._API_GET_COMPANY_CONTENT +
      "?page=" +
      page;

    if (localStorage.getItem(SessionKey._LANGUAGE) && localStorage.getItem(SessionKey._LANGUAGE) != 'undefined') {
      if (LanguageOption.find(
        (x) => x.code === localStorage.getItem(SessionKey._LANGUAGE)) != undefined) {
        apiUrl += "&languageId=" + LanguageOption.find(
          (x) => x.code === localStorage.getItem(SessionKey._LANGUAGE)
        ).languageId;
      }
      else {
        apiUrl += "&languageId=1";
      }
    } else {
      apiUrl += "&languageId=1";
    }

    // LanguageOption.find(
    //   (x) => x.code === localStorage.getItem(SessionKey._LANGUAGE)
    // ).languageId;

    var responseJson = await ApiEngine.get(apiUrl);

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      const title = responseJson[ApiKey._API_DATA_KEY][0]["title"];
      const content = responseJson[ApiKey._API_DATA_KEY][0]["content"];
      setHtml(content);
      setTitle(title);
    } else {
      _dispatch(
        showResponseMessage(false, responseJson[ApiKey._API_MESSAGE_KEY])
      );
    }
    _dispatch(setIdle());
  }

  return (
    <>
      {displayGameMenu && (
        <>
          <section className="section-padding">
            <ContentHeader
              title={title}
            // escButtonOnClick={() => window.history.go(-1)}
            />
            <div className="custom-description-container">
              {parse(html)}
            </div>
          </section>
        </>
      )}
    </>
  );
};
export default About;
