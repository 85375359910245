import React, { useState, useEffect } from "react";
import "react-simple-keyboard/build/css/index.css";
import Sidebar from "components/sidebar";
import { ApiUrl } from "../../util/Constant";
import moment from "moment";
import { stringIsNullOrEmpty, numberWithCurrencyFormat } from "../../util/Util";
import { useTranslation } from "react-i18next";
import DynamicLoadTable2 from "../../components/custom/DynamicLoadTable2";
import ContentHeader from "components/content/header";
import DateRangeFilter from "../../components/custom/DateRangeFilter";
import RightInformation from "../../components/custom/RightInformation";
import { walletInformation, recentTransaction, latestGames } from "../../config/game-configs";
import { map } from "lodash";

const WalletStatement = (props) => {
  const { t } = useTranslation();
  const [walletUrl, setWalletUrl] = useState("");
  const [startDate, setStartDate] = useState(moment().startOf("week").format('YYYY-MM-DD'));
  const [endDate, setEndDate] = useState(moment().endOf("week").format('YYYY-MM-DD'));
  const [selectedDate, setSelectedDate] = useState("");

  useEffect(() => {
    getWalletStatement(startDate, endDate);
  }, []);

  async function getWalletStatement(start, end, selected = "") {
    let apiUrl =
      ApiUrl._API_GET_MEMBER_WALLET_STATEMENT +
      "?StartDate=" +
      moment(start).startOf('day').format("YYYY-MM-DD") +
      "&EndDate=" +
      moment(end).endOf('day').format("YYYY-MM-DD") +
      "&v=" + moment(start).startOf('day').format("YYYY-MM-DD");

    if (!stringIsNullOrEmpty(selected)) {
      apiUrl += "&SelectedDate=" + moment(selected).format("YYYY-MM-DD");
    }
    setWalletUrl(apiUrl);
  }

  useEffect(() => {
    if (!stringIsNullOrEmpty(selectedDate)) {
      getWalletStatement(startDate, endDate, selectedDate);
    }
  }, [selectedDate]);

  return (
    <>

      <h2 class="title-with-blue-under mb-4 mt-4">{t("WALLET_STATEMENT")}</h2>
      <div id="wallet-statement">
        <DateRangeFilter
          startDate={startDate}
          endDate={endDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          onSave={(start, end) => {
            setStartDate(moment(start));
            setEndDate(moment(end));
            getWalletStatement(start, end);
            setSelectedDate("");
          }}
          options={true}
        />
        <div className="card card-package">
          <div className="card-body">
            <div className="table-responsive">
              <table className="table reportTable">
                <thead>
                  <tr>
                    <th>{t("DATE")}</th>
                    <th>{t("REMARK")}</th>
                    <th style={{ textAlign: "right" }}>
                      {t("PRE_MEMBER_CREDIT")}
                    </th>
                    <th style={{ textAlign: "right" }}>
                      {t("AMOUNT")}
                    </th>
                    <th style={{ textAlign: "right" }}>
                      {t("POST_MEMBER_CREDIT")}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <DynamicLoadTable2
                    pageSize={20}
                    apiQuery={walletUrl}
                    searchEnabled={true}
                    isTable={true}
                    render={(item, i) => {
                      let isGrouped =
                        moment(endDate, "YYYY-MM-DD").diff(
                          moment(startDate, "YYYY-MM-DD"),
                          "days"
                        ) >= 1 && stringIsNullOrEmpty(selectedDate);
                      return (
                        <tr key={"wallet_statement_" + i}>
                          <td
                            className={isGrouped ? "hoverable" : ""}
                            onClick={() => {
                              if (isGrouped) {
                                setSelectedDate(moment(item["parsedTime"], 'MM/DD/YYYY').format('YYYY-MM-DD'));
                              }
                            }}
                          >
                            {moment(item["parsedTime"], 'MM/DD/YYYY').format('YYYY-MM-DD')}
                          </td>
                          <td>
                            {item["remark"]}
                            {item["serviceCharge"] != "0"
                              ? " - Fees " +
                              (!isGrouped
                                ? "@" +
                                item["serviceChargePercentage"] +
                                "%"
                                : "") +
                              "= " +
                              numberWithCurrencyFormat(
                                parseFloat(item["serviceCharge"]),
                                3
                              )
                              : ""}
                          </td>
                          <td style={{ textAlign: "right" }}>
                            {numberWithCurrencyFormat(
                              parseFloat(item["preMemberCredit"]),
                              3
                            )}
                          </td>
                          <td
                            style={{
                              textAlign: "right",
                              color:
                                item["postMemberCredit"] >
                                  item["preMemberCredit"]
                                  ? "green"
                                  : "red",
                            }}
                          >
                            {numberWithCurrencyFormat(
                              parseFloat(item["amount"]),
                              3
                            )}
                          </td>
                          <td style={{ textAlign: "right" }}>
                            {numberWithCurrencyFormat(
                              parseFloat(item["postMemberCredit"]),
                              3
                            )}
                          </td>
                        </tr>
                      )
                    }}
                  />
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default WalletStatement;
