import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { isNull, map } from "lodash";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import {
  stringIsNullOrEmpty,
  createMultiPartFormBody,
  createFormBody,
  numberWithCurrencyFormat,
} from "../../util/Util";
import { useHistory, useRouteMatch, Link } from "react-router-dom";
import { ApiKey, ApiUrl, WebUrl, SessionKey } from "../../util/Constant";
import moment from 'moment';
import ApiEngine from "../../util/ApiEngine.js";
import { useSelector, useDispatch } from "react-redux";
import {
  setDisplayLogin,
  setDisplayRegister,
  setActiveTab,
  setBusy,
  setIdle,
  showMessage,
  showResponseMessage
} from "../../redux/AppAction";
import Slider from "react-slick";
import { numberWithCurrencyFormat2 } from "../../util/Util.js";

const RightInformation = (props) => {
  const _history = useHistory();
  const { t, i18n } = useTranslation();
  const _dispatch = useDispatch();
  var _userData = useSelector((state) => state.authState.userData);
  var promoBannerList = useSelector((state) => state.informationState.promotion);
  var infoData = useSelector((state) => state.informationState.walletInfo);
  var gamesData = useSelector((state) => state.informationState.latestGame);
  var newDownlinesData = useSelector((state) => state.informationState.newDownlineMember);
  var shareHolderTotalUnclaimedDetails = useSelector((state) => state.informationState.unclaimed);
  var _companySummary = useSelector((state) => state.informationState.downlineSale);
  var _helpCentreData = useSelector((state) => state.informationState.helpCentreCategory);

  var _rewardData = useSelector((state) => state.informationState.rewardInfo);
  var _redemptionHistory = useSelector((state) => state.informationState.redeptionHistory);
  var url_string = window.location;
  var url = new URL(url_string);
  let {
    info,
    //infoTitle,
    //infoData,
    recent,
    recentTitle,
    recentData,
    games,
    //gamesTitle,
    //gamesData,
    promotion,
    buttonText,
    button,
    //grandTotalTitle,
    grandTotalAmountTitle,
    grandTotalData,
    grandTotal,
    newDownlines,
    //newDownlinesTitle,
    //newDownlinesData,
    buttonTwo,
    buttonTwoQuantity,
    buttonTwoText,
    shareHolder,
    shareHolderMembers,
    nonShareHolderMembers,
    shareHolderTotalUnclaimed,
    //shareHolderTotalUnclaimedTitle,
    //shareHolderTotalUnclaimedDetails,
    companySummary,
    //companySummaryGrandTotal,
    //companySummaryNetProfit,
    //companySummaryTurnover,
    claimHistory,
    claimHistoryTitle,
    claimHistoryData,
    redemption,
    //redemptionData,
    unclaimedBalance,
    unclaimedBalanceAmount,
    unclaimedSummary,
    unclaimedSummaryData,
    helpCentre,
    helpCentreList,
    helpCentreSubCategory,
    helpCentreFaqs,
    buttonEditReferral,
    buttonReferralCode,
    buttonShareReferral,
    buttonShareList,
    rewardInfo,
    commButton
  } = props;

  const [rightModalVisible, setRightModalVisible] = useState(false);
  //const { isLoggedIn } = useSelector((state) => state.authState);
  //const [infoData, setInfoData] = useState({});
  //const [gamesData, setGamesData] = useState({});
  //const [newDownlinesData, setNewDownlinesData] = useState({});
  //const [buttonTwoQuantity, setButtonTwoQuantity] = useState({});
  //const [shareHolderTotalUnclaimedDetails, setShareHolderTotalUnclaimedDetails] = useState(0);
  const [companySummaryGrandTotal, setCompanySummaryGrandTotal] = useState(_companySummary["summaryGrandTotal"]);
  const [companySummaryNetProfit, setCompanySummaryNetProfit] = useState(_companySummary["netProfit"]);
  const [companySummaryTurnover, setCompanySummaryTurnover] = useState(_companySummary["vsales"]);
  const [gameCategoryData, setGameCategoryData] = useState(_rewardData.gameCategoryData);
  const [categoryData, setcategoryData] = useState(_rewardData.categoryData);
  const [grandTotalReward, setGrandTotalReward] = useState(_rewardData.grandTotalReward);

  const [channels, setChannels] = useState([]);

  const [referralCodeId, setReferralCodeId] = useState(0);
  const [referralCategories, setReferralCategories] = useState({});
  const [referralCodeDetail, setReferralCodeDetail] = useState({});
  const [showShareModal, setShowShareModal] = useState(false);
  const { isLoggedIn } = useSelector((state) => state.authState);

  const promoBannerSlick = {
    infinite: true,
    swipe: true,
    swipeToSlide: true,
    dots: false,
    arrows: false,
    lazyLoad: true,
    autoplay: true,
    autoplaySpeed: 3000
  };

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    if (!stringIsNullOrEmpty(buttonReferralCode)) {
      setReferralCodeId(buttonReferralCode.id);
      setReferralCategories(buttonReferralCode.availableCategories);
      setReferralCodeDetail(buttonReferralCode.referralCodeDetail);
    }
  }, [buttonReferralCode]);


  async function init() {
    _dispatch(setBusy());
    var responseJson = await ApiEngine.get(
      ApiUrl._API_GET_COMMUNICATION_CHANNELS + "?all=true&languageCode=" +
      (localStorage.getItem(SessionKey._LANGUAGE) && localStorage.getItem(SessionKey._LANGUAGE) != 'undefined' ?
        localStorage.getItem(SessionKey._LANGUAGE) : 'en')
    );

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      setChannelData(responseJson[ApiKey._API_DATA_KEY]);
    }

    _dispatch(setIdle());
  }

  function setChannelData(data) {
    var channels = [];
    data.filter((i) => i.channelName.match(/Livechat/i)).map((d, index) => {
      var channel = {
        channelName: d.channelName,
        name: d.channelDescription,
        description: "",
        actionUrl: d.cta,
        image: d.channelImage,
        attachment: d.attachment,
      };

      channels.push(channel);
    });
    setChannels(channels);
  }

  async function GoToFaq(
    slug,
    sub_slug,
    faq,
    category_id,
    sub_category_id,
    faq_id

  ) {
    if (sub_slug) {
      _history.push({
        pathname: WebUrl._URL_HELP_CENTRE_FAQ
          .replace(":category", slug)
          .replace(":sub_category", sub_slug)
          .replace(":faq", faq),
        state: {
          category_id: category_id,
          sub_category_id: sub_category_id,
          faq_id: faq_id,
        },
      });
    }
  }


  async function GoToSubCategory(slug, sub_slug, category_id, sub_category_id) {
    if (slug) {
      _history.push({
        pathname: WebUrl._URL_HELP_CENTRE_SUB_CATEGORY
          .replace(":category", slug)
          .replace(":sub_category", sub_slug),
        state: {
          category_id: category_id,
          sub_category_id: sub_category_id,
        },
      });
    }
  }

  async function enterGame(gameId) {
    if (!window.ReactNativeWebView) {
      window.open(
        `${process.env.REACT_APP_ENDPOINT_URL || ""}${ApiUrl._API_START_GAME
        }?gameId=${gameId}&isApp=0&device=d`
      );
    } else {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({
          url:
            window.location.origin +
            WebUrl._URL_GAME_PAGE +
            "?gameId=" +
            gameId,
        })
      );
    }
  }

  return (
    <>
      <div className="right-information-component-container">

        {commButton && (
          <>
            <div className="custom-box-with-icon-side mb-4" onClick={() => _history.push(WebUrl._URL_MEMBER_PRODUCT_RATE_DETAIL)}>
              <div className="first">{t("MEMBER_PRODUCT_RATE_%")}</div>
              <div className="second">
                <img
                  src="/img/version3/arrow-right.png"
                  alt="arrow"
                />
              </div>
            </div>
          </>
        )}

        {info && (
          <>
            <div className="right-information-box mb-4">
              <h2 className="title-with-blue-under mb-3">{t("WALLET_INFO")}</h2>

              {infoData &&
                infoData.length &&
                map(infoData, (item, index) => {
                  return (
                    <div className="right-custom-info-item">
                      <div>{item.productName}</div>
                      <div>{numberWithCurrencyFormat(parseFloat(item.balance), 2)}</div>
                    </div>
                  );
                })}
            </div>
          </>
        )}

        {rewardInfo && (
          <>
            <div className="right-information-box mb-4">
              <h2 className="title-with-blue-under mb-3">{t("REWARD_INFO")}</h2>

              {categoryData &&
                categoryData.length &&
                map(categoryData, (item, index) => {
                  return (
                    <div className="right-custom-info-item">
                      <div>{t(item.originalTitle)}</div>
                      {item.originalTitle == "All" ? numberWithCurrencyFormat(grandTotalReward, 2, true) :
                        numberWithCurrencyFormat(gameCategoryData[item.originalTitle] ? gameCategoryData[item.originalTitle]['totalAmount'] : "0", 2, true)}

                    </div>
                  );
                })}
            </div>
          </>
        )}

        {grandTotal && (
          <>
            <div className="right-information-box mb-4">
                  <h2 className="title-with-blue-under mb-4">
                    {t("GRAND_TOTAL_REWARD_CLAIMABLE")}
                    {grandTotalAmountTitle && (
                      <div className="mt-2 text-white">{t("MYR")} {grandTotalAmountTitle}</div>
                    )}
                  </h2>
                   
              {grandTotalData &&
                grandTotalData.length &&
                map(grandTotalData, (item, index) => {
                  return (
                    <div className="right-custom-info-item text-white">
                      <div>{t(item.label)}</div>
                      <div>{t("MYR")} {item.value}</div>
                    </div>
                  );
                })}
            </div>
          </>
        )}

        {newDownlines && newDownlinesData.length > 0 && (
          <>
            <div className="right-information-box mb-4">
              <h2 className="title-with-blue-under mb-3">
                {t("NEW_DOWNLINES_MEMBER")}
              </h2>
              {newDownlinesData &&
                newDownlinesData.length &&
                map(newDownlinesData, (item, index) => {
                  return (
                    <div className="right-custom-downline-item">
                      <div className="first">
                        <div>
                          <img
                            src="/img/version3/user.png"
                            className="img-responsive"
                            alt={item.username}
                          />
                        </div>
                        <div>
                          <div className="title">{item.username}</div>
                          <div className="id">{item.phoneNumber}</div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              {newDownlinesData.length > 0 &&
                <div className="right-custom-recent-view-btn" onClick={() => _history.push(WebUrl._URL_DOWNLINE)}>
                <a href="">{t("VIEW_ALL")}</a>
              </div>
              }
            </div>
          </>
        )}  

        {redemption && _redemptionHistory.length > 0 && (
          <>
            <div className="right-information-box mb-4">
              <h2 className="title-with-blue-under mb-3">
                Redemption History
              </h2>

              

              {_redemptionHistory &&
                _redemptionHistory.length &&
                map(_redemptionHistory, (item, index) => {
                  return (
                    <>
                      {moment(item.redeemTime).format('DD-MM-YYYY') == moment().format('DD-MM-YYYY') ? (
                        <>
                          {
                            index === 0 &&
                            <>
                              <div className="right-downline-today mb-3">{t("TODAY")}</div>
                            </>

                          }
                        </>
                      ) :
                        (
                          <>
                            {
                              index === 0 ? (
                                <>
                                 <div className="right-downline-today mb-3">{moment(item.redeemTime).format('DD MMM')}</div>
                                    
                                </>) :
                                (
                                  <>
                                    {moment(item.redeemTime).format('DD-MM-YYYY') != moment(_redemptionHistory[index - 1].redeemTime).format('DD-MM-YYYY') &&
                                      <>
                                        <div className="right-downline-today mb-3">{moment(item.redeemTime).format('DD MMM')}</div>
                                      </>
                                    }
                                  </>
                                )
                            }
                            
                          </>
                        )

                      }
                      <div className="right-custom-redemption-item">
                        <div>
                          <img
                            src={item.redeemData.itemImage}
                            className="img-responsive"
                            alt="voucher"
                          />
                        </div>
                        <div>
                          <div className="title">{item.redeemData.itemName}</div>
                          <div className="date">{item.redeemData.itemId} | {moment(item.redeemTime).format('DD-MM-YYYY')}</div>
                          {item.redeemData.itemReceived && (
                            <>
                              <div className="font-12 color-main mt-1">
                                Redeemed
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </>
                  );
                })}
            </div>
          </>
        )}

        {button && (
          <>
            <div className="custom-box-with-icon-side mb-4" onClick={() => buttonText == "REWARDS_TNC" ? _history.push(WebUrl._URL_REWARDS_TNC) : _history.push(WebUrl._URL_MAIN_WALLET)}>
              <div className="first">{t(buttonText)}</div>
              <div className="second">
                <img
                  src="/img/version3/arrow-right.png"
                  alt="arrow"
                />
              </div>
            </div>
          </>
        )}

        {helpCentreList && (
          <>
            <div className="right-information-box mb-4">
              {_helpCentreData &&
                _helpCentreData.length > 0 &&
                _helpCentreData.filter((x) => !stringIsNullOrEmpty(x.publishedAt)).map((item, index) => {
                  return (
                    <>
                      <h2 className="title-with-blue-under ">{item.title}</h2>
                      {item.sub_categories.filter((x) => !stringIsNullOrEmpty(x.publishedAt) && ((!x.slug.includes("_")) || x.slug.match("_myr"))).map((item1, index1) => {
                        return (
                          <div style={{ marginBottom: "10px" }}>
                            {item1.faqs.length == 0 ? (
                              <>
                                <div className={item1.slug === helpCentreFaqs ? "helpcentre-active" : ""}>
                                  <div className="sub-sub-title cursor-global " onClick={() =>
                                    GoToSubCategory(
                                      item.slug,
                                      item1.slug,
                                      item.category_id,
                                      item1.category_id
                                    )
                                  }>{item1.Title}</div></div>
                              </>
                            ) :
                              <>
                                {item1.faqs.filter((x) => !stringIsNullOrEmpty(x.publishedAt)).length > 0 && (
                                  <>
                                    {
                                      item1.faqs.filter((x) => !stringIsNullOrEmpty(x.publishedAt) && ((!x.slug.includes("_")) || x.slug.match("_myr"))).map((item2, index2) => {
                                        return (
                                          <div style={{ marginBottom: "10px" }}>
                                            {!isNull(item2.publishedAt) &&
                                              <div onClick={() =>
                                                GoToFaq(
                                                  item1.category.slug,
                                                  item1.slug,
                                                  item2.slug,
                                                  item1.category.category_id,
                                                  item1.sub_category_id,
                                                  item2.faq_id
                                                )
                                              }>
                                                <div className={item2.slug === helpCentreFaqs ? "helpcentre-active" : ""}>
                                                  <div className="sub-sub-title cursor-global">{item2.Title}</div></div>
                                              </div>
                                            }
                                          </div>
                                        )
                                      })
                                    }
                                  </>
                                )
                                }
                              </>
                            }
                          </div>
                        )
                      })}
                    </>
                  )
                })}
            </div>
          </>
        )}

        


        {/*{promotion && (*/}
        {/*      <>*/}
        {/*            <img*/}
        {/*      src="/img/version3/main-wallet/promotion.png"*/}
        {/*      className="w-100 mb-4"*/}
        {/*              alt="play"*/}
        {/*            />*/}
        {/*      </>*/}
        {/*)}*/}

        {buttonTwo && (
          <>
            <div className="custom-box-with-icon-side mb-4 right-button-two" onClick={() => (buttonTwoText == "REFERRAL_GROUPS" ? _history.push(WebUrl._URL_REFERRAL) : _history.push(WebUrl._URL_DOWNLINE))}>
              <div className="quantity">{buttonTwoQuantity}</div>
              <div className="first text-white">{t(buttonTwoText)}</div>
              <div className="second">
                <img
                  src="/img/version3/next-blue.png"
                  alt="arrow"
                />
              </div>
            </div>
          </>
        )}

        {promotion && promoBannerList.length > 0 ? <div>
          <Slider {...promoBannerSlick} className="mobile-promo-slider mb-4">
            {promoBannerList.map((banner, index) => {
              return (
                <div key={index} className={"mobile-promo-banner promo-" + (index + 1)} onClick={() => _history.push(WebUrl._URL_PROMOTION)}>
                  <img className="mobile-promo-img" src={banner.image} />
                </div>
              )
            })}
          </Slider>
        </div> : <></>}

        {recent && (
          <>
            <div className="right-information-box mb-4">
              {recentTitle && (
                <>
                  <h2 className="title-with-blue-under mb-3">{t(recentTitle)}</h2>
                </>
              )}

              {recentData &&
                recentData.length ? (
                map(recentData, (item, index) => {
                  return (
                    <div className="right-custom-recent-item">
                      <div className="right-custom-recent-box">
                        {(item.status == 0 || item.status == 1 || item.status == 2) && (
                          <div className="first">
                            <div>
                              <img
                                src="/img/version3/main-wallet/processing.png"
                                className="img-responsive"
                                alt="search"
                              />
                            </div>
                            <div>
                              <div className="title mb-1">{t("PROCESSING")}</div>
                              <div className="date">{item.id} | {moment(item.date).format('DD-MM-YYYY')}</div>
                            </div>
                          </div>
                        )}
                        {(item.status == 3 || item.status == 5) && (
                          <div className="first">
                            <div>
                              <img
                                src="/img/version3/main-wallet/claimed.png"
                                className="img-responsive"
                                alt="search"
                              />
                            </div>
                            <div>
                              <div className="title">{t("APPROVED")}</div>
                              <div className="date">{item.id} | {moment(item.date).format('DD-MM-YYYY')}</div>
                            </div>
                          </div>
                        )}
                        {item.status == 4 && (
                          <div className="first">
                            <div>
                              <img
                                src="/img/version3/main-wallet/claimed.png"
                                className="img-responsive"
                                alt="search"
                                style={{ color: 'red' }}
                              />
                            </div>
                            <div>
                              <div className="title">{t("REJECTED")}</div>
                              <div className="date">{item.id} | {moment(item.date).format('DD-MM-YYYY')}</div>
                            </div>
                          </div>
                        )}
                        {item.amount && (
                          <>
                            <div className="second">$ {item.amount}</div>
                          </>
                        )}
                      </div>
                    </div>
                  );
                })) : 
                (
                  <div style={{ fontSize:'11px' }}>
                    {t("NO_DATA_FOUND")}
                  </div>
                )}
              {recentData.length > 0 && 
                <div className="right-custom-recent-view-btn" onClick={() => recentTitle == "RECENT_REWARD_CLAIMABLE" ? _history.push(WebUrl._URL_REWARD_HISTORY) : _history.push(WebUrl._URL_MAIN_WALLET)}>
                  <a>{t("VIEW_ALL")}</a>
                </div>
              }
            </div>
          </>
        )}

        {claimHistory && (
          <>
            <div className="right-information-box mb-4">
              {claimHistoryTitle && (
                <>
                  <h2 className="title-with-blue-under mb-3">
                    {t(claimHistoryTitle)}
                  </h2>
                </>
              )}

              {claimHistoryData &&
                claimHistoryData.length ?
                map(claimHistoryData, (item, index) => {
                  return (
                    <div className="right-custom-recent-item mb-3 pb-3">
                      <div className="right-custom-recent-box">
                        {item.netProfit >= 0 && (
                          <div className="first">
                            <div>
                              <img
                                src="/img/version3/main-wallet/claimed.png"
                                className="img-responsive"
                                alt="claimed"
                              />
                            </div>
                            <div>
                              <div className="title mb-0">{moment(item.date).format("YYYY-MM-DD")}</div>
                            </div>
                          </div>
                        )}
                        {item.netProfit < 0 && (
                          <div className="first">
                            <div>
                              <img
                                src="/img/version3/main-wallet/cancel.png"
                                className="img-responsive"
                                alt="cancel"
                              />
                            </div>
                            <div>
                              <div className="title mb-0">{moment(item.date).format("YYYY-MM-DD")}</div>
                            </div>
                          </div>
                        )}
                        {item.netProfit && (
                          <>
                            <div
                              className={
                                item.netProfit < 0
                                  ? "second color-red"
                                  : "second color-green"
                              }
                            >
                              {item.netProfit}
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  );
                })
                :
                <>
                  {t("NO_DATA_FOUND")}
                </>  
              }
              {claimHistoryData.length > 0 &&  
                <div className="right-custom-recent-view-btn" onClick={() => _history.push(WebUrl._URL_CLAIM_HISTORY)}>
                  <a>{t("VIEW_ALL")}</a>
                </div>
              }
            </div>
          </>
        )}

        {unclaimedBalance && (
          <>
            <div className="right-information-box mb-4">
              <h2 className="title-no-blue mb-2">
                {t("CURRENT_UNCLAIMED_BALANCE")}
              </h2>
              <div className="mt-2 color-main font-14">
                GEM {unclaimedBalanceAmount}
              </div>
            </div>
          </>
        )}

        {unclaimedSummary && (
          <>
            <div className="right-information-box mb-4">
              <h2 className="title-with-blue-under mb-3">{t("UNCLAIMED_SUMMARY")}</h2>
              {unclaimedSummaryData &&
                unclaimedSummaryData.length ?
                map(unclaimedSummaryData.slice(0, 4),(item, index) => {
                  return (
                    <div className="right-custom-recent-item mb-3 pb-3">
                      <div className="right-custom-recent-box">
                        <div className="first">
                          <div>
                            <div className="title mb-0 color-gray">
                              {t("MY_SALES")} - {item.ownSales}
                            </div>
                          </div>
                        </div>
                        <div className="unclaimed-mini-tag">Unclaimed</div>
                      </div>
                    </div>
                  );
                })
                :
                <>
                  {t("NO_DATA_FOUND")}
                </>
                
              }
              {unclaimedSummaryData.length > 0 &&  
                <div className="right-custom-recent-view-btn" onClick={() => _history.push(WebUrl._URL_VIP_CLAIM)}>
                  <a>{t("VIEW_ALL")}</a>
                </div>
              }
            </div>
          </>
        )}

        {shareHolder && (
          <>
            <div className="right-information-box mb-4">
              <h2 className="title-with-blue-under mb-3">
                {t("SHAREHOLDERS")}
                <div className="mt-2 text-white">
                  {shareHolderMembers} {t("MEMBER")}
                </div>
              </h2>
              <h2 className="title-no-blue mb-0">
                {t("NON_SHAREHOLDERS")}
                <div className="mt-2 text-white">
                  <span className="color-main">{nonShareHolderMembers} </span>
                  {t("MEMBER")}
                </div>
              </h2>
            </div>
          </>
        )}

        {companySummary && (
          <>
            <div className="right-information-box mb-4">
              <h2 className="title-no-blue mb-2">{t("COMPANY_SUMMARY")}</h2>
              <div className="mt-2 text-white font-14">
                {t("GRAND_TOTAL")} {" "} - {" "}
                <span className="color-main">
                   {companySummaryGrandTotal}{" "}
                </span>
              </div>
              <div className="hr-blue mt-2 mb-2" style={{ maxWidth: 50 }}></div>
              <h2 className="title-no-blue mb-2">{t("MY_INCOME")}</h2>
              <div className="mt-2 text-white font-14">
                {t("NET_PROFIT")} -
                <span className="color-main"> {companySummaryNetProfit !== undefined ? numberWithCurrencyFormat(parseFloat(companySummaryNetProfit), 3, true) : 0} </span>
              </div>
              <div className="mt-2 text-white font-14">
                {t("VALID_TURNOVER")} - 
                <span className="color-main"> {companySummaryTurnover !== undefined ? numberWithCurrencyFormat(parseFloat(companySummaryTurnover), 3, true) : 0} </span>
              </div>
            </div>
          </>
        )}

        {shareHolderTotalUnclaimed && (
          <>
            <div className="custom-box-with-icon-side mb-4 " onClick={() => _history.push(WebUrl._URL_VIP_CLAIM)}>
              <div className="first">{t("TOTAL_UNCLAIMED")}</div>
              <div className="second text-red">
                {shareHolderTotalUnclaimedDetails}
              </div>
            </div>
          </>
        )}

        {games && (
          <>
            <div className="right-information-box mb-4">
              <h2 className="title-with-blue-under mb-3">{t("LATEST_GAME")}</h2>

              {gamesData &&
                gamesData.length > 0 &&
                gamesData.map((item, index) => {
                  return (
                    <div className="right-custom-game-item cursor-global" onClick={() => { 
                      enterGame(item.id)
                    }}>
                      <div className="first">
                        <div style={{ width: '40px' }}>
                          <img
                            src={item.image}
                            className="img-responsive"
                            alt={item.title}
                          />
                        </div>
                        <div>
                          <div className="title">{item.title}</div>
                          <div className="provider">{item.provider}</div>
                        </div>
                      </div>
                      <div className="second">
                        <img
                          src="/img/version3/main-wallet/play.png"
                          className="img-responsive"
                          alt="play"
                        />
                      </div>
                    </div>
                  );
                })}
            </div>
          </>
        )}

        {helpCentre && (
          <>
            <div className="right-information-box mb-4">
              <h2 className="title-with-blue-under mb-3">{t("OTHER_WAY_TO_GET_HELP")}</h2>

              {channels &&
                channels.map((channel, index) => {
                  return (
                    <div className="right-custom-way-item cursor-global" onClick={() => {
                      window.open(channel.actionUrl, "_blank", 'live-chat')
                    }}>
                      <div className="first">
                        <div key={index}>
                          <div className="title">{t("LIVE_CHAT")}</div>
                        </div>
                      </div>
                      <div className="second">
                        <img
                          src="/img/version3/next-gray.png"
                          className="img-responsive img1"
                          alt="accordion"
                        />
                      </div>
                    </div>
                  )
                })
              }
              {isLoggedIn &&
                <div className="right-custom-way-item cursor-global" onClick={() =>
                  _history.push({
                    pathname: WebUrl._URL_CONTACT_US,
                  })
                }>
                  <div className="first">
                    <div>
                      <div className="title">{t("CONTACT")}</div>
                    </div>
                  </div>
                  <div className="second">
                    <img
                      src="/img/version3/next-gray.png"
                      className="img-responsive img1"
                      alt="accordion"
                    />
                  </div>
                </div>
                }
            </div>
          </>
        )}
        {buttonEditReferral &&
          !stringIsNullOrEmpty(referralCodeId) &&
          !stringIsNullOrEmpty(referralCategories) &&
          !stringIsNullOrEmpty(referralCodeDetail) && (
          <>
            <div className="custom-box-with-icon-side mb-4 right-button-two">
              <Link to={{
                pathname: WebUrl._URL_REFERRAL_EDIT,
                state: {
                  id: referralCodeId,
                  availableCategories: referralCategories,
                  referralCodeDetail: referralCodeDetail,
                }
              }}
                style={{ display: 'flex' }}
              >
                <div className="first" style={{ color:'#fff' } }>{t("EDIT_REFERRAL_DETAIL")}</div>
                <div className="second" style={{ marginLeft: '115px' }}>
                  <img
                    src="/img/version3/next-gray.png"
                    alt="arrow"
                  />
                </div>
              </Link>
            </div>
          </>
          )}

        {buttonShareReferral &&
          (
          <>
            <div className="custom-box-with-icon-side mb-4 right-button-two" onClick={() => setShowShareModal(true) }>
              <div className="first">{t("SHARE")} {t("REFERRAL_CODE")}</div>
                <div className="second">
                  <img
                    src="/img/version3/next-gray.png"
                    alt="arrow"
                  />
                </div>
            </div>
          </>
        )
        }
        

      </div>
      <div className={"right-floating-icon-box " + (rightModalVisible ? 'active' : '')} onClick={() => setRightModalVisible(!rightModalVisible)}>
        <img
          src="/img/version3/float_button.png"
          className="img-responsive"
          alt="i8"
        />
      </div>
      <Modal
        id="custom-modal-small"
        className="right-custom-modal"
        fade={true}
        isOpen={rightModalVisible}
        contentClassName="modal-brand modal-bottom"
        toggle={() => {
          setRightModalVisible(false);
        }}
        
      >
        <ModalBody>
          {info && (
            <>
              <div className="right-information-box mb-4">
                <h2 className="title-with-blue-under mb-3">{t("WALLET_INFO")}</h2>

                {infoData &&
                  infoData.length &&
                  map(infoData, (item, index) => {
                    return (
                      <div className="right-custom-info-item">
                        <div>{item.productName}</div>
                        <div>{numberWithCurrencyFormat(parseFloat(item.balance), 2)}</div>
                      </div>
                    );
                  })}
              </div>
            </>
          )}

          {rewardInfo && (
            <>
              <div className="right-information-box mb-4">
                <h2 className="title-with-blue-under mb-3">{t("REWARD_INFO")}</h2>

                {categoryData &&
                  categoryData.length &&
                  map(categoryData, (item, index) => {
                    return (
                      <div className="right-custom-info-item">
                        <div>{t(item.originalTitle)}</div>
                        {item.originalTitle == "All" ? numberWithCurrencyFormat(grandTotalReward, 2, true) :
                          numberWithCurrencyFormat(gameCategoryData[item.originalTitle] ? gameCategoryData[item.originalTitle]['totalAmount'] : "0", 2, true)}

                      </div>
                    );
                  })}
              </div>
            </>
          )}

          {grandTotal && (
            <>
              <div className="right-information-box mb-4">
                    <h2 className="title-with-blue-under mb-3">
                      {t("GRAND_TOTAL_REWARD_CLAIMABLE")}
                      {grandTotalAmountTitle && (
                    <div className="mt-2">{t("MYR")} {grandTotalAmountTitle}</div>
                      )}
                    </h2>

                {grandTotalData &&
                  grandTotalData.length &&
                  map(grandTotalData, (item, index) => {
                    return (
                      <div className="right-custom-info-item text-white">
                        <div>{item.label}</div>
                        <div>{item.value}</div>
                      </div>
                    );
                  })}
              </div>
            </>
          )}

          {newDownlines && newDownlinesData.length > 0  && (
            <>
              <div className="right-information-box mb-4">
                <h2 className="title-with-blue-under mb-3">
                  {t("NEW_DOWNLINES_MEMBER")}
                </h2>
                {newDownlinesData &&
                  newDownlinesData.length &&
                  map(newDownlinesData, (item, index) => {
                    return (
                      <div className="right-custom-downline-item">
                        <div className="first">
                          <div>
                            <img
                              src="/img/version3/user.png"
                              className="img-responsive"
                              alt={item.username}
                            />
                          </div>
                          <div>
                            <div className="title">{item.username}</div>
                            <div className="id">{item.phoneNumber}</div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                {newDownlinesData.length > 0 &&
                  <div className="right-custom-recent-view-btn" onClick={() => _history.push(WebUrl._URL_DOWNLINE)}>
                    <a href="">{t("VIEW_ALL")}</a>
                  </div>
                }
              </div>
            </>
          )}

          {redemption && _redemptionHistory.length > 0 && (
            <>
              <div className="right-information-box mb-4">
                <h2 className="title-with-blue-under mb-3">
                  Redemption History
                </h2>



                {_redemptionHistory &&
                  _redemptionHistory.length &&
                  map(_redemptionHistory, (item, index) => {
                    return (
                      <>
                        {moment(item.redeemTime).format('DD-MM-YYYY') == moment().format('DD-MM-YYYY') ? (
                          <>
                            {
                              index === 0 &&
                              <>
                                <div className="right-downline-today mb-3">{t("TODAY")}</div>
                              </>

                            }
                          </>
                        ) :
                          (
                            <>
                              {
                                index === 0 ? (
                                  <>
                                    <div className="right-downline-today mb-3">{moment(item.redeemTime).format('DD MMM')}</div>

                                  </>) :
                                  (
                                    <>
                                      {moment(item.redeemTime).format('DD-MM-YYYY') != moment(_redemptionHistory[index - 1].redeemTime).format('DD-MM-YYYY') &&
                                        <>
                                          <div className="right-downline-today mb-3">{moment(item.redeemTime).format('DD MMM')}</div>
                                        </>
                                      }
                                    </>
                                  )
                              }

                            </>
                          )

                        }
                        <div className="right-custom-redemption-item">
                          <div>
                            <img
                              src={item.redeemData.itemImage}
                              className="img-responsive"
                              alt="voucher"
                            />
                          </div>
                          <div>
                            <div className="title">{item.redeemData.itemName}</div>
                            <div className="date">{item.redeemData.itemId} | {moment(item.redeemTime).format('DD-MM-YYYY')}</div>
                            {item.redeemData.itemReceived && (
                              <>
                                <div className="font-12 color-main mt-1">
                                  Redeemed
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      </>
                    );
                  })}
              </div>
            </>
          )}

          {button && (
            <>
              <div className="custom-box-with-icon-side mb-4" onClick={() => buttonText == "REWARDS_TNC" ? _history.push(WebUrl._URL_REWARDS_TNC) : _history.push(WebUrl._URL_MAIN_WALLET)}>
                <div className="first">{t(buttonText)}</div>
                <div className="second">
                  <img
                    src="/img/version3/arrow-right.png"
                    alt="arrow"
                  />
                </div>
              </div>
            </>
          )}

          {helpCentreList && (
            <>
              <div className="right-information-box mb-4">
                {_helpCentreData &&
                  _helpCentreData.length > 0 &&
                  _helpCentreData.filter((x) => !stringIsNullOrEmpty(x.publishedAt)).map((item, index) => {
                    return (
                      <>
                        <h2 className="title-with-blue-under ">{item.title}</h2>
                        {item.sub_categories.filter((x) => !stringIsNullOrEmpty(x.publishedAt) && ((!x.slug.includes("_")) || x.slug.match("_myr"))).map((item1, index1) => {
                          return (
                            <div style={{ marginBottom: "10px" }}>
                              {item1.faqs.length == 0 ? (
                                <>
                                  <div className={item1.slug === helpCentreFaqs ? "helpcentre-active" : ""}>
                                    <div className="sub-sub-title cursor-global " onClick={() =>
                                      GoToSubCategory(
                                        item.slug,
                                        item1.slug,
                                        item.category_id,
                                        item1.category_id
                                      )
                                    }>{item1.Title}</div></div>
                                </>
                              ) :
                                <>
                                  {item1.faqs.filter((x) => !stringIsNullOrEmpty(x.publishedAt)).length > 0 && (
                                    <>
                                      {
                                        item1.faqs.filter((x) => !stringIsNullOrEmpty(x.publishedAt) && ((!x.slug.includes("_")) || x.slug.match("_myr"))).map((item2, index2) => {
                                          return (
                                            <div style={{ marginBottom: "10px" }}>
                                              {!isNull(item2.publishedAt) &&
                                                <div onClick={() =>
                                                  GoToFaq(
                                                    item1.category.slug,
                                                    item1.slug,
                                                    item2.slug,
                                                    item1.category.category_id,
                                                    item1.sub_category_id,
                                                    item2.faq_id
                                                  )
                                                }>
                                                  <div className={item2.slug === helpCentreFaqs ? "helpcentre-active" : ""}>
                                                    <div className="sub-sub-title cursor-global">{item2.Title}</div></div>
                                                </div>
                                              }
                                            </div>
                                          )
                                        })
                                      }
                                    </>
                                  )
                                  }
                                </>
                              }
                            </div>
                          )
                        })}
                      </>
                    )
                  })}
              </div>
            </>
          )}

          
          {/*{promotion && (*/}
          {/*      <>*/}
          {/*            <img*/}
          {/*      src="/img/version3/main-wallet/promotion.png"*/}
          {/*      className="w-100 mb-4"*/}
          {/*              alt="play"*/}
          {/*            />*/}
          {/*      </>*/}
          {/*)}*/}

          {buttonTwo && (
            <>
              <div className="custom-box-with-icon-side mb-4 right-button-two" onClick={() => (buttonTwoText == "REFERRAL_GROUPS" ? _history.push(WebUrl._URL_REFERRAL) : _history.push(WebUrl._URL_DOWNLINE))}>
                <div className="quantity">{buttonTwoQuantity}</div>
                <div className="first text-white">{t(buttonTwoText)}</div>
                <div className="second">
                  <img
                    src="/img/version3/next-blue.png"
                    alt="arrow"
                  />
                </div>
              </div>
            </>
          )}

          {promotion && promoBannerList.length > 0 ? <div>
            <Slider {...promoBannerSlick} className="mobile-promo-slider mb-4">
              {promoBannerList.map((banner, index) => {
                return (
                  <div key={index} className={"mobile-promo-banner promo-" + (index + 1)} onClick={() => _history.push(WebUrl._URL_PROMOTION)}>
                    <img className="mobile-promo-img" src={banner.image} />
                  </div>
                )
              })}
            </Slider>
          </div> : <></>}

          {recent && (
            <>
              <div className="right-information-box mb-4">
                {recentTitle && (
                  <>
                    <h2 className="title-with-blue-under mb-3">
                      {t(recentTitle)}
                    </h2>
                  </>
                )}

                {recentData &&
                  recentData.length &&
                  map(recentData, (item, index) => {
                    return (
                      <div className="right-custom-recent-item">
                        <div className="right-custom-recent-box">
                          {(item.status == 0 || item.status == 1 || item.status == 2) && (
                            <div className="first">
                              <div>
                                <img
                                  src="/img/version3/main-wallet/processing.png"
                                  className="img-responsive"
                                  alt="search"
                                />
                              </div>
                              <div>
                                <div className="title mb-1">{t("PROCESSING")}</div>
                                <div className="date">{item.id} | {moment(item.date).format('DD-MM-YYYY')}</div>
                              </div>
                            </div>
                          )}
                          {(item.status == 3 || item.status == 5) && (
                            <div className="first">
                              <div>
                                <img
                                  src="/img/version3/main-wallet/claimed.png"
                                  className="img-responsive"
                                  alt="search"
                                />
                              </div>
                              <div>
                                <div className="title">{t("APPROVED")}</div>
                                <div className="date">{item.id} | {moment(item.date).format('DD-MM-YYYY')}</div>
                              </div>
                            </div>
                          )}
                          {item.status == 4 && (
                            <div className="first">
                              <div>
                                <img
                                  src="/img/version3/main-wallet/claimed.png"
                                  className="img-responsive"
                                  alt="search"
                                  style={{ color: 'red' }}
                                />
                              </div>
                              <div>
                                <div className="title">{t("REJECTED")}</div>
                                <div className="date">{item.id} | {moment(item.date).format('DD-MM-YYYY')}</div>
                              </div>
                            </div>
                          )}
                          {item.amount && (
                            <>
                              <div className="second">$ {numberWithCurrencyFormat2(item.amount, 2, true)}</div>
                            </>
                          )}
                        </div>
                      </div>
                    );
                  })}
                {recentData.length > 0 &&
                  <div className="right-custom-recent-view-btn" onClick={() => recentTitle == "RECENT_REWARD_CLAIMABLE" ? _history.push(WebUrl._URL_REWARD_HISTORY) : _history.push(WebUrl._URL_MAIN_WALLET)}>
                    <a>{t("VIEW_ALL")}</a>
                  </div>
                }
              </div>
            </>
          )}

          {claimHistory && (
            <>
              <div className="right-information-box mb-4">
                {claimHistoryTitle && (
                  <>
                    <h2 className="title-with-blue-under mb-3">
                      {t(claimHistoryTitle)}
                    </h2>
                  </>
                )}

                {claimHistoryData &&
                  claimHistoryData.length ?
                  map(claimHistoryData, (item, index) => {
                    return (
                      <div className="right-custom-recent-item mb-3 pb-3">
                        <div className="right-custom-recent-box">
                          {item.netProfit >= 0 && (
                            <div className="first">
                              <div>
                                <img
                                  src="/img/version3/main-wallet/claimed.png"
                                  className="img-responsive"
                                  alt="claimed"
                                />
                              </div>
                              <div>
                                <div className="title mb-0">{moment(item.date).format("YYYY-MM-DD")}</div>
                              </div>
                            </div>
                          )}
                          {item.netProfit < 0 && (
                            <div className="first">
                              <div>
                                <img
                                  src="/img/version3/main-wallet/cancel.png"
                                  className="img-responsive"
                                  alt="cancel"
                                />
                              </div>
                              <div>
                                <div className="title mb-0">{moment(item.date).format("YYYY-MM-DD")}</div>
                              </div>
                            </div>
                          )}
                          {item.netProfit && (
                            <>
                              <div
                                className={
                                  item.netProfit < 0
                                    ? "second color-red"
                                    : "second color-green"
                                }
                              >
                                {item.netProfit}
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    );
                  })
                  :
                  <>
                    {t("NO_DATA_FOUND")}
                  </>
                }
                {claimHistoryData.length > 0 &&
                  <div className="right-custom-recent-view-btn" onClick={() => _history.push(WebUrl._URL_CLAIM_HISTORY)}>
                    <a>{t("VIEW_ALL")}</a>
                  </div>
                }
              </div>
            </>
          )}

          {unclaimedBalance && (
            <>
              <div className="right-information-box mb-4">
                <h2 className="title-no-blue mb-2">
                  {t("CURRENT_UNCLAIMED_BALANCE")}
                </h2>
                <div className="mt-2 color-main font-14">
                  GEM {unclaimedBalanceAmount}
                </div>
              </div>
            </>
          )}

          {unclaimedSummary && (
            <>
              <div className="right-information-box mb-4">
                <h2 className="title-with-blue-under mb-3">{t("UNCLAIMED_SUMMARY")}</h2>
                {unclaimedSummaryData &&
                  unclaimedSummaryData.length ?
                  map(unclaimedSummaryData.slice(0,4), (item, index) => {
                    return (
                      <div className="right-custom-recent-item mb-3 pb-3">
                        <div className="right-custom-recent-box">
                          <div className="first">
                            <div>
                              <div className="title mb-0 color-gray">
                                {t("MY_SALES")} - {item.ownSales}
                              </div>
                            </div>
                          </div>
                          <div className="unclaimed-mini-tag">Unclaimed</div>
                        </div>
                      </div>
                    );
                  })
                  :
                  <>
                    {t("NO_DATA_FOUND")}
                  </>

                }
                {unclaimedSummaryData.length > 0 &&
                  <div className="right-custom-recent-view-btn" onClick={() => _history.push(WebUrl._URL_VIP_CLAIM)}>
                    <a>{t("VIEW_ALL")}</a>
                  </div>
                }
              </div>
            </>
          )}

          {shareHolder && (
            <>
              <div className="right-information-box mb-4">
                <h2 className="title-with-blue-under mb-3">
                  {t("SHAREHOLDERS")}
                  <div className="mt-2 text-white">
                    {shareHolderMembers} {t("MEMBER")}
                  </div>
                </h2>
                <h2 className="title-no-blue mb-0">
                  {t("NON_SHAREHOLDERS")}
                  <div className="mt-2 text-white">
                    <span className="color-main">{nonShareHolderMembers} </span>
                    {t("MEMBER")}
                  </div>
                </h2>
              </div>
            </>
          )}

          {companySummary && (
            <>
              <div className="right-information-box mb-4">
                <h2 className="title-no-blue mb-2">{t("COMPANY_SUMMARY")}</h2>
                <div className="mt-2 text-white font-14">
                  {t("GRAND_TOTAL")} {" "} -  {" "}
                  <span className="color-main">
                    {companySummaryGrandTotal}{" "}
                  </span>
                </div>
                <div className="hr-blue mt-2 mb-2" style={{ maxWidth: 50 }}></div>
                <h2 className="title-no-blue mb-2">{t("MY_INCOME")}</h2>
                <div className="mt-2 text-white font-14">
                  {t("NET_PROFIT")} -
                  <span className="color-main"> {companySummaryNetProfit !== undefined ? numberWithCurrencyFormat(parseFloat(companySummaryNetProfit), 3, true) : 0} </span>
                </div>
                <div className="mt-2 text-white font-14">
                  {t("VALID_TURNOVER")} -
                  <span className="color-main"> {companySummaryTurnover !== undefined ? numberWithCurrencyFormat(parseFloat(companySummaryTurnover), 3, true) : 0} </span>
                </div>
              </div>
            </>
          )}

          {shareHolderTotalUnclaimed && (
            <>
              <div className="custom-box-with-icon-side mb-4 " onClick={() => _history.push(WebUrl._URL_VIP_CLAIM)}>
                <div className="first">{t("TOTAL_UNCLAIMED")}</div>
                <div className="second text-red">
                  {shareHolderTotalUnclaimedDetails}
                </div>
              </div>
            </>
          )}

          {games && (
            <>
              <div className="right-information-box mb-4">
                <h2 className="title-with-blue-under mb-3">{t("LATEST_GAME")}</h2>

                {gamesData &&
                  gamesData.length &&
                  map(gamesData, (item, index) => {
                    return (
                      <div className="right-custom-game-item cursor-global" onClick={() => {
                        enterGame(item.id)
                      }}>
                        <div className="first">
                          <div>
                            <img
                              src={item.image}
                              className="img-responsive"
                              alt={item.title}
                            />
                          </div>
                          <div>
                            <div className="title">{item.title}</div>
                            <div className="provider">{item.provider}</div>
                          </div>
                        </div>
                        <div className="second">
                          <img
                            src="/img/version3/main-wallet/play.png"
                            className="img-responsive"
                            alt="play"
                          />
                        </div>
                      </div>
                    );
                  })}
              </div>
            </>
          )}

          {helpCentre && (
            <>
              <div className="right-information-box mb-4">
                <h2 className="title-with-blue-under mb-3">{t("OTHER_WAY_TO_GET_HELP")}</h2>

                {channels &&
                  channels.map((channel, index) => {
                    return (
                      <div className="right-custom-way-item cursor-global" onClick={() => {
                        window.open(channel.actionUrl, "_blank", 'live-chat')
                      }}>
                        <div className="first">
                          <div key={index}>
                            <div className="title">{t("LIVE_CHAT")}</div>
                          </div>
                        </div>
                        <div className="second">
                          <img
                            src="/img/version3/next-gray.png"
                            className="img-responsive img1"
                            alt="accordion"
                          />
                        </div>
                      </div>
                    )
                  })
                }
                {isLoggedIn &&
                  <div className="right-custom-way-item cursor-global" onClick={() =>
                    _history.push({
                      pathname: WebUrl._URL_CONTACT_US,
                    })
                  }>
                    <div className="first">
                      <div>
                        <div className="title">{t("CONTACT")}</div>
                      </div>
                    </div>
                    <div className="second">
                      <img
                        src="/img/version3/next-gray.png"
                        className="img-responsive img1"
                        alt="accordion"
                      />
                    </div>
                  </div>
                }
              </div>
            </>
          )}

          {buttonEditReferral &&
            !stringIsNullOrEmpty(referralCodeId) &&
            !stringIsNullOrEmpty(referralCategories) &&
            !stringIsNullOrEmpty(referralCodeDetail) && (
              <>
                <div className="custom-box-with-icon-side mb-4 right-button-two">
                  <Link to={{
                    pathname: WebUrl._URL_REFERRAL_EDIT,
                    state: {
                      id: referralCodeId,
                      availableCategories: referralCategories,
                      referralCodeDetail: referralCodeDetail,
                    }
                  }}
                    style={{ display: 'flex' }}
                  >
                    <div className="first" style={{ color: '#898F97' }}>{t("EDIT_REFERRAL_DETAIL")}</div>
                    <div className="second" style={{ marginLeft: '115px' }}>
                      <img
                        src="/img/version3/next-gray.png"
                        alt="arrow"
                      />
                    </div>
                  </Link>
                </div>
              </>
            )}

          {buttonShareReferral &&
            (
              <>
                <div className="custom-box-with-icon-side mb-4 right-button-two" onClick={() => setShowShareModal(true)}>
                  <div className="first">{t("SHARE")} {t("REFERRAL_CODE")}</div>
                  <div className="second">
                    <img
                      src="/img/version3/next-gray.png"
                      alt="arrow"
                    />
                  </div>
                </div>
              </>
            )
          }

        </ModalBody>
      </Modal>

      <Modal
        isOpen={showShareModal}
        id="modal-small-custom"
        fade={true}
        toggle={() => {
          setShowShareModal(false);
        }}
        centered
      >
        <ModalBody>
          <div className="text-center">
            <h3 className="mb-4">{t("SHARE")} {t("REFERRAL_CODE")}</h3>
            <div className="ref-share">
              {buttonShareList}
            </div>
            <div className="mt-4">
              <button type="submit" className="btn custom-btn" onClick={() => setShowShareModal(false)}>{t("CLOSE")}</button>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

RightInformation.propTypes = {
  info: PropTypes.bool,
  recent: PropTypes.bool,
  games: PropTypes.bool,
  promotion: PropTypes.bool,
  button: PropTypes.bool,
  grandTotal: PropTypes.bool,
  newDownlines: PropTypes.bool,
  buttonTwo: PropTypes.bool,
  shareHolder: PropTypes.bool,
  shareHolderTotalUnclaimed: PropTypes.bool,
  companySummary: PropTypes.bool,
  claimHistory: PropTypes.bool,
  redemption: PropTypes.bool,
  unclaimedBalance: PropTypes.bool,
  unclaimedSummary: PropTypes.bool,
  helpCentre: PropTypes.bool,
  helpCentreList: PropTypes.bool,
  buttonEditReferral: PropTypes.bool,
  buttonShareReferral: PropTypes.bool,
  rewardInfo: PropTypes.bool,
  commButton: PropTypes.bool,

  infoTitle: PropTypes.string,
  recentTitle: PropTypes.string,
  gamesTitle: PropTypes.string,
  buttonText: PropTypes.string,
  grandTotalAmountTitle: PropTypes.string,
  //grandTotalTitle: PropTypes.string,
  //newDownlinesTitle: PropTypes.string,
  //buttonTwoQuantity: PropTypes.string,
  buttonTwoText: PropTypes.string,
  shareHolderMembers: PropTypes.string,
  nonShareHolderMembers: PropTypes.string,
  //shareHolderTotalUnclaimedTitle: PropTypes.string,
  //shareHolderTotalUnclaimedDetails: PropTypes.string,
  companySummaryGrandTotal: PropTypes.string,
  companySummaryNetProfit: PropTypes.string,
  companySummaryTurnover: PropTypes.string,
  claimHistoryTitle: PropTypes.string,
  unclaimedBalanceAmount: PropTypes.string,
  helpCentreSubCategory: PropTypes.string,
  helpCentreFaqs: PropTypes.string,
  buttonShareList: PropTypes.string,

  //infoData: PropTypes.array,
  recentData: PropTypes.array,
  //gamesData: PropTypes.array,
  grandTotalData: PropTypes.array,
  newDownlinesData: PropTypes.array,
  claimHistoryData: PropTypes.array,
  //redemptionData: PropTypes.array,
  unclaimedSummaryData: PropTypes.array,
  buttonReferralCode: PropTypes.array,
};

export default RightInformation;
