import React, { useEffect, useState } from "react";
import { PageSettings } from "../../config/page-settings.js";
import { _ROUTES, _LOGIN_ROUTE } from "../../config/page-route.jsx";
import LiveChat from "react-livechat";
import { useSelector } from "react-redux";
import { filter } from "lodash";
import { Route, useHistory, useLocation } from "react-router-dom";
import appsettings from "../../appsettings-fe.json";
// import Header from "components/header";
import Header from "components/header-v3";
import Footer from "components/footer/index.js";
import SideMenu from "components/side-menu";
import ScrollToTop from "./scrolltotop";

const Content = () => {
  const history = useHistory();
  const location = useLocation();
  const [routes, setRoutes] = useState([]);
  const [scrollTop, setScrollTop] = useState(0);
  const liveChatLicense = parseInt(
    appsettings.ApplicationConfig.liveChatLicense
  );
  const authState = useSelector((state) => state.authState);

  useEffect(() => {
    const scrollEvent = () => {
      window.addEventListener("scroll", (e) => {
        setScrollTop(e.target.documentElement.scrollTop);
      });
    };

    scrollEvent();

    return () => {
      //window.removeEventListener("scroll");
    };
  }, []);

  useEffect(() => {
    setRoutes(
      filter(
        _ROUTES,
        (route) =>
          authState.isLoggedIn ||
          !route.authRequired ||
          route.path == history.location.pathname
      )
    );
  }, [authState, location]);

  const PrivateRoute = (index, routeData) => {
    return (
      <Route
        key={index}
        exact={routeData.exact}
        path={routeData.path}
        component={routeData.component}
      />
    );
  };

  const headerClassName = () => {
    const { pathname } = location;

    if (pathname === "/") {
      return scrollTop > 150 ? "fixedme" : "";
    }
    if (pathname === "/download") {
      return "d-none";
    } else {
      return scrollTop > 150 ? "without-banner fixedme" : "without-banner";
    }
  };

  const { pathname } = location;

  if (pathname === "/landing") {
    return (
      <PageSettings.Consumer>

        {({ pageContentFullWidth, pageContentClass, pageContentInverseMode }) => (
          <div
            className={
              "content " +
              (pageContentFullWidth ? "content-full-width " : "") +
              (pageContentInverseMode ? "content-inverse-mode " : "") +
              pageContentClass
            }
          >
            {/* <Header className={headerClassName()} /> */}
            <div className="container container-theme-width">
              <div className="row">
                <div className="col-12">
                  {routes.map((route, index) => PrivateRoute(index, route))}
                </div>
              </div>
            </div>
          </div>
        )}
      </PageSettings.Consumer>
    );

  } else {
    return (
      <PageSettings.Consumer>

        {({ pageContentFullWidth, pageContentClass, pageContentInverseMode }) => (
          <div
            className={
              "content " +
              (pageContentFullWidth ? "content-full-width " : "") +
              (pageContentInverseMode ? "content-inverse-mode " : "") +
              pageContentClass
            }
          >
            {/* <Header className={headerClassName()} /> */}
            <Header />
            <ScrollToTop />
            <div className="container container-theme-width pb-5">
              <div className="row">
                <div className="col-12">
                  <div className="theme-container">
                    <div className="first-container">
                      <SideMenu />
                    </div>
                    <div className="second-container">
                      {routes.map((route, index) => PrivateRoute(index, route))}

                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="container container-theme-width pb-5">
              <div className="row">
                <div className="col-3"><SideMenu/></div>
                <div className="col-9">{routes.map((route, index) => PrivateRoute(index, route))}</div>
              </div>
            </div> */}

            <LiveChat license={liveChatLicense} />
            <Footer />
          </div>
        )}
      </PageSettings.Consumer>
    );
  }


};

export default Content;
