import React from "react";
import PropTypes from "prop-types";

const Result4D = ({ title, titleColor, color, icon, children }) => {
  return (
    <div className="col-sm-6 col-lg-4">
      <div
        className="color-wrapper result-wrapper col-md-12"
        style={{
          backgroundColor: color,
        }}
      >
        <div className="col-md-12 d-flex justify-content-between resultCell result-card-header">
          <div
            className="pool-name font-bold"
            style={{
              color: titleColor,
            }}
          >
            {title}
          </div>
          <div className="pool-name">
            <img
              style={{
                width: 44,
                height: 30,
              }}
              className="img-100"
              src={icon}
              alt="4D"
            />
          </div>
        </div>
        {children}
      </div>
    </div>
  );
};

Result4D.propTypes = {
  title: PropTypes.string.isRequired,
  titleColor: PropTypes.string,
  color: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  children: PropTypes.element.isRequired,
};

Result4D.defaultProps = {
  titleColor: "#ffffff",
};

export default Result4D;
