import React, { useEffect } from 'react'
import { useTranslation } from "react-i18next";
import { Modal, ModalBody } from "reactstrap"

const SuccessModal = ({ isOpen, setIsOpen, title, description, primary, secondary, primaryText, secondaryText, primaryClick, secondaryClick }) => {
  const { t } = useTranslation();

  return (
    <Modal
      isOpen={isOpen}
      toggle={() => setIsOpen(false)}
      centered
      fade={false}
      contentClassName="custom-success-modal"
    >
      <ModalBody>
        <div className="modal-container">
          <div className="img-wrapper">
            <img src="/img/version3/successful.svg" alt="success" />
          </div>

          <div className="text-container">
            <div className="title-wrapper">
              <span>{t(title)}</span>
            </div>

            <div className="description-wrapper">
              <span>{t(description)}</span>
            </div>
          </div>

          {
            primary &&
              <div className="btn-wrapper">
                {secondary && <button type="button" className="secondary-btn" onClick={secondaryClick} >{t(secondaryText)}</button>}
                <button type="button" className="primary-btn" onClick={primaryClick} >{t(primaryText)}</button>
              </div>
          }
        </div>
      </ModalBody>
    </Modal>
  );
};

export default SuccessModal