import React, { useState, useEffect, useRef } from "react";
import ContentHeader from "components/content/header";
import RightInformation from "../../components/custom/RightInformation";
import { redemptionHistory, latestGames } from "../../config/game-configs";
import Breadcrumb from "components/custom/Breadcrumb";
import { map } from "lodash";
import Input from "../../components/custom/Input";
import { useTranslation } from "react-i18next";
import Icon from "@mdi/react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";

const Gamepoint = (props) => {
    const [currentActiveTab, setCurrentActiveTab] = useState(0);
    const [redeemModalVisible, setRedeemModalVisible] = useState(false);
    var { t } = useTranslation();

    const breadcrumb = [
        {
          value: 'Home',
          active: false,
        },
        {
          value: 'Wallet',
          active: false,
        },
        {
          value: 'Use Points',
          active: false,
        },
        {
          value: 'GamePoint Redemption',
          active: true,
        },
      ];
    const unclaimedData = [
        {
            sales: '0.144',
            date: '041282023  l  12:55:38PM',
        },
        {
            sales: '2.143',
            date: '041282023  l  12:55:38PM',
        },
        {
            sales: '1.142',
            date: '041282023  l  12:55:38PM',
        },
        {
            sales: '0.141',
            date: '041282023  l  12:55:38PM',
        },
        {
            sales: '0.144',
            date: '041282023  l  12:55:38PM',
        },
      ];
    return (
        <>
            <section className="section-padding" id="shareholder-section">
                <Breadcrumb
                    data={breadcrumb}
                    // escButtonOnClick={() => window.history.go(-1)}
                />
                <div className="with-information-container">
                    <div className="left">
                        <h3 className="mb-4">GamePoint Redemption</h3>
                        <div className="gamepoint-banner">
                            <img src="/img/version3/main-wallet/points-banner.png" alt="GamePoint Redemption" className="w-100"/>
                        </div>
                        <div className="font-20 text-center mt-4">2,000THB Cash Voucher</div>
                        <div className="setting-points-container mt-4">
                            <div className="first">
                                <div className="title">
                                    Points
                                </div>
                                <div className="details">
                                    35,000 points
                                </div>
                            </div>
                            <div className="second"></div>
                            <div className="third">
                                <div className="title">
                                    Validity
                                </div>
                                <div className="details">
                                    1 May - 31 May 2023
                                </div>
                            </div>
                        </div>
                        <p className="mt-4 font-14">
                            Enjoy your 2,000THB cash voucher by playing your favorite game under i8.live*min spend 1,000THB. 
                        </p>
                        <div className="hr-blue mt-4 mb-4" style={{maxWidth: 100}}></div>
                        <div>
                            <h3 className="mb-4">Terms & Conditions</h3>
                            <ul>
                                <li>
                                    Valid for one-time use in any category. Valid in Thailand
                                </li>
                                <li>
                                    The voucher must be redeemed within the stimulated period. No extension of validity is allowed.
                                </li>
                                <li>
                                    Expired meal vouchers will not be accepted or renewed. Entitlements are not transferable, exchangeable for cash or extendable.
                                </li>
                            </ul>
                        </div>
                        <div className="mt-5">
                            <button className="btn custom-btn-style-1" onClick={() => setRedeemModalVisible(true)}>Redeem</button>
                        </div>
                    </div>
                    <div className="right">
                        <RightInformation
                            redemption={true}
                            redemptionData={redemptionHistory}
                            games={true}
                            //gamesTitle="Latest Game"
                            //gamesData={latestGames}
                            promotion={true}
                        />
                    </div>
                </div>
            </section>
            <Modal
                id="custom-modal-small"
                fade={true}
                isOpen={redeemModalVisible}
                contentClassName="modal-brand modal-bottom"
                toggle={() => {
                setRedeemModalVisible(false);
                }}
                centered
            >
                <ModalBody>
                    <div className="tip-body">
                    <div className="text-center">
                        <h6 className="title mb-1">Confirm to use 35,000point to redeem 2,000THB Cash Voucher? </h6>
                    </div>
                    <div className="custom-button-two-col mt-5">
                        <div>
                        <button className="btn custom-btn btn-red">Cancel</button>
                        </div>
                        <div>
                        <button className="btn custom-btn">Confirm</button>
                        </div>
                    </div>
                    </div>
                
              </ModalBody>
            </Modal>
        </>
    );
};

export default Gamepoint;