import React, { useState, useEffect } from "react";
import "react-simple-keyboard/build/css/index.css";
import Sidebar from "components/sidebar";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { useForm } from "react-hook-form";
import { ApiUrl } from "../../util/Constant";
import { stringIsNullOrEmpty, numberWithCurrencyFormat } from "../../util/Util";
import DynamicLoadTable from "../../components/custom/DynamicLoadTable";
import ContentHeader from "components/content/header";
import DateRangeFilter from "../../components/custom/DateRangeFilter";

const InternalTransferReport = (props) => {
  const { t } = useTranslation();
  const [transferUrl, setTransferUrl] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
    const [startDate, setStartDate] = useState(moment().startOf("week").format('YYYY-MM-DD'));
    const [endDate, setEndDate] = useState(moment().endOf("week").format('YYYY-MM-DD'));

  useEffect(() => {
    // getTransferData();
    getTransferData(startDate, endDate);
  }, []);

  async function getTransferData(start, end, selected = "") {
    var apiUrl =
      ApiUrl._API_GET_INTERNAL_TRANSFER +
      "?StartDate=" +
      moment(start).format("YYYY-MM-DD") +
      "&EndDate=" +
      moment(end).format("YYYY-MM-DD") +
      "&v=" +
      Date.now();

    if (!stringIsNullOrEmpty(selected)) {
      apiUrl += "&SelectedDate=" + moment(selected).format("YYYY-MM-DD");
    }
    setTransferUrl(apiUrl);
  }

  useEffect(() => {
    if (!stringIsNullOrEmpty(selectedDate)) {
      getTransferData(startDate, endDate, selectedDate);
    }
  }, [selectedDate]);

  return (
    <>
      <section className="section-padding" id="game-record-section">
        <div className="container">
          <div className="row">
            <div className="col-3" id="account-menu">
              <Sidebar activePage={"REPORTS"} />
            </div>
            <div className="col-9">
              <ContentHeader enableBackButton title={t("INTERNAL_TRANSFER")} />
              <hr className="right-title-hr" />
              <div className="template-box mt-3" id="internal-transfer-box">
                <DateRangeFilter
                  startDate={startDate}
                  endDate={endDate}
                  setStartDate={setStartDate}
                  setEndDate={setEndDate}
                  onSave={(start, end) => {
                    setStartDate(moment(start));
                    setEndDate(moment(end));
                    getTransferData(start, end);
                    setSelectedDate("");
                  }}
                  options={true}
                />
                <div className="card card-package">
                  <div className="card-body p-0" style={{ overflowX: "auto" }}>
                    <table className="table reportTable">
                      <thead>
                        <tr>
                          <th>{t("DATE")}</th>
                          <th>{t("FROMUSERNAME")}</th>
                          <th>{t("TOUSERNAME")}</th>
                          <th>{t("AMOUNT")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <DynamicLoadTable
                          pageSize={20}
                          apiQuery={transferUrl}
                          isTable={true}
                          searchEnabled={true}
                          render={(item, i) => {
                            return (
                              <tr key={"internal_transfer_report_" + i}>
                                <td>{item["date"]}</td>
                                <td>{item["fromUsername"]}</td>
                                <td>{item["toUsername"]}</td>
                                <td>
                                  {numberWithCurrencyFormat(
                                    parseFloat(item["amount"]),
                                    2
                                  )}
                                </td>
                              </tr>
                            );
                          }}
                        />
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default InternalTransferReport;
