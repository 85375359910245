import React from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { LanguageKey } from "./../../util/Constant";

const MenuRowItem = ({ item, iconCustomClass }) => {
  const { t, i18n } = useTranslation(LanguageKey._PRIMARY);
  const _history = useHistory();
  if (item.onClick) {
    return (
      <div key={item.text} onClick={() => item.onClick()}>
        <div className="wallet-main-boxes">
          <div className="first">
            <div className="img">
              <img
                src={item.image}
                className={`img-responsive ${iconCustomClass}`}
                alt="transfer"
              />
            </div>
            <div>
              <h3>{t(item.text)}</h3>
            </div>
          </div>
          {item.rightButton}
        </div>
      </div>
    );
  }

  return (
    <a key={item.text} onClick={() => _history.push(item.link)}>
      <div className="wallet-main-boxes">
        <div className="first">
          <div className="img">
            <img
              src={item.image}
              className={`img-responsive ${iconCustomClass}`}
              alt="transfer"
            />
          </div>
          <div>
            <h3>{t(item.text)}</h3>
          </div>
        </div>
        {item.rightButton}
      </div>
    </a>
  );
};

MenuRowItem.propTypes = {
  item: PropTypes.object.isRequired,
  iconCustomClass: PropTypes.string,
};

export default MenuRowItem;
