import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

const CustomDropdown = ({ options }) => {
  const { t, i18n } = useTranslation();
  const _history = useHistory();
  const [selectedOption, setSelectedOption] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (options.length > 0 && !selectedOption) {
      setSelectedOption(options[0]);
      setIsLoading(false);
    }
  }, [options, selectedOption]);

  return (
    !isLoading &&
    <div className="custom-dropdown-2" onMouseEnter={() => setIsOpen(true)} onMouseLeave={() => setIsOpen(false)}>
      <div className="dropdown-container option">
          <div className="dropdown-item-wrapper" style={{ cursor: "pointer" }}>
          <div className="first">
            <img src={selectedOption.image} alt="balance" />
          </div>
          <div className="second">
            <span>{t(selectedOption.title)}</span>
          </div>
          <div className="arrow-container">
            {isOpen ? <img src="/img/arrow-up.svg" alt="arrow up" /> : <img src="/img/arrow-down.svg" alt="arrow down" />}
          </div>
        </div>
      </div>
      {isOpen && (
        <div className="options">
          {options.map((option) => (
            <div className="dropdown-container option" onClick={() => { _history.push(option.url); setIsOpen(false); }}>
              <div className="dropdown-item-wrapper" style={{ cursor: "pointer" }}>
                <div className="first">
                  <img src={option.image} alt="balance" />
                </div>
                <div className="second">
                  <span>{t(option.title)}</span>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default CustomDropdown;