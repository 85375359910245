import React, { useState, useEffect, useRef } from "react";
import ContentHeader from "components/content/header";
import RightInformation from "../../components/custom/RightInformation";
import Breadcrumb from "components/custom/Breadcrumb";
import { map } from "lodash";
import { WebUrl, ApiKey, ApiUrl, PhoneNumberPrefix } from "../../util/Constant";
import ApiEngine from "../../util/ApiEngine";
import {
  createFormBody,
  createMultiPartFormBody,
  formatNumber,
  stringIsNullOrEmpty,
  numberWithCurrencyFormat
} from "../../util/Util";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  setBusy,
  setIdle,
  showMessage,
  showResponseMessage,
} from "../../redux/AppAction";
import { useForm } from "react-hook-form";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from "reactstrap";
import moment from 'moment';
import DatePicker from "react-datepicker";

const EditProfile = (props) => {
    const _dispatch = useDispatch();
  const { t } = useTranslation();
  var _userData = useSelector((state) => state.authState.userData);

    //const [latestGame, setLatestGame] = useState({});
    const [checkVipPinExist, setCheckVipPinExist] = useState(true);
    const [member, setMember] = useState();
    const [isShareholder, setIsShareholder] = useState(false);
    const [isPhoneVerified, setIsPhoneVerified] = useState(false);
    const [isEmailVerified, setIsEmailVerified] = useState(false);
    const [formattedPhone, setFormattedPhone] = useState("");
    const [memberFullName, setMemberFullName] = useState("");
    const [formattedEmail, setFormattedEmail] = useState("");
    const [memberId, setMemberId] = useState("");
    const [birthdayValue, setBirthdayValue] = useState("");
    const [selectedPhonePrefix, setSelectedPhonePrefix] = useState("");
    const [showFormattedPhone, setshowFormattedPhone] = useState("");
    const [grandTotalRewards, setGrandTotalRewards] = useState(0);
    const [tacPhoneNumber, setTACphoneNumber] = useState("");
    const [sentCode, setSentCode] = useState(false);
    const [countdownPeriod, setCountdownPeriod] = useState(0);
    const [countdownPeriodEmail, setCountdownPeriodEmail] = useState(0);
    const _COUNTDOWN_PERIOD = 60;
    const [showVerifyPhoneModal, setShowVerifyPhoneModal] = useState(false);
    const _SECONDS_IN_MS = 1000;
    const [showVerifyEmailModal, setShowVerifyEmailModal] = useState(false);
    const [startDate, setStartDate] = useState("");
    const [sentCodeEmail, setSentCodeEmail] = useState(false);
    const [emailActiveStep, setEmailActiveStep] = useState(1);
    const [birthdayString, setBirthdayString] = useState("");
  const [enteredCodeEmail, setEnteredCodeEmail] = useState("");
  const {
    register: profileRegister,
    errors: profileErrors,
    handleSubmit: handleProfileSubmit,
    setValue: setProfileValue,
    getValues: getProfileValues,
    clearErrors: clearProfileErrors,
    watch: profileWatch,
  } = useForm();
  console.log("1", startDate);
    const breadcrumb = [
        {
          value: 'SETTINGS',
          active: false,
          link: WebUrl._URL_SETTINGS
        },
        {
          value: 'EDIT_PROFILE',
          active: true,
          link: WebUrl._URL_EDIT_PROFILE
        },
      ];

      const countryCodeData = [
        {
          code: "+60",
        },
        {
          code: "+61",
        },
        {
          code: "+62",
        },
        {
          code: "+63",
        },
      ];

      useEffect(() => {
        init();
        //getLatestGame();
        getRewardData();
      }, []);

      //const getLatestGame = async () => {
      //  var latestGameJson = await ApiEngine.get(ApiUrl._API_GET_LATEST_GAME);

      //  if (latestGameJson[ApiKey._API_SUCCESS_KEY]) {
      //    setLatestGame(latestGameJson[ApiKey._API_DATA_KEY]);
      //  }
      //}

  async function init() {
    let params = {};
    let responseJson = await ApiEngine.get(ApiUrl._API_GET_MEMBER_DETAILS_BY_ID);
    if (responseJson[ApiKey._API_SUCCESS_KEY]) {

      if (responseJson[ApiKey._API_DATA_KEY]["isShareHolder"] === true) {
        let responseJson2 = await ApiEngine.post(
          ApiUrl._API_CHECK_SHAREHOLDER_PIN_NUMBER,
          createFormBody(params)
        );

        if (!responseJson2[ApiKey._API_SUCCESS_KEY]) {
          setCheckVipPinExist(false);
        }
      }

      setMember(responseJson[ApiKey._API_DATA_KEY]);
      setIsShareholder(responseJson[ApiKey._API_DATA_KEY]["isShareHolder"]);
      setIsPhoneVerified(responseJson[ApiKey._API_DATA_KEY]["isPhoneVerify"]);
      setIsEmailVerified(responseJson[ApiKey._API_DATA_KEY]["isEmailVerify"]);
      
      setFormattedPhone(responseJson[ApiKey._API_DATA_KEY]["phoneNumber"]);
      setMemberFullName(responseJson[ApiKey._API_DATA_KEY]["userFullName"]);

      setFormattedEmail(responseJson[ApiKey._API_DATA_KEY]["email"]);
      setMemberId(responseJson[ApiKey._API_DATA_KEY]['id']);
      setBirthdayValue(responseJson[ApiKey._API_DATA_KEY]["birthDateString"]);
      
      var tempPhoneNumber = responseJson[ApiKey._API_DATA_KEY]["phoneNumber"];
      setSelectedPhonePrefix(
        PhoneNumberPrefix.filter((prefix) =>
          tempPhoneNumber.includes(prefix.value)
        ).length > 0
          ? PhoneNumberPrefix.filter((prefix) =>
            tempPhoneNumber.includes(prefix.value)
          )[0].value
          : PhoneNumberPrefix[0].value
      );

      setshowFormattedPhone(
        responseJson[ApiKey._API_DATA_KEY]["phoneNumber"].substring(
          //responseJson[ApiKey._API_DATA_KEY]["phoneNumber"].indexOf(
          //    selectedPhonePrefix
          //) + 2,
          PhoneNumberPrefix.filter((x) => tempPhoneNumber.includes(x.value))[0].value.length,
          responseJson[ApiKey._API_DATA_KEY]["phoneNumber"].length
        )
      );
      //checkENB(responseJson[ApiKey._API_DATA_KEY]);
    } else {
      _dispatch(
        showResponseMessage(
          responseJson[ApiKey._API_SUCCESS_KEY],
          responseJson[ApiKey._API_MESSAGE_KEY]
        )
      );
    }
  }

  const getRewardData = async () => {
    let responseJson = await ApiEngine.get(
      ApiUrl._API_GET_CATEGORY_REWARD_RATES + "?all=true"
    );

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      let data = responseJson[ApiKey._API_DATA_KEY];
      let tempObj = {};
      let grandTotalReward = 0;
      let categoryTotal = {};
      data.map((rewardData, index) => {
        if (!tempObj[rewardData["date"]]) {
          tempObj[rewardData["date"]] = { totalAmount: 0, totalAmountClaimable: 0 };
        }
        if (!tempObj[rewardData["date"]][rewardData["categoryTitle"]]) {
          tempObj[rewardData["date"]][rewardData["categoryTitle"]] = { totalAmount: 0, totalAmountClaimable: 0 };
        }
        if (!tempObj[rewardData["date"]][rewardData["categoryTitle"]][rewardData["providerName"]]) {
          tempObj[rewardData["date"]][rewardData["categoryTitle"]][rewardData["providerName"]] = { productImage: rewardData["productImage"], rate: rewardData["rewardRate"] };
        }
        tempObj[rewardData["date"]].totalAmount += rewardData["amount"];
        tempObj[rewardData["date"]][rewardData["categoryTitle"]].totalAmount += rewardData["amount"];
        if (rewardData["claimed"] !== "True") {
          tempObj[rewardData["date"]].totalAmountClaimable += rewardData["amount"];
          tempObj[rewardData["date"]][rewardData["categoryTitle"]].totalAmountClaimable += rewardData["amount"];
          grandTotalReward += rewardData["amount"];

          if (!categoryTotal[rewardData["categoryTitle"]]) {
            categoryTotal[rewardData["categoryTitle"]] =
            {
              category: rewardData["categoryTitle"],
              totalAmount: rewardData["amount"]
            };
          }
          else if (categoryTotal[rewardData["categoryTitle"]]) {
            categoryTotal[rewardData["categoryTitle"]].totalAmount += rewardData["amount"];
          }
          else if (categoryTotal[rewardData["categoryTitle"]]) {
            categoryTotal[rewardData["categoryTitle"]] =
            {
              category: rewardData["categoryTitle"],
              totalAmount: rewardData["amount"]
            };
          }
        }

      });
      setGrandTotalRewards(grandTotalReward);
    } else {
      _dispatch(
        showResponseMessage(false, responseJson[ApiKey._API_MESSAGE_KEY])
      );
    }
  }
  const validatePhoneNumber = (e) => {
    var regexp = /^\d+$/;
    const { value } = e.target;

    if (e.nativeEvent.data === null || regexp.test(e.nativeEvent.data)) {
      // setFormattedPhone(e.target.value);

      if (value.startsWith(selectedPhonePrefix)) {
        const repValue = value.replace(selectedPhonePrefix, ""); //replace to space
        const newValue = repValue.replace(/ /g, ""); //replace white space
        setshowFormattedPhone(newValue);

        setFormattedPhone(selectedPhonePrefix + newValue); //177277883
      }
    }
  };

  useEffect(() => {
    if (countdownPeriod > 0) {
      setTimeout(() => {
        let tempPeriod = countdownPeriod - 1;
        setCountdownPeriod(tempPeriod);
      }, _SECONDS_IN_MS);
    } else {
      setSentCode(false);
    }
  }, [countdownPeriod]);

  useEffect(() => {
    if (countdownPeriodEmail > 0) {
      setTimeout(() => {
        let tempPeriod = countdownPeriodEmail - 1;
        setCountdownPeriodEmail(tempPeriod);
      }, _SECONDS_IN_MS);
    } else {
      setSentCode(false);
    }
  }, [countdownPeriodEmail]);

  const sendVerificationCode = async () => {
    let phoneNumber = selectedPhonePrefix + showFormattedPhone;
    await ApiEngine.post(
      ApiUrl._API_SEND_VERIFICATION_CODE,
      createMultiPartFormBody({ phoneNumber: phoneNumber })
    ).then((response) => {
      if (response[ApiKey._API_SUCCESS_KEY]) {
        let tempTACphoneNumber = response[ApiKey._API_DATA_KEY];
        setTACphoneNumber(tempTACphoneNumber);
        setSentCode(true);
        setCountdownPeriod(_COUNTDOWN_PERIOD);
      }
    });
  };

  const sendVerificationCodeEmail = async () => {
    _dispatch(setBusy());
    await ApiEngine.post(
      ApiUrl._API_SEND_EMAIL_VERIFICATION_CODE,
      createMultiPartFormBody({ email: formattedEmail })
    ).then((response) => {
      if (response[ApiKey._API_SUCCESS_KEY]) {
        setSentCodeEmail(true);
        setCountdownPeriodEmail(_COUNTDOWN_PERIOD);
        setEmailActiveStep(3);
      } else {
        _dispatch(showResponseMessage(response[ApiKey._API_SUCCESS_KEY], response[ApiKey._API_MESSAGE_KEY]));
      }
      _dispatch(setIdle());
    });
  };

  const submitPhoneNumberForm = async (data, e) => {

    _dispatch(setBusy());
    if (!isPhoneVerified) {
      {
        let verifyJson = await ApiEngine.post(
          ApiUrl._API_VERIFY_PHONE_NUMBER,
          createMultiPartFormBody({
            verificationCode: data.profileVerifyCode,
          })
        );
        if (verifyJson[ApiKey._API_SUCCESS_KEY]) {
          
          submitPhoneNumber();

          setShowVerifyPhoneModal(false);
          _dispatch(
            showResponseMessage(
              verifyJson[ApiKey._API_SUCCESS_KEY],
              verifyJson[ApiKey._API_MESSAGE_KEY]
            )
          );
        } else {
          _dispatch(
            showResponseMessage(
              verifyJson[ApiKey._API_SUCCESS_KEY],
              verifyJson[ApiKey._API_MESSAGE_KEY]
            )
          );
        }
      }
    } 
    setSentCode(false);
    _dispatch(setIdle());
  };

  async function submitPhoneNumber() {
    let data = [];

    data["phoneNumber"] = selectedPhonePrefix + showFormattedPhone;
    data["isMemberOperate"] = true;

    var responseJson = await ApiEngine.post(
      ApiUrl._API_UPDATE_MEMBER_PHONE_NUMBER,
      createMultiPartFormBody(data)
    );

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      _dispatch(
        showResponseMessage(
          responseJson[ApiKey._API_SUCCESS_KEY],
          responseJson[ApiKey._API_MESSAGE_KEY]
        )
      );
    }

    if (!responseJson[ApiKey._API_SUCCESS_KEY]) {
      _dispatch(
        showResponseMessage(false, responseJson[ApiKey._API_MESSAGE_KEY])
      );
    }
    init();
  }

  const confirmMessageClick = async () => {
    var a = startDate.toString();
    let parts = a.split(" ");
    let months = {
      Jan: "01",
      Feb: "02",
      Mar: "03",
      Apr: "04",
      May: "05",
      Jun: "06",
      Jul: "07",
      Aug: "08",
      Sep: "09",
      Oct: "10",
      Nov: "11",
      Dec: "12"
    };
    let date = (parts[3] + "-" + months[parts[1]] + "-" + parts[2]);
    setBirthdayString(date);
    if (stringIsNullOrEmpty(formattedEmail)) {
      let success = false
      let message = 'PLS_VERIFIFY_THE_CODE_FIRST'
      _dispatch(showResponseMessage(success, message));
    } else {
      setEmailActiveStep(2);

    }
  }

  const verifyEmail = async () => {
    let returnBool = [];
    await ApiEngine.post(ApiUrl._API_VERIFY_EMAIL, createMultiPartFormBody({ verificationCode: enteredCodeEmail }))
      .then(async (response) => {
        if (response[ApiKey._API_SUCCESS_KEY]) {
          returnBool = [
            {
              success: response[ApiKey._API_SUCCESS_KEY],
              message: response[ApiKey._API_MESSAGE_KEY]
            }]
        } else {
          returnBool = [
            {
              success: response[ApiKey._API_SUCCESS_KEY],
              message: response[ApiKey._API_MESSAGE_KEY]
            }]
        }
      })
    return returnBool;
  }

  const submitEmail = async () => {
    _dispatch(setBusy());
    let success = '';
    let message = '';
    if (countdownPeriodEmail > 0) {
      let verifyIsSuccess = await verifyEmail();

      verifyIsSuccess.map((option) => {
        success = option["success"]
        message = option["message"]
      });

      if (success) {
        let params = {
          "email": formattedEmail,
          "birthDate": birthdayString
        }
        if (!stringIsNullOrEmpty(memberId)) {
          params["id"] = memberId;
        }
        let formBody = createFormBody(params);
        _dispatch(setBusy());

        fetch(ApiUrl._API_UPDATE_MEMBER_EMAIL_AND_BIRTH_DATE, {
          method: ApiKey._API_POST,
          headers: {
            'Content-Type': ApiKey._API_FORM_URLENCODED,
            "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY
          },
          body: formBody
        }).then((response) => response.json()).then((responseJson) => {
          _dispatch(setIdle());
          if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            setShowVerifyEmailModal(false);
            setEmailActiveStep(1);
            init();
          }
          _dispatch(showResponseMessage(responseJson[ApiKey._API_SUCCESS_KEY], responseJson[ApiKey._API_MESSAGE_KEY]));

        });
      } else {
        _dispatch(showResponseMessage(success, message));

      }
    } else {
      success = false
      message = 'Verification Code Expire Already!'
      _dispatch(showResponseMessage(success, message));
    }
    _dispatch(setIdle());
  }
  console.log("223", enteredCodeEmail);
    return (
        <>
            <section className="section-padding" id="withdrawal-section">
                <Breadcrumb
                    data={breadcrumb}
                    // escButtonOnClick={() => window.history.go(-1)}
                />
                <div className="with-information-container">
                    <div className="left">
                        {/*<div className="font-12 color-main text-underline text-right mb-3">*/}
                        {/*    Edit*/}
                        {/*</div>*/}
                        <div className="text-center">
                            <img
                                src="/img/version3/user-big.png"
                                alt='claim'
                                className="img-responsive m-auto"
                            />
                        </div>
                        <div className="setting-points-container mt-3">
                            <div className="first">
                                <div className="title">
                                    {t("POINTS")}
                                </div>
                                <div className="details">
                                {
                                  _userData['loyaltyPoints'] === "" ? t("LOADING") + "... " : " " + numberWithCurrencyFormat(parseFloat(_userData['loyaltyPoints']), 0, true) + " "
                                }
                                {t("POINTS")}
                                </div>
                            </div>
                            <div className="second"></div>
                            <div className="third">
                                <div className="title">
                                  {t("PROMOTION") }
                                </div>
                                <div className="details">
                                  {numberWithCurrencyFormat(grandTotalRewards, 3, true)}
                                </div>
                            </div>
                        </div>
                        <div className="edit-profile-form">
                            <div className="input-with-icon-container mt-4">
                                <input
                                  type='text'
                                  className="custom-input"
                                  placeholder={ member ? member.username : t("USERNAME")}
                                  disabled={true}

                                />
                                <div className="input-icon">
                                    <img src="/img/version3/register-login/username.png" alt="username" className="img-responsive" />
                                </div>
                                <div className="input-right-icon">
                                    <img src="/img/version3/next-gray.png" alt="username" className="img-responsive" />
                                </div>
                            </div>
                            <div className="input-with-icon-container mt-4">
                                <div className="custom-phone-container">
                                <select
                                  className="custom-select-container"
                                  onChange={(e) => setSelectedPhonePrefix(e.target.value)}
                                  disabled={isPhoneVerified ? true : false}
                                  value={selectedPhonePrefix}
                                >
                                    {PhoneNumberPrefix.map((prefix, index) => {
                                    return (
                                        <option
                                        key={index}
                                        className={"phone-prefix-" + (index + 1)}
                                        value={prefix.value}
                                      >
                                        {prefix.label}
                                        </option>
                                    );
                                    })}
                                </select>

                                <div className="second">
                                    <input
                                      name="profilePhoneNumber"
                                      className="custom-phone-input"
                                      placeholder={t("PHONE_NUMBER")}
                                      autoComplete="off"
                                      readOnly={isPhoneVerified}
                                      maxLength={20}
                                      defaultValue={`${showFormattedPhone}`}
                                      ref={profileRegister({
                                        // required: true,
                                        required: "FIELD_REQUIRED",
                                        minLength: {
                                          value: 8,
                                          message: t("MIN_CHARACTERS", { length: 8 }),
                                        },
                                        pattern: {
                                          value: /^[0-9]+$/g,
                                          message:
                                            t("MIN_CHARACTERS", { length: 8 }) +
                                            " " +
                                            t("AND") +
                                            " " +
                                            t("ONLY_NUMBERS"),
                                        },
                                      })}
                                      errors={profileErrors}
                                      onChange={(e) => {
                                        setshowFormattedPhone(e.target.value);
                                      }}
                                      onInput={(e) => validatePhoneNumber(e)}
                                    />
                                </div>
                                </div>
                                <div className="input-right-icon">
                                  {isPhoneVerified ? 
                                    (
                                      <img src="/img/version3/verified.png" alt="username" className="img-responsive" />
                                    )
                                    :
                                    (
                                      <div onClick={() => {  setShowVerifyPhoneModal(true); setEmailActiveStep(1)} }>
                                        <i className="fas fa-paper-plane"></i>
                                      </div>
                                      
                                    )}
                                    
                              </div>
                            </div>
                            
                            <div className="input-with-icon-container mt-4">
                                <input
                                  type='text'
                                  className="custom-input"
                                  placeholder={t("EMAIL")}
                                  readOnly={isEmailVerified}
                                  value={formattedEmail}
                                  autoComplete="off"
                                  ref={profileRegister({
                                      required: "PLEASE_ENTER_EMAIL",
                                      pattern: {
                                          value: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                                          message: "INVALID_EMAIL"
                                      }
                                  })}
                                  errors={profileErrors}
                                  onChange={(e) => {
                                    setFormattedEmail(e.target.value);
                                  }}
                                />
                                <div className="input-icon">
                                    <img src="/img/version3/register-login/email.png" alt="email" className="img-responsive" />
                                </div>
                                <div className="input-right-icon">
                                {isEmailVerified ?
                                  (
                                    <img src="/img/version3/verified.png" alt="username" className="img-responsive" />
                                  ) 
                                  :
                                  (
                            <div onClick={() => { setShowVerifyEmailModal(true);  }}>
                                    <i className="fas fa-paper-plane"></i>
                                    </div>
                                    
                                  )}
                                </div>
                            </div>
                            <div className="input-with-icon-container mt-4">
                                <input
                                    type='date'
                                    className="custom-input"
                                    placeholder={t("BIRTHDATE")}
                                    autoComplete="off"
                                    readOnly="true"
                                    maxLength={20}
                                    defaultValue={birthdayValue}
                                />
                                <div className="input-icon">
                                    <img src="/img/version3/register-login/calendar.png" alt="birthday" className="img-responsive" />
                                </div>
                                <div className="input-right-icon">
                                    <img src="/img/version3/next-gray.png" alt="birthday-icon" className="img-responsive" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="right">
                        <RightInformation
                            button={true}
                            buttonText="WALLET_INFO"
                            games={true}
                            //gamesTitle="LATEST_GAME"
                            //gamesData={latestGame}
                        />
                    </div>
                </div>
            </section>
            <Modal
              isOpen={showVerifyPhoneModal}
              id="modal-small-custom"
              fade={true}
              toggle={() => {
                setShowVerifyPhoneModal(false);
              }}
              centered
            >
              <ModalBody>
                <form onSubmit={handleProfileSubmit(submitPhoneNumberForm)}>
                  <div className="text-center">
                    <div className="phone-number-verified-div">
                      <div style={{ fontSize: '20px' }} >{t("PHONE_NUMBER_VERIFIED")}</div>
                      <div className="phone-number-text">{t("CODE_SENT_TO_NUMBER", { phoneNumber: "+" + selectedPhonePrefix + showFormattedPhone })}</div>
                      <div>
                        <input
                          type="password"
                          className="custom-input"
                          placeholder={t("ENTER_VERIFICATION_CODE")}
                          autoComplete="off"
                          style={{ padding: '0px 20px 0px 25px' }}
                          name="profileVerifyCode"
                          ref={profileRegister({
                            required: 'FIELD_REQUIRED',
                            pattern: { value: /^[0-9]+$/, message: "INVALID_VERIFICATION_CODE" }
                          })}
                        />
                      </div>
                      {sentCode && countdownPeriod != 0 ? (
                        <div className="phone-number-text">{t("RESET_CODE_IN_SECONDS", { value: countdownPeriod })}</div>
                      ) : (
                        <div className="phone-number-text" onClick={() => sendVerificationCode()}>{t("SEND_VERIFICATION_CODE")}</div>
                      )
                      }
                      <div><button type="submit" className="btn custom-btn">{t("VERIFY")}</button></div>
                    </div>
                  </div>
                </form>
              </ModalBody>
            </Modal>
            <Modal
              isOpen={showVerifyEmailModal}
              id="modal-small-custom"
              fade={true}
              toggle={() => {
                setShowVerifyEmailModal(false);
              }}
              centered
            >
          <ModalBody>
            {emailActiveStep === 1 && (
              <>
                  <div className="text-center">
                      <div className="phone-number-verified-div">
                      <div style={{ width: '300px', alignSelf: 'center' }}>
                      <div style={{ fontSize: '20px' }} >{t("UPDATE_EMAIL_AND_BIRTH_DATE")}</div>
                      <div className="phone-number-text">{t("UPDATE_EMAIL_AND_BIRTH_DATE_TEXT")}</div>
                      </div>
                      
                      <div style={{ textAlign: 'left' }}>{t("ENTER_BIRTH_DATE")}</div>
                      <div style={{ marginTop: '-15px' }}>
                        <DatePicker
                          type="date"
                          className="birthdate-custom-input"
                          placeholder={t("ENTER_BIRTH_DATE")}
                          selected={startDate}
                          maxDate={moment().subtract(18, 'years')._d}
                          onChange={date => setStartDate(date)}
                          dateFormat="yyyy-MM-dd"
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                        />
                      </div>
                      <div style={{ textAlign: 'left' }}>{t("EMAIL")}</div>
                      <div style={{ marginTop: '-15px' }}>
                        <input
                          type='text'
                          className="custom-input"
                          style={{ padding: '0px 20px 0px 25px' }}
                          placeholder={t("EMAIL")}
                          readOnly={isEmailVerified}
                          value={formattedEmail}
                          autoComplete="off"
                          ref={profileRegister({
                            required: "PLEASE_ENTER_EMAIL",
                            pattern: {
                              value: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                              message: "INVALID_EMAIL"
                            }
                          })}
                          errors={profileErrors}
                          onChange={(e) => {
                            setFormattedEmail(e.target.value);
                          }}
                        />
                    </div>
                    <div onClick={() => confirmMessageClick()}><button type="submit" disabled={stringIsNullOrEmpty(formattedEmail) || stringIsNullOrEmpty(startDate)} className="btn custom-btn">{t("CONTINUE")} </button></div>
                    </div>
                  </div>
              </>
            )}

            {emailActiveStep === 2 && (
              <>
                <div className="text-center">
                  <div className="phone-number-verified-div">
                    {t("ARE_YOU_CONFIRM_TO_SELECT_AS_YOUR_BIRTHDAY", { value: birthdayString })}
                    <br />
                    {t("CANNOT_BE_CHANGED_AFTER_CONFIRMATION")}
                  </div>
                  <div className="confirm-btn-div" style={{ justifyContent: 'center', paddingTop: '20px' } }>
                    <div>
                      <button type="submit" style={{ background: 'red' }} className="btn custom-btn" onClick={() => setEmailActiveStep(1)}>{t("CLOSE")} </button>
                    </div>
                    <div onClick={() => sendVerificationCodeEmail()}>
                      <button type="submit" className="btn custom-btn">{t("CONFIRM")} </button>
                    </div>
                  </div>
                </div>
              </>
            )}
            {emailActiveStep === 3 && (
              <>
                <div className="text-center">
                  <div className="phone-number-verified-div">
                    <div style={{ fontSize: '20px' }} >{t("EMAIL_VERIFICATION_CODE")}</div>
                    <div className="phone-number-text">{t("CODE_SENT_TO_NUMBER", { phoneNumber: formattedEmail })}</div>
                    <div>
                      <input
                        type="password"
                        className="custom-input"
                        placeholder={t("ENTER_VERIFICATION_CODE")}
                        autoComplete="off"
                        style={{ padding: '0px 20px 0px 25px' }}
                        name="profileVerifyCode"
                        onChange={(e) => setEnteredCodeEmail(e.target.value)}
                        ref={profileRegister({
                          required: 'FIELD_REQUIRED',
                          pattern: { value: /^[0-9]+$/, message: "INVALID_VERIFICATION_CODE" }
                        })}
                      />
                    </div>
                    {sentCodeEmail && countdownPeriodEmail != 0 ? (
                      <div className="phone-number-text">{t("RESET_CODE_IN_SECONDS", { value: countdownPeriodEmail })}</div>
                    ) : (
                      <div className="phone-number-text" onClick={() => sendVerificationCodeEmail()}>{t("SEND_VERIFICATION_CODE")}</div>
                    )}

                    <div onClick={() => submitEmail()}><button type="submit" className="btn custom-btn" disabled={stringIsNullOrEmpty(formattedEmail) || stringIsNullOrEmpty(enteredCodeEmail) || enteredCodeEmail.length < 6 || enteredCodeEmail.length > 6}>{t("VERIFY")}</button></div>
                  </div>
                </div>
              </>
            )}
                
              </ModalBody>
            </Modal>
        </>
    );
};

export default EditProfile;
