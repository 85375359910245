import React, { useState, useEffect } from "react";
import "react-simple-keyboard/build/css/index.css";
import ApiEngine from "../../util/ApiEngine";
import { ApiKey, ApiUrl, SessionKey, LanguageOption } from "../../util/Constant";
import { useDispatch } from "react-redux";
import { showResponseMessage } from "../../redux/AppAction";
import { useTranslation } from "react-i18next";
import ContentHeader from "components/content/header";
import Input from "components/custom/Input";
import { Modal, ModalBody } from "reactstrap";

const VideoTutorial = (props) => {
  var _dispatch = useDispatch();
  const [video, setVideo] = useState([]);
  const [displayGameMenu, setDisplayGameMenu] = useState(true);
  const { t, i18n } = useTranslation();
  const [showUrlAlert, setShowUrlAlert] = useState(false);
  const [keyword, setKeyword] = useState("");

  useEffect(() => {
    init();
  }, []);

  async function init() {
    // var responseJson = await ApiEngine.get(
    //   ApiUrl._API_GET_VIDEO +
    //     "selectedLanguage=" +
    //     localStorage.getItem(SessionKey._LANGUAGE)
    // );
    var language = (LanguageOption.filter(option => option.value == localStorage.getItem(SessionKey._LANGUAGE))[0] !== undefined) ? (LanguageOption.filter(option => option.value == localStorage.getItem(SessionKey._LANGUAGE))[0].languageId) : 7;
    var responseJson = await ApiEngine.get(ApiUrl._API_GET_VIDEO + "?languageId=" + language);

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      setVideo(responseJson[ApiKey._API_DATA_KEY]);
    } else {
      _dispatch(
        showResponseMessage(
          responseJson[ApiKey._API_SUCCESS_KEY],
          responseJson[ApiKey._API_MESSAGE_KEY]
        )
      );
    }
  }

  useEffect(() => {
    setDisplayGameMenu(false);
    setTimeout(() => setDisplayGameMenu(true), 1);
  }, [(localStorage.getItem(SessionKey._LANGUAGE) && localStorage.getItem(SessionKey._LANGUAGE) != 'undefined' ?
    localStorage.getItem(SessionKey._LANGUAGE) : 'en')]);

  function copyVideoUrl(content) {
    navigator.clipboard.writeText(content);
    setShowUrlAlert(true);
  }

  async function getVideo(data) {
    var language = (LanguageOption.filter(option => option.value == localStorage.getItem(SessionKey._LANGUAGE))[0] !== undefined) ? (LanguageOption.filter(option => option.value == localStorage.getItem(SessionKey._LANGUAGE))[0].languageId) : 7;
    var responseJson = await ApiEngine.get(ApiUrl._API_GET_VIDEO_TUTORIAL_SEARCH + "?languageId=" + language + "&keyword=" + data);

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      setVideo(responseJson[ApiKey._API_DATA_KEY]["video"]);
    } else {
      _dispatch(
        showResponseMessage(
          responseJson[ApiKey._API_SUCCESS_KEY],
          responseJson[ApiKey._API_MESSAGE_KEY]
        )
      );
    }
  }


  return (
    <div id="video-tutorial-v3">
      {displayGameMenu && (
        <>
          <section className="section-padding">
            <ContentHeader
              title={t("VIDEO_TUTORIAL")}
            // escButtonOnClick={() => window.history.go(-1)}
            />
            <div>
              <div className="search-container mb-4">
                <div className="search-box-vt">
                  <Input
                    id="header-search"
                    name="header-search"
                    placeholder={t("SEARCH...")}
                    onChange={(e) => getVideo(e.target.value)}
                    inputGroupType="append"
                    inputGroupContent={<div className="header-search-icon-video-tutorial" > <img
                      src="/img/version3/search.png"
                      alt="search"
                    /> </div>}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              {video.length > 0 ? (video.map((video, index) => {
                return (
                  <div className="col-lg-4 col-6 mb-4">
                    <div className="video-item-box">
                      <div className="first position-relative">
                        <iframe
                          className="video"
                          src={video["content"]}
                          allowfullscreen="allowfullscreen"
                          mozallowfullscreen="mozallowfullscreen"
                          msallowfullscreen="msallowfullscreen"
                          oallowfullscreen="oallowfullscreen"
                          webkitallowfullscreen="webkitallowfullscreen"
                        ></iframe>
                      </div>
                      <div className="video-tutorial-content">
                        <div className="first">
                          <div className="custom-icon-box"><img src="/img/version3/i8-logo-small.png" alt="i8" /></div>
                          <div>
                            <div className="video-name">{video['title']}</div>
                            <div className="video-provider">i8Live_Official</div>
                          </div>
                        </div>
                        <div className="transform-button" onClick={() => copyVideoUrl(video['content'])}>
                          <img src="/img/version3/icon-copy.png" alt="i8" />
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })) : (
                <>
                  {t("NO_DATA_FOUND")}
                </>
              )}
            </div>
          </section>
        </>
      )}
      <Modal
        isOpen={showUrlAlert}
        id="modal-small-custom"
        fade={true}
        toggle={() => {
          setShowUrlAlert(false);
        }}
        centered
      >
        <ModalBody>
          <div className="text-center">
            <div className="confirm-div">
              <img
                className="cop-code"
                src="/img/version3/success.svg"
                alt="success"
              />
              <div>{t("COPY_SUCCESS")}</div>
              <button type="submit" className="btn custom-btn" style={{ width: "60% !important" }} onClick={() => setShowUrlAlert(false)}>{t("OK")}</button>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};
export default VideoTutorial;
