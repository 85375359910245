import React, { useState, useEffect } from "react";
import "react-simple-keyboard/build/css/index.css";
import Sidebar from "components/sidebar";
import { map } from "lodash";
import { ApiKey, ApiUrl, WebUrl } from "../../util/Constant";
import {
  setBusy,
  setIdle,
  showMessage,
  showResponseMessage,
} from "../../redux/AppAction";
import ApiEngine from "../../util/ApiEngine";
import { useDispatch } from "react-redux";
import SweetAlert from "react-bootstrap-sweetalert";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import ContentHeader from "components/content/header";
import RightInformation from "../../components/custom/RightInformation";
import { walletInformation, recentTransaction, latestGames } from "../../config/game-configs";
import { Nav, NavItem, NavLink, TabContent, TabPane, Alert, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

const Referral = (props) => {
  const { t, i18n } = useTranslation();
  const _dispatch = useDispatch();
  const _history = useHistory();
  const [referralCodes, setReferralCodes] = useState([]);
  const [selectedReferral, setSelectedReferral] = useState({});
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [displayContent, setDisplayContent] = useState(true);
  //const [latestGame, setLatestGame] = useState({});
  const [referralCodeCount, setReferralCodeCount] = useState(0);

  useEffect(() => {
    init();
    //getLatestGame();
  }, []);

  async function deleteReferral(id) {
    try {
      let responseJson = await ApiEngine.get(
        ApiUrl._API_DEL_REFERRAL + "?id=" + id
      );
      if (responseJson[ApiKey._API_SUCCESS_KEY]) {
        _dispatch(
          showResponseMessage(
            responseJson[ApiKey._API_SUCCESS_KEY],
            t(responseJson[ApiKey._API_MESSAGE_KEY])
          )
        );
        init();
        setConfirmDelete(false);
        setSelectedReferral({});
      } else {
        throw responseJson[ApiKey._API_MESSAGE_KEY];
      }
    } catch (errorMessage) {
      _dispatch(showMessage(false, t(errorMessage)));
    } finally {
      _dispatch(setIdle());
    }
  }

  async function init() {
    _dispatch(setBusy());
    setDisplayContent(false);
    var responseJson = await ApiEngine.get(
      ApiUrl._API_GET_MEMBER_REFERRAL_CODES
    );
    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      setReferralCodes(responseJson[ApiKey._API_DATA_KEY]);
      setReferralCodeCount((responseJson[ApiKey._API_DATA_KEY]).length);
    } else {
      _dispatch(
        showResponseMessage(
          responseJson[ApiKey._API_SUCCESS_KEY],
          responseJson[ApiKey._API_MESSAGE_KEY]
        )
      );
    }
    setDisplayContent(true);
    _dispatch(setIdle());
  }

  //const getLatestGame = async () => {
  //  var latestGameJson = await ApiEngine.get(ApiUrl._API_GET_LATEST_GAME);

  //  if (latestGameJson[ApiKey._API_SUCCESS_KEY]) {
  //    setLatestGame(latestGameJson[ApiKey._API_DATA_KEY]);
  //  }
  //}

  return (
    <div id="referral">
      <section className="section-padding" id="settings-section">
        <ContentHeader
          title={t("REFERRAL")}
        />
        <div className="with-information-container">
          <div className="left">
            <div>
              <button
                type="button"
                className="create-downline-btn"
                onClick={() => {
                  _history.push({
                    pathname: WebUrl._URL_REFERRAL_EDIT,
                  });
                }}
              >
                {t("CREATE") + " " + t("REFERRAL_CODE")}
              </button>
            </div>
            <h2 class="title-with-blue-under mb-4 mt-4">{t("REFERRAL_GROUPS")}</h2>
            <div>
              {displayContent && referralCodes && referralCodes.length ? (
                map(referralCodes, (referralCode, index) => {
                  return (
                    <div key={index}>
                      <div className="custom-main-boxes mb-4">
                        <div className="first">
                          <i
                            id="delReferral"
                            className="fas fa-trash d-flex align-items-center"
                            style={{ color: "white" }}
                            onClick={() => {
                              setSelectedReferral(referralCode);
                              setConfirmDelete(true);
                            }}
                          ></i>
                        </div>
                        <Link
                          className="referral-link"
                          to={{
                            pathname: WebUrl._URL_REFERRAL_CODE,
                            state: { id: referralCode.id },
                          }}
                        >
                          <span className="title">
                            {referralCode.code + " - " + referralCode.remark}
                            <br />
                            {referralCode.totalMember} {t("MEMBER")}
                          </span>
                          <img
                            src="/img/version3/next-blue.png"
                            className="img-responsive"
                            alt="next-page"
                          />
                        </Link>
                      </div>
                      {/* {index === referralCodes.length - 1 && (
                        <div className="container create-group-btn">
                          <button
                            type="button"
                            className="custom-btn-style-1"
                            onClick={() => {
                              _history.push({
                                pathname: WebUrl._URL_REFERRAL_EDIT,
                              });
                            }}
                          >
                            {t("CREATE")}
                          </button>
                        </div>
                      )} */}
                    </div>
                  );
                })
              ) :
                (referralCodes.length == 0 &&
                  <>
                    <div className="custom-main-boxes mb-4">
                      <div className="font-14 color-a4">
                        {t("NO_REFERRAL_CODE_YET")}
                      </div>
                    </div>

                    {/* <div className="container create-group-btn">
                    <button
                      type="button"
                      className="custom-btn-style-1"
                      onClick={() => {
                        _history.push({
                          pathname: WebUrl._URL_REFERRAL_EDIT,
                        });
                      }}
                    >
                      {t("CREATE")}
                    </button>
                  </div> */}
                  </>
                )}
            </div>
          </div>
          <div className="right">
            <RightInformation
              promotion={true}
              buttonTwo={true}
              buttonTwoQuantity={referralCodeCount}
              buttonTwoText="REFERRAL_GROUPS"
              games={true}
            //gamesTitle="LATEST_GAME"
            //gamesData={latestGame}

            />
          </div>
        </div>

        {/* <div className="container">
          <div className="row">
            <div className="col-3" id="account-menu">
              <Sidebar activePage={"REFERRAL"} />
            </div>
            <div className="col-9">
              <ContentHeader enableBackButton title={t("REFERRAL")} />
              <hr className="right-title-hr" />
              {displayContent && referralCodes && referralCodes.length ? (
                map(referralCodes, (referralCode, index) => {
                  return (
                    <div key={index}>
                      <div className="wallet-main-boxes">
                        <div className="first">
                          <i
                            id="delReferral"
                            className="fas fa-trash d-flex align-items-center"
                            style={{ color: "white" }}
                            onClick={() => {
                              setSelectedReferral(referralCode);
                              setConfirmDelete(true);
                            }}
                          ></i>
                        </div>
                        <Link
                          className="referral-link"
                          to={{
                            pathname: WebUrl._URL_REFERRAL_CODE,
                            state: { id: referralCode.id },
                          }}
                        >
                          <span className="font-18 text-white font-semi mb-0">
                            {referralCode.code + " - " + referralCode.remark}
                          </span>
                          <img
                            src={require("../../assets/img/assets/next-page.png")}
                            className="img-responsive"
                            alt="next-page"
                          />
                        </Link>
                      </div>
                      {index === referralCodes.length - 1 && (
                        <div className="container create-group-btn">
                          <button
                            type="button"
                            className="custom-btn-style-1"
                            onClick={() => {
                              _history.push({
                                pathname: WebUrl._URL_REFERRAL_EDIT,
                              });
                            }}
                          >
                            {t("CREATE")}
                          </button>
                        </div>
                      )}
                    </div>
                  );
                })
              ): 
              (referralCodes.length == 0 &&
                <>
                  <div className="wallet-main-boxes">
                    <div className="font-18 text-white font-semi mb-0">
                        {t("NO_REFERRAL_CODE_YET")}
                    </div>
                  </div>

                  <div className="container create-group-btn">
                    <button
                      type="button"
                      className="custom-btn-style-1"
                      onClick={() => {
                        _history.push({
                          pathname: WebUrl._URL_REFERRAL_EDIT,
                        });
                      }}
                    >
                      {t("CREATE")}
                    </button>
                  </div>
                </>
              )}
            </div>
          </div>
        </div> */}
      </section>
      {/*<SweetAlert*/}
      {/*  show={confirmDelete}*/}
      {/*  type="warning"*/}
      {/*  title={t("DELETE_REFERRAL", selectedReferral.code) + "?"}*/}
      {/*  showCancel*/}
      {/*  cancelBtnText={t("CANCEL")}*/}
      {/*  confirmBtnText={t("CONFIRM")}*/}
      {/*  onConfirm={() => {*/}
      {/*    deleteReferral(selectedReferral.id);*/}
      {/*    setConfirmDelete(false);*/}
      {/*  }}*/}
      {/*  onCancel={() => {*/}
      {/*    setConfirmDelete(false);*/}
      {/*  }}*/}
      {/*/>*/}
      <Modal
        isOpen={confirmDelete}
        id="modal-small-custom"
        fade={true}
        toggle={() => {
          setConfirmDelete(false);
        }}
        centered
      >
        <ModalBody>
          <div className="text-center">
            <div className="confirm-div">
              <img
                className="cop-code"
                src="/img/version3/error.svg"
                alt="error"
              />
              <div>{t("DELETE_REFERRAL")}</div>
              <div className="confirm-btn-div">
                <button type="submit" className="btn custom-btn" style={{ background: '#FF334B' }} onClick={() => setConfirmDelete(false)}>{t("CANCEL")}</button>
                <button type="submit" className="btn custom-btn" onClick={() => deleteReferral(selectedReferral.id)}>{t("CONFIRM")}</button>

              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};
export default Referral;
