import React, { useEffect, useState } from "react";
import "react-simple-keyboard/build/css/index.css";
import Sidebar from "components/sidebar";
import { map } from "lodash";
import ContentHeader from "components/content/header";
import DateRangeFilter from "../../components/custom/DateRangeFilter";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { ApiKey, ApiUrl, TransactionStatus } from "util/Constant";
import { numberWithCurrencyFormat, stringIsNullOrEmpty } from "../../util/Util";
import DynamicLoadTable from "components/custom/DynamicLoadTable";
import { Modal, ModalBody } from "reactstrap";
import Icon from "@mdi/react";
import {
  mdiCheckCircle,
  mdiCloseCircle,
  mdiDotsHorizontalCircle,
} from "@mdi/js";
import ApiEngine from "util/ApiEngine";

const TransactionHistory = (props) => {
  const { t } = useTranslation();

  const [startDate, setStartDate] = useState(moment().format("YYYY-MM-DD"));
  const [depositStartDate, setDepositStartDate] = useState(moment().format("YYYY-MM-DD"));
  const [depositEndDate, setDepositEndDate] = useState(moment().format("YYYY-MM-DD"));

  const [withdrawalStartDate, setWithdrawalStartDate] = useState(moment().format("YYYY-MM-DD"));
  const [withdrawalEndDate, setWithdrawalEndDate] = useState(moment().format("YYYY-MM-DD"));

  const [transferStartDate, setTransferStartDate] = useState(moment().format("YYYY-MM-DD"));
  const [transferEndDate, setTransferEndDate] = useState(moment().format("YYYY-MM-DD"));

  const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));
  const [selectedDate, setSelectedDate] = useState("");
  const [depositUrl, setDepositUrl] = useState("");
  const [withdrawalUrl, setWithdrawalUrl] = useState("");
  const [TransferUrl, setTransferUrl] = useState("");
  const [selectedTab, setSelectedTab] = useState("dr");
  const [currentItem, setItem] = useState({ transactionTypeString: "" });
  const [showTransactionDetailPopup, setShowTransactionDetailPopup] =
    useState(false);
  const [walletTransferRecord, setWalletTransferRecord] = useState([]);

  const transactionResult = [
    {
      date: "6 March 2022",
      status: "Rejected",
      amount: "1000.00",
      details: "#",
    },
    {
      date: "5 March 2022",
      status: "Rejected",
      amount: "1000.00",
      details: "#",
    },
    {
      date: "4 March 2022",
      status: "Approved",
      amount: "1000.00",
      details: "Vie#w",
    },
    {
      date: "3 March 2022",
      status: "Approved",
      amount: "1000.00",
      details: "#",
    },
    {
      date: "2 March 2022",
      status: "Rejected",
      amount: "1000.00",
      details: "#",
    },
    {
      date: "1 March 2022",
      status: "Approved",
      amount: "1000.00",
      details: "#",
    },
  ];
  const tabHeadings = [
    {
      title: "DEPOSIT_RECORD",
      link: "dr",
    },
    {
      title: "WITHDRAWAL_RECORD",
      link: "wr",
    },
    {
      title: "TRANSFER_RECORD",
      link: "tr",
    },
  ];

  useEffect(() => {
    getTransactionData(startDate, endDate);
  }, []);

  useEffect(() => {
    // if (selectedTab !== "tr"){
    // getTransactionData(startDate, endDate);

    if (selectedTab === "dr") {
      getTransactionData(depositStartDate, depositEndDate);
    }
    if (selectedTab === "wr") {
      getTransactionData(withdrawalStartDate, withdrawalEndDate);
    }
    if (selectedTab === "tr") {
      getTransactionData(transferStartDate, transferEndDate);
    }
    // }
    // else{
    //   getWalletTransferReport();
    // }
  }, [selectedTab]);

  const getWalletTransferReport = async () => {
    let responseJson = await ApiEngine.get(ApiUrl._API_GET_WALLET_TRANSFER + "?dateFrom=" + moment(startDate).format('YYYY-MM-DD HH:mm:ss') + "&dateTo=" + moment(endDate).endOf('day').format('YYYY-MM-DD HH:mm:ss'));
    console.log(responseJson[ApiKey._API_DATA_KEY]);
  }

  async function getTransactionData(start, end, selected = "") {
    let apiUrl = "";
    if (selectedTab === "dr") {
      apiUrl = `${ApiUrl._API_GET_TRANSACTION_BY_MEMBER_DEPOSIT_AND_WITHDRAWAL
        }?StartDate=${moment(start).format("YYYY-MM-DD")}&EndDate=${moment(
          end
        ).format("YYYY-MM-DD")}&type=1`;

      if (!stringIsNullOrEmpty(selected)) {
        apiUrl += "&SelectedDate=" + moment(selected).format("YYYY-MM-DD");
      }

      setDepositUrl(apiUrl);
    } else if (selectedTab === "wr") {
      apiUrl = `${ApiUrl._API_GET_TRANSACTION_BY_MEMBER_DEPOSIT_AND_WITHDRAWAL
        }?StartDate=${moment(start).format("YYYY-MM-DD")}&EndDate=${moment(
          end
        ).format("YYYY-MM-DD")}&type=2`;

      if (!stringIsNullOrEmpty(selected)) {
        apiUrl += "&SelectedDate=" + moment(selected).format("YYYY-MM-DD");
      }

      setWithdrawalUrl(apiUrl);
    }
    else if (selectedTab === "tr") {
      apiUrl = `${ApiUrl._API_GET_WALLET_TRANSFER + "?dateFrom=" + moment(start).startOf("day").format('YYYY-MM-DD HH:mm:ss') + "&dateTo=" + moment(end).endOf('day').format('YYYY-MM-DD HH:mm:ss')}`;

      if (!stringIsNullOrEmpty(selected)) {
        apiUrl += "&SelectedDate=" + moment(selected).format("YYYY-MM-DD");
      }

      setTransferUrl(apiUrl);
    }

    // if (!stringIsNullOrEmpty(selected)) {
    //   apiUrl += "&SelectedDate=" + moment(selected).format("YYYY-MM-DD");
    // }
  }
  return (
    <>
      <section className="section-padding" id="deposit-section">
        <div className="container">
          <div className="row">
            <div className="col-3" id="account-menu">
              <Sidebar activePage={"WALLET"} />
            </div>
            <div className="col-9">
              <ContentHeader
                enableBackButton
                title={t("TRANSACTION_HISTORY")}
              />
              <ul className="nav nav-tabs mt-5" id="my-tabs">
                {tabHeadings &&
                  tabHeadings.length &&
                  map(tabHeadings, (item, index) => {
                    return (
                      <li key={"transaction_history" + index}>
                        <a
                          data-toggle="tab"
                          href={"#" + item.link}
                          className={index == 0 ? "font-12 active" : "font-12"}
                          onClick={() => {
                            setSelectedTab(item.link);
                          }}
                        >
                          {t(item.title)}
                        </a>
                      </li>
                    );
                  })}
              </ul>
              <div className="template-box mt-3" id="transaction-history-box">
                <div className="tab-content">
                  <div id="dr" className="tab-pane in active">
                    <DateRangeFilter
                      startDate={startDate}
                      endDate={endDate}
                      setStartDate={setStartDate}
                      setEndDate={setEndDate}
                      onSave={(start, end) => {
                        setDepositStartDate(moment(start));
                        setDepositEndDate(moment(end));

                        setStartDate(moment(start));
                        setEndDate(moment(end));
                        getTransactionData(start, end);
                        setSelectedDate("");
                      }}
                      options={true}
                    />

                    <div className="thc-result-mainbox font-12">
                      <table className="thr-table">
                        <thead className="thr-thead">
                          <tr className="text-white text-center border-bot-black">
                            <th className="thr-titles pb-15">{t("DATE")}</th>
                            <th className="thr-titles pb-15">{t("STATUS")}</th>
                            <th className="thr-titles pb-15">{t("AMOUNT")}</th>
                            <th className="thr-titles pb-15">{t("DETAIL")}</th>
                          </tr>
                        </thead>
                        <br />
                        <tbody className="thr-tbody">
                          <DynamicLoadTable
                            pageSize={20}
                            apiQuery={depositUrl}
                            searchEnabled={true}
                            isTable={true}
                            render={(item, i) => {
                              var statusHtml = "";
                              switch (item["approvalStatus"]) {
                                case TransactionStatus._APPROVED:
                                  statusHtml = (
                                    <span style={{ color: "#00FA4B" }}>
                                      {t("APPROVED")}
                                    </span>
                                  );
                                  break;
                                case TransactionStatus._REJECTED:
                                  statusHtml = (
                                    <span style={{ color: "#FF0000" }}>
                                      {t("REJECTED")}
                                    </span>
                                  );
                                  break;
                                case TransactionStatus._OPEN:
                                  statusHtml = (
                                    <span style={{ color: "#FFFFFF" }}>
                                      {t("PROCESSING")}
                                    </span>
                                  );
                                  break;
                                default:
                                  statusHtml = (
                                    <span style={{ color: "#FFFFFF" }}>
                                      {t("PROCESSING")}
                                    </span>
                                  );
                                  break;
                              }

                              return (
                                <tr className="thr-item" key={i}>
                                  <td className="thr-item-date">
                                    {/* {moment(item["date"], "MM/DD/YYYY").format(
                                      "D MMMM YYYY"
                                    )} */}
                                    {item["date"]}
                                  </td>
                                  <td>{statusHtml}</td>
                                  <td className="thr-item-amount">
                                    {numberWithCurrencyFormat(
                                      parseFloat(item["amount"]),
                                      3
                                    )}
                                  </td>
                                  <td
                                    style={{ color: "#FFC159", cursor: "pointer" }}
                                    onClick={() => {
                                      console.log(item);
                                      setItem(item);
                                      setShowTransactionDetailPopup(true);
                                    }}
                                  >
                                    {t("VIEW")}
                                  </td>
                                </tr>
                              );
                            }}
                          />
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div id="wr" className="tab-pane fade">
                    <DateRangeFilter
                      startDate={startDate}
                      endDate={endDate}
                      setStartDate={setStartDate}
                      setEndDate={setEndDate}
                      onSave={(start, end) => {
                        setWithdrawalStartDate(moment(start));
                        setWithdrawalEndDate(moment(end));

                        setStartDate(moment(start));
                        setEndDate(moment(end));
                        getTransactionData(start, end);
                        setSelectedDate("");
                      }}
                      options={true}
                    />

                    <div className="thc-result-mainbox font-12">
                      <table className="thr-table">
                        <thead className="thr-thead">
                          <tr className="text-white text-center border-bot-black">
                            <th className="thr-titles pb-15">{t("DATE")}</th>
                            <th className="thr-titles pb-15">{t("STATUS")}</th>
                            <th className="thr-titles pb-15">{t("AMOUNT")}</th>
                            <th className="thr-titles pb-15">{t("DETAIL")}</th>
                          </tr>
                        </thead>
                        <br />
                        <tbody className="thr-tbody">
                          <DynamicLoadTable
                            pageSize={20}
                            apiQuery={withdrawalUrl}
                            searchEnabled={true}
                            isTable={true}
                            render={(item, i) => {
                              var statusHtml = "";
                              switch (item["approvalStatus"]) {
                                case TransactionStatus._APPROVED:
                                  statusHtml = (
                                    <span style={{ color: "#00FA4B" }}>
                                      {t("APPROVED")}
                                    </span>
                                  );
                                  break;
                                case TransactionStatus._REJECTED:
                                  statusHtml = (
                                    <span style={{ color: "#FF0000" }}>
                                      {t("REJECTED")}
                                    </span>
                                  );
                                  break;
                                case TransactionStatus._OPEN:
                                  statusHtml = (
                                    <span style={{ color: "#FFFFFF" }}>
                                      {t("PROCESSING")}
                                    </span>
                                  );
                                  break;
                                default:
                                  statusHtml = (
                                    <span style={{ color: "#FFFFFF" }}>
                                      {t("PROCESSING")}
                                    </span>
                                  );
                                  break;
                              }

                              return (
                                <tr className="thr-item" key={i}>
                                  <td className="thr-item-date">
                                    {item["date"]}
                                  </td>
                                  <td>{statusHtml}</td>
                                  <td className="thr-item-amount">
                                    {numberWithCurrencyFormat(
                                      parseFloat(item["amount"]),
                                      3
                                    )}
                                  </td>
                                  <td
                                    style={{ color: "#FFC159", cursor: "pointer" }}
                                    onClick={() => {
                                      setItem(item);
                                      setShowTransactionDetailPopup(true);
                                    }}
                                  >
                                    {t("VIEW")}
                                  </td>
                                </tr>
                              );
                            }}
                          />
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div id="tr" className="tab-pane fade">
                    <DateRangeFilter
                      startDate={startDate}
                      endDate={endDate}
                      setStartDate={setStartDate}
                      setEndDate={setEndDate}
                      onSave={(start, end) => {
                        setTransferStartDate(moment(start));
                        setTransferEndDate(moment(end));

                        setStartDate(moment(start));
                        setEndDate(moment(end));
                        getTransactionData(start, end);
                        setSelectedDate("");
                      }}
                      options={true}
                    />

                    <div className="thc-result-mainbox font-12">
                      <table className="thr-table">
                        <thead className="thr-thead">
                          <tr className="text-white text-center border-bot-black">
                            <th className="thr-titles pb-15">{t("DATE")}</th>
                            <th className="thr-titles pb-15">{t("REQUEST_AMOUNT")}</th>
                            <th className="thr-titles pb-15">{t("CONVERSION_RATE")}</th>
                            <th className="thr-titles pb-15">{t("AMOUNT")}</th>
                            <th className="thr-titles pb-15">{t("FROMWALLET")}</th>
                            <th className="thr-titles pb-15">{t("TOWALLET")}</th>
                            <th className="thr-titles pb-15">{t("BONUS")}</th>
                            <th className="thr-titles pb-15">{t("FROMWALLETBALANCEBEFORE")}</th>
                            <th className="thr-titles pb-15">{t("FROMWALLETBALANCEAFTER")}</th>
                            <th className="thr-titles pb-15">{t("TOWALLETBALANCEBEFORE")}</th>
                            <th className="thr-titles pb-15">{t("TOWALLETBALANCEAFTER")}</th>
                          </tr>
                        </thead>
                        <br />
                        <tbody className="thr-tbody">
                          <DynamicLoadTable
                            pageSize={20}
                            apiQuery={TransferUrl}
                            searchEnabled={true}
                            isTable={true}
                            render={(item, i) => {
                              return (
                                <tr className="thr-item" key={i}>
                                  <td className="thr-item-date">
                                    {moment(item["createdTime"]).format("D MMMM YYYY")}
                                  </td>
                                  <td className="thr-item-amount">
                                    {numberWithCurrencyFormat(
                                      parseFloat(item["walletAmount"]),
                                      2
                                    )}
                                  </td>
                                  <td className="thr-item-amount">
                                    {numberWithCurrencyFormat(
                                      parseFloat(item["conversionRate"]),
                                      2
                                    )}
                                  </td>
                                  <td className="thr-item-amount">
                                    {numberWithCurrencyFormat(
                                      parseFloat(item["depositAmount"]),
                                      2
                                    )}
                                  </td>
                                  <td className="thr-item-fromWallet">
                                    {item["transferPerson"]}
                                  </td>
                                  <td className="thr-item-toWallet">
                                    {item["productName"]}
                                  </td>
                                  <td className="thr-item-bonus">
                                    {item["bonus"]}
                                  </td>
                                  <td className="thr-item-fromWalletBalanceBefore">
                                    {numberWithCurrencyFormat(
                                      parseFloat(item["walletBefore"]),
                                      2
                                    )}
                                  </td>
                                  <td className="thr-item-fromWalletBalanceAfter">
                                    {numberWithCurrencyFormat(
                                      parseFloat(item["walletAfter"]),
                                      2
                                    )}
                                  </td>
                                  <td className="thr-item-toWalletBalanceBefore">
                                    {numberWithCurrencyFormat(
                                      parseFloat(item["gameWalletBefore"]),
                                      2
                                    )}
                                  </td>
                                  <td className="thr-item-toWalletBalanceAfter">
                                    {numberWithCurrencyFormat(
                                      parseFloat(item["gameWalletAfter"]),
                                      2
                                    )}
                                  </td>
                                </tr>
                              );
                            }}
                          />
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal
          isOpen={showTransactionDetailPopup}
          contentClassName="transaction-detail-popup-modal-body-wrapper modal-brand"
          wrapClassName="transaction-detail-popup-modal"
          toggle={() => {
            setShowTransactionDetailPopup(false);
          }}
          centered
        >
          <ModalBody>
            <div className="row justify-content-center">
              <div className="transaction-detail-title">
                {t(`${currentItem["transactionTypeString"].toUpperCase()}_RECORD_DETAIL`)}
              </div>
            </div>

            {(() => {
              switch (currentItem["approvalStatus"]) {
                case TransactionStatus._APPROVED:
                  return (
                    <>
                      <div className="row justify-content-center">
                        <div className="transaction-detail-status-icon">
                          <Icon
                            path={mdiCheckCircle}
                            size={0.8}
                            color="#03BB3A"
                          />
                        </div>
                      </div>
                      <div className="row justify-content-center">
                        <div className="transaction-detail-status">
                          <span style={{ color: "#00FA4B" }}>
                            {t("APPROVED")}
                          </span>
                        </div>
                      </div>
                    </>
                  );
                case TransactionStatus._REJECTED:
                  return (
                    <>
                      <div className="row justify-content-center">
                        <div className="transaction-detail-status-icon">
                          <Icon path={mdiCloseCircle} size={0.8} color="red" />
                        </div>
                      </div>
                      <div className="row justify-content-center">
                        <div className="transaction-detail-status">
                          <span style={{ color: "#FF0000" }}>
                            {t("REJECTED")}
                          </span>
                        </div>
                      </div>
                    </>
                  );
                case TransactionStatus._OPEN:
                  return (
                    <>
                      <div className="row justify-content-center">
                        <div className="transaction-detail-status-icon">
                          <Icon
                            path={mdiDotsHorizontalCircle}
                            size={0.8}
                            color="#002e6c"
                          />
                        </div>
                      </div>
                      <div className="row justify-content-center">
                        <div className="transaction-detail-status">
                          <span style={{ color: "#002e6c" }}>
                            {t("PROCESSING")}
                          </span>{" "}
                        </div>
                      </div>
                    </>
                  );
                default:
                  return (
                    <>
                      <div className="row justify-content-center">
                        <div className="transaction-detail-status-icon">
                          <Icon
                            path={mdiDotsHorizontalCircle}
                            size={0.8}
                            color="red"
                          />
                        </div>
                      </div>
                      <div className="row justify-content-center">
                        <div className="transaction-detail-status">
                          <span style={{ color: "#FF0000" }}>
                            {t("PROCESSING")}
                          </span>{" "}
                        </div>
                      </div>
                    </>
                  );
              }
            })()}

            <div className="row justify-content-center">
              <div className="transaction-detail-amount">
                {numberWithCurrencyFormat(
                  parseFloat(`${currentItem["amount"]}`),
                  3
                )}
                {/* {`RM ${currentItem["amount"]}`} */}
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="transaction-detail-date">
                {t("ON") + " " + `${moment(currentItem["dateTime"]).format(
                  "h:mm:ssA, MM/DD/YYYY"
                )}`}
              </div>
            </div>
          </ModalBody>
        </Modal>
      </section>
    </>
  );
};
export default TransactionHistory;
