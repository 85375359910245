import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { WebUrl } from "../../util/Constant";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

const ContentHeader = ({
  enableBackButton,
  title,
  backButtonOnClick,
  escButtonOnClick,
  enableEscButton,
}) => {
  const _history = useHistory();
  const { t } = useTranslation();
  useEffect(() => {
    window.addEventListener("keydown", (e) => {
      if (e.key === "Escape") {
        _history.replace(WebUrl._URL_MAIN);
      }
    });
  }, []);

  return (
    <div className="right-title-box">
      <div className="first">
        <h3>
          {enableBackButton && (
            <>
              <a onClick={() => backButtonOnClick()}>
                <img
                  src="/img/version3/modal-back.png"
                  className="img-responsive"
                  alt="back"
                />
              </a>
              &nbsp;
            </>
          )}
          {t(title)}
        </h3>
      </div>
      {enableEscButton && (
        <>
          <div className="second">
            {/* <div onClick={() => escButtonOnClick()}> */}
            <div onClick={() => _history.replace(WebUrl._URL_MAIN)}>
              <img
                src="/img/assets/close.svg"
                className="img-responsive"
                alt="close"
              />
              <span>ESC</span>
            </div>
          </div>
        </>
      )}

    </div>
  );
};

ContentHeader.propTypes = {
  enableBackButton: PropTypes.bool,
  title: PropTypes.string.isRequired,
  backButtonOnClick: PropTypes.func,
  escButtonOnClick: PropTypes.func,
  enableEscButton: PropTypes.func,
};

ContentHeader.defaultProps = {
  enableBackButton: false,
  enableEscButton: false,
  backButtonOnClick: () => window.history.go(-1),
  escButtonOnClick: () => { },
};

export default ContentHeader;
