import React, { useState, useEffect, useRef } from "react";
import { filter, indexOf, map } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import Input from "../custom/Input";
import { performLogin, performLogout, updateUserData } from "../../redux/AuthAction.js";
import { Link, useHistory, useLocation } from "react-router-dom";
import {
  LanguageOption,
  ApiUrl,
  ApiKey,
  SessionKey,
  TransactionType,
  BankingChannel,
  PanelType,
  WebUrl,
  _MAIN_WALLET_ID,
  AlertTypes,
  DisplayDevice,
  Language,
} from "./../../util/Constant.js";
import {
  setBusy,
  setIdle,
  showMessage,
  showResponseMessage
} from "../../redux/AppAction.js";
import { setDisplayLogin, setDisplayRegister } from "../../redux/AppAction";
import { useTranslation } from "react-i18next";
import { saveLanguageId } from "../../redux/LanguageAction.js";
import ApiEngine from "./../../util/ApiEngine.js";
import {
  createFormBody,
  createMultiPartFormBody,
  numberWithCurrencyFormat,
  stringIsNullOrEmpty,
} from "./../../util/Util.js";
import { Alert, Modal, ModalBody } from "reactstrap";
import PinInput from "react-pin-input";
import { useForm, Controller } from "react-hook-form";
import { param } from "jquery";



const SideMenu = () => {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const search = window.location.search;
  const params = new URLSearchParams(search);
  var _userData = useSelector((state) => state.authState.userData);
  const { isLoggedIn } = useSelector((state) => state.authState);
  const { activeTab } = useSelector((state) => state.appState);
  var _dispatch = useDispatch();
  const _history = useHistory();
  const [isSettingsDropdownVisible, setIsSettingsDropdownVisible] =
    useState(false);
  const [appUrl, setAppUrl] = useState("");
  const [downloadURL, setDownloadURL] = useState("");
  const [selectedLanguage, setSelectedLanguage] = useState();
  const [selectedLanguageCode, setSelectedLanguageCode] = useState('th');
  const [showCreateShareholderPin, setShowCreateShareholderPin] = useState(false);
  const [showPinEntry, setShowPinEntry] = useState(false);
  const [isShareholder, setIsShareholder] = useState(false);
  const [pinEntered, setPinEntered] = useState(false);
  const [invalidPin, setInvalidPin] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [createShareholderPin, setCreateShareholderPin] = useState(false);
  const [pinNumberFinal, setPinNumberFinal] = useState("");
  const [enteredPinNumberFinal, setEnteredPinNumberFinal] = useState("");
  const [pinNumberFinalFocused, setPinNumberFinalFocused] = useState(0);
  const [forgetPin, setForgetPin] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const eleFinalPin = useRef(null);
  const [countdownPeriod, setCountdownPeriod] = useState(0);
  const [totalDownlines, setTotalDownlines] = useState(0);
  const _COUNTDOWN_PERIOD = 120;
  const _ALERT_TIMEOUT = 3000;
  const _SECONDS_IN_MS = 1000;
  const [dailyCheckinStatus, setDailyCheckinStatus] = useState(false);
  const [isLogoutProcess, setIsLogoutProcess] = useState(false);

  const {
    handleSubmit,
    control,
    register,
    setValue,
    errors,
  } = useForm();
  const sideMenuData = [
    {
      image: "/img/version3/menu/home.svg",
      label: "HOME",
      link: WebUrl._URL_MAIN,
      isAuth: false,
      category: ""
    },
    {
      image: "/img/version3/menu/promotion.svg",
      label: "PROMOTION",
      link: WebUrl._URL_PROMOTION,
      isAuth: false
    },
    {
      image: "/img/version3/menu/check-in.svg",
      label: "DAILY_CHECK_IN",
      link: WebUrl._URL_DAILY_CHECK_IN,
      isAuth: true,
      category: "daily-check-in"
    },
    {
      image: "/img/version3/menu/game-point.svg",
      label: "GAMEPOINT_REDEMPTION",
      link: WebUrl._URL_LOYALTY_REDEMPTION,
      isAuth: true,
      category: "loyalty"
    },
    {
      image: "/img/version3/menu/kpi.svg",
      label: "KPI",
      link: WebUrl._URL_KPI,
      isAuth: true,
      category: "kpi"
    },
    //{
    //  image: "/img/version3/menu/4d.svg",
    //  label: "4D_RESULT",
    //  link: WebUrl._URL_4D_RESULT,
    //  isAuth: false,
    //  category: "4d-result"
    //},
    {
      image: "/img/version3/menu/referral.svg",
      label: "REFERRAL",
      link: WebUrl._URL_REFERRAL,
      isAuth: true,
      category: "referral"
    },
    {
      image: "/img/version3/menu/downline.svg",
      label: "DOWNLINES",
      link: WebUrl._URL_DOWNLINE,
      isAuth: true,
      category: "downline"
    },
    {
      image: "/img/version3/menu/vip.svg",
      label: "VIP_EVENT",
      link: WebUrl._URL_VIP +
        "?GAzobE73Vse4nr8WpufA=" +
        _userData.hideSummary,
      isAuth: true,
      category: "vip-event"
    },
    {
      image: "/img/version3/menu/reports.svg",
      label: "REPORTS",
      link: WebUrl._URL_REPORT,
      isAuth: true,
      category: "report"
    },
    {
      image: "/img/version3/menu/rewards.svg",
      label: "REWARDS",
      link: WebUrl._URL_REWARDS,
      isAuth: true,
      category: "rewards"
    },
    {
      image: "/img/version3/menu/video-tutorial.svg",
      label: "VIDEO_TUTORIAL",
      link: WebUrl._URL_VIDEO_TUTORIAL,
      isAuth: false,
      category: "video-tutorial"
    },
    {
      image: "/img/version3/menu/live-chat.svg",
      label: "LIVE_CHAT",
      link: WebUrl._URL_CONTACT_US,
      isAuth: false,
      category: "contact-us"
    },
    {
      image: "/img/version3/menu/setting.svg",
      label: "SETTINGS",
      link: WebUrl._URL_SETTINGS,
      isAuth: false,
      category: "settings"
    },
  ];

  const sideMenuData2 = [
    {
      image: "/img/version3/menu/home.svg",
      label: "HOME",
      link: WebUrl._URL_MAIN,
      isAuth: false
    },
    {
      image: "/img/version3/menu/promotion.svg",
      label: "PROMOTION",
      link: WebUrl._URL_PROMOTION,
      isAuth: false
    },
    {
      image: "/img/version3/menu/about.svg",
      label: "ABOUT_US",
      link: WebUrl._URL_ABOUT.replace(":category", "about-us"),
      isAuth: false
    },
    {
      image: "/img/version3/menu/help-centre.svg",
      label: "HELP_CENTRE",
      link: WebUrl._URL_HELP_CENTRE,
      isAuth: false
    },
    {
      image: "/img/version3/menu/afffilate.svg",
      label: "AFFILIATE_PARTNER_PROGRAM",
      link: WebUrl._URL_ABOUT.replace(":category", "affiliate-partner-program"),
      isAuth: false
    },
    {
      image: "/img/version3/menu/privacy.svg",
      label: "PRIVACY_POLICY",
      link: WebUrl._URL_ABOUT.replace(":category", "privacy-policy"),
      isAuth: false
    },

    {
      image: "/img/version3/menu/tnc.svg",
      label: "TERMS_AND_CONDITIONS",
      link: WebUrl._URL_ABOUT.replace(":category", "terms-and-conditions"),
      isAuth: false
    },
    {
      image: "/img/version3/menu/responsible-gambling.svg",
      label: "RESPONSIBLE_GAMBLING",
      link: WebUrl._URL_ABOUT.replace(":category", "responsible-gambling"),
      isAuth: false
    },

  ];

  const sideMenuData3 = [
    {
      image: "/img/version3/menu/download-app.svg",
      label: "DOWNLOAD_APP",
      link: downloadURL,
      isAuth: false
    },
  ];

  useEffect(() => {
    // const value = LanguageOption.find((item) => localStorage.getItem(SessionKey._LANGUAGE) === item.code );
    if (params.get("languageCode") != null) {
      onChangeLanguage(params.get("languageCode"));
      localStorage.setItem(SessionKey._LANGUAGE, params.get("languageCode"));
    }
    else if (localStorage.getItem(SessionKey._LANGUAGE) && localStorage.getItem(SessionKey._LANGUAGE) != 'undefined') {
      if (LanguageOption.find(
        (x) => x.code === localStorage.getItem(SessionKey._LANGUAGE)) != undefined) {
        setSelectedLanguage(
          LanguageOption.find(
            (x) => x.code === localStorage.getItem(SessionKey._LANGUAGE)
          ).prefix
        );
        setSelectedLanguageCode(
          LanguageOption.find(
            (x) => x.code === localStorage.getItem(SessionKey._LANGUAGE)
          ).code
        );


        let downloadURL = (window.location.href.includes("staging") || window.location.href.includes("localhost")) ?
          "http://download.i8global.com/?lang=" + localStorage.getItem(SessionKey._LANGUAGE) + "&country=my"
          :
          "http://download.i8king.com/?lang=" + localStorage.getItem(SessionKey._LANGUAGE) + "&country=my"
          ;

        setDownloadURL(downloadURL);
        i18n.changeLanguage(localStorage.getItem(SessionKey._LANGUAGE));
      }
      else {
        i18n.changeLanguage("en");
        setSelectedLanguage("EN");
        setSelectedLanguageCode("EN");
        let downloadURL = (window.location.href.includes("staging") || window.location.href.includes("localhost")) ?
          "http://download.i8global.com/?lang=" + localStorage.getItem(SessionKey._LANGUAGE) + "&country=my"
          :
          "http://download.i8king.com/?lang=" + localStorage.getItem(SessionKey._LANGUAGE) + "&country=my"
          ;

        setDownloadURL(downloadURL);
        localStorage.setItem(SessionKey._LANGUAGE, "en");
      }
    } else {
      i18n.changeLanguage("en");
      setSelectedLanguage("EN");
      setSelectedLanguageCode("EN");
      let downloadURL = (window.location.href.includes("staging") || window.location.href.includes("localhost")) ?
        "http://download.i8global.com/?lang=" + localStorage.getItem(SessionKey._LANGUAGE) + "&country=en"
        :
        "http://download.i8king.com/?lang=" + localStorage.getItem(SessionKey._LANGUAGE) + "&country=en"
        ;

      setDownloadURL(downloadURL);
      localStorage.setItem(SessionKey._LANGUAGE, "en");
    }
  }, [(localStorage.getItem(SessionKey._LANGUAGE) && localStorage.getItem(SessionKey._LANGUAGE) != 'undefined' ?
    localStorage.getItem(SessionKey._LANGUAGE) : 'en')]);

  const onChangeLanguage = async (selectedLanguage) => {
    _dispatch(setBusy());
    setSelectedLanguage(
      LanguageOption.find((x) => x.code === selectedLanguage).prefix
    );
    i18n.changeLanguage(selectedLanguage);
    if (!isLoggedIn) {
      _dispatch(saveLanguageId(selectedLanguage, true));
    } else {
      _dispatch(saveLanguageId(selectedLanguage));
      let data = {};
      // data["id"] = _userData.userId;
      // data["selectedTheme"] = _userData.selectedTheme;
      data["languageSetting"] = selectedLanguage;
      // data["isMemberOperate"] = true;

      // Silently update user's language settings
      await ApiEngine.post(
        // ApiUrl._API_CREATE_OR_UPDATE_MEMBER,
        ApiUrl._API_UPDATE_MEMBER_LANGUAGE,
        createMultiPartFormBody(data)
      );

      if (window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage(
          JSON.stringify({
            action: "language",
            language: selectedLanguage,
          })
        );
      }

    }
    _dispatch(setIdle());
  };

  useEffect(() => {
    if (isLoggedIn) {
      checkShareHolder();
      checkDailyCheckin();
    }
  }, [isLoggedIn]);

  async function checkDailyCheckin() {
    if (isLoggedIn) {
      let responseJson = await ApiEngine.get(ApiUrl._API_CHECK_DAILY_CHECK_IN_STATUS);
      if (responseJson[ApiKey._API_SUCCESS_KEY] === true) {
        var status = responseJson[ApiKey._API_DATA_KEY];
        setDailyCheckinStatus(status);
      }
    }
  }

  const checkShareHolder = async () => {
    if (isLoggedIn) {
      let responseJson = await ApiEngine.get(ApiUrl._API_GET_MEMBER_DETAILS_BY_ID);
      if (responseJson[ApiKey._API_SUCCESS_KEY]) {
        if (responseJson[ApiKey._API_DATA_KEY]["isShareHolder"] === true) {
          setIsShareholder(responseJson[ApiKey._API_DATA_KEY]["isShareHolder"]);
          let params = {};
          var responseJson2 = await ApiEngine.post(
            ApiUrl._API_CHECK_SHAREHOLDER_PIN_NUMBER,
            createFormBody(params)
          );

          if (!responseJson2[ApiKey._API_SUCCESS_KEY]) {
            setCreateShareholderPin(true);
          }
        }
        setTotalDownlines(responseJson[ApiKey._API_DATA_KEY]["totalDownlines"]);
      }
    }
  }

  useEffect(() => {
    if (countdownPeriod > 0) {
      setTimeout(() => {
        let tempPeriod = countdownPeriod - 1;
        setCountdownPeriod(tempPeriod);
      }, _SECONDS_IN_MS);
    }
  }, [countdownPeriod]);

  function onVerifyPinKeyPress(value) {
    let currentIndex = 0;

    let pinStr = enteredPinNumberFinal;
    if (value !== "{bksp}") {
      if (pinNumberFinal === "") {
        currentIndex = pinNumberFinalFocused;
        // eleFinalPin["current"]["elements"][currentIndex].state.value = value;
        pinStr = value;
        setEnteredPinNumberFinal(pinStr);
        if (currentIndex !== 3) {
          currentIndex++;
          setPinNumberFinalFocused(currentIndex);
          eleFinalPin["current"]["elements"][currentIndex].focus();
        }
      }
    } else {
      eleFinalPin["current"].clear();
      setPinNumberFinal("");
      setEnteredPinNumberFinal("");
      setPinNumberFinalFocused(currentIndex);
      eleFinalPin["current"]["elements"][currentIndex].focus();
    }
  }

  const verifyPin = async () => {
    let params = {
      pinNumber: enteredPinNumberFinal,
    };
    let responseJson = await ApiEngine.post(
      ApiUrl._API_CHECK_SHAREHOLDER_PIN_NUMBER,
      createFormBody(params)
    );
    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      localStorage.setItem("6FAZhVi1QvBGsKaUl7WPhVTwRPf1HUhF", "wdAvBAPKCPB1TvXPsfPSda8PUUdEGaHg");
      setShowPinEntry(false);
      _history.push(WebUrl._URL_VIP +
        "?GAzobE73Vse4nr8WpufA=" +
        _userData.hideSummary);

    } else {
      localStorage.setItem("6FAZhVi1QvBGsKaUl7WPhVTwRPf1HUhF", "2vr5EqInInUuJj9LS0nK7e3hIfjKAGJv");
      _dispatch(showResponseMessage(responseJson[ApiKey._API_SUCCESS_KEY], responseJson[ApiKey._API_MESSAGE_KEY]))
      setInvalidPin(true);
      setErrorMessage(responseJson[ApiKey._API_MESSAGE_KEY]);
      setPinNumberFinal("");
      setEnteredPinNumberFinal("");
      setPinNumberFinalFocused(0);
      eleFinalPin["current"].clear();
    }
  };

  async function sendPinCode() {
    if (countdownPeriod < 1) {
      let responseJson = await ApiEngine.get(
        ApiUrl._API_CHECK_IF_PHONE_VERIFIED
      );
      if (responseJson[ApiKey._API_SUCCESS_KEY]) {
        var hpNum = responseJson[ApiKey._API_DATA_KEY];
        let params = {
          phoneNumber: hpNum,
          isShareholder: "true",
        };
        let smsJson = await ApiEngine.post(
          ApiUrl._API_SEND_PIN_CODE,
          createFormBody(params)
        );
        if (smsJson[ApiKey._API_SUCCESS_KEY]) {
          setForgetPin(true);
          setCountdownPeriod(_COUNTDOWN_PERIOD);
        }
      }
    } else {
      setShowAlert(true);
      setTimeout(() => setShowAlert(false), [_ALERT_TIMEOUT]);
    }
  }


  return (
    <>
      <div id="side-menu">
        {!isLoggedIn ?
          <>
            <div className="side-menu-top">
              <div className="title">
                {t("WELCOME_TO_I8LIVE")}
              </div>
              <div className="wave-icon">
                <img src="/img/version3/menu/wave.png" alt="wave-icon" />
              </div>
            </div>
          </>
          :
          <>
            {/* ///show when login// */}
            <div className="side-menu-top login">
              <div>
                <img src="/img/version3/menu/user.png" alt="user-icon" />
              </div>
              <div>
                <div className="title">{_userData["username"]}</div>
                <div className="details">{totalDownlines} {t("DOWNLINE")}</div>
              </div>
              {/* ///end show when login// */}
            </div>
            <hr className="hr-normal mt-3 mb-3" />
          </>
        }
        <div className="side-menu-list">
          {isLoggedIn &&
            sideMenuData &&
            sideMenuData.length &&
            sideMenuData
              .filter((x) => (!isLoggedIn ? !x.isAuth : !isShareholder ? x.label !== "VIP_EVENT" : x))
              .filter((x) => (!dailyCheckinStatus ? x.label !== "DAILY_CHECK_IN" : x))
              .map((item, index) => {
                return (
                  <div key={"side-menu-items" + item.label} style={{ cursor: "pointer" }}
                    onClick={() => {
                      if (item.label === "VIP_EVENT" && window.location.href !== WebUrl._URL_VIP +
                        "?GAzobE73Vse4nr8WpufA=" +
                        _userData.hideSummary) {
                        if (createShareholderPin) {
                          setShowCreateShareholderPin(true);
                          setShowPinEntry(false);
                        }
                        else {
                          setShowCreateShareholderPin(false);
                          setShowPinEntry(true);
                        }

                      }
                      else {
                        _history.push(item.link)
                      }
                    }}>
                    <div
                      key={item.link}
                      className={"side-menu-item " + ((location.pathname.includes(item.category) && item.link !== '/') ? "active" : (location.pathname.substring(location.pathname.indexOf('/') + 1) === "" && item.link === '/') ? "active" : "")}
                    >
                      <div className="side-menu-icon">
                        <img
                          src={item.image}
                          alt={item.label}
                        />
                      </div>
                      <div>{t(item.label)}</div>
                    </div>
                  </div>
                );
              })}
        </div>
        <hr className="hr-normal mt-3 mb-3" />
        <div className="side-menu-list">
          {sideMenuData2 &&
            sideMenuData2.length &&
            sideMenuData2
            .filter((x) => (isLoggedIn ? x.label !== "HOME" : x))
            .filter((x) => (isLoggedIn ? x.label !== "PROMOTION" : x))
              .map((item, index) => {
                return (
                  <div key={"side-menu-2-items" + item.label} onClick={() => { _history.push(item.link); }} style={{ cursor: "pointer" }}>
                    <div className={"side-menu-item " + (item.link === location.pathname ? "active" : "")}>
                      <div className="side-menu-icon">
                        <img
                          src={item.image}
                          alt={item.label}
                        />
                      </div>
                      <div>{t(item.label)}</div>
                    </div>
                  </div>
                );
              })}
        </div>
        <hr className="hr-normal mt-3 mb-3" />
        <div className="side-menu-list">
          {sideMenuData3 &&
            sideMenuData3.length &&
            map(sideMenuData3, (item, index) => {
              return (
                <a key={"side-menu-3-items" + item.label} href={item.link}>
                  <div className={"side-menu-item " + (item.link === location.pathname ? "active" : "")}>
                    <div className="side-menu-icon">
                      <img
                        src={item.image}
                        alt={item.label}
                      />

                    </div>
                    <div>{t(item.label)}</div>
                  </div>
                </a>
              );
            })}
        </div>
        {isLoggedIn ? (
          <div className="logout-button mt-5">
            <div className="first">
              <img src="/img/version3/menu/logout.svg" alt="logout" />
            </div>
            <div onClick={() => {
              setIsLogoutProcess(true);
              _dispatch(performLogout());
              _history.replace(WebUrl._URL_MAIN);
              setIsSettingsDropdownVisible(false);
              localStorage.removeItem('isReadAllNotification');
              localStorage.removeItem('unreadNotificationCount');
            }}>
              {isLogoutProcess && isLoggedIn ? t("LOGGING_OUT") + "..." : t("LOGOUT")}</div>
          </div>
        ) : (
          <div className="mt-5 side-menu-login-register">
            <div className="side-menu-login"><div onClick={() => _dispatch(setDisplayLogin(true))}>{t("LOGIN")}</div></div>
            <div>|</div>
            <div className="side-menu-register"><div onClick={() => _dispatch(setDisplayRegister(true))}>{t("REGISTER")}</div></div>
          </div>
        )}
      </div>

      <Modal
        contentClassName="transaction-pin-modal"
        fade={false}
        isOpen={isLoggedIn && showCreateShareholderPin}
        toggle={() => setShowCreateShareholderPin(false)}
        centered
      >
        <ModalBody>
          <form onSubmit={handleSubmit(createShareholderPin)}>
            <div className="transaction-pin">
              <div>
                {t("SET_SHAREHOLDER_NEW_PIN")}
              </div>
              <div className="custom-pin-container mt-4">
                <PinInput
                  length={4}
                  autocomplete="off"
                  initialValue=""
                  secret={true}
                  autoSelect={true}
                  disabled={false}
                  type="numeric"
                  inputMode="number"
                  regexCriteria={/^[ 0-9_@./#&+-]*$/}
                  onChange={(value, index) => {
                    onVerifyPinKeyPress(value, index);
                  }}
                  ref={eleFinalPin}
                  inputFocusStyle={{
                    borderBottom: "2px solid #FFC159",
                  }}

                />
              </div>
              <div>
                {t("CONFIRM_SHAREHOLDER_NEW_PIN")}
              </div>
              <div className="custom-pin-container mt-4">
                <PinInput
                  length={4}
                  autocomplete="off"
                  initialValue=""
                  secret={true}
                  autoSelect={true}
                  disabled={false}
                  type="numeric"
                  inputMode="number"
                  regexCriteria={/^[ 0-9_@./#&+-]*$/}
                  onChange={(value, index) => {
                    onVerifyPinKeyPress(value, index);
                  }}
                  ref={eleFinalPin}
                  inputFocusStyle={{
                    borderBottom: "2px solid #FFC159",
                  }}

                />
              </div>
            </div>
            <br />


            <button type="submit" disabled={pinNumberFinal.length != 4} className="custom-btn-style-1">
              {t("SUBMIT")}
            </button>
          </form>
        </ModalBody>
      </Modal>

      <Modal
        contentClassName="transaction-pin-modal"
        fade={false}
        isOpen={isLoggedIn && showPinEntry}
        toggle={() => setShowPinEntry(false)}
        id="vip-pin-modal-new"
        centered
      >
        <ModalBody>
          <form onSubmit={handleSubmit(verifyPin)}>
            <div className="transaction-pin">
              <div>
                {t("ENTER_YOUR_PIN")}
              </div>
              <div className="custom-pin-container mt-4">
                <PinInput
                  length={4}
                  autocomplete="off"
                  initialValue=""
                  secret={true}
                  autoSelect={true}
                  disabled={false}
                  type="numeric"
                  inputMode="number"
                  regexCriteria={/^[ 0-9_@./#&+-]*$/}
                  onChange={(value, index) => {
                    onVerifyPinKeyPress(value, index);
                  }}
                  ref={eleFinalPin}
                  inputFocusStyle={{
                    borderBottom: "2px solid #FFC159",
                  }}

                />
              </div>
            </div>
            <br />
            {forgetPin && countdownPeriod > 0 ? (
              <p
                className="text-brand"
                style={{
                  textAlign: "center",
                  marginTop: "5px",
                }}
              >
                {t("PIN_SENT")} {t("RESEND_IN")} {countdownPeriod}{" "} {t("SECOND")}
              </p>
            ) : (
              <strong
                className="text-brand display-value"
                style={{ cursor: "pointer", paddingTop: '30px' }}
                onClick={() => {
                  setForgetPin(true);
                  sendPinCode();
                }}
              >
                {t("RESET_PIN")}
              </strong>
            )}
            {showAlert && (
              <div
                className="content content-full-width"
                style={{
                  position: "fixed",
                  top: "10px",
                  left: "0",
                  width: "100%",
                  zIndex: 9999,
                }}
              >
                <div className="container">
                  <Alert color="danger">
                    {t("WAIT_TO_RESEND")}
                  </Alert>
                </div>
              </div>
            )}
            <button type="submit" disabled={enteredPinNumberFinal.length != 4} className="custom-btn-style-1">
              {t("SUBMIT")}
            </button>
          </form>
        </ModalBody>
      </Modal>
    </>
  );
};

export default SideMenu;
