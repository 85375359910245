import React, { useState, useEffect } from "react";
import "react-simple-keyboard/build/css/index.css";
import Sidebar from "components/sidebar";
import { filter, map } from "lodash";
import ContentHeader from "components/content/header";
import { useTranslation } from "react-i18next";
import { numberWithCurrencyFormat, stringIsNullOrEmpty } from "../../util/Util";
import { ApiKey, ApiUrl } from "../../util/Constant";
import { showResponseMessage } from "../../redux/AppAction";
import { useDispatch } from "react-redux";
import ApiEngine from "../../util/ApiEngine";
import moment from "moment-timezone";

const PendingTransaction = (props) => {
  const { t } = useTranslation();
  const _dispatch = useDispatch();
  const [countdownPeriod, setCountdownPeriod] = useState(0);
  const [hasPendingTxn, setHasPendingTxn] = useState(false);
  const [pendingTransactions, setPendingTransactions] = useState([]);
  const [countdownStr, setCountdownStr] = useState("");
  const [timeDiffSeconds, setTimeDiffSeconds] = useState(0);
  const [transType, setTransType] = useState("");
  const [transAmt, setTransAmt] = useState(0);
  const [dateTime, setDateTime] = useState(moment());
  const [countdownTime, setCountdownTime] = useState();
  const _SECONDS_IN_MS = 1000;
  const [tempItems, setTempItems] = useState([]);

  async function init() {
    try {
      // Handling all pending transactions
      var checkPendingRes = await ApiEngine.get(
        ApiUrl._API_GET_PENDING_TRANSACTION_BY_MEMBER_DEPOSIT_AND_WITHDRAWAL
      );

      const { data } = checkPendingRes;

      if (data.length > 0) { 
        const newPendingTransactionRecords = data.map((item, index) => {
          var now = moment();           
          var itemdatetime = moment(item.dateTime, 'DD/MM/YYYY, HH:mm:ss');
          var timespan; 
          if(item.channel == '')
          {
            itemdatetime = moment(item.dateTime, 'DD/MM/YYYY, HH:mm:ss').add(20, 'minutes'); 
            timespan = itemdatetime.diff(now, 'seconds');
          }
          else
          {
            itemdatetime = moment(item.dateTime, 'DD/MM/YYYY, HH:mm:ss'); 
            timespan = 0;
          }
                    
          let tempS = timespan; 
          setTimeDiffSeconds(tempS);
          setCountdownPeriod(tempS < 0 ? 0 : tempS);
          return {...item, timeSpan: timespan};
        });

        setPendingTransactions(newPendingTransactionRecords);
        setHasPendingTxn(true);
      }
    } catch (err) {
      _dispatch(showResponseMessage(false, err));
    }
  }

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    if (countdownPeriod > 0) {
      clearTimeout(countdownTime);
      setCountdownTime(
        setTimeout(() => {
          let tempPeriod = countdownPeriod - 1;
          setCountdownPeriod(tempPeriod);
          let tempX = moment.duration(tempPeriod);
          let tempMM = Math.floor(tempX["_milliseconds"] / 60);
          let tempSS = tempX["_milliseconds"] - tempMM * 60;
          setTimeDiffSeconds(tempX["_milliseconds"]);
          let tempStr =
            "00 : " +
            (tempMM.toString().length > 1 ? tempMM : "0" + tempMM) +
            " " +
            ":" +
            " " +
            (tempSS.toString().length > 1 ? tempSS : "0" + tempSS);
          setCountdownStr(tempStr);
        }, _SECONDS_IN_MS)
      );
    }
}, [countdownPeriod]);

  return (
    <>
      <section className="section-padding" id="withdrawal-section">
        <div className="container">
          <div className="row">
            <div className="col-3" id="account-menu">
              <Sidebar activePage={"WALLET"} />
            </div>
            <div className="col-9">
              <ContentHeader
                enableBackButton
                title={t("PENDING_TRANSACTION")}
              />
              <hr className="right-title-hr" />

              {pendingTransactions.length > 0 &&
                map(pendingTransactions, (item, index) => {
                  return (
                    <div
                      key={index}
                      className="template-box mt-4"
                      id="pending-box"
                    >
                      <div className="pending-transaction-box align-items-center">
                        <div className="first">
                          <h4 className="mb-4">{t("PROCESSING")}</h4>
                          <p className="pending-transaction-time">
                            {(item.timeSpan > 0 && item.timeSpan <= 600) ? countdownStr : ""}
                          </p>
                        </div>
                        <div className="second font-15">
                          <p className="pt-min-title mb-1">
                            {t(item.transactionTypeString.toUpperCase())}
                          </p>
                          <p className="text-white pt-date mb-0">{item.dateTime.replace(/,/g, ' |')}</p>
                          <p className="pt-money mb-0">
                            {/* <span>MYR </span> */}
                            {numberWithCurrencyFormat(
                              parseFloat(item.amount),
                              3
                            )}
                          </p>
                          <p className="text-red font-thin-italic mb-0">
                            *{t("CONTACT_CS_FOR_TRANSACTION")}
                          </p>
                          <span className="circle-orange"></span>
                        </div>
                      </div>
                    </div>
                  );
                })}

              {!hasPendingTxn && (
                <div className="col-md-12">
                  <div className="font-thick">{t("NO_DATA_FOUND")}</div>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default PendingTransaction;
