import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { Spinner } from "reactstrap";
import { useTranslation } from "react-i18next";

const CustomDropdown = ({ options }) => {
  const { t, i18n } = useTranslation();
  const _history = useHistory();
  const [selectedOption, setSelectedOption] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (options.length > 0 && !selectedOption) {
      setSelectedOption(options[0]);
      setIsLoading(false);
    }
  }, [options, selectedOption]);

  const handleSelect = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
  };

  return (
    !isLoading &&
    <div className="custom-dropdown" onMouseEnter={() => setIsOpen(true)} onMouseLeave={() => setIsOpen(false)}>
        <div className="header-wallet-container option">
          <div className="header-balance" style={{ cursor: "pointer" }} onClick={() => { _history.push(selectedOption.url); setIsOpen(false); }}>
            <div className="first">
              <img src={selectedOption.image} alt="balance" />
            </div>
            <div className="second">
              <span>|</span>
            </div>
            <div className="third">
              <span>{t(selectedOption.title)}</span>
              <span>{selectedOption.value ? isNaN(parseFloat(selectedOption.value)) ? "0.00" : selectedOption.value : <Spinner role="status" size="sm" />}</span>
            </div>
            <div className="arrow-container">
              {isOpen ? <img src="/img/arrow-up-blue.svg" alt="arrow up" /> : <img src="/img/arrow-down-blue.svg" alt="arrow down" /> }
            </div>
          </div>
        </div>
        {isOpen && (
          <div className="options">
            {options.filter(x => { return x.title !== selectedOption.title }).map((option) => (
              <div className="header-wallet-container option" onClick={() => { _history.push(option.url); handleSelect(option) }}>
                <div className="header-balance" style={{ cursor: "pointer" }}>
                  <div className="first">
                    <img src={option.image} alt="balance" />
                  </div>
                  <div className="second">
                    <span>|</span>
                  </div>
                  <div className="third">
                    <span>{t(option.title)}</span>
                    <span>{option.value ? isNaN(parseFloat(option.value)) ? "0.00" : option.value : <Spinner role="status" size="sm" />}</span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
  );
};

export default CustomDropdown;