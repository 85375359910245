import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Slider from "react-slick";
import { map } from "lodash";
import { stringIsNullOrEmpty } from "../../util/Util";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { showMessage } from "../../redux/AppAction";
import { AlertTypes, WebUrl } from "../../util/Constant";
import { useHistory } from "react-router-dom";

const PromoBanner = ({ promoBannerList }) => {
  const { t } = useTranslation();
  const _dispatch = useDispatch();
  let _history = useHistory();
  const { isLoggedIn } = useSelector(state => state.authState);
  //const maxBannerSliderToShow = 3;
  const [maxBannerSliderToShow, setMaxBannerSliderToShow] = useState(3);
  useEffect(() => {
    if (promoBannerList.length <= 3) {
      setMaxBannerSliderToShow(promoBannerList.length);
    } else {
      setMaxBannerSliderToShow(3);
    }
  }, [promoBannerList]);
  const bannerSlider = {
    infinite: true,
    slidesToShow: maxBannerSliderToShow,
    slidesToScroll: 1,
    arrows: false,
    dots: false,
    lazyLoad: true,
    autoplay: true,
    autoPlaySpeed: 3000,
    responsive: [
      //{
      //  breakpoint: 2000,
      //  settings: {
      //    slidesToShow: 3,
      //  },
      //},
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  return (
    <div className="mt-5 promotion-new">
      {/* {promoBannerList.length > 0 &&*/}
      {/*       <Slider {...bannerSlider} className="promo-banner-slider">*/}
      {/*         {promoBannerList.map((banner, index) => {*/}
      {/*           return (*/}
      {/*               <div key={"promo-banner-" + index} style={{ textAlign: "center" }} onClick={() => {*/}
      {/*                 _history.push(WebUrl._URL_PROMOTION);*/}
      {/*             }}>*/}
      {/*               <img src={banner.image} className={"promo-banner-img img-" + (index + 1)} style={{ borderRadius: '8px' }} alt={"promo-banner-img img-" + (index + 1)} />*/}
      {/*               </div>*/}
      {/*           )*/}
      {/*         })}*/}
      {/*       </Slider>*/}
      {/*}*/}
      {promoBannerList.length > 0 &&
        promoBannerList.map((banner, index) => {
          return (
            <div style={{ textAlign: "center" }} onClick={() => {
              _history.push(WebUrl._URL_PROMOTION);
            }}>
              <img src={banner.image} className="promo-banner-img" style={{ borderRadius: '8px', maxWidth: '100%' }} alt="promo-banner-img" />
            </div>
          )
        })

      }
    </div>
  );
};

export default PromoBanner;
