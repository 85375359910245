import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { WebUrl } from "util/Constant";
import { useTranslation } from "react-i18next";
const GameCategory = ({ item, active }) => {
  const { t } = useTranslation();
  const availableGameCategory = useSelector((state) => (state.gameState.gameProviders));

  const [link, setLink] = useState("");
  useEffect(() => {
    if (item.originalTitle === "Slots") {
      setLink(WebUrl._URL_GAME_PROVIDER.replace(":title", item.originalTitle.toLowerCase()));
    } else if (item.originalTitle === "All") {
      setLink(WebUrl._URL_MAIN);
    } else {
      setLink("/" + item.originalTitle.toLowerCase());
    }
  }, []);
  return (
    (availableGameCategory && availableGameCategory.filter(
      (n) => n.categoryTitle === item.originalTitle
    ).length > 0 ||
      item.title === "All") &&
    <li>
      <Link
        // to={WebUrl._URL_GAME_PROVIDER.replace(":title", item.originalTitle)}
        to={link}
        className={`cat-item ${active && "active"}`}
      >
        <div className='cat-img'>
          <img
            src={`/img/assets/game-category/svg/${item.originalTitle}.svg`}
            className='img-responsive'
            alt={item.title}
          />
        </div>
        <div className={'cat-details' + (item.originalTitle == "Connect" ? " connect-font" : "")}>{t(item.originalTitle)}</div>
      </Link>
    </li>
  );
};

GameCategory.propTypes = {
  index: PropTypes.number.isRequired,
  item: PropTypes.object.isRequired,
  active: PropTypes.bool,
};

GameCategory.defaultProps = {
  active: false,
};

export default GameCategory;
