import React, { useState, useEffect, useRef } from "react";
import "react-simple-keyboard/build/css/index.css";
import { isEmpty, isNull, isUndefined, map } from "lodash";
import ContentHeader from "components/content/header";
import ApiEngine from "../../util/ApiEngine";
import { useForm, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  ApiKey, ApiUrl, SessionKey, WebUrl,
  LanguageOption, TransactionType
} from "../../util/Constant";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import {
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Modal,
  ModalBody,
  ModalHeader
} from "reactstrap";
import { stringIsNullOrEmpty, createFormBody, numberWithCurrencyFormat2 } from "../../util/Util";
import {
  showResponseMessage,
  showResponseMessageWithoutT,
} from "../../redux/AppAction";
import classnames from "classnames";
import { Link } from "react-router-dom";
import { mdiHistory } from "@mdi/js";
import Icon from "@mdi/react";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import Slider from "react-slick";
import Spinner from 'react-bootstrap/Spinner';
import StyledSelect from "../../components/custom/StyledSelect";
import parse from 'react-html-parser';
import { size } from "lodash";
import { mdiChevronUp } from '@mdi/js';
import { mdiChevronDown } from '@mdi/js';
import { mdiRadioboxMarked } from '@mdi/js';

//deposit platform
import NormalDeposit from "./NormalDeposit";
import InstantDeposit from "./InstantDeposit";

//other
import TickIcon from "../../assets/img/assets/tick.svg";
import BlueWarningIcon from "../../assets/img/v3/notice_blue.svg";
import Breadcrumb from "components/custom/Breadcrumb";
import RightInformation from "../../components/custom/RightInformation";

function CustomToggle({ children, eventKey }) {
  const wrapOnClick = useAccordionButton(eventKey, () => { });
  return <div onClick={wrapOnClick}>{children}</div>;
}

const Deposit = () => {
  const { t } = useTranslation();
  const _dispatch = useDispatch();
  const { setValue, control } = useForm();

  const {
    handleSubmit: handleFullNameSubmit,
    register: registerFullName,
    getValues: getValuesFullName,
    errors: errorsFullName,
    setValue: setValueFullName,
  } = useForm();

  // const _NORMAL_DEPOSIT = 2;
  const _INSTANT_DEPOSIT = 1;
  const _NORMAL_DEPOSIT = 3;

  //bonus deposit
  const [bonusDepositList, setBonusDepositList] = useState([]);
  const [bonusDeposit, setBonusDeposit] = useState("");
  const [bonusDepositName, setBonusDepositName] = useState("");

  //member fullname
  const [showFullnamePopup, setShowFullnamePopup] = useState(false);

  //pending transation
  const [hasPendingTxn, setHasPendingTxn] = useState(false);
  const [countdownStr, setCountdownStr] = useState("");
  const [timeDiffSeconds, setTimeDiffSeconds] = useState(0);
  const [countdownTime, setCountdownTime] = useState();
  const [depositCountdownPeriod, setDepositCountdownPeriod] = useState(0);
  const _SECONDS_IN_MS = 1000;

  //normal deposit content
  const [normalDepositContent, setNormalDepositContent] = useState("");

  //member transaction history
  const [memberTransactionHistory, setMemberTransactionHistory] = useState([]);

  //deposit tutorial
  const [howToDepositModal, setHowToDepositModal] = useState(false);
  const [paymentMethodTypeId, setPaymentMethodTypeId] = useState("");
  const [apiData, setApiData] = useState([]);
  const META_COUNT = 10;
  const [paymentMethodList, setPaymentMethodList] = useState([]);
  const [defaultPaymentMethodActive, setDefaultPaymentMethodActive] = useState(null);

  //other
  const [headerMsg, setHeaderMsg] = useState("");
  const [transType, setTransType] = useState("");
  const [paymentMethodActive, setPaymentMethodActive] = useState(_NORMAL_DEPOSIT);
  const isMounted = useRef(false);
  let _userData = useSelector((state) => state.authState.userData);
  const bannerSlick = {
    slidesToShow: 3,
    dots: true,
    arrows: false,
    swipe: true,
    infinite: false,
    swipeToSlide: true,
    speed: 1000,
  };
  const [displayInstantDeposit, setDisplayInstantDeposit] = useState(true);
  const [displayNormalDeposit, setDisplayNormalDeposit] = useState(true);
  const [recentTransaction, setRecentTransaction] = useState({});

  const breadcrumb = [
    {
      value: 'HOME',
      active: false,
      link: WebUrl._URL_MAIN
    },
    {
      value: 'WALLET',
      active: false,
      link: WebUrl._URL_MAIN_WALLET
    },
    {
      value: 'DEPOSIT',
      active: true,
    },
  ];

  //useEffect
  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    if (isMounted.current) {
      updateBonusDeposit(bonusDepositList[0]);
    }
    else {
      isMounted.current = true;
    }
  }, [bonusDepositList.length > 0 && !isUndefined(bonusDepositList)])


  useEffect(() => {
    if (depositCountdownPeriod > 0) {
      clearTimeout(countdownTime);
      setCountdownTime(
        setTimeout(() => {
          let tempPeriod = depositCountdownPeriod - 1;
          setDepositCountdownPeriod(tempPeriod);
          let tempX = moment.duration(tempPeriod);
          let tempMM = Math.floor(tempX["_milliseconds"] / 60);
          let tempSS = tempX["_milliseconds"] - tempMM * 60;
          setTimeDiffSeconds(tempX["_milliseconds"]);
          let tempStr =
            (tempMM.toString().length > 1 ? tempMM : "0" + tempMM) +
            " " +
            t("MINUTES") +
            " " +
            (tempSS.toString().length > 1 ? tempSS : "0" + tempSS) +
            " " +
            t("SECOND");
          setCountdownStr(tempStr);
        }, _SECONDS_IN_MS)
      );
    }
  }, [depositCountdownPeriod]);

  useEffect(() => {
    getList();
    // getContent();
    // setPaymentMethodTypeId("");
  }, [howToDepositModal == true]);

  //useEffect(() => {
  //  if (!displayInstantDeposit && displayNormalDeposit) {
  //    setPaymentMethodActive(_NORMAL_DEPOSIT);
  //  } else {
  //    setPaymentMethodActive(_INSTANT_DEPOSIT);
  //  }
  //}, [displayInstantDeposit, displayNormalDeposit]);

  //init/call function

  const checkPendingTxn = async () => {
    setHeaderMsg("");
    var pendingTransactionJson = await ApiEngine.get(
      ApiUrl._API_GET_PENDING_TRANSACTION
    );
    if (pendingTransactionJson[ApiKey._API_SUCCESS_KEY]) {
      if (pendingTransactionJson[ApiKey._API_DATA_KEY] != null) {
        clearTimeout(countdownTime);
        let tempS = parseInt(pendingTransactionJson[ApiKey._API_DATA_KEY]["timeSpan"]);
        setTransType(pendingTransactionJson[ApiKey._API_DATA_KEY]["transType"]);
        setTimeDiffSeconds(tempS);
        setDepositCountdownPeriod(tempS < 0 ? 0 : tempS);
        setHasPendingTxn(true);
        setHeaderMsg(t("NEW_TXN_PROHIBITED"));
      }
    } else {
      _dispatch(
        showResponseMessage(false, pendingTransactionJson[ApiKey._API_MESSAGE_KEY])
      );
    }
  }

  const getBonusDeposit = async () => {
    var language = (LanguageOption.filter(option => option.value == localStorage.getItem(SessionKey._LANGUAGE))[0] !== undefined) ? (LanguageOption.filter(option => option.value == localStorage.getItem(SessionKey._LANGUAGE))[0].languageId) : 7;
    var responseJson = await ApiEngine.get(ApiUrl._API_GET_BONUS_DEPOSIT + "?languageId=" + language);

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      let data = responseJson[ApiKey._API_DATA_KEY];
      let itemList = []

      let item = null;
      data.map((row, index) => {
        item =
        {
          name: row["bonusName"],
          bonusId: row["id"],
          info:
          {
            bonus: row["bonus"],
            minimumDeposit: row["minimumDeposit"],
            rollover: row["rollover"],
            maxBonus: row["maxBonus"],
            freeSpinStatus: row["freeSpinStatus"],
            maxLimitPerMember: row["maxLimitPerMember"],
            dayLimit: row["dayLimit"],
            image: row["bonusImage"],
            popImage: row["popupImage"],
            remainingCount: row["remainingCount"],
            refreshDateTime: row["refreshDateTime"] !== "" ? moment(row["refreshDateTime"], "YYYY-MM-DD").format("YYYY-MM-DD HH:mm:ss") : "",
            gameCategory: row["gameCategory"]
          }
        }
        itemList.push(item);
      })
      setBonusDepositList(itemList)
    } else {
      _dispatch(
        showResponseMessage(false, responseJson[ApiKey._API_MESSAGE_KEY])
      );
    }
  }

  const getMemberFullName = async () => {
    let memberJson = await ApiEngine.get(ApiUrl._API_GET_USER_FULLNAME);
    if (memberJson[ApiKey._API_SUCCESS_KEY]) {
      if (
        stringIsNullOrEmpty(memberJson[ApiKey._API_DATA_KEY]["userFullName"])
      ) {
        setShowFullnamePopup(false);
      }
    }
  }

  const getDepositContent = async () => {
    //let activeLanguageCode =
    //    (localStorage.getItem(SessionKey._LANGUAGE) !== null ||
    //        localStorage.getItem(SessionKey._LANGUAGE) !== undefined ||
    //        localStorage.getItem(SessionKey._LANGUAGE) !== "undefined")
    //        ? localStorage.getItem(SessionKey._LANGUAGE) : "en";
    //let responseJson = await ApiEngine.get(ApiUrl._API_GET_COMPANY_CONTENT_LANGUAGE + "?title=NormalDepositTnCDesktop&languageCode=" + activeLanguageCode);
    //if (responseJson[ApiKey._API_SUCCESS_KEY]) {
    //    let data = responseJson[ApiKey._API_DATA_KEY];
    //    setNormalDepositContent(data);
    //}
  }

  const getMemberTransactionHistory = async () => {
    let responseJson = await ApiEngine.get(ApiUrl._API_GET_MEMBER_TRANSACTION_HISTORY);//FIX THIS

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      let data = responseJson[ApiKey._API_DATA_KEY];
      setMemberTransactionHistory(data);
    }

  }

  async function getList() {
    let reponseJ = await ApiEngine.get(ApiUrl._API_GET_DEPOSIT_TUTORIAL_CONTENT);

    const methodList = [];
    if (reponseJ[ApiKey._API_SUCCESS_KEY]) {
      reponseJ[ApiKey._API_DATA_KEY].forEach(function (method) {
        if (method.status) {
          if (method.paymentMethod == 1) {
            methodList.push({
              label: t("INSTANT_PAYMENT"),
              value: method.paymentMethod,
            });
          } else if (method.paymentMethod == 2) {
            methodList.push({
              label: t("DUITNOW"),
              value: method.paymentMethod,
            });
          } else {
            methodList.push({
              label: t("NORMAL_DEPOSIT"),
              value: method.paymentMethod,
            });
          }
        }
      })
    }
    if (methodList.length > 0) {
      const paymentMethodActiveState = paymentMethodActive;
      const value = paymentMethodActiveState == 0 ? methodList[0].value : paymentMethodActiveState;

      // if in BO disable certain deposit tutorial and cannot found, will get back the list to show
      const paymentMethodListArr = methodList.filter(x => x.value == value);

      if (size(paymentMethodListArr) === 0) {
        setDefaultPaymentMethodActive(methodList[0].value);
        getContent(methodList[0].value);
      }
      else {
        setDefaultPaymentMethodActive(value);
        getContent(value);
      }

      setPaymentMethodList(methodList);
    }


    // setPaymentMethodList(methodList);
    // getContent(paymentMethodActive);
  }

  async function getContent(paymentMethod) {

    var id = paymentMethod;

    var language = (LanguageOption.filter(option => option.value == localStorage.getItem(SessionKey._LANGUAGE))[0] !== undefined) ? (LanguageOption.filter(option => option.value == localStorage.getItem(SessionKey._LANGUAGE))[0].languageId) : 7;
    let responseJson = await ApiEngine.get(ApiUrl._API_GET_DEPOSIT_TUTORIAL_CONTENT_LANGUAGE + "?LanguageId=" + language + "&depositTutorialId=" + id);

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      var data = responseJson[ApiKey._API_DATA_KEY][0];
      if (!stringIsNullOrEmpty(data)) {
        var content = JSON.parse(data["content"]);
        var contentLength = Object.keys(content).length;


        var newData = [];
        for (var i = 0; i < contentLength; i++) {
          newData[i] = {
            name: (i + 1) + ".",
            key: content["parameter_" + (i + 1)],
            value: content["parameter_" + (i + 1)],
          };

        }
        setApiData([]);
        setApiData(newData);
      }
      else {
        setApiData([]);
      }
    }
  }

  const getRecentTransaction = async () => {
    var recentTransactionJson = await ApiEngine.get(ApiUrl._API_GET_RECENT_TRANSACTION_RECORD + "?transactionTypeId=" + TransactionType._DEPOSIT);

    if (recentTransactionJson[ApiKey._API_SUCCESS_KEY]) {
      setRecentTransaction(recentTransactionJson[ApiKey._API_DATA_KEY]);
    }
  }

  const init = () => {
    checkPendingTxn();
    getMemberFullName();
    getBonusDeposit();
    getDepositContent();
    getMemberTransactionHistory();
    getRecentTransaction();
  }


  //other function

  async function updateBonusDeposit(menu) {
    setBonusDeposit(menu["bonusId"]);
    setBonusDepositName(menu["name"]);
  }

  async function submitFullName(data) {
    let params = {
      userFullName: data.userFullName,
    };

    let responseJson = await ApiEngine.post(
      ApiUrl._API_UPDATE_MEMBER_USERFULLNAME,
      createFormBody(params)
    );

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      _dispatch(
        showResponseMessage(
          responseJson[ApiKey._API_SUCCESS_KEY],
          responseJson[ApiKey._API_MESSAGE_KEY]
        )
      );

      setShowFullnamePopup(false);
    } else {
      _dispatch(
        showResponseMessage(
          responseJson[ApiKey._API_SUCCESS_KEY],
          responseJson[ApiKey._API_MESSAGE_KEY]
        )
      );
    }
  }

  //render 
  return (
    <section className="section-padding" id="deposit-section">
      <Breadcrumb
        data={breadcrumb}
      />
      <div className="with-information-container">
        <div className="left">
          <div className="row">
            <div className="col">
              {hasPendingTxn && headerMsg && (
                <div className="alert deposit-alert-warning">
                  {timeDiffSeconds > 0
                    ? t("PLEASE_WAIT") +
                    " " +
                    countdownStr +
                    " " +
                    (transType === "Deposit"
                      ? t("FOR_DEPOSIT_APPROVAL")
                      : t("FOR_WITHDRAWAL_APPROVAL"))
                    : headerMsg}
                </div>
              )}

              <div className="accordion-container">

                <div className="deposit-container-box">
                  <div>
                    <div>
                      <h4 className="font-15 mb-3">
                        {t("BONUS_DEPOSIT")}
                        {!stringIsNullOrEmpty(bonusDeposit)
                          ? bonusDeposit != "-1"
                            ? ": " + bonusDepositName
                            : ": " + t("NONE")
                          : ""}
                      </h4>
                    </div>
                  </div>
                  <div>
                    <div className="bonusdeposit-menu">
                      <div className="font-11 mb-3" style={{ color: '#5c708a' }}>
                        {t("SELECT_PACKAGE_OPTION")}
                      </div>
                      {bonusDepositList.length === 0 ?
                        <div className="loading-spinner-wrapper">
                          <Spinner animation="border" role="status" size="sm">
                            <span className="sr-only">{t("LOADING")}...</span>
                          </Spinner>
                        </div>
                        :
                        <Slider {...bannerSlick} className="deposit-slick">
                          {bonusDepositList.map((menu, index) => {
                            return (
                              <div key={"deposit-bonus-" + index} >
                                <CustomToggle eventKey="1">
                                  <div
                                    className={"deposit-slick-item" + (bonusDeposit == menu["bonusId"] ? ' active' : '')}
                                    onClick={() =>
                                      updateBonusDeposit(menu)
                                    }
                                  >
                                    {/*{bonusDeposit == menu["bonusId"] ?*/}
                                    {/*  <img src={TickIcon} alt="active" className="bonus-deposit-tick " />*/}
                                    {/*  : <></>}*/}
                                    <div className="deposit-bonus-image">
                                      <img
                                        src={menu["info"]["image"]}
                                        className="w-100"
                                        alt={menu["info"]["bonus"]}
                                        loading="lazy"
                                      />
                                    </div>
                                    <div className="deposit-bonus-details">
                                      {menu["info"] &&
                                        <>
                                          <div> {t("BONUS") + " (%) : " + menu["info"]["bonus"]}</div>
                                          <div> {t("MINIMUM_DEPOSIT") + " : RM" + (numberWithCurrencyFormat2(menu["info"]["minimumDeposit"], 2, true))}</div>
                                          <div> {t("ROLLOVER") + " : x" + menu["info"]["rollover"]}</div>
                                          {menu["info"]["bonus"] > 0 && menu["info"]["maxBonus"] && <div> {t("MAX_BONUS") + " : RM" + (numberWithCurrencyFormat2(menu["info"]["maxBonus"], 2, true))}</div>}
                                          {/* <div> {t("ELIGIBLE_FOR_FREE_SPIN") + " : " + (menu["info"]["freeSpinStatus"] ? t("YES") : t("NO"))}</div> */}
                                          <div> {menu["info"]["maxLimitPerMember"] !== 0 && t("CLAIM_LIMIT") + " : " + menu["info"]["maxLimitPerMember"] + " " + t("TIMES") + (menu["info"]["remainingCount"] > 0 ? " (" + menu["info"]["remainingCount"] + " " + t("LEFT") + ")" : "")} </div>
                                          <div> {menu["info"]["maxLimitPerMember"] !== 0 && menu["info"]["dayLimit"] !== 0 &&
                                            <>
                                              {menu["info"]["dayLimit"] === 1 ?
                                                <span className="claim-limit-reminder">{"*" + t("LIMIT_REFRESHES_EVERY_DAY", { value: menu["info"]["maxLimitPerMember"] })}</span>
                                                :
                                                <span className="claim-limit-reminder">{"*" + t("LIMIT_REFRESHES_EVERY_FEW_DAYS", { value: menu["info"]["dayLimit"] })}</span>
                                              }
                                            </>
                                          }</div>
                                          <div>{<li> {t("GAME_CATEGORY") + " : " + String(menu["info"]["gameCategory"]).replaceAll(",", ", ")}</li>}</div>
                                          <div> {menu["info"]["maxLimitPerMember"] !== 0 && menu["info"]["dayLimit"] !== 0 && menu["info"]["refreshDateTime"] !== "" && <span className="claim-limit-reminder">{menu["info"]["refreshDateTime"] !== "" && "*" + t("REFRESHES_ON") + " " + menu["info"]["refreshDateTime"]}</span>} </div>
                                        </>
                                      }
                                    </div>
                                  </div>
                                </CustomToggle>
                              </div>
                            );
                          })}
                        </Slider>
                      }
                    </div>
                  </div>
                </div>

                <div className="deposit-container-box">
                  <div className="d-none">
                    <div className="font-15">{t("PAYMENT_METHOD")}</div>
                  </div>
                  <div>
                    <div className="payment-method-box">
                      <div className="row" style={{ justifyContent: "space-between", padding: "0px 10px" }}>
                        <h4 className="font-15 mb-3">{t("PAYMENT_METHOD")}</h4>
                        <div className="font-15 mb-3">
                          <div style={{ marginRight: "5px", fontSize: "12px", textDecoration: "underline", color: "rgba(51, 105, 255, 1)", fontFamily: 'Montserrat', cursor: 'pointer' }} onClick={() => setHowToDepositModal(true)}> {t("HOW_TO_DEPOSIT")} </div>
                        </div>
                      </div>

                      <Nav className="nav nav-tabs" id="deposit-tabs">
                        <NavItem key={_NORMAL_DEPOSIT}>
                          <NavLink
                            className={classnames({
                              active: paymentMethodActive === _NORMAL_DEPOSIT,
                            })}
                            onClick={(e) => {
                              e.preventDefault();
                              setPaymentMethodActive(_NORMAL_DEPOSIT);
                              getContent(_NORMAL_DEPOSIT);
                            }}
                          >
                            {t("NORMAL_DEPOSIT")}
                          </NavLink>
                        </NavItem>
                      </Nav>

                      <TabContent activeTab={paymentMethodActive}>
                        <TabPane key={_NORMAL_DEPOSIT} tabId={_NORMAL_DEPOSIT}>
                          <NormalDeposit
                            parentInit={init}
                            bonusDeposit={bonusDeposit}
                            checkPendingTxn={checkPendingTxn}
                          />
                        </TabPane>
                      </TabContent>
                    </div>
                  </div>
                </div>
                {!stringIsNullOrEmpty(normalDepositContent) && (
                  <>
                    <hr className="deposit-custom-hr" />
                    {parse(normalDepositContent)}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="right">
          <RightInformation
            info={true}
            recent={true}
            recentTitle="RECENT_DEPOSIT_TRANSACTION"
            recentData={recentTransaction}
            games={true}
          />
        </div>
      </div>
      <Modal
        fade={false}
        contentClassName="modal-brand modal-bottom modal-numpad"
        isOpen={showFullnamePopup}
        centered
      >
        <ModalBody>
          <form onSubmit={handleFullNameSubmit(submitFullName)}>
            <div className="display-wrapper text-center">
              <strong
                className="reload-title text-yellow d-block"
                style={{ fontSize: "20px" }}
              >
                {t("ENTER_YOUR_FULL_NAME")}
              </strong>
              <strong
                className="reload-title text-yellow mb-3 d-block"
                style={{ fontSize: "12px", fontStyle: "italic" }}
              >
                {t("IN_YOUR_BANK_ACCOUNT")}
              </strong>
              <input
                type="text"
                name="userFullName"
                className="withdrawal-fullName-input"
                ref={registerFullName({
                  required: "FIELD_REQUIRED",
                  pattern: {
                    value: /^[a-z A-Z]+$/,
                    message: "INVALID_NAME",
                  },
                })}
                onChange={(e) =>
                  setValueFullName("userFullName", e.target.value)
                }
              />
              {errorsFullName.userFullName && (
                <div className="invalid-feedback">
                  {t(errorsFullName.userFullName.message)}
                </div>
              )}
              <button
                type="submit"
                className="btn btn-block btn-brand-gradient mx-auto"
                style={{ width: "200px" }}
              >
                {t("SUBMIT")}
              </button>
            </div>
          </form>
        </ModalBody>
      </Modal>
      <Modal
        fade={false}
        contentClassName="modal-brand modal-bottom modal-numpad how-to-deposit"
        isOpen={howToDepositModal}
        toggle={() => setHowToDepositModal(false)}
        centered
      >
        <ModalBody>
          <div>
            <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', justifyContent: 'space-between' }}>
              <h4 style={{ color: 'white' }}>{t("HOW_TO_DEPOSIT")}</h4>
              <div>
                <button
                  type="button"
                  className="modal-X-btn-1"
                  onClick={() =>
                    setHowToDepositModal(false)
                  }
                  data-dismiss="modal"
                  aria-hidden="true"
                >
                  <span>&times;</span>
                </button>
              </div>
            </div>
            <div className="form-group m-b-15">
              <div className="deposit-tutorial">
                <Controller
                  control={control}
                  name="paymentMethodTypeId"
                  render={({ onChange, value }) => {
                    return (
                      <StyledSelect
                        options={paymentMethodList}
                        placeholder={paymentMethodList[0] !== undefined ?
                          t(paymentMethodList[0].label) :
                          paymentMethodList.filter(option => option.value == value).label}
                        value={value === undefined && paymentMethodList[0] !== undefined ?
                          paymentMethodList.filter(x => x.value == defaultPaymentMethodActive)[0].value :
                          value}
                        // placeholder={paymentMethodList[0] !== undefined ? t(paymentMethodList[0].label) : paymentMethodList.filter(option => option.value == value).label}
                        // value={paymentMethodList[0] !== undefined ? paymentMethodList.filter(x => x.value == paymentMethodActive)[0].value : value}
                        onChange={(e) => {
                          onChange(e.value)
                          getContent(e.value);
                        }} />
                    )
                  }} />
              </div>
            </div>
            <div className="tutorial-content-wrapper">
              {Object.keys(apiData).map((key, value) => {
                if (!stringIsNullOrEmpty(apiData[key].value)) {
                  return (
                    <div key={key} className="tutorial-row">
                      <div>{parse(apiData[key].value)}</div>
                    </div>
                  )
                }
              })}
            </div>
          </div>
        </ModalBody>
      </Modal>

    </section>
  );
};

export default Deposit;
