import {
  _LOGIN_SUCCESS,
  _LOGIN_FAILED,
  _LOGOUT,
  _GET_SHOP_CREDIT,
  _UPDATE_USER_DATA,
} from "./AuthAction";

const _INITIAL_STATE = {
  userData: {},
  isLoggedIn: false,
  loginMessage: "",
  loginStateInitialized: false,
  creditLimit: 0,
};

/// <summary>
/// Author :
/// </summary>
export default (state = _INITIAL_STATE, action) => {
  switch (action.type) {
    case _LOGIN_SUCCESS:
      return {
        ...state,
        userData: action.userData,
        isLoggedIn: true,
        loginMessage: "",
        loginStateInitialized: true,
      };
      break;
    case _LOGIN_FAILED:
      return {
        loginMessage: action.message,
        isLoggedIn: false,
        userData: {},
        loginStateInitialized: true,
      };
      break;
    case _UPDATE_USER_DATA:
      return {
        ...state,
        userData: action.userData,
      };
      break;
    case _GET_SHOP_CREDIT:
      return {
        ...state,
        creditLimit: action.creditLimit,
      };
      break;
    case _LOGOUT:
      return {
        userData: {},
        isLoggedIn: false,
        loginMessage: "",
        loginStateInitialized: true,
        creditLimit: 0,
      };
      break;
    default:
      return state;
      break;
  }
};
