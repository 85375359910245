import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { filter, map, isEmpty } from "lodash";
import { setGameCategories, setGameProviders } from "redux/GameAction";
import ApiEngine from "util/ApiEngine";
import { ApiUrl, ApiKey, AlertTypes, WebUrl, SessionKey, LanguageOption, _MAIN_WALLET_ID } from "util/Constant";
import { stringIsNullOrEmpty, numberWithCurrencyFormat, createFormBody } from "../../util/Util";
import PropTypes from "prop-types";
import { useRouteMatch, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { useForm } from "react-hook-form";
import moment from "moment";
import {
  setDisplayLogin,
  setDisplayRegister,
  setBusy,
  setIdle,
  showMessage,
  showResponseMessage
} from "../../redux/AppAction";
import UMIcon from "../../assets/img/icon/dashboardv2_um.png";
import BottomSuccessModal from "../../components/custom/BottomSuccessModal";
import reloadPasswordIcon from "../../assets/img/icon/reset_password.svg";
import Icon from "@mdi/react";
import { mdiContentCopy } from "@mdi/js";
import Spinner from 'react-bootstrap/Spinner';
import SweetAlert from "react-bootstrap-sweetalert";



const GameSectionV3 = ({ playableCategory, isSpecialRollover }) => {
  const { t } = useTranslation();
  const _dispatch = useDispatch();
  const _history = useHistory();
  var url_string = window.location;
  var url = new URL(url_string);
  const match = useRouteMatch(url.pathname);
  var selectedLanguage = localStorage.getItem(SessionKey._LANGUAGE) && localStorage.getItem(SessionKey._LANGUAGE) != 'undefined' ?
    localStorage.getItem(SessionKey._LANGUAGE) : 'en';

  const { isLoggedIn } = useSelector((state) => state.authState);
  const _userData = useSelector((state) => state.authState.userData);
  const languageId = useSelector((state) => state.languageState.languageId);
  let title = "";

  if (match) {
    const { path } = match;
    const value = path.replace('/', '');
    title = value;
  }

  const gameCategories = useSelector((state) => {
    return state.gameState.gameCategories;
  });

  const [activeCategory, setActiveCategory] = useState("All");
  const [availableMasterProducts, setAvailableMasterProducts] = useState([]);
  const [availableFilteredMasterProducts, setAvailableFilteredMasterProducts] = useState([]);
  const [availableSlotsProviders, setAvailableSlotsProviders] = useState([]);
  const [selectedProviders, setSelectedProviders] = useState("");
  const [productGameList, setProductGameList] = useState([]);
  const [productGameListTotal, setProductGameListTotal] = useState(0);


  const [packageNameKeyword, setPackageNameKeyword] = useState("");
  const [isNewWindow, setIsNewWindow] = useState(false);
  const [memberData, setMemberData] = useState({});
  const [showCopyAlert, setShowCopyAlert] = useState(false);
  const _USER_DATA = useSelector((s) => s.authState.userData);
  const [showProductPasswordAlert, setShowProductPasswordAlert] =
    useState(false);
  const [showPregamePopup, setShowPregamePopup] = useState(false);
  const [walletList, setWalletList] = useState([]);
  const [fromOptions, setFromOptions] = useState([]);
  const [toOptions, setToOptions] = useState([]);
  const [currentInput, setCurrentInput] = useState();
  const [productGameId, setProductGameId] = useState(0);
  const [masterProductId, setMasterProductId] = useState(0);
  const [paidAmount, setPaidAmount] = useState();
  const [transferDate, setTransferDate] = useState();
  const [isTransferSuccess, setIsTransferSuccess] = useState(false);
  const [transcactionNumber, setTranscactionNumber] = useState();
  const [popupBannerImage, setPopupBannerImage] = useState("");
  const [bonus, setBonus] = useState(0);
  const [commission, setCommission] = useState(0);
  const [clubFee, setClubFee] = useState(0);
  const [selectedGameName, setSelectedGameName] = useState("");
  const [gameWalletBalanceBeforeTransfer, setGameWalletBalanceBeforeTransfer] =
    useState(0);
  const [memberMainWalletBalance, setMemberMainWalletBalance] = useState("");
  const [mpData, setMpData] = useState([]);
  const [isApp, setIsApp] = useState(false);
  const [toLobbyBtn, setToLobbyBtn] = useState(false);
  const [appUrl, setAppUrl] = useState("");
  const [appDeepLink, setAppDeepLink] = useState("");
  const [masterProductContent, setMasterProductContent] = useState("");
  const [conversionRate, setConversionRate] = useState(0);

  const [activeLanguageId, setActiveLanguageId] = useState(1);
  const [isLoadingProvidersList, setIsLoadingProvidersList] = useState(false);
  const [isLoadingSlotGameList, setIsLoadingSlotGameList] = useState(false);
  const [selectedSlotsProviderName, setSelectedSlotsProviderName] = useState("NextSpin");
  const [showNonSeamlessTransferConfirmation, setShowNonSeamlessTransferConfirmation] = useState(false);
  const [game93ConnectData, setGame93ConnectData] = useState({});

  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);
  const [isDragging, setIsDragging] = useState(false);
  const [isGrabbing, setIsGrabbing] = useState(false);
  const [mouseDownTime, setMouseDownTime] = useState(null);
  const [timeoutId, setTimeoutId] = useState(null);




  let usernameRef = useRef();
  let passwordRef = useRef();
  let containerRef = useRef();

  const _ALERT_TIMEOUT = 3000;
  const LONG_PRESS_DURATION = 300;

  const {
    handleSubmit: handleSubmitPopup,
    register: registerPopup,
    control: controlPopup,
    watch: watchPopup,
    getValues: getValuesPopup,
    setValue: setValuePopup,
    errors: errorsPopup,
    reset: resetPopup,
  } = useForm();


  //use Effect function

  useEffect(() => {
    getGameCategories();
    getAvailableMasterProduct();
  }, [isLoggedIn]);


  useEffect(() => {
    if (localStorage.getItem(SessionKey._LANGUAGE) && localStorage.getItem(SessionKey._LANGUAGE) != "undefined") {
      let tempLangId = LanguageOption.filter(x => x.code === localStorage.getItem(SessionKey._LANGUAGE))[0].languageId;
      setActiveLanguageId(tempLangId);
    }
  }, [localStorage.getItem(SessionKey._LANGUAGE)]);

  //get game categories for tab
  const getGameCategories = async (item, index) => {
    const { data: gameCategories } = await ApiEngine.get(
      ApiUrl._API_GET_PRODUCT_CATEGORY_DESKTOP +
      "?selectedLanguage=" +
      selectedLanguage
    );
    _dispatch(
      setGameCategories({
        gameCategories,
      })
    );
  };

  //get available master product
  const getAvailableMasterProduct = async (item, index) => {
    setIsLoadingProvidersList(true);
    var newResponseJson = await ApiEngine.get(
      ApiUrl._API_GET_AVAILABLE_MASTER_PRODUCT_V3_DESKTOP
    );
    if (newResponseJson[ApiKey._API_SUCCESS_KEY]) {
      let data = newResponseJson[ApiKey._API_DATA_KEY];
      setAvailableMasterProducts(data.filter(x => !stringIsNullOrEmpty(x.productImage)));
      setAvailableFilteredMasterProducts(data.filter(x => !stringIsNullOrEmpty(x.productImage)));
      setAvailableSlotsProviders(data.filter(x => !stringIsNullOrEmpty(x.productImage) && (x.categoryName === "Slots" || x.categoryName === "SlotMix")));
      setGame93ConnectData(data.filter(x => x.categoryName === "Connect")[0]);
      setIsLoadingProvidersList(false);
    } else {
      setIsLoadingProvidersList(false);
      throw newResponseJson[ApiKey._API_MESSAGE_KEY];
    }
  };

  //handle catergory click
  const handleCategoryClick = async (category) => {
    if (!isLoadingProvidersList) {
      setActiveCategory(category);
      filteredAvailableMasterProducts(category);
      if (category === "Connect") {
        if (isLoggedIn) {
          let gameData = game93ConnectData.game;
          startGame(gameData["id"], gameData["gameName"], gameData["isApp"], gameData["appUrl"], gameData["appDeepLink"], gameData["packageNameKeyword"], game93ConnectData["productId"], game93ConnectData["isSeamless"], "Connect");
        }
      }
      else if (category !== activeCategory) {
        if (category === "Slots") {
          handleProviderClick(availableMasterProducts.filter(x => x.categoryName === category && x.hasOwnLobby)[0]);
        } else {
          setSelectedProviders("");
        }
      }
    }
  }

  //filtered available master products
  const filteredAvailableMasterProducts = async (category) => {
    if (category !== "All") {
      setAvailableFilteredMasterProducts(availableMasterProducts.filter(x => x.categoryName === category))
    } else {
      setAvailableFilteredMasterProducts(availableMasterProducts);
    }

  }

  // retrieve product game list by provider name
  const handleSlotsProviderClick = async (productName, category) => {
    if (!isDragging) {
      setSelectedProviders(productName);
      setIsLoadingSlotGameList(true)
      var responseJson = await ApiEngine.get(
        ApiUrl._API_GET_PRODUCT_GAMES_LIST_DASHBOARD + "?languageId=" + activeLanguageId + (category === "" ? "" : "&category=" + category) + ("&providerName=" + productName) + ("&start=0&count=18")
      );
      if (responseJson[ApiKey._API_SUCCESS_KEY]) {
        setProductGameList(responseJson[ApiKey._API_DATA_KEY][ApiKey._API_DATA_KEY]);
        setProductGameListTotal(responseJson[ApiKey._API_DATA_KEY]['totalCount'])
        setIsLoadingSlotGameList(false);
      } else {
        setIsLoadingSlotGameList(false);
      }
    }
  }

  // check provider has lobby or redirect start game
  async function handleProviderClick(provider) {
    setSelectedSlotsProviderName(provider.productName);
    if (isLoggedIn) {
      setPopupBannerImage(provider["popupBannerImage"]);
      if (provider.hasOwnLobby) {
        setSelectedProviders(provider.productName);
        if (provider.categoryName === "Slots") {
          setActiveCategory(provider.categoryName);
          handleSlotsProviderClick(provider.productName, provider.categoryName);
        } else {
          _history.push({
            pathname: WebUrl._URL_GAME_LIST.replace(":provider", provider.productName),
          });
        }
      } else {
        if (provider.game != null && provider.game["gameUm"] !== true) {
          startGame(
            provider.game["id"],
            provider.game["gameName"],
            provider.game["isApp"],
            provider.game["appUrl"],
            provider.game["appDeepLink"],
            provider.game["packageNameKeyword"],
            provider.productId,
            provider.isSeamless,
            provider.categoryName,
          );
        } else {
          _dispatch(
            showMessage({
              type: AlertTypes._INFO,
              content: t("GAME_UM"),
              onConfirm: () => {
                //console.log("hi");
              },
            })
          );
        }
      }

    }
    else {
      if (provider.hasOwnLobby) {
        setSelectedProviders(provider.productName);
        if (provider.categoryName === "Slots") {
          setActiveCategory(provider.categoryName);
          handleSlotsProviderClick(provider.productName, provider.categoryName);
        } else {
          _history.push({
            pathname: WebUrl._URL_GAME_LIST.replace(":provider", provider.productName),
          });
        }
      } else {
        _dispatch(
          showMessage({
            type: AlertTypes._INFO,
            content: t("PLEASE_LOGIN_FIRST"),
            onConfirm: () => {
              //console.log("hi");
            },
          })
        );
      }
    }
  }

  /// function for start game to check is the member under special rollover and the choose game is under playable category
  async function startGame(
    gameId,
    gameName,
    isApp,
    appUrl,
    appDeepLink,
    packageNameKeyword,
    masterProductId,
    isSeamless,
    categoryTitle,
  ) {
    if (isLoggedIn) {
      setSelectedGameName(gameName);
      setMasterProductId(masterProductId);
      setIsApp(isApp);
      setAppDeepLink(appDeepLink);
      setPackageNameKeyword(packageNameKeyword);
      if (isApp && !stringIsNullOrEmpty(appUrl)) {
        setAppUrl(appUrl);
      }

      if (isSpecialRollover) {
        let productCategoryListArr = [...playableCategory];
        if (productCategoryListArr.includes(categoryTitle)) {
          checkIsSeamlessBeforeStart(gameId, gameName, isSeamless)
        }
        else {
          _dispatch(
            showMessage({
              type: AlertTypes._INFO,
              content: t("ROLLOVER_EXIST"),
              onConfirm: () => {
              },
            })
          );
        }
      }
      else {
        checkIsSeamlessBeforeStart(gameId, gameName, isSeamless)
      }
    }
    else {
      _dispatch(
        showMessage({
          type: AlertTypes._INFO,
          content: t("PLEASE_LOGIN_FIRST"),
          onConfirm: () => {
          },
        })
      );
    }
  }

  // function for checking the game selected is seamless or non seamless
  async function checkIsSeamlessBeforeStart(
    gameId,
    gameName,
    isSeamless
  ) {
    if (isSeamless === false) {
      setProductGameId(gameId);
      setShowNonSeamlessTransferConfirmation(true);
    } else {
      enterGame(gameId)
    }
  }

  //direct enter game
  async function enterGame(gameId) {
    let startGameApiUrl = `${ApiUrl._API_START_GAME
      }?gameId=${gameId}&isApp=0&device=d`;
    window.open(startGameApiUrl);
  }


  //proceed non seamless transfer 
  async function proceedNonSeamlessTransfer(gameId, gameName) {
    _dispatch(setBusy());
    var responseJson = await ApiEngine.get(
      ApiUrl._API_GET_NON_SEAMLESS_PRODUCT_BALANCE +
      "?masterProductId=" +
      masterProductId
    );
    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      setWalletList(responseJson[ApiKey._API_DATA_KEY]);

      let tempFromOptions = [];
      let tempToOptions = [];

      responseJson[ApiKey._API_DATA_KEY].map((product) => {
        let option = {
          label: product.productName,
          value: product.productId,
          amount: parseFloat(product.balance),
        };
        if (product.productId === _MAIN_WALLET_ID) {
          tempFromOptions.push(option);
        } else if (product.productId === masterProductId) {
          setBonus(product.bonus);
          setCommission(product.commission);
          if (product.productName === "D2 Poker") {
            setClubFee(product.clubFee);
          }
          setGameWalletBalanceBeforeTransfer(product.balance);
          if (product.masterProductContent !== null) {
            setMasterProductContent(product.masterProductContent);
          } else {
            setMasterProductContent("");
          }
          tempToOptions.push(option);
        }
      });
      setFromOptions(tempFromOptions);
      setToOptions(tempToOptions);

      var mpJson = await ApiEngine(
        ApiUrl._API_GET_MEMBER_PRODUCT_DETAILS +
        "?masterProductId=" +
        masterProductId
      );
      if (mpJson[ApiKey._API_SUCCESS_KEY]) {
        let data = mpJson[ApiKey._API_DATA_KEY];
        setMpData(data);

        let productName = gameName.replaceAll(' ', '');

        if (data.hasOwnProperty('conversionRate')) {
          data['conversionRate'].map((item) => {
            if (item.key.includes(productName + "ConversionRate")) {
              setConversionRate(item.value);
            }
          })
        }

        if (window.ReactNativeWebView && isApp) {
          window.ReactNativeWebView.postMessage(
            JSON.stringify({
              action: "deeplink",
              url: appUrl,
              deeplink: appDeepLink
                .replace("[username]", data["username"])
                .replace("[password]", data["password"]),
              packageKeyword: packageNameKeyword,
            })
          );
        }
      }
    }
    var isNewWindowJson = await ApiEngine(
      ApiUrl._API_GET_GAME_IS_NEW_WINDOW + "?gameId=" + gameId
    );
    // setShowPregamePopup(true);

    setIsNewWindow(isNewWindowJson[ApiKey._API_DATA_KEY]);
    setShowPregamePopup(true);

    _dispatch(setIdle());
  }


  // update wallet balance of non seamless 
  async function updateWalletBalance() {
    var responseJson = await ApiEngine.get(
      ApiUrl._API_GET_NON_SEAMLESS_PRODUCT_BALANCE
    );
    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      let tempFromOptions = [];
      let tempToOptions = [];
      responseJson[ApiKey._API_DATA_KEY].map((product) => {
        let option = {
          label: product.productName,
          value: product.productId,
          amount: parseFloat(product.balance),
        };
        if (product.productId === _MAIN_WALLET_ID) {
          setMemberMainWalletBalance(
            isNaN(parseFloat(product.balance), 3)
              ? 0.0
              : numberWithCurrencyFormat(parseFloat(product.balance), 3, true)
          );
          tempFromOptions.push(option);
        } else if (product.productId === masterProductId) {
          let gameWalletBalance = parseFloat(product.balance);
          setGameWalletBalanceBeforeTransfer(gameWalletBalance);
          tempToOptions.push(option);
        }
      });
      setFromOptions(tempFromOptions);
      setToOptions(tempToOptions);
    }
  }


  //submit transfer form of non seamless
  const submitPopupForm = async (data, e) => {
    _dispatch(setBusy());
    let params = {
      fromWallet: _MAIN_WALLET_ID,
      toWallet: masterProductId,
      amount: data.amount,
    };

    let responseJson = await ApiEngine.post(
      ApiUrl._API_CREATE_WALLET_TRANSFER_TRANSACTION,
      createFormBody(params)
    );

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      await updateWalletBalance(
        parseFloat(responseJson[ApiKey._API_DATA_KEY].amount).toFixed(2)
      );
      setPaidAmount(responseJson[ApiKey._API_DATA_KEY].amount);
      setTransferDate(moment().format("DD MMM YYYY, hh:mm A"));
      setIsTransferSuccess(true);
      setTranscactionNumber(responseJson[ApiKey._API_DATA_KEY].transactionId);

      e.target.reset();
    } else {
      _dispatch(
        showResponseMessage(
          responseJson[ApiKey._API_SUCCESS_KEY],
          responseJson[ApiKey._API_MESSAGE_KEY]
        )
      );
    }
    _dispatch(setIdle());
  };

  function copyText(textType) {
    if (textType === "username") {
      usernameRef.current.select();
    } else if (textType === "password") {
      passwordRef.current.select();
    }
    document.execCommand("copy");
    setShowCopyAlert(true);
    setTimeout(() => setShowCopyAlert(false), [_ALERT_TIMEOUT]);
  }

  async function resetProductPassword(masterProductId) {
    setMasterProductId(masterProductId);
    let apiUrl =
      ApiUrl._API_CHANGE_PRODUCT_PASSWORD +
      "?masterProductId=" +
      masterProductId;
    let responseJson = await ApiEngine.get(apiUrl);
    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      let data = responseJson[ApiKey._API_DATA_KEY];
      setMpData(data);
      setShowProductPasswordAlert(true);
      setTimeout(() => setShowProductPasswordAlert(false), [_ALERT_TIMEOUT]);
    }
  }

  const scrollToPosition = (isBtn, leftOrRight, productName) => {
    var position;
    let containerDiv = document.getElementById("providerList");
    let fullDivLength = containerDiv.scrollWidth;
    var totalper = fullDivLength / availableSlotsProviders.length;
    if (isBtn) {
      if (leftOrRight === "left" && containerRef.current.scrollLeft > (totalper * 7)) {
        position = containerRef.current.scrollLeft - (totalper * 7);
      }
      else if (leftOrRight === "right" && containerRef.current.scrollLeft < (fullDivLength - (totalper * 7))) {
        position = containerRef.current.scrollLeft + (totalper * 7);
      }
    }
    else {
      const index = availableSlotsProviders.findIndex(e => e.productName === productName);
      if (index !== -1) {
        if (containerRef.current) {
          position = totalper * (index);
        }
      }
    }
    containerRef.current.scrollLeft = position;
  };

  const ToLobby = () => {
    if (!stringIsNullOrEmpty(selectedProviders)) {
      _history.push({

        pathname: WebUrl._URL_GAME_LIST.replace(":provider", selectedProviders),
      });
    }
  }


  const customButtons = [
    {
      text: 'Custom Button 1',
      className: 'btn btn-primary',
      value: 'customButton1'
    },
    {
      text: 'Custom Button 2',
      className: 'btn btn-secondary',
      value: 'customButton2'
    }
  ];

  const handleMouseDown = (e) => {
    setStartX(e.clientX);
    setScrollLeft(containerRef.current.scrollLeft);
    setMouseDownTime(new Date().getTime());
    setIsGrabbing(true);
    setIsDragging(true);
  };

  const handleMouseMove = (e) => {
    if (isDragging && isGrabbing) {
      const deltaX = e.clientX - startX;
      const containerRect = containerRef.current.getBoundingClientRect();
      const containerWidth = containerRef.current.clientWidth;
      const containerHeight = containerRef.current.clientHeight;

      if (
        e.clientX < containerRect.left ||
        e.clientX > containerRect.left + containerWidth ||
        e.clientY < containerRect.top ||
        e.clientY > containerRect.top + containerHeight
      ) {

        setIsDragging(false);
        setIsGrabbing(false);
        return;
      }

      containerRef.current.scrollLeft = scrollLeft - deltaX;
    }
  };

  const handleMouseUp = (e) => {
    setIsGrabbing(false);
    const currentTime = new Date().getTime();
    if (currentTime - mouseDownTime < LONG_PRESS_DURATION) {
      setIsDragging(false);
    }
  };

  const handleSelectStart = (e) => {
    if (isDragging) {
      e.preventDefault();
    }
  };

  //<div className="slots-left-right-btn">
  //  <div className="left-btn" onClick={() => scrollToPosition(true, "left")}>{"<"}</div>
  //  <div className="right-btn" onClick={() => scrollToPosition(true, "right")}>{">"}</div>
  //</div>

  return (
    <div className="text-center mt-4">
      <ul className="game-section-v3-category-box list-inline mb-0" onClick={() => localStorage.setItem(SessionKey._ISONLOAD, true)}>
        {gameCategories &&
          gameCategories.length > 0 &&
          map(gameCategories.filter(x => x.originalTitle !== "Virtual-Sports" && x.originalTitle !== "V.Sports" && x.originalTitle !== "Fish Games" && x.originalTitle !== "RNG" && x.originalTitle !== "SlotMix"), (item, index) => {
            return (
              <li key={"game_categories_" + index} className={activeCategory === item.originalTitle ? "active" : ""} onClick={() => handleCategoryClick(item.originalTitle)}>
                <div className="cat-item">
                  <div className='cat-img'>
                    <img
                      src={`/img/version3/game-section-v3/game-categories/${item.originalTitle}.svg`}
                      className='img-responsive'
                      alt={item.title}
                    />
                  </div>
                  <div className={'cat-details' + (item.originalTitle === "Connect" ? " connect-font" : "")}>{t(item.originalTitle)}</div>
                </div>
              </li>
            );
          })}
      </ul>

      {isLoadingProvidersList ? <div className="game-section-loading">
        <Spinner animation="border"
          role="status"
          size="xl"
          style={{ verticalAlign: "middle", marginLeft: "10px" }}>
          <span className="sr-only">{t("LOADING")}...</span>
        </Spinner>
      </div> :
        activeCategory === "Slots" && !stringIsNullOrEmpty(selectedProviders) ?
          availableSlotsProviders &&
          availableSlotsProviders.length > 0 &&
          <div className="slot-provider-wrapper mt-4">
            <div className="scroll-left-btn" onClick={() => scrollToPosition(true, "left")}>{"<"}</div>
              <ul className={`game-section-v3-slots-provider-box list-inline ${isGrabbing && "dragging" }`} id="providerList" ref={containerRef}
              onMouseDown={(e) => handleMouseDown(e)}
              onMouseMove={(e) => handleMouseMove(e)}
              onMouseUp={(e) => handleMouseUp(e)}
              onSelectStart={(e) => handleSelectStart(e)}
              onLoad={() => scrollToPosition(false, "", selectedSlotsProviderName)}>
            {availableSlotsProviders.map((provider, index) => {
              return (
                <li
                  key={"game_slots_provider_" + index}
                  className={(selectedProviders === provider.productName ? "active" : "") + (provider.isUM ? " is-um um-display" : "")}
                  style={{ cursor: isGrabbing ? "grabbing" : provider.isUM ? "not-allowed" : "pointer" }}
                  onClick={() => {
                    if (!provider.isUM) {
                      handleSlotsProviderClick(provider.productName, provider.categoryName);
                    }
                  }}>
                  <img
                    src={`/img/version3/game-section-v3/game-slots-providers/${provider.productName}.png`}
                    className='img-responsive'
                    alt={provider.title}
                    draggable="false"
                  />
                  {provider.isUM &&
                    <div
                      style={{
                        backgroundImage: `url(${UMIcon})`,
                        zIndex: 1
                      }}
                      className="v2-um-tag-icon"
                    ></div>
                  }
                </li>
              );
            })}
            </ul>
            <div className="scroll-right-btn" onClick={() => scrollToPosition(true, "right")}>{">"}</div>
          </div>
          :
          availableFilteredMasterProducts && availableFilteredMasterProducts.length > 0 &&
          <>
            <div className="game-section-v3-provider-container">
              {availableFilteredMasterProducts.map((provider, index) => {
                return (
                  <div
                    key={"game-section-v3-provider-" + index}
                    className="provider-items"
                    style={{ cursor: provider.isUM ? "not-allowed" : "pointer" }}
                    onClick={() => {
                      if (!provider.isUM) {
                        handleProviderClick(provider);
                      }
                    }}>
                    <img
                      src={provider.productImage}
                      alt="provider"
                      className="w-100"
                      style={{ filter: provider.isUM ? "grayscale(1)" : "unset"}}
                      onClick={() => { }}
                    />
                    {provider.isUM &&
                      <div
                        style={{
                          backgroundImage: `url(${UMIcon})`,
                          zIndex: 1
                        }}
                        className="v2-um-tag-icon"
                      ></div>
                    }
                  </div>)
              })
              }
            </div>
          </>
      }

      {isLoadingSlotGameList ?
        <div className="game-section-loading">
          <Spinner animation="border"
            role="status"
            size="xl"
            style={{ verticalAlign: "middle", marginLeft: "10px" }}>
            <span className="sr-only">{t("LOADING")}...</span>
          </Spinner>
        </div> :
        activeCategory === "Slots" && productGameList && productGameList.length > 0 &&
        <>
          <div className="product-games-list-container" style={{ paddingTop: '35px' }} >

            {productGameList.map((game, i) => {
              return (
                <div className="product-games-list-items">

                  <div className={"product-game-box desktop " + (isLoggedIn ? game["gameUm"] === true ? "game-um" : "cursor-pointer" : "")} onClick={() => {
                    if (game["gameUm"] !== true) {
                      startGame(game["id"], game["gameName"], game["isApp"], game["appUrl"], game["appDeepLink"], game["packageNameKeyword"], game.product["id"], game.product["isSeamless"], game.productCategory["originalTitle"])
                    }
                  }}>
                    {game["isNewGame"] || game["isRecommended"] ? (
                      <div className="tag">
                        <img
                          src={
                            game["isRecommended"]
                              ? "/img/assets/hot.svg"
                              : "/img/assets/new.svg"
                          }
                          className="img-responsive"
                          alt={game["isRecommended"] ? "hot" : "new"}
                          loading="lazy"
                        />
                      </div>
                    ) : null}

                    <div
                      className="product-game-image"
                      style={{
                        backgroundImage:
                          (isLoggedIn
                            ? game["gameUm"] == true
                              ? "linear-gradient(rgba(0,0,0,.8), rgba(0,0,0,.8)), "
                              : " "
                            : " ") /*+ `url(${encodeURI(game["image"])})`*/,
                        position: "relative",
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat",
                        borderRadius: "16px",
                      }}
                    >
                      {isLoggedIn && game["gameUm"] == true && (
                        <>
                          <div
                            style={{
                              backgroundImage: `url(${UMIcon})`,
                              zIndex: 1
                            }}
                            className="v2-um-tag-icon"
                          ></div>
                        </>
                      )}
                      <img
                        src={game["image"]}
                        alt="Game provider"
                        style={{
                          position: "relative",
                          backgroundPosition: "center",
                          backgroundRepeat: "no-repeat",
                          borderRadius: "16px",
                          opacity: game["gameUm"] == true ? "0.7" : "1"
                        }}
                        loading="lazy"
                      />
                    </div>
                    <div className="product-game-name">
                      {game["gameName"]}
                    </div>
                  </div>

                </div>
              )
            })}
          </div>

          {productGameListTotal > 18 && <button className="more-games-button" onClick={ToLobby}>{t("MORE_GAMES")}</button>
          }
        </>
      }
      <hr className="hr-right-new" />


      <Modal
        fade={true}
        contentClassName="modal-brand modal-bottom modal-edit-sh"
        id="confirm-non-seamless-transfer-modal"
        isOpen={showNonSeamlessTransferConfirmation}
        centered
        toggle={() => {
          showNonSeamlessTransferConfirmation(false);
        }}
      >
        <ModalBody>

            <div className="question">{t("CONFIRM_TRANSFER_NON_SEAMLESS_CREDIT", { value: selectedGameName })}</div>
            <div className="buttons">
              <button className="btn btn-block btn-brand-gradient mt-3" style={{ width: "200px" }}
                onClick={() => {
                  enterGame(productGameId);
                  setShowNonSeamlessTransferConfirmation(false);
                }} >{t("NO") + "(" + t("START_GAME") + ")"}</button>
              <button className="btn btn-block btn-brand-gradient mt-3" style={{ width: "200px" }}
                onClick={() => {
                  setShowNonSeamlessTransferConfirmation(false);
                  proceedNonSeamlessTransfer(productGameId, selectedGameName);
                }} >{t("CONFIRM")}</button>
            </div>
        </ModalBody>
      </Modal>



      <Modal
        id="pregame-popup"
        fade={false}
        contentClassName="pregame-popup"
        isOpen={showPregamePopup}
        toggle={() => {
          setShowPregamePopup(false);
        }}
        centered
      >
        <ModalHeader className="m-auto border-0">
          <div className="modal-header-wrapper">
            <span className="font-20 text-white font-bold">
              {selectedGameName}
            </span>
          </div>
        </ModalHeader>
        <ModalBody style={{ padding: "0px 16px 41px" }}>
          <form onSubmit={handleSubmitPopup(submitPopupForm)}>
            <div className="container">
              <div className="row">
                <div className="col-12 d-flex wallet-info text-center text-white font-bold">
                  <div className="col-6 border-right">
                    {fromOptions.length > 0 && (
                      <>
                        <span className="font-15">{fromOptions[0].label}</span>
                        <br />
                        <span className="font-16">
                          {numberWithCurrencyFormat(fromOptions[0].amount, 3)}
                        </span>
                      </>
                    )}
                  </div>
                  <div className="col-6">
                    {toOptions.length > 0 && (
                      <>
                        <span className="font-15">{toOptions[0].label}</span>
                        <br />
                        <span className="font-16">
                          {numberWithCurrencyFormat(toOptions[0].amount, 3)}
                        </span>
                      </>
                    )}
                  </div>
                </div>

                <div className="col-12 d-flex">
                  <div className="col-8">
                    <div className="pregame-transfer-input">
                      <input
                        type="text"
                        className="custom-input-style-2"
                        placeholder={"0.00"}
                        name="amount"
                        onClick={(e) => {
                          setCurrentInput(e.target);
                        }}
                        ref={registerPopup({
                          required: "PLEASE_ENTER_AMOUNT",
                          validate: {
                            minValue: (value) =>
                              parseFloat(value) > 0 ||
                              "PLEASE_ENTER_VALUE_MORE_THAN_ZERO",
                          },
                        })}
                      />
                      {errorsPopup.amount && (
                        <div className="invalid-feedback">
                          {t(errorsPopup.amount.message)}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-4 pregame-transfer-btn">
                    <button type="submit" className="btn btn-brand-gradient">
                      {t("TRANSFER")}
                    </button>
                  </div>
                </div>

                <div className="col-12 d-flex transfer-balance font-15 text-white font-bold">
                  <div>{selectedGameName + " " + t("NEW_BALANCE") + " : "}
                    {selectedGameName == '918Kiss' || selectedGameName == "D2 Poker" || selectedGameName == "Pussy888" || selectedGameName == "Mega888" ?
                      <div className="reminder font-11 text-white font-semi-italic">
                        1 THB = {conversionRate} point
                      </div>
                      : ""
                    }
                  </div>

                  <div>
                    {
                      selectedGameName == '918Kiss' || selectedGameName == "D2 Poker" || selectedGameName == "Pussy888" || selectedGameName == "Mega888"
                        ?
                        watchPopup("amount") > 0 &&
                        numberWithCurrencyFormat(
                          parseFloat(toOptions[0].amount) +
                          parseFloat(watchPopup("amount")) * conversionRate,
                          2,
                          true
                        )
                        :
                        watchPopup("amount") > 0 &&
                        numberWithCurrencyFormat(
                          parseFloat(toOptions[0].amount) +
                          parseFloat(watchPopup("amount")),
                          2,
                          true
                        )
                    }
                    {" "}
                    {watchPopup("amount") == "" && "-"}
                  </div>

                  {/* <div>
                    {watchPopup("amount") > 0 &&
                      numberWithCurrencyFormat(
                        // parseFloat(toOptions[0].amount) +
                        toOptions.length > 0
                          ? parseFloat(toOptions[0].amount) +
                              parseFloat(watchPopup("amount"))
                          : parseFloat(0) + parseFloat(watchPopup("amount")),
                        2,
                        true
                      )}{" "}
                    {watchPopup("amount") == "" && "-"}
                  </div> */}
                </div>
              </div>
              {masterProductContent.length > 1 && !stringIsNullOrEmpty(masterProductContent) && (
                <div className="masterProductContent text-white">{masterProductContent}</div>
              )}
            </div>
          </form>
          <div className="container">
            <div className="row">
              {(bonus > 0 || commission > 0 || clubFee > 0) && (
                <div className="col-12 d-flex bonus-commission font-12 text-white">
                  {bonus > 0 && (
                    <div className="bonus">
                      {t("BONUS") +
                        " (%) : " +
                        numberWithCurrencyFormat(parseFloat(bonus), 2, true) +
                        "%"}
                      &nbsp;&nbsp;
                    </div>
                  )}

                  {bonus > 0 && commission > 0 && <div>{"/"}&nbsp;&nbsp;</div>}

                  {commission > 0 && (
                    <div className="commission">
                      {t("COMMISSION") +
                        " (%) : " +
                        numberWithCurrencyFormat(
                          parseFloat(commission),
                          2,
                          true
                        ) +
                        "%"}
                      &nbsp;&nbsp;
                    </div>
                  )}

                  {commission > 0 && clubFee > 0 && (
                    <div>{"/"}&nbsp;&nbsp;</div>
                  )}

                  {clubFee > 0 && (
                    <div className="clubFee">
                      {t("CLUB_FEE") +
                        " (%) : " +
                        numberWithCurrencyFormat(parseFloat(clubFee), 2, true) +
                        "%"}
                    </div>
                  )}
                </div>
              )}
              {isApp && appDeepLink !== "" && (
                <>
                  <div className="col-12" style={{ paddingBottom: "20px" }}>
                    <hr
                      style={{
                        backgroundColor: "#ffc159",
                        width: "90%",
                        padding: "0.5px 0",
                      }}
                    />
                    <div
                      className="text-center"
                      style={{
                        marginTop: "-28px",
                      }}
                    >
                      <span
                        className="font-16 text-gold font-bold"
                        style={{
                          backgroundColor: "#001A3D",
                          padding: "0 25px",
                        }}
                      >
                        {selectedGameName + " " + t("ACCOUNT")}
                      </span>
                    </div>
                  </div>
                  <div className="username-and-password">
                    <div className="details username">
                      <div className="details-label">
                        {t("USERNAME") + " : "}
                      </div>
                      <input
                        type="text"
                        className="username-input"
                        value={mpData["username"]}
                        ref={usernameRef}
                        readOnly
                      />
                      <div
                        style={{ marginLeft: "10px" }}
                        onClick={() => copyText("username")}
                      >
                        <Icon path={mdiContentCopy} size={1} color="#ffc159" />
                      </div>
                    </div>
                    <div className="details password">
                      <div className="details-label">
                        {t("PASSWORD") + " : "}
                      </div>
                      <input
                        type="text"
                        className="password-input"
                        value={mpData["password"]}
                        ref={passwordRef}
                        readOnly
                      />
                      <div
                        style={{ marginLeft: "10px" }}
                        onClick={() => copyText("password")}
                      >
                        <Icon path={mdiContentCopy} size={1} color="#ffc159" />
                      </div>
                      <div
                        className="refresh-password-btn"
                        style={{
                          backgroundImage: `url(${reloadPasswordIcon})`,
                        }}
                        onClick={() => resetProductPassword(masterProductId)}
                      >
                        <div className="tooltip-text">
                          <span>{t("REFRESH_PASSWORD")}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
              {selectedGameName == "Mega888" && (
                <>
                  <div className="col-12" style={{ paddingBottom: "20px" }}>
                    <hr
                      style={{
                        backgroundColor: "#ffc159",
                        width: "90%",
                        padding: "0.5px 0",
                      }}
                    />
                    <div
                      className="text-center"
                      style={{
                        marginTop: "-28px",
                      }}
                    >
                      <span
                        className="font-16 text-gold font-bold"
                        style={{
                          backgroundColor: "#001A3D",
                          padding: "0 25px",
                        }}
                      >
                        {selectedGameName + " " + t("ACCOUNT")}
                      </span>
                    </div>
                  </div>
                  <div className="username-and-password">
                    <div className="details username">
                      <div className="details-label">
                        {t("USERNAME") + " : "}
                      </div>
                      <input
                        type="text"
                        className="username-input"
                        value={mpData["username"]}
                        ref={usernameRef}
                        readOnly
                      />
                      <div
                        style={{ marginLeft: "10px" }}
                        onClick={() => copyText("username")}
                      >
                        <Icon path={mdiContentCopy} size={1} color="#ffc159" />
                      </div>
                    </div>
                    <div className="details password">
                      <div className="details-label">
                        {t("PASSWORD") + " : "}
                      </div>
                      <input
                        type="text"
                        className="password-input"
                        value={mpData["password"]}
                        ref={passwordRef}
                        readOnly
                      />
                      <div
                        style={{ marginLeft: "10px" }}
                        onClick={() => copyText("password")}
                      >
                        <Icon path={mdiContentCopy} size={1} color="#ffc159" />
                      </div>
                    </div>
                  </div>
                </>
              )}

              <div className="col-12 d-flex start-btn-wrapper">
                {!isApp || (isApp && appDeepLink != "") ? (
                  <button
                    type="button"
                    onClick={() => {
                      enterGame(productGameId);
                    }}
                    className="btn pregame-popup-btn btn-block btn-brand"
                  >
                    {t("START_GAME")}
                  </button>
                ) : (
                  <button
                    type="button"
                    onClick={() => {
                      if (window.ReactNativeWebView) {
                        window.ReactNativeWebView.postMessage(
                          JSON.stringify({ url: appUrl })
                        );
                      } else {
                        window.open(appUrl);
                      }
                    }}
                    className="btn pregame-popup-btn btn-block btn-brand"
                  >
                    {t("DOWNLOAD_APP")}
                  </button>
                )}
              </div>
            </div>
          </div>
          <div
            className="pregame-popup-close-btn"
            onClick={() => {
              setShowPregamePopup(false);
              setToLobbyBtn(false);
            }}
          >
            <img
              src="/img/assets/close.svg"
              className="img-responsive"
              alt="close"
            />
          </div>
        </ModalBody>
      </Modal>
      <BottomSuccessModal
        isOpen={isTransferSuccess}
        setIsOpen={setIsTransferSuccess}
        title={"TRANSFER_SUCCESS"}
        amount={paidAmount}
        date={transferDate}
        transactionNumber={transcactionNumber}
      />
    </div>
  );
};

GameSectionV3.propTypes = {
  isSpecialRollover: PropTypes.bool,
  playableCategory: PropTypes.array
};

GameSectionV3.defaultProps = {
  isSpecialRollover: false,
  playableCategory: [],
};

export default GameSectionV3;
