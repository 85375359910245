import React, { useState, useEffect } from "react";
import "react-simple-keyboard/build/css/index.css";
import { ApiKey, ApiUrl, WebUrl, SessionKey } from "../../util/Constant";
import { isEmpty } from "lodash";
import { useDispatch } from "react-redux";
import { showResponseMessage, setBusy, setIdle, } from "../../redux/AppAction.js";
import ApiEngine from "../../util/ApiEngine.js";
import { stringIsNullOrEmpty, isObjectEmpty, numberWithCurrencyFormat } from "../../util/Util";
import StyledSelect from "../../components/custom/StyledSelectDesktop";
import { useTranslation } from "react-i18next";
import GameMenu from "components/game-list/game-menu";
import { useHistory } from "react-router-dom";
import ContentHeader from "components/content/header";
import moment from 'moment';

const Kpi = (props) => {
  const { t } = useTranslation();
  var _dispatch = useDispatch();
  var _history = useHistory();

  const [monthOptions, setMonthOptions] = useState([]);
  const [kpiData, setKpiData] = useState({});
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [selectedMonth, setSelectedMonth] = useState("");
  const [dailySummary, setDailySummary] = useState({});
  const [displayGameMenu, setDisplayGameMenu] = useState(true);

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    if (!stringIsNullOrEmpty(selectedMonth)) {
      setSelectedIndex(
        kpiData.bonusDetail.findIndex((bd) => bd.month == selectedMonth)
      );
    }
  }, [selectedMonth]);

  useEffect(() => {
    setDisplayGameMenu(false);
    setTimeout(() => setDisplayGameMenu(true), 1);
  }, [(localStorage.getItem(SessionKey._LANGUAGE) && localStorage.getItem(SessionKey._LANGUAGE) != 'undefined' ?
    localStorage.getItem(SessionKey._LANGUAGE) : 'en')]);

  async function init() {
    _dispatch(setBusy());

    var responseJson = await ApiEngine.get(ApiUrl._API_GET_MEMBER_DETAIL_KPI);

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {

      let data = responseJson[ApiKey._API_DATA_KEY];

      setMonthOptions(
        data.bonusDetail.map((bd) => {
          return { label: bd.month, value: bd.month };
        })
      );
      setKpiData(data);
      setDailySummary(data["dailySummary"]);
      setSelectedMonth(data["bonusDetail"][0]["month"]);
    } else {
      _dispatch(
        showResponseMessage(false, responseJson[ApiKey._API_MESSAGE_KEY])
      );
    }

    _dispatch(setIdle());
  }

  if (isObjectEmpty(kpiData)) {
    return (null);
  }
  else {
    return (
      <>
        {displayGameMenu && (
          <>
            <section className="section-padding" id="downline-kpi-section">
              <div className="title-with-blue-under" style={{ marginBottom: "20px" }}>{t("KPI")}</div>

              <div className="kpi-grid">
                <div>
                  <div className="kpi-title">
                    {t("TOTAL_MONTHLY_BONUS")}
                  </div>
                  <div className="kpi-details">
                    <span>{t("TOTAL_BONUS")}</span>
                    <span>
                      {numberWithCurrencyFormat(parseFloat(kpiData["data"][selectedIndex]["bonus"]), 3, true)}
                    </span>
                  </div>
                  <div className="kpi-details">
                    <span>{t("DOWNLINE")}</span>
                    <span>
                      {numberWithCurrencyFormat(parseFloat(kpiData["data"][selectedIndex]["downlineBonus"]), 3, true)}
                    </span>
                  </div>
                  <div className="kpi-details">
                    <span>{t("OWN_BONUS")}</span>
                    <span>
                      {numberWithCurrencyFormat(parseFloat(kpiData["data"][selectedIndex]["ownBonus"]), 3, true)}
                    </span>
                  </div>
                </div>
                <div>
                  <div className="kpi-title">
                    {t("TOTAL_MONTHLY_COMMISSION")}
                  </div>
                  <div className="kpi-details">
                    <span>{t("TOTAL_COMMISSION")}</span>
                    <span>
                      {numberWithCurrencyFormat(parseFloat(kpiData["data"][selectedIndex]["commission"]), 3, true)}
                    </span>
                  </div>
                  <div className="kpi-details">
                    <span>{t("DOWNLINE")}</span>
                    <span>
                      {numberWithCurrencyFormat(parseFloat(kpiData["data"][selectedIndex]["downlineCommission"]), 3, true)}
                    </span>
                  </div>
                  <div className="kpi-details">
                    <span>{t("OWN_COMMISSION")}</span>
                    <span>
                      {numberWithCurrencyFormat(parseFloat(kpiData["data"][selectedIndex]["ownCommission"]), 3, true)}
                    </span>
                  </div>
                </div>
                <div>
                  <div className="kpi-title">
                    {t("TOTAL_SALES")}
                  </div>
                  <div className="kpi-details">
                    <span>{t("TOTAL_SALES")}</span>
                    <span>
                      {numberWithCurrencyFormat(parseFloat(kpiData["data"][selectedIndex]["sales"]), 3, true)}
                    </span>
                  </div>
                  <div className="kpi-details">
                    <span>{t("DOWNLINE")}</span>
                    <span>
                      {numberWithCurrencyFormat(parseFloat(kpiData["data"][selectedIndex]["downlineSales"]), 3, true)}
                    </span>
                  </div>
                  <div className="kpi-details">
                    <span>{t("OWN_SALES")}</span>
                    <span>
                      {numberWithCurrencyFormat(parseFloat(kpiData["data"][selectedIndex]["ownSales"]), 3, true)}
                    </span>
                  </div>
                </div>
              </div>

              <div className="kpi-select-search mt-4 mb-4">
                <StyledSelect
                  options={monthOptions}
                  value={selectedMonth}
                  onChange={(e) => {
                    setSelectedMonth(e.value);
                  }}
                  className="custom-select-kpi-container"
                />
              </div>

              <ContentHeader title='KPI History' />

              <div className="kpi-table">
                <table className="table reportTable">
                  <thead>
                    <tr>
                      <th>{t("DATE")}</th>
                      <th>{t("SALES")}</th>
                      <th>{t("COMMISSION_SHORT")}</th>
                      <th>{t("DOWNLINE")}</th>
                      <th>{t("YOURS")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {dailySummary &&
                      dailySummary.length > 0 ?
                      dailySummary.filter((i) => i.month == moment(selectedMonth).lang("en-gb").format('MMM YY')).length > 0 ?
                        dailySummary
                          .filter((i) => i.month == moment(selectedMonth).lang("en-gb").format('MMM YY'))
                          .map((summary, index) => {
                            return (
                              <tr key={"kpi_history" + index}>
                                <td>{summary.date}</td>
                                <td>
                                  {numberWithCurrencyFormat(
                                    summary.sales,
                                    3,
                                    true
                                  )}
                                </td>
                                <td>
                                  {numberWithCurrencyFormat(
                                    summary.commission,
                                    3,
                                    true
                                  )}
                                </td>
                                <td>
                                  {numberWithCurrencyFormat(
                                    summary.downlineCommission,
                                    3,
                                    true
                                  )}
                                </td>
                                <td>
                                  {numberWithCurrencyFormat(
                                    summary.ownCommission,
                                    3,
                                    true
                                  )}
                                </td>
                              </tr>
                            );
                          })
                        :
                        <tr>
                          <td colSpan="5">
                            <span style={{ fontSize: "12px" }} >{t("NO_DATA_FOUND")}</span>
                          </td>
                        </tr>
                      :
                      <tr>
                        <td colSpan="5">
                          <span style={{ fontSize: "12px" }} >{t("NO_DATA_FOUND")}</span>
                        </td>
                      </tr>
                    }
                  </tbody>
                  <tfoot>
                    <tr>
                      <td>{t("TOTAL")}</td>
                      <td>
                        {dailySummary &&
                          dailySummary.length &&
                          numberWithCurrencyFormat(
                            parseFloat(
                              dailySummary
                                .filter((i) => i.month == moment(selectedMonth).lang("en-gb").format('MMM YY'))
                                .reduce(
                                  (total, data) => total + data.sales,
                                  0
                                )
                            ),
                            3,
                            true
                          )}
                      </td>
                      <td>
                        {dailySummary &&
                          dailySummary.length &&
                          numberWithCurrencyFormat(
                            parseFloat(
                              dailySummary
                                .filter((i) => i.month == moment(selectedMonth).lang("en-gb").format('MMM YY'))
                                .reduce(
                                  (total, data) =>
                                    total + data.commission,
                                  0
                                )
                            ),
                            3,
                            true
                          )}
                      </td>
                      <td>
                        {dailySummary &&
                          dailySummary.length &&
                          numberWithCurrencyFormat(
                            parseFloat(
                              dailySummary
                                .filter((i) => i.month == moment(selectedMonth).lang("en-gb").format('MMM YY'))
                                .reduce(
                                  (total, data) =>
                                    total + data.downlineCommission,
                                  0
                                )
                            ),
                            3,
                            true
                          )}
                      </td>
                      <td>
                        {dailySummary &&
                          dailySummary.length &&
                          numberWithCurrencyFormat(
                            parseFloat(
                              dailySummary
                                .filter((i) => i.month == moment(selectedMonth).lang("en-gb").format('MMM YY'))
                                .reduce(
                                  (total, data) =>
                                    total + data.ownCommission,
                                  0
                                )
                            ),
                            3,
                            true
                          )}
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </div>


              {/* <div className="container">
                <div className="row">
                  <div className="col-md-12">
                    <div className="template-box mt-3" id="downline-kpi">
                      <div
                        className="template-box bg-darkblue"
                        id="downline-accordions"
                      >
                        <div className="kpi-account">
                          <p className="font-15 text-white font-semi mb-0">
                            {kpiData["username"]}
                            <br />
                            {kpiData["phone"]}
                          </p>
                          <p className="font-15 text-gold font-semi mb-0">
                            {t("DOWNLINE")}: {kpiData["totalDownline"]}
                          </p>
                        </div>
                        <div className="border-bot-black mb-1"></div>
                        <div className="font-semi text-white font-15">
                          <StyledSelect
                            options={monthOptions}
                            value={selectedMonth}
                            onChange={(e) => {
                              setSelectedMonth(e.value);
                            }}
                          />
                        </div>
                        <div className="tag">
                          <div className="border-bot-black pb-1"></div>
                        </div>
                        <div>
                          <div
                            className="kpi-accordion-title font-15 text-gold font-semi font-semi mt-3"
                            data-toggle="collapse"
                            data-target="#kpi-drop1"
                            aria-expanded="true" 
                          >
                            <span>{t("TOTAL_MONTHLY_BONUS")}</span>
                            <div className="acc-arrows">
                              <img
                                src={require("../../assets/img/assets/drop-gold.svg")}
                                className="img-responsive img1"
                                alt="accordion"
                              />
                              <img
                                src={require("../../assets/img/assets/up-gold.svg")}
                                className="img-responsive img2"
                                alt="accordion"
                              />
                            </div>
                          </div>

                          <div
                            id="kpi-drop1"
                            className="collapse show text-white mt-3"
                          >
                            <div className="kpi-accordion-details">
                              <span>{t("TOTAL_BONUS")}</span>
                              <span>
                                {numberWithCurrencyFormat(parseFloat(kpiData["data"][selectedIndex]["bonus"]), 3, true)}
                              </span>
                            </div>
                            <div className="kpi-accordion-details">
                              <span>{t("DOWNLINE")}</span>
                              <span>
                                {numberWithCurrencyFormat(parseFloat(kpiData["data"][selectedIndex]["downlineBonus"]), 3, true)}
                              </span>
                            </div>
                            <div className="kpi-accordion-details">
                              <span>{t("OWN_BONUS")}</span>
                              <span>
                                {numberWithCurrencyFormat(parseFloat(kpiData["data"][selectedIndex]["ownBonus"]), 3, true)}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div>
                          <div
                            className="kpi-accordion-title font-15 text-gold font-semi font-semi mt-3"
                            data-toggle="collapse"
                            data-target="#kpi-drop2"
                            aria-expanded="true" 
                          >
                            <span>{t("TOTAL_MONTHLY_COMMISSION")}</span>
                            <div className="acc-arrows">
                              <img
                                src={require("../../assets/img/assets/drop-gold.svg")}
                                className="img-responsive img1"
                                alt="accordion"
                              />
                              <img
                                src={require("../../assets/img/assets/up-gold.svg")}
                                className="img-responsive img2"
                                alt="accordion"
                              />
                            </div>
                          </div>

                          <div
                            id="kpi-drop2"
                            className="collapse show text-white mt-3"
                          >
                            <div className="kpi-accordion-details">
                              <span>{t("TOTAL_COMMISSION")}</span>
                              <span>
                                {numberWithCurrencyFormat(parseFloat(kpiData["data"][selectedIndex]["commission"]), 3, true)}
                              </span>
                            </div>
                            <div className="kpi-accordion-details">
                              <span>{t("DOWNLINE")}</span>
                              <span>
                                {numberWithCurrencyFormat(parseFloat(kpiData["data"][selectedIndex]["downlineCommission"]), 3, true)}
                              </span>
                            </div>
                            <div className="kpi-accordion-details">
                              <span>{t("OWN_COMMISSION")}</span>
                              <span>
                                {numberWithCurrencyFormat(parseFloat(kpiData["data"][selectedIndex]["ownCommission"]), 3, true)}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div>
                          <div
                            className="kpi-accordion-title font-15 text-gold font-semi font-semi mt-3"
                            data-toggle="collapse"
                            data-target="#kpi-drop3"
                            aria-expanded="true" 
                          >
                            <span>{t("TOTAL_SALES")}</span>
                            <div className="acc-arrows">
                              <img
                                src={require("../../assets/img/assets/drop-gold.svg")}
                                className="img-responsive img1"
                                alt="accordion"
                              />
                              <img
                                src={require("../../assets/img/assets/up-gold.svg")}
                                className="img-responsive img2"
                                alt="accordion"
                              />
                            </div>
                          </div>

                          <div
                            id="kpi-drop3"
                            className="collapse show text-white mt-3"
                          >
                            <div className="kpi-accordion-details">
                              <span>{t("TOTAL_SALES")}</span>
                              <span>
                              {numberWithCurrencyFormat(parseFloat(kpiData["data"][selectedIndex]["sales"]), 3, true)}
                              </span>
                            </div>
                            <div className="kpi-accordion-details">
                              <span>{t("DOWNLINE")}</span>
                              <span>
                              {numberWithCurrencyFormat(parseFloat(kpiData["data"][selectedIndex]["downlineSales"]), 3, true)}
                              </span>
                            </div>
                            <div className="kpi-accordion-details">
                              <span>{t("OWN_SALES")}</span>
                              <span>
                              {numberWithCurrencyFormat(parseFloat(kpiData["data"][selectedIndex]["ownSales"]), 3, true)}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="template-box bg-darkblue mt-4"
                        id="downline-kpi-table"
                      >
                        <div className="thc-result-mainbox font-12">
                          <div className="transac-history-result border-bot-black">
                            <div className="thr-titles">{t("DATE")}</div>
                            <div className="thr-titles">{t("SALES")}</div>
                            <div className="thr-titles">
                              {t("COMMISSION_SHORT")}.
                            </div>
                            <div className="thr-titles">{t("DOWNLINE")}</div>
                            <div className="thr-titles">{t("YOURS")}</div>
                          </div>
                          {dailySummary &&
                            dailySummary.length &&
                            dailySummary
                              .filter((i) => i.month == selectedMonth)
                              .map((summary, index) => {
                                return (
                                  <div
                                    key={index}
                                    className="transac-history-result"
                                  >
                                    <div className="thr-titles">
                                      <p>{summary.date}</p>
                                    </div>
                                    <div className="thr-titles">
                                      <p>
                                        {numberWithCurrencyFormat(
                                          summary.sales,
                                          3,
                                          true
                                        )}
                                      </p>
                                    </div>
                                    <div className="thr-titles">
                                      <p>
                                        {numberWithCurrencyFormat(
                                          summary.commission,
                                          3,
                                          true
                                        )}
                                      </p>
                                    </div>
                                    <div className="thr-titles">
                                      <p>
                                        {numberWithCurrencyFormat(
                                          summary.downlineCommission,
                                          3,
                                          true
                                        )}
                                      </p>
                                    </div>
                                    <div className="thr-titles">
                                      <p>
                                        {numberWithCurrencyFormat(
                                          summary.ownCommission,
                                          3,
                                          true
                                        )}
                                      </p>
                                    </div>
                                  </div>
                                );
                              })}
                          <div className="transac-history-result-total">
                            <div className="thr-titles">
                              <p>{t("TOTAL")}</p>
                            </div>
                            <div className="thr-titles">
                              <p>
                                {dailySummary &&
                                  dailySummary.length &&
                                  numberWithCurrencyFormat(
                                    parseFloat(
                                      dailySummary
                                        .filter((i) => i.month == selectedMonth)
                                        .reduce(
                                          (total, data) => total + data.sales,
                                          0
                                        )
                                    ),
                                    3,
                                    true
                                  )}
                              </p>
                            </div>
                            <div className="thr-titles">
                              <p>
                                {dailySummary &&
                                  dailySummary.length &&
                                  numberWithCurrencyFormat(
                                    parseFloat(
                                      dailySummary
                                        .filter((i) => i.month == selectedMonth)
                                        .reduce(
                                          (total, data) =>
                                            total + data.commission,
                                          0
                                        )
                                    ),
                                    3,
                                    true
                                  )}
                              </p>
                            </div>
                            <div className="thr-titles">
                              <p>
                                {dailySummary &&
                                  dailySummary.length &&
                                  numberWithCurrencyFormat(
                                    parseFloat(
                                      dailySummary
                                        .filter((i) => i.month == selectedMonth)
                                        .reduce(
                                          (total, data) =>
                                            total + data.downlineCommission,
                                          0
                                        )
                                    ),
                                    3,
                                    true
                                  )}
                              </p>
                            </div>
                            <div className="thr-titles">
                              <p>
                                {dailySummary &&
                                  dailySummary.length &&
                                  numberWithCurrencyFormat(
                                    parseFloat(
                                      dailySummary
                                        .filter((i) => i.month == selectedMonth)
                                        .reduce(
                                          (total, data) =>
                                            total + data.ownCommission,
                                          0
                                        )
                                    ),
                                    3,
                                    true
                                  )}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
            </section>
          </>
        )}
      </>
    );
  }
};

export default Kpi;
