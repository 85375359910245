import React, { useState, useEffect, useRef } from "react";
import ContentHeader from "components/content/header";
import RightInformation from "../../components/custom/RightInformation";
import { walletInformation, recentTransaction, latestGames } from "../../config/game-configs";
import Breadcrumb from "components/custom/Breadcrumb";
import { map } from "lodash";
import Input from "../../components/custom/Input";
import { useTranslation } from "react-i18next";
import Icon from "@mdi/react";
import { WebUrl, ApiUrl, ApiKey } from "../../util/Constant";
import ApiEngine from "../../util/ApiEngine";
import moment from 'moment';
import { stringIsNullOrEmpty, numberWithCurrencyFormat, createMultiPartFormBody } from "../../util/Util";
import { useDispatch, useSelector } from "react-redux";
import {
  setBusy,
  setIdle,
  showResponseMessage
} from "../../redux/AppAction";
import { useForm } from "react-hook-form";

const ShareholderEdit = (props) => {
  var _userData = useSelector((state) => state.authState.userData);
  var url_string = window.location;
  var url = new URL(url_string);
    const [currentActiveTab, setCurrentActiveTab] = useState(0);
    var { t } = useTranslation();
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const [memberId, setMemberId] = useState(params.get("memberId"));
    const [memberData, setMemberData] = useState({});
    const [share, setShare] = useState(0);
    const [activeOptionsList, setActiveOptionsList] = useState([]);
    const [hideSummary, setHideSummary] = useState(false);
    const [shareholderIndex, setShareholderIndex] = useState("");
    const [isAssignNewSH, setIsAssignNewSH] = useState(false);
  const _dispatch = useDispatch();
  //const [latestGame, setLatestGame] = useState({});
  const [totalUnClaimable, setTotalUnClaimable] = useState("");
  const [processedGrandTotal, setProcessedGrandTotal] = useState({});
  const [companyGrandTotal, setCompanyGrandTotal] = useState("");

  const {
    register,
    handleSubmit,
    unregister
  } = useForm();

    const breadcrumb = [
        {
          value: 'VIP_EVENT',
          active: false,
          link: WebUrl._URL_VIP +
            "?GAzobE73Vse4nr8WpufA=" +
            _userData.hideSummary
        },
        {
          value: 'SHAREHOLDER',
          active: false,
          link: WebUrl._URL_SHAREHOLDER +
            "?startDate=" +
            url.searchParams.get("startDate") +
            "&endDate=" +
            url.searchParams.get("endDate") +
            "&GAzobE73Vse4nr8WpufA=" +
            hideSummary
        },
        {
          value: 'EDIT_DOWNLINE_DETAILS',
          active: true,
          link: WebUrl._URL_SHAREHOLDER_EDIT +
            "?memberId=" +
            memberId +
            "&startDate=" +
            url.searchParams.get("startDate") +
            "&endDate=" +
            url.searchParams.get("endDate") +
            "&GAzobE73Vse4nr8WpufA=" +
            hideSummary
        },
    ];

    useEffect(() => {
      getMemberDetail();
    }, []);

    async function getMemberDetail(){
    
      let responseJson = await ApiEngine.get(ApiUrl._API_GET_MEMBER_DETAILS_BY_ID + "?memberId=" + memberId + "&shareholder=true");
      if (responseJson[ApiKey._API_SUCCESS_KEY]) {
        let editingDownlineData = responseJson[ApiKey._API_DATA_KEY];
        let tempOptionsList = [];
        for (var i = editingDownlineData["minShare"]; i <= editingDownlineData["maxShare"]; i += 5) {
          tempOptionsList.push(i.toFixed(2));
        }
        setActiveOptionsList(tempOptionsList);

        setMemberData(responseJson[ApiKey._API_DATA_KEY]);
        setHideSummary(responseJson[ApiKey._API_DATA_KEY]["hideSummary"]);
        if (responseJson[ApiKey._API_DATA_KEY]['isShareHolder'] !== undefined) {
          setShare(responseJson[ApiKey._API_DATA_KEY]['sharePercent']);
        }
        else {
          setShare(responseJson[ApiKey._API_DATA_KEY]['maxShare']);
        }

        setIsAssignNewSH(responseJson[ApiKey._API_DATA_KEY]['isShareHolder'] === undefined);

      }
    }

  const submitForm = async () => {
    _dispatch(setBusy());
    let params =
    {
      "memberId": memberData.id,
      "share": share,
      "hideSummary": hideSummary
    };

    let responseJson = await ApiEngine.post(
      ApiUrl._API_UPDATE_SHAREHOLDER,
      createMultiPartFormBody(params)
    );

    //if (responseJson[ApiKey._API_SUCCESS_KEY]) {
    //  setShowConfirmation(false);
    //  setCurrentActiveTab(0);
    //  init();
    //}
    _dispatch(setIdle());
  }

    useEffect(() => {
      getDownlineSales(_userData["username"]);
      //getLatestGame();
      //getUnClaimed();
    }, []);

    async function getDownlineSales(username) {
      _dispatch(setBusy());
      var responseJson = await ApiEngine.get(`${ApiUrl._API_GET_MEMBER_SHAREHOLDER_DETAIL}?&startdate=${url.searchParams.get("startDate")}&enddate=${url.searchParams.get("endDate")}&username=${username}`);

      if (responseJson[ApiKey._API_SUCCESS_KEY]) {
        let data = responseJson[ApiKey._API_DATA_KEY]["shareholderData"];

        let grandTotal = { sales: 0, vsales: 0, netProfit: 0 };
        let summaryGrandTotal = 0;

        data["downlineData"].map((e, i) => {
          grandTotal.sales += e.grossSales;
          grandTotal.vsales += e.validTurnover;
          grandTotal.netProfit += e.profit;
          grandTotal.netProfit += e.settlement;

        });

        data["summaryData"].map((summary, index) => {
          summaryGrandTotal += summary["netProfit"];
        });

        setProcessedGrandTotal(grandTotal);
        setCompanyGrandTotal(summaryGrandTotal);
      }
      else {
        _dispatch(showResponseMessage(false, responseJson[ApiKey._API_MESSAGE_KEY]));
      }
      _dispatch(setIdle());
    }

    //const getLatestGame = async () => {
    //  var latestGameJson = await ApiEngine.get(ApiUrl._API_GET_LATEST_GAME);

    //  if (latestGameJson[ApiKey._API_SUCCESS_KEY]) {
    //    setLatestGame(latestGameJson[ApiKey._API_DATA_KEY]);
    //  }
    //}

    //const getUnClaimed = async () => {
    //  var unClimedJson = await ApiEngine.get(ApiUrl._API_GET_MEMBER_SHAREHOLDER_UNCLAIM);

    //  if (unClimedJson[ApiKey._API_SUCCESS_KEY]) {
    //    setTotalUnClaimable(unClimedJson[ApiKey._API_DATA_KEY]["totalclaimable"]);
    //  }
    //}

    return (
        <>
            <section className="section-padding" id="shareholder-section">
                <Breadcrumb
                    data={breadcrumb}
                    // escButtonOnClick={() => window.history.go(-1)}
                />
                <div className="with-information-container">
                    <div className="left">
                        <h3 className="mb-3">{t("EDITSHAREHOLDER")}</h3>
                        <div className="font-16">{memberData["username"]}</div>
                        <div className="font-12">{memberData["phoneNumber"]}</div>
                        <div className="hr-blue" style={{maxWidth: '100px',margin:'18px 0px'}}></div>
                        <div className="shareholder-accordion-details">
                            <span className="detail-title mb-3 font-12">
                                {t("SHAREHOLDERDATE")}
                            </span>
                            <span className="detail-value font-14 mb-3">
                                {moment(memberData.shareHolderDate).format("YYYY-MM-DD HH:MM:SS")}
                            </span>
                        </div>
                        
                        <div>
                            <form  onSubmit={handleSubmit(submitForm)} >
                                <div className="shareholder-accordion-details">
                                  <div className="display-flex flex-column">
                                    <span className="detail-title mb-3 font-12">
                                      {t("SHAREPERCENT")} (%)
                                    </span>
                                    <span
                                      className="red-mini-text"
                                      style={{
                                        marginTop:
                                          "-15px",
                                      }}
                                    >
                                      <i>{t("MIN") + " : "} {memberData["minShare"]} % {t("MAX") + " : "} {memberData["maxShare"]}%</i>
                                    </span>
                                  </div>          
                                  <span>
                                    <div className={""}>
                                      <select
                                        className={"referral-add-minus-new"}
                                        placeholder={t(
                                          "SHAREPERCENT"
                                        )}
                                        onChange={(e) => { setShare(e.target.value); }}
                                      >
                                        <option value={share} disabled selected hidden>{numberWithCurrencyFormat(share, 2, true) + "%"}</option>
                                        {activeOptionsList.map((optionValue, index) => {
                                          return (
                                            <option key={index} value={optionValue}>{optionValue + "%"}</option>
                                          )
                                        })}
                                      </select>
                                    </div>
                                  </span>     
                                 </div>
                                 <div>
                                    <div className="shareholder-data-detail shareholder-hide-summary" style={{padding:'10px 0px !important'}} onClick={() => setHideSummary(!hideSummary)}>
                                    <div className="hide-summary-icon">
                                      {hideSummary ? (
                                        <img src="/img/version3/hide-summary.png" alt="hide" />
                                      ) : (
                                        <img src="/img/version3/eye-blue.png" alt="show" />
                                      )}
                                    </div>
                                    <div>
                                      {t("HIDE_SUMMARY")}
                                    </div>
                                  </div>
                                 </div>
                                 <button className="btn custom-btn-style-1 mt-4">{t("SAVE")}</button>
                            </form>
                        </div>


                    </div>
                    <div className="right">
                        <RightInformation
                            companySummary={true}
                            //companySummaryGrandTotal={companyGrandTotal}
                            //companySummaryNetProfit={processedGrandTotal.netProfit}
                            //companySummaryTurnover={processedGrandTotal.vsales}
                            games={true}
                            //gamesTitle="LATEST_GAME"
                            //gamesData={latestGame}
                            shareHolderTotalUnclaimed={true}
                            //shareHolderTotalUnclaimedTitle="TOTAL_UNCLAIMED"
                            shareHolderTotalUnclaimedDetails={totalUnClaimable}
                            promotion={true}
                        />
                    </div>
                </div>
            </section>
        </>
    );
};

export default ShareholderEdit;
