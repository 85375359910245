/// <summary>
/// Author: -
/// </summary>
import axios from "axios";

const ApiCms = axios.create({
  baseURL: process.env.REACT_APP_CMS_API_URL,
  headers: {
    Authorization: `Bearer ${process.env.REACT_APP_CMS_API_KEY}`,
  },
});

export default ApiCms;
