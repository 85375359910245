import React, { useState, useEffect } from "react";
import "react-simple-keyboard/build/css/index.css";
import { ApiKey, ApiUrl, WebUrl, LanguageKey } from "../../util/Constant";
import moment from "moment";
import { stringIsNullOrEmpty, numberWithCurrencyFormat } from "../../util/Util";
import { useTranslation } from "react-i18next";
import DynamicLoadTable2 from "../../components/custom/DynamicLoadTable2";
import { map } from "lodash";
import Header from "../../components/custom/Header";
import { useHistory, Link, useLocation } from "react-router-dom";
import Breadcrumb from "components/custom/Breadcrumb";
import RightInformation from "../../components/custom/RightInformation";
import { useDispatch, useSelector } from "react-redux";
import ApiEngine from "../../util/ApiEngine";
import {
    setBusy,
    setIdle
} from "../../redux/AppAction";

const InactiveDownlines = (props) => {
    const { t } = useTranslation();
    const [inactiveUrl, setInactiveUrl] = useState("");
    const [downlineDepositLimit, setDownlineDepositLimit] = useState("");
    const [downlineCount, setDownlineCount] = useState("");
    var _history = useHistory();
    const _dispatch = useDispatch();

    useEffect(() => {
        getInactiveDownlines();
        getInactiveDownlineLimit();
    }, []);

    async function getInactiveDownlines() {
        let apiUrl = ApiUrl._API_GET_INACTIVE_DOWNLINE_DETAIL;
        setInactiveUrl(apiUrl);
    }

    async function getInactiveDownlineLimit() {
        _dispatch(setBusy());
        var responseJson = await ApiEngine.get(ApiUrl._API_GET_INACTIVE_DOWNLINE_DEPOSIT_LIMIT);
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            setDownlineDepositLimit(responseJson[ApiKey._API_DATA_KEY]);
        }
        _dispatch(setIdle());
    }

    const breadcrumb = [
        {
            value: 'DOWNLINE',
            active: false,
            link: WebUrl._URL_DOWNLINE
        },
        {
            value: t("INACTIVE_DOWNLINE_TITLE", { value: downlineDepositLimit }),
            active: true,
            link: WebUrl._URL_INACTIVE_DOWNLINES
        },
    ];

    return (
        <>
            <Breadcrumb data={breadcrumb} />
            <div id="inactive-downline">
                <div className="left">
                    <div className="card card-package">
                        <div className="card-body">
                            <div className="table-responsive">
                                <table className="table reportTable">
                                    <thead>
                                        <tr>
                                            <th className="text-left">{t("USERNAME")}</th>
                                            <th className="text-left">{t("PHONE_NUMBER")}</th>
                                            <th className="text-left">
                                                {t("REGISTERED_DATE")}
                                            </th>
                                            <th className="text-right">
                                                {t("DEPOSIT")}
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <DynamicLoadTable2
                                            pageSize={20}
                                            apiQuery={inactiveUrl}
                                            searchEnabled={true}
                                            isTable={true}
                                            render={(item, i) => {
                                                return (
                                                    <tr key={"inactive_downline" + i}>
                                                        <td className="text-left">{item["username"]}</td>
                                                        <td className="text-left">{item["phoneNumber"]} </td>
                                                        <td className="text-left">{item["registeredDate"]}  </td>
                                                        <td className="text-right">{item["deposit"]}  </td>
                                                    </tr>
                                                )
                                            }}
                                        />
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="right">
                    <RightInformation
                        newDownlines={true}
                        buttonTwo={true}
                        buttonTwoQuantity={downlineCount}
                        buttonTwoText="DOWNLINE_GROUPS"
                        games={true}
                        promotion={true}
                    />
                </div>
            </div>

        </>
    );
};
export default InactiveDownlines;