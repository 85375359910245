import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

const PlusMinusMaxButton = (props) => {
  let {
    onClickMinus,
    styleValueMinus,
    styleValuePlus,
    styleValueButton,
    onClickPlus,
    optionValue,
    value,
    category,
    handleSelectCatValue,
    handleSelectValue,
    errors,
    type,
    product,
    buttonSection,
    text,
    onClickMax,
    section,
  } = props;

  const { t, i18n } = useTranslation();

  //default className because in referral page different px
  let maxClassName = "row-data-max";
  let addMinusClassName = "plus-minus-wrapper";
  let addMinusIconClassName = "edit-plus-minus-icon";
  let selectClassName = "bonusComm-option-select";

  if (section === "referral") {
    maxClassName = "referral-max-box";
    addMinusClassName = "referral-add-minus";
    addMinusIconClassName = "";
    selectClassName = "";
  }

  return (
    <>
      {buttonSection == "plusminus" && (
        <>
          <div className={addMinusClassName}>
            <div
              className={addMinusIconClassName}
              style={{
                opacity: styleValueMinus ? "1.0" : "0.5",
                cursor: styleValueMinus ? "pointer" : "not-allowed",
              }}
              onClick={() => onClickMinus()}
            >
              <img
                src="/img/version3/minus.png"
                className=""
                alt="minus"
              />
              {/* - */}
            </div>

            <select
              className={selectClassName}
              value={value !== "-" ? parseFloat(value.toFixed(2)) : "-"}
              placeholder={value !== "-" ? value.toFixed(2) : "-"}
              onChange={(e) => {
                if (type === "clubfee") { type = "club" + "f".toUpperCase() + "ee"; }
                if (
                  type == "bonus" ||
                  type == "commission" ||
                  type == "clubFee"
                ) {
                  handleSelectCatValue(
                    type,
                    category.id,
                    category.originalTitle,
                    e.target.value
                  );
                } else if (
                  type == "maxBonus" ||
                  type == "maxCommission" ||
                  type == "maxClubFee"
                ) {
                  handleSelectValue(
                    type === "maxBonus"
                      ? "bonus"
                      : type === "maxCommission"
                        ? "commission"
                        : type === "maxClubFee"
                          ? "clubFee"
                          : "",
                    product.productId,
                    e.target.value
                  );
                }
              }}
            >
              {optionValue}
            </select>

            <div
              className={addMinusIconClassName}
              style={{
                opacity: styleValuePlus ? "1.0" : "0.5",
                cursor: styleValuePlus ? "pointer" : "not-allowed",
              }}
              onClick={() => onClickPlus()}
            >
              <img
                src="/img/version3/plus.png"
                className=""
                alt="minus"
              />
              {/* + */}
            </div>
          </div>

          {!type == "maxCommission" &&
            !type == "maxClubFee" &&
            errors.editPercent && (
              <div className="invalid-feedback">
                {t(errors.editPercent.message)}
              </div>
            )}
        </>
      )}

      {buttonSection == "max" && (
        // <td className="row-data-4">
        <div
          style={{
            opacity: styleValueButton ? "0.5" : "1.0",
            cursor: styleValueButton ? "not-allowed" : "pointer",
          }}
          className={maxClassName + " font-bold text-uppercase"}
          onClick={onClickMax}
        >
          {text}
        </div>
        // </td>
      )}
    </>
  );
};

PlusMinusMaxButton.propTypes = {
  styleValueMinus: PropTypes.bool,
  styleValuePlus: PropTypes.bool,
  onClickMinus: PropTypes.func,
  onClickPlus: PropTypes.func,
  onClickMax: PropTypes.func,
  optionValue: PropTypes.array,
  errors: PropTypes.object,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  category: PropTypes.object,
  product: PropTypes.object,
  buttonSection: PropTypes.string,
  text: PropTypes.string,
  type: PropTypes.string,
  handleSelectCatValue: PropTypes.func,
  handleSelectValue: PropTypes.func,
  section: PropTypes.string,
};

export default PlusMinusMaxButton;
