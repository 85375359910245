import React, { useEffect, useState } from "react";
import "react-simple-keyboard/build/css/index.css";
//import { bannerList } from "../../config/game-configs";
import moment from "moment";
import useCountDown from 'react-countdown-hook';
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import PinInput from "react-pin-input";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  Modal,
  ModalBody
} from "reactstrap";
import SecureLS from "secure-ls";
import openEye from "../../assets/img/version3/register-login/eye-blue.png";
import closedEye from "../../assets/img/version3/register-login/hide.png";
import {
  setBusy,
  setDisplayLogin,
  setDisplayRegister,
  setIdle,
  showMessage,
  showResponseMessage
} from "../../redux/AppAction";
import { performLogin } from "../../redux/AuthAction.js";
import { AlertTypes, ApiKey, ApiUrl, PhoneNumberPrefix, SessionKey, WebUrl, LanguageOption } from "../../util/Constant";
import ApiEngine from "./../../util/ApiEngine.js";
import {
  createFormBody,
  createMultiPartFormBody,
  stringIsNullOrEmpty
} from "./../../util/Util.js";
import parse from 'react-html-parser';
import SuccessModal from "../custom/SuccessModal"
import { bool } from "prop-types";

const Auth = () => {
  const { t, i18n } = useTranslation();
  const _history = useHistory();
  const _dispatch = useDispatch();
  var ls = new SecureLS();
  const _MIN_PASSWORD_LENGTH = 6;
  const _TAC_VALID_TIMER = 300000;
  const _SECONDS_IN_MS = 1000;
  const _TAC_TIMEOUT_IN_MINUTES = 5;
  const initialTime = 120 * 1000;
  const interval = 1000;

  const search = window.location.search;
  const params = new URLSearchParams(search);

  const { isLoggedIn } = useSelector((state) => state.authState);
  let isShowLogin = useSelector((state) => state.appState.isShowLogin);
  let isShowRegister = useSelector((state) => state.appState.isShowRegister);


  const [enteredPinNumber, setEnteredPinNumber] = useState("");
  const [countdownTAC, { start, pause, resume, reset }] = useCountDown(initialTime, interval);

  const [registerModalVisible, setRegisterModalVisible] = useState(false);
  const [registerSuccess, setRegisterSuccess] = useState(false);
  const [registerActiveStep, setRegisterActiveStep] = useState(1);

  const [loginModalVisible, setLoginModalVisible] = useState(false);
  const [loginActiveStep, setLoginActiveStep] = useState(1);

  const [isHiddenLoginPassword, setIsHiddenLoginPassword] = useState(closedEye);
  const [loginPasswordVisible, setLoginPasswordVisible] = useState(false);

  const [isHiddenRegisterPassword, setIsHiddenRegisterPassword] = useState(closedEye);
  const [isHiddenRegisterConfirmPassword, setIsHiddenRegisterConfirmPassword] = useState(closedEye);
  const [newRegisterPasswordVisible, setNewRegisterPasswordVisible] = useState(false);
  const [confirmRegisterPasswordVisible, setConfirmRegisterPasswordVisible] = useState(false);

  const [isHiddenResetPassword, setIsHiddenResetPassword] = useState(closedEye);
  const [isHiddenResetConfirmPassword, setIsHiddenResetConfirmPassword] = useState(closedEye);
  const [newResetPasswordVisible, setNewResetPasswordVisible] = useState(false);
  const [confirmResetPasswordVisible, setConfirmResetPasswordVisible] = useState(false);

  const [keptUsername, setKeptUsername] = useState("");
  const [keptPassword, setKeptPassword] = useState("");
  const [loginFailCount, setLoginFailCount] = useState(0);
  const [usernameLogin, setUsernameLogin] = useState("");
  const [referralCode, setReferralCode] = useState("");
  const [canSendTAC, setCanSendTAC] = useState(true);
  const [TACcountdownPeriod, setTACCountdownPeriod] = useState(0);
  const [TACsent, setTACsent] = useState(false);
  const [countdownPeriod, setCountdownPeriod] = useState(0);
  const [TACtimedOut, setTACtimedOut] = useState(true);
  const [selectedPhonePrefix, setSelectedPhonePrefix] = useState(
    PhoneNumberPrefix[0].value
  );
  const [formattedPhone, setFormattedPhone] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState(false);
  const [showTacEntry, setShowTacEntry] = useState(false);
  const [TACBtnAble, setTACBtnAble] = useState(false);
  const [TACtimer, setTACtimer] = useState(setTimeout(() => { }, 100000));
  const [isDigit, setIsDigit] = useState(false);
  const [username, setUsername] = useState("");
  const [phoneNum, setPhoneNum] = useState("");
  const [tempPhoneNum, setTempPhoneNum] = useState("");
  const [memberData, setMemberData] = useState({});
  const [showCreateShareholderPin, setShowCreateShareholderPin] = useState(false);
  const [uplineDetail, setUplineDetail] = useState({});
  const [registerPopupContent, setRegisterPopupContent] = useState("");
  //const [registerWhatsAppUrl, setRegisterWhatsAppUrl] = useState("");
  //const _APPEND_REGISTER_WHATSAPP_URL = "?text=register%20";

  const verifyState = {
    button: "1"
  };


  const {
    register: registerLogin,
    handleSubmit: handleSubmitLogin,
    setValue: setValueLogin,
    getValues: getValuesLogin,
    watch: watchLogin,
    errors: errorsLogin,
  } = useForm();

  const {
    register: checkReferralRegister,
    handleSubmit: handleSubmitCheckReferral,
    errors: checkReferralErrors,
    unregister: checkReferralUnregister
  } = useForm();


  const {
    register: registerRegister,
    handleSubmit: handleSubmitRegister,
    errors: errorsRegister,
    setError: setErrorRegister,
    clearErrors: clearErrorsRegister,
    setValue: setValueRegister,
    getValues: getValuesRegister,
    watch: watchRegister,
    control: controlRegister,
    trigger: triggerRegister,
    phoneNumberChk: phoneNumberChkRegister,
    reset: resetRegister
  } = useForm({
    mode: "onBlur",
  });

  const {
    register: forgotPasswordRegister,
    handleSubmit: forgotPasswordHandleSubmit,
    errors: forgotPasswordErrors,
    unregister: forgotPasswordUnregister
  } = useForm();

  const {
    register: tacVerifyRegister,
    handleSubmit: tacVerifyHandleSubmit,
    unregister: tacVerifyUnregister
  } = useForm();
  const {
    register: ResetPasswordRegister,
    handleSubmit: ResetPasswordHandleSubmit,
    errors: ResetPasswordErrors,
    watch: ResetPasswordWatch,
    setValue: ResetPasswordSetValue,
    unregister: ResetPasswordUnregister,
    watch,
  } = useForm();

  const countryCodeData = [
    {
      code: "+61",
    },
    {
      code: "+60",
    },
    {
      code: "+62",
    },
    {
      code: "+66",
    },
    {
      code: "+85",
    }
  ];

  const [isCheckingReferral, setIsCheckingReferral] = useState(false);
  const [isRegistering, setIsRegistering] = useState(false);

  const [agreeTnC, setAgreeTnC] = useState(false);
  const [agreeAge18, setAgreeAge18] = useState(false);
  const [showTnC, setShowTnC] = useState(false);
  const [content, setContent] = useState("");

  //use effect function start region ---------------------------------------------------------------------

  //init
  useEffect(() => {
    init();
    const parsedReferralCode = params.get('code');
    if (parsedReferralCode != null) {
      handleReferralCode(parsedReferralCode);
      checkReferralCode(parsedReferralCode);
    }
  }, []);

  // get content when language changed
  useEffect(() => {
    let tempLanguage = localStorage.getItem(SessionKey._LANGUAGE);
    if (tempLanguage === undefined || tempLanguage === null) {
      tempLanguage = "en";
    }
    getTnC(tempLanguage);
    getCompanyContent();
  }, [(localStorage.getItem(SessionKey._LANGUAGE) && localStorage.getItem(SessionKey._LANGUAGE) != 'undefined' ?
    localStorage.getItem(SessionKey._LANGUAGE) : 'en')]);

  //trigger after send TAC request count down period
  useEffect(() => {
    if (countdownPeriod > 0) {
      setTimeout(() => {
        let tempPeriod = countdownPeriod - 1;
        setCountdownPeriod(tempPeriod);
      }, _SECONDS_IN_MS);
      // }, 10);
    }
  }, [countdownPeriod]);


  //trigger TAC valid count down period
  useEffect(() => {
    if (TACcountdownPeriod > 0) {
      setTACtimer(setTimeout(() => {
        let tempPeriod = TACcountdownPeriod - 1;
        setTACCountdownPeriod(tempPeriod);
      }, _SECONDS_IN_MS));
      // }, 10));
    }
  }, [TACcountdownPeriod]);

  //use effect function end region ---------------------------------------------------------------------


  //api function start region ---------------------------------------------------------------------

  //init function
  const init = async () => {
    await getCompanyContent();
  }

  //submit login 
  const submitLoginForm = async (data, e) => {
    _dispatch(setBusy());
    localStorage.setItem(SessionKey._ISSHOWMINIGAMETRIGGER, true);
    _dispatch(setDisplayLogin(false));
    if (data.isKeepedPassword) {
      ls.set("gR97lPRIn797", {
        Do3SwI4cuvqR: data.loginUsername,
        gR1IPGBN01dS: data.loginPassword,
        PYTLczcty9uH: data.isKeepedPassword,
      });
    } else {
      ls.set("gR97lPRIn797", {
        Do3SwI4cuvqR: "fake",
        gR1IPGBN01dS: "null",
        PYTLczcty9uH: false,
      });
    }
    await _dispatch(
      performLogin(data.loginUsername, data.loginPassword, data.isKeepedLogin)
    );
    let params = {
      username: data.loginUsername,
    };
    var loginFailCountJson = await ApiEngine.post(
      ApiUrl._API_GET_LOGIN_FAILED_COUNT,
      createFormBody(params)
    );
    if (loginFailCountJson[ApiKey._API_SUCCESS_KEY]) {
      let data = loginFailCountJson[ApiKey._API_DATA_KEY];
      setLoginFailCount(data);
    }
    _dispatch(setIdle());
  }

  //check referral exist

  async function submitCheckReferralCode() {
    checkReferralCode(referralCode);
  }

  const checkReferralCode = async (code) => {
    setIsCheckingReferral(true);
    let responseJson = await ApiEngine.get(
      ApiUrl._API_GET_UPLINE_BY_REFERRAL_CODE + "?code=" + code
    );
    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      setUplineDetail(responseJson[ApiKey._API_DATA_KEY]);
      setIsCheckingReferral(false);
      await _dispatch(setDisplayRegister(true));
      setRegisterActiveStep(2);
      resetRegister();
      //let apiUrl1 = ApiUrl._API_GET_WHATSAPP_PHONE + "?type=ChatSpherePhoneNumber";
      //let settingJson = await ApiEngine.get(apiUrl1);

      //if (settingJson[ApiKey._API_SUCCESS_KEY]) {
      //  let data1 = settingJson[ApiKey._API_DATA_KEY];
      //  setRegisterWhatsAppUrl("https://wa.me/" + data1 + _APPEND_REGISTER_WHATSAPP_URL + code);
      //}
    } else {
      _dispatch(
        showMessage({
          type: AlertTypes._ERROR,
          content: t(responseJson[ApiKey._API_MESSAGE_KEY]),
        })
      );
      setIsCheckingReferral(false);
      setReferralCode("");
    }
  }

  //submit register
  const submitRegisterForm = async (data, e) => {
    _dispatch(setBusy());
    if (data.registerTAC == undefined) {
      _dispatch(showResponseMessage(false, t("TAC_REQUIRED")));
      _dispatch(setIdle());
      return;
    }

    let TACparams = {
      TAC: data.registerTAC,
      phoneNumber: selectedPhonePrefix + formattedPhone,
      referralCode: referralCode,
    };
    let TACjson = await ApiEngine.post(
      ApiUrl._API_VERIFY_TAC_CODE,
      createFormBody(TACparams)
    );
    if (TACjson[ApiKey._API_SUCCESS_KEY]) {
      let params = {
        username: data.registerUsername,
        password: data.registerPassword,
        userFullName: data.registerFullName,
        phoneNumber: selectedPhonePrefix + formattedPhone,
        referralCode: referralCode,
        isMemberOperate: true,
        languageSetting: localStorage.getItem(SessionKey._LANGUAGE) && localStorage.getItem(SessionKey._LANGUAGE) != 'undefined' ?
          localStorage.getItem(SessionKey._LANGUAGE) : 'en',
      };
      let responseJson = await ApiEngine.post(
        ApiUrl._API_CREATE_OR_UPDATE_MEMBER,
        createFormBody(params)
      );
      _dispatch(
        showMessage({
          type: responseJson[ApiKey._API_SUCCESS_KEY]
            ? AlertTypes._SUCCESS
            : AlertTypes._ERROR,
          content: t(responseJson[ApiKey._API_MESSAGE_KEY]),
          onConfirm: async () => {
            if (responseJson[ApiKey._API_SUCCESS_KEY]) {
              setRegisterSuccess(true);
              //_dispatch(showResponseMessage(true, t("SUCCESS_REGISTER_ACCOUNT")));
              setTACBtnAble(false);
              clearTimeout();
              await _dispatch(performLogin(data.registerUsername, data.registerPassword));
              await _dispatch(setDisplayRegister(false));
              if (!window.ReactNativeWebView) {
                _history.replace(WebUrl._URL_MAIN);
              }
              setRegisterActiveStep(1);
              setCanSendTAC(false);
              clearTimeout(TACtimer);
              setTACsent(false);
              setTACCountdownPeriod(0);
              setPhoneNumberError(false);
              setShowTnC(false);
              setAgreeAge18(false);
              setReferralCode("")
              _dispatch(setIdle());
            } else {
              _dispatch(setIdle());
            }
          },
        })
      );
    } else {
      _dispatch(showResponseMessage(false, TACjson[ApiKey._API_MESSAGE_KEY]));
    }
    _dispatch(setIdle());
  }

  //check username
  async function checkUsername(username) {
    let avail = false;
    let responseJson = await ApiEngine.get(
      ApiUrl._API_CHECK_USERNAME + "?username=" + username
    );
    avail = responseJson[ApiKey._API_SUCCESS_KEY];
    return avail;
  }

  //send TAC code
  async function sendTACCode() {
    if (
      (TACtimedOut || countdownPeriod > 0) &&
      typeof formattedPhone !== "undefined" &&
      (selectedPhonePrefix + formattedPhone).length >= 10
    ) {
      if (!errorsRegister.phoneNumber) {
        let params = {
          phoneNumber: selectedPhonePrefix + formattedPhone,
          referralCode: referralCode,
          languageCode: localStorage.getItem(SessionKey._LANGUAGE) && localStorage.getItem(SessionKey._LANGUAGE) != 'undefined' ?
            localStorage.getItem(SessionKey._LANGUAGE) : 'en',
          countdown: countdownPeriod,
          TACExist: !TACtimedOut,
        };

        let responseJson = await ApiEngine.post(
          ApiUrl._API_SEND_TAC_CODE,
          createFormBody(params)
        );
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
          setPhoneNumberError(false);
          setTACsent(true);
          setCountdownPeriod(_TAC_VALID_TIMER / _SECONDS_IN_MS);
          setShowTacEntry(true);
          setTACBtnAble(false);
          setTimeout(() => {
            setTACBtnAble(true);
            setTACtimedOut(true);
          }, _TAC_VALID_TIMER);
        } else {
          setPhoneNumberError(true);
        }
      }
    } else {
      setErrorRegister("registerPhone", { message: "PHONE_NUMBER_INVALID" });
    }
    setCanSendTAC(false);
    clearTimeout(TACtimer);
    setTACCountdownPeriod((_TAC_VALID_TIMER / _SECONDS_IN_MS));
    setTimeout(() => {
      setCanSendTAC(true);
    }, _SECONDS_IN_MS * 60 * _TAC_TIMEOUT_IN_MINUTES);
  }

  //submit forgot password
  async function submitForgotPasswordForm(data) {
    _dispatch(setBusy());

    let formData = {
      "language": i18n.language,
      "username": data.username
    };
    let formBody = createMultiPartFormBody(formData);
    var responseJson = await ApiEngine.post(
      ApiUrl._API_SEND_RESET_PASSWORD_SMS,
      formBody
    );

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      setUsername(data.username)
      setPhoneNum(responseJson.data)
      setTempPhoneNum(responseJson.data)
      setLoginActiveStep(3);
      start();
    }
    else {
      _dispatch(showResponseMessage(false, responseJson[ApiKey._API_MESSAGE_KEY]))
    }
    _dispatch(setIdle());
  }

  //submit verify TAC
  async function submitVerifyTAC(data) {


    _dispatch(setBusy());
    if (verifyState.button == 1) {

      let formData = {
        "taccode": enteredPinNumber,
        "username": username
      };
      let formBody = createMultiPartFormBody(formData);
      let TACjson = await ApiEngine.post(
        ApiUrl._API_VERIFY_RESET_PASSWORD_SMS,
        formBody
      );
      if (TACjson[ApiKey._API_SUCCESS_KEY]) {
        setLoginActiveStep(4);
        reset();
      } else {
        _dispatch(showResponseMessage(false, t("TAC_CODE_INCORRECT")));
      }
    } else if (verifyState.button == 2) {

      let formData = {
        "language": i18n.language,
        "username": username
      };
      let formBody = createMultiPartFormBody(formData);
      //data["phoneNumber"] = formattedPhoneNumber;
      data["language"] = i18n.language;
      var responseJson = await ApiEngine.post(
        ApiUrl._API_SEND_RESET_PASSWORD_SMS,
        formBody
      );

      if (responseJson[ApiKey._API_SUCCESS_KEY]) {
        //setPhoneNum(responseJson.data);
        //setTempPhoneNum(responseJson.data);
        reset();
        start();
      }
    }

    _dispatch(setIdle());
  }

  //submit new password
  async function submitNewPassword(data) {
    _dispatch(setBusy());
    let formData = {
      "tacCode": enteredPinNumber,
      "newpassword": data.newPassword,
      "confpassword": data.confPassword,
      "username": username
    };
    let formBody = createMultiPartFormBody(formData);

    var responseJson = await ApiEngine.post(
      ApiUrl._API_MEMBER_RESET_PASSWORD_BEFORE_LOGIN,
      formBody
    );

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      _dispatch(showResponseMessage(true, t("SUCCESS_RESET_PASSWORD")));
      setLoginActiveStep(1);
    }
    _dispatch(setIdle());
  }

  //get tnc content
  const getTnC = async (languageCode) => {
    let responseJson = await ApiEngine.get(ApiUrl._API_GET_COMPANY_CONTENT_LANGUAGE + "?title=TnC&languageCode=" + languageCode);
    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      let data = responseJson[ApiKey._API_DATA_KEY];
      setContent(data);
    }
  }

  //api function end region ---------------------------------------------------------------------

  //normal function start region ---------------------------------------------------------------------
  const toggleHiddenRegisterPassword = () => {
    setNewRegisterPasswordVisible(!newRegisterPasswordVisible);
    if (isHiddenRegisterPassword === closedEye) {
      setIsHiddenRegisterPassword(openEye);
    } else {
      setIsHiddenRegisterPassword(closedEye);
    }
  };
  const toggleHiddenRegisterConfirmPassword = () => {
    setConfirmRegisterPasswordVisible(!confirmRegisterPasswordVisible);
    if (isHiddenRegisterConfirmPassword === closedEye) {
      setIsHiddenRegisterConfirmPassword(openEye);
    } else {
      setIsHiddenRegisterConfirmPassword(closedEye);
    }
  };
  const toggleHiddenLoginPassword = () => {
    setLoginPasswordVisible(!loginPasswordVisible);
    if (isHiddenLoginPassword === closedEye) {
      setIsHiddenLoginPassword(openEye);
    } else {
      setIsHiddenLoginPassword(closedEye);
    }
  };

  const toggleHiddenResetPassword = () => {
    setNewResetPasswordVisible(!newResetPasswordVisible);
    if (isHiddenResetPassword === closedEye) {
      setIsHiddenResetPassword(openEye);
    } else {
      setIsHiddenResetPassword(closedEye);
    }
  };
  const toggleHiddenResetConfirmPassword = () => {
    setConfirmResetPasswordVisible(!confirmResetPasswordVisible);
    if (isHiddenResetConfirmPassword === closedEye) {
      setIsHiddenResetConfirmPassword(openEye);
    } else {
      setIsHiddenResetConfirmPassword(closedEye);
    }
  };


  const handleReferralCode = (code) => {
    setReferralCode(code);
  };

  function handlePhoneNumber(e) {
    const re = /^[0-9\b]+$/;
    // if (e.target.value === "" || re.test(e.target.value) )
    if (e.target.value.match(re) !== null) {
      setIsDigit(true);
      //  setFormattedPhone(selectedPhonePrefix + e.target.value)
      setFormattedPhone(e.target.value)
    }
    else {
      setIsDigit(false);
    }
  };

  const getCompanyContent = async (/*page*/) => {
    let langId = LanguageOption.find(
      (x) => x.code === (localStorage.getItem(SessionKey._LANGUAGE) && localStorage.getItem(SessionKey._LANGUAGE) != 'undefined' ? localStorage.getItem(SessionKey._LANGUAGE) : 'en')
    ).languageId;
    //let responseJson = await ApiEngine.get(ApiUrl._API_GET_COMPANY_CONTENT + "?page=" + page + "&languageId=" + selectedLanguageId);
    let responseJson = await ApiEngine.get(ApiUrl._API_GET_COMPANY_CONTENT + "?page=RegisterPopup&languageId=" + langId);
    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      let data = responseJson[ApiKey._API_DATA_KEY][0];
      setRegisterPopupContent(data["content"]);
      //let tempCompanyContentList = companyContentList;
      //if (tempCompanyContentList.length === 0 || tempCompanyContentList.filter(x => x.key === page).length === 0) {
      //  tempCompanyContentList.push(page, data["content"]);
      //  setCompanyContentList(tempCompanyContentList);
      //}
    }
  }

  //normal function end region ---------------------------------------------------------------------


  return (
    <>
      <Modal
        id="custom-modal"
        isOpen={isShowRegister && !isLoggedIn}
        contentClassName="modal-brand modal-bottom"
        centered
        fade={false}
      >
        <ModalBody>
          {registerActiveStep === 1 && (
            <>
              <div className="modal-body-with-image">
                <div className="modal-left-side">
                  <div className="modal-back" onClick={() => { if (!isCheckingReferral) { _dispatch(setDisplayRegister(false)); setReferralCode(""); } }}>
                    <div>
                      <img src="/img/version3/modal-back.png" alt="register" className="img-responsive" />
                    </div>
                    <div onClick={() => { if (!isCheckingReferral) { _dispatch(setDisplayRegister(false)); setReferralCode(""); } }}>
                      {t("CLOSE")}
                    </div>
                  </div>
                  <form onSubmit={handleSubmitCheckReferral(submitCheckReferralCode)} style={{ width: '100%', alignSelf: 'center' }}>
                    <div>
                      <div className="modal-title">
                        <h2>{t("HELLO_THERE")}</h2>
                        <div>
                          <img src="/img/version3/register-wave.png" alt="register" className="img-responsive" />
                        </div>
                      </div>
                      <p className="text-center mt-4">{t("REGISTER_BY_REFERRAL_CODE")}</p>
                      <div className="custom-modal-label mt-4">
                        <div>{t("BY_REFERRAL_CODE")}</div>
                      </div>
                      <div className="input-with-icon-container mt-4">
                        <input
                          type='text'
                          name='referralCode'
                          className="custom-input"
                          placeholder="eg. AbCd3fGH +"
                          ref={checkReferralRegister({
                            required: "FIELD_REQUIRED",
                          })}
                          onChange={(e) => handleReferralCode(e.target.value)}
                          disabled={isCheckingReferral}
                          errors={checkReferralErrors}
                          value={referralCode}
                        />
                        <div className="input-icon">
                          <img src="/img/version3/code.png" alt="referral-code" className="img-responsive" />
                        </div>
                      </div>
                      {checkReferralErrors.referralCode && (
                        <div className="invalid-feedback">
                          {t(checkReferralErrors.referralCode.message)}
                        </div>
                      )}
                      {!stringIsNullOrEmpty(registerPopupContent) && <div className="referral-code-text">
                        <span className="color-red">*</span>{parse(registerPopupContent)}
                      </div>}
                      <button type="submit" disabled={isCheckingReferral} className="btn custom-btn mt-5" >{isCheckingReferral ? t("CHECKING_REFERRAL") + "..." : t("REGISTER")}</button>

                      <div className="modal-small-p mt-5 pb-5 text-center">
                        {t("HAVE_AN_ACCOUNT")} <a className="sign-in" onClick={() => { setIsCheckingReferral(false); setLoginActiveStep(1); _dispatch(setDisplayRegister(false)); _dispatch(setDisplayLogin(true)); }}>{t("SIGN_IN")}</a>
                      </div>
                    </div>
                  </form>
                  <div></div>
                </div>
                <div className="modal-right-side">
                  <img src="/img/version3/i8_bonus_offer_EN.png" alt="register" className="img-responsive" />
                </div>
              </div>
            </>
          )}

          {registerActiveStep === 2 && (
            <>
              <div className="modal-body-with-image">
                <div className="modal-left-side">
                  <div className="modal-back mb-5" onClick={() => {
                    if (!isRegistering) {
                      setRegisterActiveStep(1);
                      setCanSendTAC(false);
                      clearTimeout(TACtimer);
                      setTACsent(false);
                      setTACCountdownPeriod(0);
                      setPhoneNumberError(false);
                      setShowTnC(false);
                      setAgreeAge18(false);
                    }
                  }}>
                    <div>
                      <img src="/img/version3/modal-back.png" alt="register" className="img-responsive" />
                    </div>
                    <div onClick={() => {
                      if (!isRegistering) {
                        setRegisterActiveStep(1);
                        setCanSendTAC(false);
                        clearTimeout(TACtimer);
                        setTACsent(false);
                        setTACCountdownPeriod(0);
                        setPhoneNumberError(false);
                        setShowTnC(false);
                        setAgreeAge18(false);
                      }
                    }}>
                      {t("BACK")}
                    </div>
                  </div>
                  <form onSubmit={handleSubmitRegister(submitRegisterForm)} style={{ width: '100%', alignSelf: 'center' }}>
                    <div>
                      <div className="modal-title">
                        <h2>{t("YOU_ARE_INVITED")}</h2>
                      </div>
                      {Object.keys(uplineDetail).length > 0 && (
                        <p className="mt-2 text-center">
                          {t("HAS_INVITED_YOU", { value: uplineDetail?.username ?? "" })}
                        </p>
                      )}
                      {/*<div className="whatsapp-btn-div " onClick={() => window.open(registerWhatsAppUrl)}>*/}
                      {/*  <div className="whatsapp-btn">*/}
                      {/*    <div>*/}
                      {/*      {t("REGISTER_WITH_WHATSAPP")}*/}
                      {/*    </div>*/}
                      {/*    <div>*/}
                      {/*      <img src={require("../../assets/img/v3/ri_whatsapp-line.svg")} alt="back" className="img-responsive" />*/}
                      {/*    </div>*/}
                      {/*  </div>*/}
                      {/*</div>*/}
                      {/*<br />*/}
                      {/*<div className="whatsapp-or-div mt-2 mb-2"><span>OR</span></div>*/}
                      {/*<br />*/}
                      <div className="input-with-icon-container">
                        <input
                          type='text'
                          className="custom-input"
                          placeholder={t("FULLNAME")}
                          autoComplete="off"
                          autoFocus={true}
                          ref={registerRegister({
                            // required: true,
                            required: "FIELD_REQUIRED",
                            pattern: {
                              value: /^[a-zA-Z\s]*$/i,
                              message:
                                "PLEASE_REMOVE_NUMBERS_AND_SPECIAL_CHARACTERS",
                            },
                          })}
                          errors={errorsRegister}
                          name="registerFullName"
                          disabled={isRegistering}
                        />
                        <div className="input-icon">
                          <img src="/img/version3/register-login/username.png" alt="username" className="img-responsive" />
                        </div>
                      </div>
                      <div className="invalid-feedback">
                        {errorsRegister.registerFullName
                          ? t(errorsRegister.registerFullName.message)
                          : ""}
                      </div>
                      <div className="input-with-icon-container mt-4">
                        <input
                          type='text'
                          className="custom-input"
                          placeholder={t("USERNAME")}
                          autoComplete="off"
                          ref={registerRegister({
                            // required: true,
                            required: "FIELD_REQUIRED",
                            validate: async (value) =>
                              (await checkUsername(value)) || "USERNAME_TAKEN",
                            pattern: {
                              value: /^[a-zA-Z0-9]*$/i,
                              message:
                                "PLEASE_REMOVE_SPACES_AND_SPECIAL_CHARACTERS",
                            },
                          })}
                          errors={errorsRegister}
                          name="registerUsername"
                          disabled={isRegistering}
                        />
                        <div className="input-icon">
                          <img src="/img/version3/register-login/username.png" alt="username" className="img-responsive" />
                        </div>
                      </div>
                      <div className="invalid-feedback">
                        {errorsRegister.registerUsername
                          ? t(errorsRegister.registerUsername.message)
                          : ""}
                      </div>
                      <div className="input-with-icon-container mt-4">
                        <input
                          type={newRegisterPasswordVisible ? 'text' : 'password'}
                          className="custom-input"
                          placeholder={t("PASSWORD")}
                          autoComplete="off"
                          ref={registerRegister({
                            // required: true,
                            required: "FIELD_REQUIRED",
                            minLength: {
                              value: _MIN_PASSWORD_LENGTH,
                              message: t("MIN_CHARACTERS", {
                                length: _MIN_PASSWORD_LENGTH,
                              }),
                            },
                            validate: (value) =>
                              watchRegister("registerConfirmPassword") == "" ||
                              value == watchRegister("registerConfirmPassword") ||
                              "PASSWORD_MISMATCH",
                          })}
                          errors={errorsRegister}
                          name="registerPassword"
                          disabled={isRegistering}
                        />
                        <div className="input-icon">
                          <img src="/img/version3/register-login/key.png" alt="username" className="img-responsive" />
                        </div>
                        <div className="input-hidden-icon" onClick={toggleHiddenRegisterPassword}>
                          <img src={isHiddenRegisterPassword} alt="password" className="img-responsive" />
                        </div>
                      </div>
                      <div className="invalid-feedback">
                        {errorsRegister.registerPassword
                          ? t(errorsRegister.registerPassword.message)
                          : ""}
                      </div>
                      <div className="input-with-icon-container mt-4">
                        <input
                          type={confirmRegisterPasswordVisible ? 'text' : 'password'}
                          className="custom-input"
                          placeholder={t("CONFIRM_PASSWORD")}
                          autoComplete="off"
                          ref={registerRegister({
                            // required: true,
                            required: "FIELD_REQUIRED",
                            minLength: {
                              value: _MIN_PASSWORD_LENGTH,
                              message: t("MIN_CHARACTERS", {
                                length: _MIN_PASSWORD_LENGTH,
                              }),
                            },
                            validate: (value) =>
                              watchRegister("registerPassword") == "" ||
                              value == watchRegister("registerPassword") ||
                              "PASSWORD_MISMATCH",
                          })}
                          errors={errorsRegister}
                          name="registerConfirmPassword"
                          disabled={isRegistering}
                        />
                        <div className="input-icon">
                          <img src="/img/version3/register-login/lock.png" alt="username" className="img-responsive" />
                        </div>
                        <div className="input-hidden-icon" onClick={toggleHiddenRegisterConfirmPassword}>
                          <img src={isHiddenRegisterConfirmPassword} alt="confirm password" className="img-responsive" />
                        </div>
                      </div>
                      <div className="invalid-feedback">
                        {errorsRegister.registerConfirmPassword
                          ? t(errorsRegister.registerConfirmPassword.message)
                          : ""}
                      </div>
                      <div className="input-with-icon-container mt-4">
                        <div className="custom-phone-container">
                          <select
                            className="custom-select-container"
                            disabled={isRegistering}
                          >
                            {countryCodeData.map((item, index) => {
                              return (
                                <option
                                  key={index}
                                  className={"phone-prefix-" + (index + 1)}
                                  value={item.code}
                                >
                                  {item.code}
                                </option>
                              );
                            })}
                          </select>

                          <div className="second">
                            <input
                              id="registerPhoneNumber"
                              name="registerPhoneNumber"
                              className="custom-phone-input"
                              placeholder={t("PHONE_NUMBER")}
                              autoComplete="off"
                              readOnly={(!canSendTAC && TACcountdownPeriod != 0) || (TACsent && countdownPeriod != 0) ? true : false}
                              ref={registerRegister({
                                // required: true,
                                required: "FIELD_REQUIRED",
                                minLength: {
                                  value: 8,
                                  message: t("MIN_CHARACTERS", { length: 8 }),
                                },
                                pattern: {
                                  value: /^[0-9]+$/g,
                                  message:
                                    t("MIN_CHARACTERS", { length: 8 }) +
                                    " " +
                                    t("AND") +
                                    " " +
                                    t("ONLY_NUMBERS"),
                                },
                              })}
                              errors={errorsRegister}
                              onChange={(e) =>
                                handlePhoneNumber(e)
                              }
                            />
                          </div>
                          <div className="third">
                            {/*<div style={{ cursor: "pointer" }} onClick={() => sendTACCode()} disabled={*/}
                            {/*  (!canSendTAC && TACcountdownPeriod != 0) || !isDigit || (selectedPhonePrefix + formattedPhone).length < 10 || TACsent && countdownPeriod > 0 || !TACtimedOut*/}
                            {/*}>*/}
                            {/*  <i className="fas fa-paper-plane"></i>*/}
                            {/*</div>*/}
                            <button type="button" onClick={() => sendTACCode()} className={`btn custom-btn`} disabled={(!canSendTAC && TACcountdownPeriod != 0) || !isDigit || (selectedPhonePrefix + formattedPhone).length < 10 || TACsent && countdownPeriod > 0 || !TACtimedOut}>{t("GET_TAC")}</button>
                          </div>
                        </div>
                        <div
                          className={
                            "invalid-feedback" +
                            (phoneNumberError == true ? " phonenumber-error" : "")
                          }
                        >


                          {phoneNumberError == true
                            ?
                            <>
                              {t("NUMBER_NOT_AVAILABLE")}
                              {/* {!canSendTAC && (", try again later in " + TACcountdownPeriod + " seconds.") } */}
                              {!canSendTAC && TACcountdownPeriod != 0 && (", " + t("PLEASE_TRY_AGAIN_IN_SECONDS", { value: TACcountdownPeriod }))}
                            </>
                            : errorsRegister.registerPhone
                              ? t(errorsRegister.registerPhone.message)
                              : ""}
                        </div>
                      </div>
                    </div>

                    <div className="input-with-icon-container mt-4">
                      {TACsent && countdownPeriod != 0 && (
                        <input
                          type='text'
                          name="registerTAC"
                          className="custom-input custom-input-no-icon"
                          placeholder={t("TAC")}
                          autoComplete="off"
                          ref={registerRegister({
                            // required: true,
                            required: "FIELD_REQUIRED",
                            minLength: {
                              value: 6,
                              message: t("MIN_CHARACTERS", { length: 6 }),
                            },
                            maxLength: {
                              value: 6,
                              message: t("MAX_CHARACTERS", { length: 6 }),
                            },
                          })}
                          errors={errorsRegister}
                          disabled={isRegistering}
                        />
                      )}
                      {TACsent && countdownPeriod != 0 && (
                        <div className="red-mini-text mt-3 ml-3">
                          {t("TAC_SENT")} {t("VALID_FOR")} {countdownPeriod}
                        </div>
                      )}
                      <div className="invalid-feedback">
                        {errorsRegister.registerTAC
                          ? t(errorsRegister.registerTAC.message)
                          : ""}
                      </div>
                    </div>
                    <label className="custom-checkbox mt-3">
                      <input
                        type="checkbox"
                        className="form-checkbox-label"
                        id="terms-and-conditions"
                        onChange={() => setAgreeTnC(!agreeTnC)}
                        disabled={isRegistering}
                      />
                      <span className="checkmark"></span>
                      <div htmlFor="terms-and-conditions">
                        {t("I_AGREE_TO")} <a style={{
                          textDecoration: "underline",
                          fontSize: "14px",
                          color: "#ffc159",
                          cursor: "pointer",
                        }} onClick={() => setShowTnC(true)}> {t("TNC_i8")}</a>
                      </div>
                    </label>
                    <label className="custom-checkbox mt-3">
                      <input
                        type="checkbox"
                        className="form-checkbox-label"
                        id="legal-age-18"
                        onChange={() => setAgreeAge18(!agreeAge18)}
                        disabled={isRegistering}
                      />
                      <span className="checkmark"></span>
                      <div htmlFor="legal-age-18">
                        {t("I_AGREE_LEGAL_AGE_18")}
                      </div>
                    </label>
                    <button type="submit" className="btn custom-btn mt-5" disabled={!agreeTnC || !agreeAge18 || isRegistering}>{t("REGISTER")}</button>
                  </form>
                  {showTnC && <div>
                    <div className="tnc-popup-wrapper"></div>
                    <div className="tnc-popup-v3">
                      <div className="tnc-popup-header">{t("TNC")}</div>
                      <div className="tnc-container">
                        {parse(content)}
                      </div>
                      <div>
                        <button type="button" onClick={() => setShowTnC(false)} className="btn btn-brand-gradient btn-block">{t('OK')}</button>
                      </div>
                    </div>
                  </div>}
                </div>
                <div className="modal-right-side">
                  <img src="/img/version3/side_bg.png" alt="register" className="img-responsive" />
                </div>
              </div>
            </>
          )}

        </ModalBody>
      </Modal>

      <Modal
        id="custom-modal"
        isOpen={isShowLogin & !isLoggedIn}
        contentClassName="modal-brand modal-bottom"
        centered
        fade={false}
      >
        <ModalBody>
          {loginActiveStep === 1 && (
            <>

              <div className="modal-body-with-image">

                <div className="modal-left-side">

                  <div className="modal-back" onClick={() => { _dispatch(setDisplayLogin(false)); setLoginActiveStep(1); }}>
                    <div>
                      <img src="/img/version3/modal-back.png" alt="back button" className="img-responsive" />
                    </div>
                    <div>
                      {t("CLOSE")}
                    </div>
                  </div>
                  <form onSubmit={handleSubmitLogin(submitLoginForm)} style={{ width: '100%', alignSelf: 'center' }}>
                    <div>
                      <div className="modal-title">
                        <h2> {t("WELCOME_BACK")}</h2>
                        <div>
                          <img src="/img/version3/register-wave.png" alt="welcome wave" className="img-responsive" />
                        </div>
                      </div>
                      <p style={{ textAlign: "center" }}>{t("WELCOME_BACK_TO_I8")}</p>
                      <div className="custom-modal-label mt-4">
                        <div>{t("WELCOME")}</div>
                      </div>
                      <div className="input-with-icon-container mt-4">
                        <input
                          type="text"
                          name="loginUsername"
                          id="loginUsername"
                          className="custom-input"
                          autoComplete="off"
                          defaultValue={keptUsername}
                          ref={registerLogin({
                            required: t("FIELD_REQUIRED"),
                          })}
                          errors={errorsLogin}
                        />
                        {errorsLogin.loginUsername && (
                          <div className="invalid-feedback">
                            {t(errorsLogin.loginUsername.message)}
                          </div>
                        )}
                        <div className="input-icon">
                          <img src="/img/version3/register-login/username.png" alt="username" className="img-responsive" />
                        </div>
                      </div>
                      <div className="input-with-icon-container mt-4">
                        <input
                          type={loginPasswordVisible ? "text" : "password"}
                          name="loginPassword"
                          id="loginPassword"
                          className="custom-input"
                          defaultValue={keptPassword}
                          autoComplete="off"
                          ref={registerLogin({
                            required: true,
                          })}
                          errors={errorsLogin}
                        />
                        {errorsLogin.loginPassword && (
                          <div className="invalid-feedback">
                            {t(errorsLogin.loginPassword.message)}
                          </div>
                        )}
                        <div className="input-icon">
                          <img src="/img/version3/register-login/key.png" alt="password" className="img-responsive" />
                        </div>
                        <div className="input-hidden-icon" onClick={toggleHiddenLoginPassword}>
                          <img src={isHiddenLoginPassword} alt="password" className="img-responsive" />
                        </div>
                      </div>
                      <div className="login-checkbox-btn-wrapper mt-4">
                        <div className="login-keep-logged-in checkbox-wrapper">
                          <input
                            type="checkbox"
                            name="isKeepedLogin"
                            ref={registerLogin()}
                            errors={errorsLogin}
                          />
                          <span>{t("KEEP_LOGGED_IN")}</span>
                        </div>
                        <div className="login-remember-password checkbox-wrapper">
                          <input
                            type="checkbox"
                            name="isKeepedPassword"
                            ref={registerLogin()}
                            errors={errorsLogin}
                            defaultChecked={ls.get("gR97lPRIn797")["PYTLczcty9uH"]}
                          />
                          <span>{t("KEEP_PASSWORD")}</span>
                        </div>
                      </div>
                      <button type="submit" className="btn custom-btn mt-4">{t("LOGIN")}</button>
                      <div className="modal-small-p mt-4 text-center">
                        {t("DONT_HAVE_AN_ACCOUNT")} <a className="sign-up" onClick={() => { setRegisterActiveStep(1); _dispatch(setDisplayRegister(true)); _dispatch(setDisplayLogin(false)); }}>{t("SIGN_UP")}</a>
                      </div>
                      <div className="modal-small-p mt-3">
                        <div style={{ textAlign: "center", cursor: "pointer" }} onClick={() => setLoginActiveStep(2)}>{t("FORGOT_PASSWORD") + "?"}</div>
                      </div>
                    </div>
                  </form>
                  <div></div>

                </div>

                <div className="modal-right-side">
                  <img src="/img/version3/i8_bonus_offer_EN.png" alt="right side banner" className="img-responsive" />
                </div>
              </div>

            </>
          )}

          {loginActiveStep === 2 && (
            <>
              <div className="modal-body-with-image">
                <div className="modal-left-side">
                  <div className="modal-back" onClick={() => {
                    setLoginActiveStep(1);
                  }}>
                    <div>
                      <img src="/img/version3/modal-back.png" alt="back button" className="img-responsive" />
                    </div>
                    <div onClick={() => {
                      setLoginActiveStep(1);
                      setCanSendTAC(false);
                      clearTimeout(TACtimer);
                    }}>
                      {t("BACK")}
                    </div>
                  </div>
                  <form onSubmit={forgotPasswordHandleSubmit(submitForgotPasswordForm)} style={{ width: '100%', alignSelf: 'center' }}>
                    <div>
                      <div className="modal-title mb-2">
                        <h2>{t("FORGOT_PASSWORD")}</h2>
                      </div>
                      <p style={{ textAlign: "center" }}>{t("SEND_OTP_RESET_PASSWORD")}</p>
                      <div className="custom-modal-label mt-4">
                        <div>{t("RESET_PASSWORD")}</div>
                      </div>
                      <div className="input-with-icon-container mt-4">
                        <input
                          type='text'
                          className="custom-input"
                          id="username"
                          name="username"
                          autoComplete={"off"}
                          ref={forgotPasswordRegister({
                            required: t("FIELD_REQUIRED"),
                          })}
                          error={forgotPasswordErrors}
                          placeholder={t("USERNAME")}
                        />
                        <div className="invalid-feedback">
                          {forgotPasswordErrors.username
                            ? t(forgotPasswordErrors.username.message)
                            : ""}
                        </div>
                        <div className="input-icon">
                          <img src="/img/version3/register-login/username.png" alt="username" className="img-responsive" />
                        </div>
                      </div>
                      <button type="submit" className="btn custom-btn mt-5">{t("SEND_VIA_SMS")}</button>
                    </div>
                  </form>
                  <div></div>
                </div>
                <div className="modal-right-side">
                  <img src="/img/version3/side_bg.png" alt="right side banner" className="img-responsive" />
                </div>
              </div>
            </>
          )}

          {loginActiveStep === 3 && (
            <>
              <form onSubmit={tacVerifyHandleSubmit(submitVerifyTAC)} style={{ width: '100%', alignSelf: 'center' }}>
                <div className="modal-body-with-image">
                  <div className="modal-left-side login-tac-modal">
                    <div className="modal-back" onClick={() => setLoginActiveStep(2)}>
                      <div>
                        <img src="/img/version3/modal-back.png" alt="back button" className="img-responsive" />
                      </div>
                      <div onClick={() => setLoginActiveStep(2)}>
                        {t("BACK")}
                      </div>
                    </div>
                    <div>
                      <div className="modal-title">
                        <h2>{t("VERFICATION_CODE")}</h2>
                      </div>
                      <p style={{ textAlign: "center" }}>{t("CODE_SENT", { value: tempPhoneNum })}</p>
                      <div className="custom-modal-label mt-4">
                        <div>{t("VERIFICATION_CODE")}</div>
                      </div>

                      <div className="custom-pin-container mt-4">
                        <PinInput
                          length={6}
                          initialValue=""
                          secret={true}
                          autoSelect={true}
                          disabled={false}
                          type="numeric"
                          inputMode="number"
                          regexCriteria={/^[ 0-9_@./#&+-]*$/}
                          onChange={(e) => setEnteredPinNumber(e)}
                        />
                      </div>


                      <button type="submit" className="btn custom-btn mt-5" onClick={() => verifyState.button = "1"}>{t("CONFIRM")}</button>
                      {countdownTAC !== 0 &&
                        <span className="modal-small-p mt-5">
                          {t("RESET_CODE_IN_SECONDS", { value: moment.duration(countdownTAC, 'milliseconds').asSeconds() })}
                        </span>
                      }
                      {countdownTAC === 0 && (
                        <div className="tac_send_again">
                          <button type="submit" onClick={() => verifyState.button = "2"}>
                            {t("DIDNT_RECEIVE_A_CODE")}
                          </button>
                        </div>
                      )}
                    </div>
                    <div></div>
                  </div>
                  <div className="modal-right-side">
                    <img src="/img/version3/side_bg.png" alt="register" className="img-responsive" />
                  </div>
                </div>
              </form>
            </>
          )}

          {loginActiveStep === 4 && (
            <>
              <div className="modal-body-with-image">
                <div className="modal-left-side">
                  <div className="modal-back" onClick={() => _dispatch(setDisplayLogin(false))}>
                    <div>
                      <img src="/img/version3/modal-back.png" alt="register" className="img-responsive" />
                    </div>
                    <div onClick={() => setLoginActiveStep(3)}>
                      {t("BACK")}
                    </div>
                  </div>
                  <form onSubmit={ResetPasswordHandleSubmit(submitNewPassword)} style={{ width: '100%', alignSelf: 'center' }}>
                    <div>
                      <div className="modal-title">
                        <h2>{t("CREATE_NEW_PASSWORD")}</h2>
                        <div>
                          <img src="/img/version3/register-wave.png" alt="register" className="img-responsive" />
                        </div>
                      </div>
                      <p style={{ textAlign: "center" }}>{t("CODE_SENT", { value: tempPhoneNum })}</p>
                      <div className="custom-modal-label mt-4">
                        <div>{t("RESET_PASSWORD")}</div>
                      </div>

                      <div className="input-with-icon-container mt-4">
                        <input
                          type={newResetPasswordVisible ? 'text' : 'password'}
                          className="custom-input"
                          placeholder={t("NEW_PASSWORD")}
                          name="newPassword"
                          id="newPassword"
                          ref={ResetPasswordRegister({
                            required: true,
                            minLength: {
                              value: _MIN_PASSWORD_LENGTH,
                              message: t("MIN_CHARACTERS", {
                                length: _MIN_PASSWORD_LENGTH,
                              }),
                            },
                            validate: (value) =>
                              watch("confPassword") == "" ||
                              value == watch("confPassword") ||
                              "PASSWORD_MISMATCH",
                          })}
                          errors={ResetPasswordErrors}
                        />
                        <div className="input-icon">
                          <img src="/img/version3/register-login/key.png" alt="username" className="img-responsive" />
                        </div>
                        <div className="input-hidden-icon" onClick={toggleHiddenResetPassword}>
                          <img src={isHiddenResetPassword} alt="password" className="img-responsive" />
                        </div>
                      </div>

                      <div className="input-with-icon-container mt-4">
                        <input
                          type={confirmResetPasswordVisible ? 'text' : 'password'}
                          className="custom-input"
                          name="confPassword"
                          id="confPassword"
                          placeholder={t("PLEASE_ENTER_CONFIRM_NEW_PASSWORD")}
                          autoComplete="off"
                          defaultValue={keptUsername}
                          ref={ResetPasswordRegister({
                            required: true,
                            minLength: {
                              value: _MIN_PASSWORD_LENGTH,
                              message: t("MIN_CHARACTERS", {
                                length: _MIN_PASSWORD_LENGTH,
                              }),
                            },
                            validate: (value) =>
                              watch("newPassword") == "" ||
                              value == watch("newPassword") ||
                              "PASSWORD_MISMATCH",
                          })}
                          errors={ResetPasswordErrors}
                        />
                        <div className="input-icon">
                          <img src="/img/version3/register-login/key.png" alt="username" className="img-responsive" />
                        </div>
                        <div className="input-hidden-icon" onClick={toggleHiddenResetConfirmPassword}>
                          <img src={isHiddenResetConfirmPassword} alt="password" className="img-responsive" />
                        </div>
                      </div>

                      <button className="btn custom-btn mt-5">{t("RESET_PASSWORD")}</button>
                    </div>
                  </form>
                  <div></div>
                </div>
                <div className="modal-right-side">
                  <img src="/img/version3/side_bg.png" alt="register" className="img-responsive" />
                </div>
              </div>
            </>
          )}
        </ModalBody>
      </Modal>

      <SuccessModal
        isOpen={registerSuccess}
        setIsOpen={setRegisterSuccess}
        title={"CONGRATULATIONS"}
        description={"ACCOUNT_REGISTER_SUCCESS"}
        primary={true}
        primaryText={"GO_TO_HOME"}
        primaryClick={() => { _history.push(WebUrl._URL_MAIN); setRegisterSuccess(false); }}
      />
    </>
  )
}

export default Auth;
