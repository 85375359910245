import React, { useState, useEffect, useRef } from "react";
import ContentHeader from "components/content/header";
import RightInformation from "../../components/custom/RightInformation";
import { latestGames, redemptionHistory } from "../../config/game-configs";
import Breadcrumb from "components/custom/Breadcrumb";
import { map } from "lodash";
import Input from "../../components/custom/Input";
import { useTranslation } from "react-i18next";
import Icon from "@mdi/react";

const ClaimGamePoints = (props) => {
    const [currentActiveTab, setCurrentActiveTab] = useState(0);
    var { t } = useTranslation();

    const breadcrumb = [
        {
          value: 'Home',
          active: false,
        },
        {
          value: 'Wallet',
          active: false,
        },
        {
          value: 'Use Points',
          active: true,
        },
      ];
    const gamepointData = [
        {
            image: "/img/version3/main-wallet/points-image.png",
            name: '1x IPhone 14 Pro Max',
            date: '31 May',
            points: '600,000',
        },
        {
            image: "/img/version3/main-wallet/points-image.png",
            name: '1x IPhone 14 Pro 1',
            date: '31 May',
            points: '600,000',
        },
        {
            image: "/img/version3/main-wallet/points-image.png",
            name: '1x IPhone 14 Pro 2',
            date: '31 May',
            points: '600,000',
        },
        {
            image: "/img/version3/main-wallet/points-image.png",
            name: '1x IPhone 14 Pro 3',
            date: '31 May',
            points: '600,000',
        },
        {
            image: "/img/version3/main-wallet/points-image.png",
            name: '1x IPhone 14 Pro 4',
            date: '31 May',
            points: '600,000',
        },
        {
            image: "/img/version3/main-wallet/points-image.png",
            name: '1x IPhone 14 Pro 5',
            date: '31 May',
            points: '600,000',
        },
      ];
    return (
        <>
            <section className="section-padding" id="shareholder-section">
                <Breadcrumb
                    data={breadcrumb}
                    // escButtonOnClick={() => window.history.go(-1)}
                />
                <div className="with-information-container">
                    <div className="left">
                        <div className="custom-box-with-icon-container mb-4 gamepoint-header">
                            <div className="">
                                <div className="title mb-1">
                                    Grand Total Point Claimable
                                </div>
                                <div className="details">
                                    THB 156.90
                                </div>
                            </div>
                            <div className="second">
                                <button
                                    type="submit"
                                    className="claim-btn"
                                >
                                    {t("CLAIM")}
                                </button>
                            </div>
                        </div>
                        <div className="custom-box-with-icon-container mb-4 gamepoint-total-box">
                            <div className="first">
                                <div className="custom-icon-box">
                                    <img src="/img/version3/main-wallet/points-small.png" alt="points" />
                                </div>
                                <div className="details">
                                    Total Point to be redeem:
                                </div>
                            </div>
                            <div className="second color-main">
                                12,587 points
                            </div>
                        </div>
                        <h2 class="title-with-blue-under mb-4">GamePoint Redemption</h2>
                        <div className="row">
                            {gamepointData &&
                                gamepointData.length &&
                                map(gamepointData, (item, index) => {
                                return (
                                    <div 
                                    key={"gamepoint-" + index}
                                    className="col-lg-4 col-6 mb-4"
                                    >
                                        <div className="gamepoint-redemption-item">
                                            <div>
                                                <img src={item.image} alt="points" className="w-100"/>
                                            </div>
                                            <div className="title-box mt-3">
                                                <div className="first">
                                                    <div className="title">
                                                        {item.name}
                                                    </div>
                                                    <div className="date">
                                                        Valid til {item.date}
                                                    </div>
                                                </div>
                                                <div className="second">
                                                    <img src="/img/version3/main-wallet/icon.png" alt="points" />
                                                </div>
                                            </div>
                                            <div className="amount mt-3">
                                                {item.points} points
                                            </div>
                                            <button className="btn btn-custom-small mt-4">Redeem</button>
                                        </div>
                                    </div>
                                );
                                })
                            }
                        </div>



                    </div>
                    <div className="right">
                        <RightInformation
                            redemption={true}
                            redemptionData={redemptionHistory}
                            games={true}
                            //gamesTitle="Latest Game"
                            //gamesData={latestGames}
                            promotion={true}
                        />
                    </div>
                </div>
            </section>
        </>
    );
};

export default ClaimGamePoints;
