import {
  _SET_GAME_CATEGORIES,
  _SET_GAME_PROVIDERS,
  _GAME_QUERY,
  _GAME_SEARCH,
} from "./GameAction";

const _INITIAL_STATE = {
  gameCategories: [],
  gameProviders: [],
  gameQuery: "/Game/GetMasterProductGroupedGame",
  gameSavedText: "",
};

/// <summary>
/// Author :
/// </summary>
export default (state = _INITIAL_STATE, action) => {
  switch (action.type) {
    case _SET_GAME_CATEGORIES:
      return {
        ...state,
        gameCategories: action.gameCategories,
      };

    case _SET_GAME_PROVIDERS:
      return {
        ...state,
        gameProviders: action.gameProviders,
      };
    case _GAME_QUERY:
      return {
        ...state,
        gameQuery: action.data,
      };

    case _GAME_SEARCH:
      return {
        ...state,
        gameSavedText: action.data,
      };

    default:
      return state;
  }
};
