import React, { useState, useEffect, useRef } from "react";
import GameMenu from "components/game-list/game-menu";
import Slider from "react-slick";
import { map } from "lodash";
import {
  MouseParallaxChild,
  MouseParallaxContainer,
} from "react-parallax-mouse";
import { useDispatch, useSelector } from "react-redux";
import { showMessage } from "../../redux/AppAction";
import { AlertTypes, ApiKey, ApiUrl, SessionKey } from "../../util/Constant";
import { useTranslation } from "react-i18next";
import ApiEngine from "../../util/ApiEngine";

const Esport = (props) => {
  const { t, i18n } = useTranslation();
  const _dispatch = useDispatch();
  const [gameData, setGameData] = useState({});
  const videoEl = useRef(null);
  const esportSlider = {
    centerMode: true,
    slidesToShow: 5,
    dots: false,
    arrows: false,
    swipe: true,
    infinite: true,
    swipeToSlide: true,
    centerPadding: "0",
    speed: 2000,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
        },
      },
    ],
  };
  const { isLoggedIn } = useSelector(state => state.authState);
  const [isSpecialRollover, setIsSpecialRollover] = useState(false);
  const [playableCategory, setPlayableCategory] = useState([]);
  const [productCategory, setProductCategory] = useState([]);

  useEffect(() => {
    attemptPlay();
  }, []);

  useEffect(() => {
    if (isLoggedIn) {
      getProductCategory();
      getMemberSpecialRollover();
    }
  }, [isLoggedIn])

  useEffect(() => {
    if (isSpecialRollover) {
      getBonusDepositProductCategory();
    }
  }, [isSpecialRollover, productCategory])

  const attemptPlay = () => {
    videoEl &&
      videoEl.current &&
      videoEl.current.play().catch((error) => {
        console.error("Error attempting to play", error);
      });
  };

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    if (isLoggedIn) {
      getProductCategory();
      getMemberSpecialRollover();
    }
  }, [isLoggedIn])

  useEffect(() => {
    if (isSpecialRollover) {
      getBonusDepositProductCategory();
    }
  }, [isSpecialRollover, productCategory])

  const init = async () => {
    let responseJson = await ApiEngine.get(ApiUrl._API_GET_PRODUCT_GAME_LIST_BY_CATEGORY_ID + "?categoryName=eSports&languageCode=" +
      (localStorage.getItem(SessionKey._LANGUAGE) && localStorage.getItem(SessionKey._LANGUAGE) != 'undefined' ?
        localStorage.getItem(SessionKey._LANGUAGE) : 'en'));
    // localStorage.getItem(SessionKey._LANGUAGE));
    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      let data = responseJson[ApiKey._API_DATA_KEY][0];
      setGameData(data);
    }
  }

  // Note: Temporary static url link until BO ready
  const esportGames = [
    {
      image: "/img/esport/1.png",
      //link: process.env.REACT_APP_ENDPOINT_URL + "/Game/StartGame?gameId=" + gameId + "&isApp=0",
    },
    {
      image: "/img/esport/2.png",
      //link: process.env.REACT_APP_ENDPOINT_URL + "/Game/StartGame?gameId=" + gameId + "&isApp=0",
    },
    {
      image: "/img/esport/3.png",
      //link: process.env.REACT_APP_ENDPOINT_URL + "/Game/StartGame?gameId=" + gameId + "&isApp=0",
    },
    {
      image: "/img/esport/4.png",
      //link: process.env.REACT_APP_ENDPOINT_URL + "/Game/StartGame?gameId=" + gameId + "&isApp=0",
    },
    {
      image: "/img/esport/5.png",
      //link: process.env.REACT_APP_ENDPOINT_URL + "/Game/StartGame?gameId=" + gameId + "&isApp=0",
    },
    {
      image: "/img/esport/3.png",
      //link: process.env.REACT_APP_ENDPOINT_URL + "/Game/StartGame?gameId=" + gameId + "&isApp=0",
    },
  ];

  async function startGame(
    gameId,
    isApp,
    categoryTitle
  ) {
    if (isSpecialRollover) {
      let productCategoryListArr = [...playableCategory];

      if (productCategoryListArr.includes(categoryTitle)) {
        window.open(
          `${process.env.REACT_APP_ENDPOINT_URL || ""}${ApiUrl._API_START_GAME
          }?gameId=${gameId}&isApp=0&device=d`
        );
      }
      else {
        _dispatch(
          showMessage({
            type: AlertTypes._INFO,
            content: t("ROLLOVER_EXIST"),
            onConfirm: () => {
            },
          })
        );
      }
    }
    else {
      window.open(
        `${process.env.REACT_APP_ENDPOINT_URL || ""}${ApiUrl._API_START_GAME
        }?gameId=${gameId}&isApp=${isApp ? 1 : 0}&device=d`
      );
    }
  }

  const getMemberSpecialRollover = async () => {
    let responseJson = await ApiEngine.get(ApiUrl._API_GET_MEMBER_IS_SPECIAL_ROLLOVER);
    let data = "";

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      data = responseJson[ApiKey._API_DATA_KEY];
      setIsSpecialRollover(data);
    }
  }

  const getBonusDepositProductCategory = async () => {
    let responseJson = await ApiEngine.get(ApiUrl._API_GET_BONUS_DEPOSIT_PRODUCT_CATEGORY);

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      let data = responseJson[ApiKey._API_DATA_KEY];
      let productCategoryArr = [];

      if (data.includes(",")) {
        productCategoryArr = responseJson[ApiKey._API_DATA_KEY].split(",");
      }
      else {
        productCategoryArr.push(data);
      }

      let tempArr = [];
      [...productCategory].forEach((x) => {
        if (productCategoryArr.includes(x.value)) {
          tempArr.push(x.originalTitle);
        }
      })

      setPlayableCategory(tempArr);
    }
  }

  const getProductCategory = async () => {
    let tempArr = [];
    var productCategoryJson = await ApiEngine.get(ApiUrl._API_GET_PRODUCT_CATEGORY);
    if (productCategoryJson[ApiKey._API_SUCCESS_KEY]) {
      let data = productCategoryJson[ApiKey._API_DATA_KEY];
      data.forEach(x => {
        if (x.id > 0) {
          tempArr.push({ value: x.id.toString(), label: x.title, originalTitle: x.originalTitle });
        }
      });
      setProductCategory(tempArr);
    }
  }

  return (
    <>
      <section className='category-section'>
        <GameMenu />
      </section>
      <section id='Esport-section1'>
        <video
          autoPlay='autoplay'
          loop
          className='controls'
          playsInline
          muted
          id='width-video'
          src="/img/webm/EsportWeb.webm"
          ref={videoEl}
        />
        {/* <MouseParallaxContainer
          className='parallax esport-banner'
          containerStyles={{
            width: "100%",
          }}
          resetOnLeave
        >
          <MouseParallaxChild className='esport-banner-img'>
            <img
              src={require("../../assets/img/esport/background.png")}
              className='img-100'
              alt='esport-main'
            />
          </MouseParallaxChild>

          <MouseParallaxChild className=''>
            <img
              src={require("../../assets/img/esport/esport_font.png")}
              className='img-responsive esport-text'
              alt='esport-text'
            />
          </MouseParallaxChild>

          <MouseParallaxChild
            factorX={0.1}
            factorY={0.03}
            className='esport-bird'
          >
            <img
              src={require("../../assets/img/esport/bird.png")}
              className='img-responsive'
              alt='esport-icon'
            />
          </MouseParallaxChild>

          <MouseParallaxChild
            factorX={-0.05}
            factorY={-0.09}
            className='esport-joker opacity-7'
          >
            <img
              src={require("../../assets/img/esport/joker.png")}
              className='img-responsive'
              alt='esport-icon'
            />
          </MouseParallaxChild>
          <MouseParallaxChild
            factorX={0.05}
            factorY={0.04}
            className='esport-joker'
          >
            <img
              src={require("../../assets/img/esport/joker.png")}
              className='img-responsive'
              alt='esport-icon'
            />
          </MouseParallaxChild>

          <MouseParallaxChild
            factorX={-0.01}
            factorY={-0.04}
            className='esport-guy'
          >
            <img
              src={require("../../assets/img/esport/guy.png")}
              className='img-responsive'
              alt='esport-icon'
            />
          </MouseParallaxChild>
        </MouseParallaxContainer> */}
      </section>
      <section
        id='Esport-section2'
        style={{
          backgroundImage: `url(${"/img/esport/background-bot.png"})`,
        }}
      >
        <Slider {...esportSlider}>
          {esportGames &&
            esportGames.length &&
            gameData &&
            map(esportGames, (item, index) => {
              return (
                <div className={'esport-item' + (gameData["gameUm"] === true ? isLoggedIn ? " game-um" : "" : "")}
                  key={index}>
                  <a onClick={() => {
                    if (isLoggedIn) {
                      startGame(
                        gameData["id"],
                        gameData["isApp"],
                        "eSports"
                      );
                    }
                    else {
                      _dispatch(
                        showMessage({
                          type: AlertTypes._INFO,
                          content: t("PLEASE_LOGIN_FIRST"),
                          onConfirm: () => {
                            console.log("hi");
                          },
                        })
                      );
                    }
                  }}>
                    <img src={item.image} className='img-100 esports-game-icon' alt='games' />
                    {isLoggedIn && <div className="um-icon">
                      <img
                        src="/img/maintenance.png"
                        className='img-responsive'
                        alt='poker-icon'
                      />
                    </div>}
                  </a>
                </div>
              );
            })}
        </Slider>
      </section>
    </>
  );
};
export default Esport;
