import React, { useState, useEffect } from "react";
import "react-simple-keyboard/build/css/index.css";
import Sidebar from "components/sidebar";
import { map } from "lodash";
import { Nav, NavItem, NavLink, TabContent, TabPane, Modal, ModalBody } from "reactstrap";
import classnames from "classnames";
import Input from "../../components/custom/Input";
import DynamicLoadTable from "../../components/custom/DynamicLoadTable";
import { useDispatch, useSelector } from "react-redux";
import SelectOption from "../../components/custom/SelectOption";
import {
  setActiveTab,
  setBusy,
  setDownlineQuery,
  setDownlineSearchText,
  setIdle,
  setPageSize,
  setY,
  showResponseMessage,
} from "../../redux/AppAction";
import { ApiKey, ApiUrl, SessionKey, WebUrl } from "../../util/Constant";
import ApiEngine from "../../util/ApiEngine";
import { useTranslation } from "react-i18next";
import { Link, useHistory, useLocation } from "react-router-dom";
import { withRouter } from "react-router";
import SweetAlert from "react-bootstrap-sweetalert";
import {
  createFormBody,
  createMultiPartFormBody,
  stringIsNullOrEmpty,
} from "../../util/Util";
import ToggleButton from "react-toggle-button";
import { Controller, useForm } from "react-hook-form";
import ContentHeader from "components/content/header";
import RightInformation from "../../components/custom/RightInformation";
import { Accordion } from "react-bootstrap";

const Downline = () => {
  const { t, i18n } = useTranslation();
  const _dispatch = useDispatch();
  const _history = useHistory();
  const _location = useLocation();
  const [categories, setCategories] = useState([]);
  const [memberData, setMemberData] = useState({});
  const [products, setProducts] = useState([]);
  const [productOption, setProductOption] = useState([]);
  const [downlineGroups, setDownlineGroups] = useState([]);
  const [downlineMemberData, setDownlineMemberData] = useState({});
  const [totalUngroupedMembers, setTotalUngroupedMembers] = useState(0);
  const [selectedGroupToDelete, setSelectedGroupToDelete] = useState([]);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [username, setUsername] = useState([]);
  const [currentActiveTab, setCurrentActiveTab] = useState(0);
  const activeTab = useSelector((state) => state.appState.activeTab);
  let _savedData = useSelector((state) => state.appState.savedText);
  let _savedPageSize = useSelector((state) => state.appState.pageSize);
  let _savedQuery = useSelector((state) => state.appState.savedQuery);
  let _userData = useSelector((state) => state.authState.userData);
  const [referralCodeCount, setReferralCodeCount] = useState(0);
  //const [latestGame, setLatestGame] = useState({});
  const [downlineCount, setDownlineCount] = useState(0);
  const [displayDataIndex, setDisplayDataIndex] = useState(-1);
  const [newDownlineData, setNewDownlineData] = useState({});

  var selectedLanguage = localStorage.getItem(SessionKey._LANGUAGE) && localStorage.getItem(SessionKey._LANGUAGE) != 'undefined' ?
    localStorage.getItem(SessionKey._LANGUAGE) : 'en';
  const { register, handleSubmit, errors, setValue, watch, control, trigger } =
    useForm();
  const [downlineData, setDownlineData] = useState({});
  const tabHeadings = [
    {
      title: "DOWNLINE",
      tab_id: 0,
    },
    {
      title: "DOWNLINE_GROUPS",
      tab_id: 1,
    },
    //{
    //  title: "DOWNLINE_GAMES",
    //  tab_id: 2,
    //},
  ];

  useEffect(() => {
    init();
  }, []);

  async function init() {
    _dispatch(setBusy());
    try {
      await getMemberDetails();
    } finally {
      _dispatch(setIdle());
    }
  }

  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      _dispatch(setActiveTab(tab));
      //if (tab == 2) {
      //  getMemberProductShowList();
      //}
    }
  };

  async function getDownlineGames(product) {
    _dispatch(setBusy());
    var responseJson = await ApiEngine.get(
      ApiUrl._API_GET_MEMBER_DOWNLINE_GAMES_BY_MASTERPRODUCT +
      "?masterProductId=" +
      product
    );
    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      setUsername(responseJson[ApiKey._API_DATA_KEY]);
    }
    _dispatch(setIdle());
  }

  useEffect(() => {
    _dispatch(
      setDownlineQuery(
        ApiUrl._API_GET_USER_MEMBER_LIST +
        "?directUserOnly=true"
      )
    );
    _dispatch(setPageSize(10));
    _dispatch(setY(0));
  }, []);


  function getText(e) {
    _dispatch(setDownlineSearchText(e.target.value));
    _dispatch(
      setDownlineQuery(
        ApiUrl._API_GET_USER_MEMBER_LIST +
        "?keyword=" +
        e.target.value +
        "&directUserOnly=true"
      )
    );
    _dispatch(setPageSize(10));
    _dispatch(setY(0));
  }

  const deleteGroup = async (groupId) => {
    let params = {
      id: groupId,
    };
    let formBody = createFormBody(params);
    var responseJson = await ApiEngine.post(
      ApiUrl._API_DELETE_DOWNLINE_GROUP,
      formBody
    );
    _dispatch(
      showResponseMessage(
        responseJson[ApiKey._API_SUCCESS_KEY],
        responseJson[ApiKey._API_MESSAGE_KEY]
      )
    );
    setConfirmDelete(false);
    getDownlineGroups(memberData);
  };

  async function getMemberDetails() {
    var responseJson = await ApiEngine.get(
      ApiUrl._API_GET_MEMBER_DETAILS_BY_ID
    );

    let categoryJson = await ApiEngine.get(
      ApiUrl._API_GET_PRODUCT_CATEGORY_DESKTOP +
      "?selectedLanguage=" +
      selectedLanguage
    );
    if (categoryJson[ApiKey._API_SUCCESS_KEY]) {
      setCategories(categoryJson[ApiKey._API_DATA_KEY]);
    }
    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      setMemberData(responseJson[ApiKey._API_DATA_KEY]);
      getDownlineGroups(responseJson[ApiKey._API_DATA_KEY]);
    } else {
      _dispatch(
        showResponseMessage(
          responseJson[ApiKey._API_SUCCESS_KEY],
          responseJson[ApiKey._API_MESSAGE_KEY]
        )
      );
    }
    //if (
    //  activeTab === tabHeadings.find((x) => x.title === "DOWNLINE_GAMES").tab_id
    //) {
    //  await getMemberProductShowList();
    //}
  }

  async function getMemberDetailsbyId(memberId, index) {
    _dispatch(setBusy());
    if (displayDataIndex === index) {
      setDisplayDataIndex(-1);
    }
    else {
      setDisplayDataIndex(index);
    }
    try {
      var responseJson = await ApiEngine.get(
        `${ApiUrl._API_GET_MEMBER_DETAILS_BY_ID}?memberId=` + memberId
      );

      if (!responseJson[ApiKey._API_SUCCESS_KEY]) {
        throw responseJson[ApiKey._API_MESSAGE_KEY];
      }

      setDownlineData(responseJson[ApiKey._API_DATA_KEY]);
    } catch (errorMessage) {
      _dispatch(
        showResponseMessage(false, responseJson[ApiKey._API_MESSAGE_KEY])
      );
    } finally {
      _dispatch(setIdle());
    }
  }

  // async function getMemberProductShowList() {
  //   _dispatch(setBusy());
  //   var productResponseJson = await ApiEngine.get(
  //     ApiUrl._API_GET_ASSIGNED_MEMBER_PRODUCT_SHOW_LIST
  //   );
  //   if (productResponseJson[ApiKey._API_SUCCESS_KEY]) {
  //     let data = productResponseJson[ApiKey._API_DATA_KEY]["products"];
  //     setProducts(data);
  //     let productList = [];
  //     data.map((data) => {
  //       productList.push({ label: data.productName, value: data.productId });
  //     });
  //     setProductOption(productList);
  //   }
  //   _dispatch(setIdle());
  // }

  const getDownlineGroups = async (memberData) => {
    var responseJson = await ApiEngine.get(ApiUrl._API_GET_DOWNLINE_GROUPS);

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      let data = responseJson[ApiKey._API_DATA_KEY];
      let downlineGroupList = [];
      let totalMembersInEachGroup = [];
      downlineGroupList.push({ label: "Default", value: 0 });
      let totalMembersInDefault = memberData["totalDirectDownlines"];
      data["totalMemberInGroup"].map((total, index) => {
        totalMembersInDefault -= total;
        totalMembersInEachGroup.push(total);
      });
      setTotalUngroupedMembers(totalMembersInDefault);
      totalMembersInEachGroup.unshift(totalMembersInDefault);
      let downlineCount = data["data"].length;
      setDownlineCount(downlineCount);
      data["data"].map((group, index) => {
        downlineGroupList.push({ label: group["name"], value: group["id"] });
      });
      setDownlineGroups(downlineGroupList);
      setDownlineMemberData(totalMembersInEachGroup);
    }
  };

  async function toggleShowValue(
    value,
    index,
    downlineUsername,
    product,
    showStatus
  ) {
    _dispatch(setBusy());
    let responseJson = await ApiEngine.post(
      ApiUrl._API_UPDATE_DOWNLINE_MEMBER_GAME +
      "?username=" +
      downlineUsername +
      "&masterProductId=" +
      product +
      "&enable=" +
      !value
    );
    let tempProducts = username;
    let tempValue = !value;
    let newArr = tempProducts[index];
    if (tempValue === true) {
      newArr.show = true;
    } else {
      newArr.show = false;
    }
    tempProducts[index] = { ...newArr };
    _dispatch(setIdle());
  }

  async function onSubmit(data, e) {
    _dispatch(setBusy());
    let params = {
      id: _location["state"]["id"],
    };

    Object.keys(data).map((key, value) => {
      params[key] = data[key];
    });

    let formBody = createMultiPartFormBody(params);
    //let tempformBody = createMultiPartFormBody({
    //    accountProductId: product["accountProductId"],
    //    commission: product['commission'],
    //    bonus: product['bonus']
    //});
    let responseJson = await ApiEngine.post(
      ApiUrl._API_UPDATE_MEMBER_PRODUCT_RATE,
      formBody
    );

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      init();
    }
    _dispatch(
      showResponseMessage(
        responseJson[ApiKey._API_SUCCESS_KEY],
        responseJson[ApiKey._API_MESSAGE_KEY]
      )
    );
    _dispatch(setIdle());
  }

  useEffect(() => {
    getReferralGroups();
    //getLatestGame();
    //getTodayDownlineMember();
  }, []);

  const getReferralGroups = async () => {
    var responseJson = await ApiEngine.get(
      ApiUrl._API_GET_MEMBER_REFERRAL_CODES
    );
    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      setReferralCodeCount((responseJson[ApiKey._API_DATA_KEY]).length);
    }
  }

  //const getLatestGame = async () => {
  //  var latestGameJson = await ApiEngine.get(ApiUrl._API_GET_LATEST_GAME);

  //  if (latestGameJson[ApiKey._API_SUCCESS_KEY]) {
  //    setLatestGame(latestGameJson[ApiKey._API_DATA_KEY]);
  //  }
  //}

  //async function getTodayDownlineMember() {
  //  _dispatch(setBusy());
  //  var responseJson = await ApiEngine.get(
  //    ApiUrl._API_GET_TODAY_NEW_DOWNLINES_MEMBER
  //  );
  //  if (responseJson[ApiKey._API_SUCCESS_KEY]) {
  //    setNewDownlineData(responseJson[ApiKey._API_DATA_KEY]);
  //  }
  //  _dispatch(setIdle());
  //}

  return (
    <>
      <section className="section-padding" id="downline-section">
        <ContentHeader
          title={t("DOWNLINE")}
        />




        <div className="with-information-container">
          <div className="left">
            <div className="downline-top-container">
              <div className="first">
                <div className="details">
                  {memberData["totalDirectDownlines"]}
                </div>
                <div className="title">
                  {t("DIRECT_DOWNLINE")}
                </div>
              </div>
              <div className="second">
                <div className="new">
                 <div
                  id="inactive-downline"
                  className="inactive-downline-wrapper"
                  onClick={() => _history.push({ pathname: WebUrl._URL_INACTIVE_DOWNLINES })}
                  >

                  <div> {t("INACTIVE_DOWNLINES")}</div>
                  <i className="fas fa-chevron-right"></i></div>
                </div>
                <div className="total">
                  <div>
                    {t("TOTAL")} &nbsp;{" "}
                    <span>
                      {memberData["totalDownlines"]}
                    </span>
                  </div>
                  <div>|</div>
                  <div>
                    {t("LEVEL")} &nbsp;{" "}
                    <span>
                      {memberData["maxDownlineLevel"]}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="custom-two-header-tab-container mt-4">
              {tabHeadings &&
                tabHeadings.length &&
                map(tabHeadings, (item, index) => {
                  return (
                    <div
                      key={"downline-tab-" + index}
                      onClick={() => setCurrentActiveTab(item.tab_id)}
                      className={currentActiveTab == item.tab_id ? 'active' : ''}
                    >
                      {t(item.title)}
                    </div>
                  );
                })
              }
            </div>
            {currentActiveTab == 0 && (
              <>
                <div id="downline-search-box-main" className="mt-4">
                  <Input
                    placeholder={t("ALIAS_NAME_PHONE")}
                    defaultValue={_savedData}
                    onInput={(e) => {
                      getText(e);
                    }}
                    inputClassName="downline-search-input"
                    inputGroupType="append"
                    inputGroupContent={
                      <i
                        className="fas fa-search"
                        style={{
                          color: "#898F97",
                          fontSize: "14px",
                        }}
                      ></i>
                    }
                  />
                </div>
                <Accordion className="downline-accordion mt-4">
                  <ul className="ul-news-list custom-dynamic-table-container" style={{ height: "unset", paddingLeft: "0px" }}>
                    <DynamicLoadTable
                      pageSize={_savedPageSize}
                      apiQuery={_savedQuery}
                      searchEnabled={true}
                      autoscrollEnabled={true}
                      render={(item, index) => {
                        return (<>
                          <Accordion.Item
                            key={index}
                            eventKey={index}
                            className="downline-main-boxes"
                          >
                            <Accordion.Header
                              onClick={() => {
                                getMemberDetailsbyId(item.id, index);
                              }}

                            >
                              <div style={{ fontSize: "15px", textAlign: "left" }} >
                                <div className="title">
                                  {item.username} {!stringIsNullOrEmpty(item.fullname) ? " - " + item.fullname : ""}
                                </div>
                                <div className="id pandding-top-global">
                                  {!stringIsNullOrEmpty(
                                    item.nickname
                                  ) && (
                                      <small className="d-block">
                                        ({item.nickname})
                                      </small>
                                    )}
                                  {item.phoneNumber}
                                </div>
                              </div>
                              <div className="acc-arrows">
                                {displayDataIndex != index ? (
                                  <img
                                    src="/img/version3/next-gray.png"
                                    className="img-responsive img1"
                                    alt="accordion"
                                  />
                                ) : (
                                  <img
                                    src="/img/version3/select-arrow.png"
                                    className="img-responsive img2"
                                    alt="accordion"
                                  />
                                )}
                              </div>
                            </Accordion.Header>
                            <Accordion.Body>
                              <div className="downlink-detail-text ">
                                <div className="downline-accordion-details">
                                  <span className="font-12 font-semi">
                                    {t("CREATED_DATE")}
                                  </span>
                                  <div className="d-item font-12">
                                    <span>{item.createdDate}</span>
                                  </div>
                                </div>
                                <div className="downline-accordion-details">
                                  <span className="font-12 font-semi">
                                    {t("COMM_RATE")}
                                  </span>
                                  <div className="d-item font-14 text-red font-semi">
                                    <Link
                                      to={{
                                        pathname:
                                          WebUrl._URL_PROGRESS_COMM_RATE,
                                        state: { id: item.id },
                                      }}
                                      className="text-red"
                                      style={{ fontSize: '14px !important' }}
                                    >
                                      {t("SEE_MORE")}
                                    </Link>
                                  </div>
                                </div>
                                <div className="downline-accordion-details">
                                  <span className="font-12 font-semi">
                                    {t("POINTS")}
                                  </span>
                                  <div className="d-item font-12">
                                    <span>
                                      {
                                        item.loyaltyPoints
                                      }
                                    </span>
                                  </div>
                                </div>
                                <div className="downline-accordion-details">
                                  <span className="font-12 font-semi">
                                    {t("DIRECT_DOWNLINE")}
                                  </span>
                                  <div className="d-item font-12">
                                    <span>
                                      {item.totalDirectDownline}
                                      {downlineData["totalDirectDownlines"]}
                                    </span>
                                  </div>
                                </div>
                                <div className="downline-accordion-details">
                                  <span className="font-12 font-semi">
                                    {t("TOTAL_DOWNLINES")}
                                  </span>
                                  <div className="d-item font-12">
                                    <span>
                                      {item.maxDownlineLevel}
                                      {downlineData["totalDownlines"]}
                                    </span>
                                  </div>
                                </div>
                                <div className="downline-accordion-details justify-content-center">
                                  <Link
                                    to={{
                                      pathname:
                                        WebUrl._URL_EDIT_DOWNLINE,
                                      state: { id: item.id },
                                    }}
                                    className="d-edit-details font-12 color-main cursor-pointer"
                                  >
                                    {t("EDIT_DOWNLINE_DETAILS")}
                                  </Link>
                                </div>
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                        </>);
                      }}
                    />
                  </ul>
                </Accordion>

              </>
            )}
            {currentActiveTab == 1 && (
              <>
                <div>
                  {downlineGroups.map((downlineGroup, index) => {
                    return (
                      <div key={index}>
                        {index === 0 && (
                          <div className="container create-group-btn">
                            <button
                              type="button"
                              className="create-downline-btn"
                              onClick={() => {
                                _history.push({
                                  pathname:
                                    WebUrl._URL_DOWNLINE_GROUP_DETAIL,
                                });
                              }}
                            >
                              {t("CREATE_DOWNLINE_GROUP")}
                            </button>
                          </div>
                        )}
                        <div className="downline-group-main-boxes downline-group">

                          <div
                            className="d-flex align-items-center"
                            style={{
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {downlineGroup.label !== "Default" && (
                              <i
                                id="delGroup"
                                className="fas fa-trash d-flex align-items-center"
                                style={{ color: "white" }}
                                onClick={() => {
                                  setSelectedGroupToDelete(
                                    downlineGroup
                                  );
                                  setConfirmDelete(true);
                                }}
                              />
                            )}
                            <div className="downline-group-div">
                              <strong
                                className="d-block"
                                style={{
                                  color: "white",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  width: "125px",
                                }}
                              >
                                {downlineGroup.label}
                              </strong>
                              <strong style={{ color: "white" }}>
                                {
                                  (downlineGroup.label !== "Default"
                                    ? downlineMemberData[index]
                                    : totalUngroupedMembers) +
                                  t(" ") + t("MEMBER")
                                }
                              </strong>
                            </div>
                          </div>
                          <div className="group-row-right">
                            <Link
                              to={{
                                pathname:
                                  WebUrl._URL_Edit_Downline_Group_Detail,
                                state: {
                                  id: downlineGroup.value,
                                  groupName: downlineGroup.label,
                                },
                              }}
                            >
                              <button
                                style={{
                                  whiteSpace: "nowrap",
                                  marginTop: "0",
                                  padding: "5px 15px",
                                  fontSize: "12px",
                                }}
                                className="custom-edit-downline"
                              >
                                {t("EDIT_%")}
                              </button>
                            </Link>
                            <Link
                              to={{
                                pathname:
                                  WebUrl._URL_DOWNLINE_GROUP_DETAIL,
                                state: { id: downlineGroup.value },
                              }}
                            >
                              <img
                                src="/img/version3/next-gray.png"
                                className="img-responsive"
                                alt="accordion ml-3"
                              />
                            </Link>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                  {/*<SweetAlert*/}
                  {/*  show={confirmDelete}*/}
                  {/*  type="warning"*/}
                  {/*  title={*/}
                  {/*    t("DELETE_GROUP") +*/}
                  {/*    selectedGroupToDelete.label +*/}
                  {/*    "?"*/}
                  {/*  }*/}
                  {/*  showCancel*/}
                  {/*  cancelBtnText={t("CANCEL")}*/}
                  {/*  confirmBtnText={t("CONFIRM")}*/}
                  {/*  onConfirm={() => {*/}
                  {/*    deleteGroup(selectedGroupToDelete.value);*/}
                  {/*    setConfirmDelete(false);*/}
                  {/*  }}*/}
                  {/*  onCancel={() => {*/}
                  {/*    setConfirmDelete(false);*/}
                  {/*  }}*/}
                  {/*/>*/}
                </div>
              </>
            )}
          </div>
          <div className="right">
            <RightInformation
              // info={true}
              // infoTitle="Wallet Info"
              // infoData={walletInformation}
              // recent={true}
              // recentTitle="Recent Transaction"
              // recentData={recentTransaction}
              newDownlines={true}
              // newDownlinesData={newDownlineData}
              // newDownlinesTitle="NEW_DOWNLINES_MEMBER"
              buttonTwo={true}
              buttonTwoQuantity={downlineCount}
              buttonTwoText="DOWNLINE_GROUPS"
              games={true}
              // gamesTitle="LATEST_GAME"
              // gamesData={latestGame}
              promotion={true}
            />
          </div>
        </div>
        <Modal
          isOpen={confirmDelete}
          id="modal-small-custom"
          fade={true}
          toggle={() => {
            setConfirmDelete(false);
          }}
          centered
        >
          <ModalBody>
            <div className="text-center">
              <div className="confirm-div">
                <img
                  className="cop-code"
                  src="/img/version3/error.svg"
                />
                <div>{t("DELETE_GROUP")}</div>
                <div className="confirm-btn-div">
                  <button type="submit" className="btn custom-btn" style={{ background: '#FF334B' }} onClick={() => setConfirmDelete(false)}>{t("CANCEL")}</button>
                  <button type="submit" className="btn custom-btn" onClick={() => deleteGroup(selectedGroupToDelete.value)}>{t("CONFIRM")}</button>
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>
        {/* <div className="container">
          <div className="row">
            <div className="col-3" id="account-menu">
              <Sidebar activePage={"DOWNLINE"} />
            </div>
            <div className="col-9">
              <ContentHeader
                title={t("DOWNLINE")}
              />
              {
                <Nav id="my-tabs" className="mt-4">
                  {tabHeadings &&
                    tabHeadings.length &&
                    map(tabHeadings, (item, index) => {
                      return (
                        <NavItem
                          key={"downline-tab-" + index}
                          onClick={() => toggleTab(item.tab_id)}
                        >
                          <NavLink
                            className={
                              "font-12 " +
                              classnames({ active: activeTab === item.tab_id })
                            }
                          >
                            <span>{t(item.title)}</span>
                          </NavLink>
                        </NavItem>
                      );
                    })}
                </Nav>
              }
              {
                <TabContent key={"tab_content"} activeTab={activeTab}>
                  {tabHeadings &&
                    tabHeadings.length &&
                    map(tabHeadings, (item, index) => {
                      return (
                        <TabPane
                          key={"downline-tab-content-" + index}
                          tabId={item.tab_id}
                        >
                          {item.tab_id == 0 && (
                            <>
                              <div
                                className="template-box mt-3"
                                id="downline-search-box-main"
                              >
                                <Input
                                  placeholder={t("ALIAS_NAME_PHONE")}
                                  defaultValue={_savedData}
                                  onInput={(e) => {
                                    getText(e);
                                  }}
                                  inputClassName="downline-search-input"
                                  inputGroupType="append"
                                  inputGroupContent={
                                    <i
                                      className="fas fa-search"
                                      style={{
                                        color: "white",
                                        fontSize: "15px",
                                      }}
                                    ></i>
                                  }
                                />
                                <div className="border-bot-black"></div>
                                <div className="row">
                                  <div className="col-lg-7 col-md-8">
                                    <div
                                      className="template-box bg-darkblue"
                                      id="downline-col"
                                    >
                                      <div className="first">
                                        <span className="mb-0 font-15 text-white">
                                          {t("NEW_MEMBER_TODAY", {
                                            value:
                                              memberData[
                                                "totalNewDownlineToday"
                                              ],
                                          })}
                                        </span>
                                      </div>
                                      <div className="second">
                                        <span className="mb-0 font-15 text-white">
                                          <div>
                                            {t("TOTAL")} &nbsp;{" "}
                                            <span>
                                              {memberData["totalDownlines"]}
                                            </span>
                                          </div>
                                          <div>
                                            {t("LEVEL")} &nbsp;{" "}
                                            <span>
                                              {memberData["maxDownlineLevel"]}
                                            </span>
                                          </div>
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-lg-5 col-md-4">
                                    <div className="template-box bg-darkblue text-center">
                                      <span className="text-gold font-semi font-20 mb-1">
                                        {memberData["totalDirectDownlines"]}
                                      </span>
                                      <br />
                                      <span className="mb-0 font-15 text-white">
                                        {t("DIRECT_DOWNLINE")}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <DynamicLoadTable
                                pageSize={_savedPageSize}
                                apiQuery={_savedQuery}
                                searchEnabled={true}
                                autoscrollEnabled={true}
                                render={(item, index) => {
                                  return (
                                    <div
                                      key={"dynamic_load_table_" + index}
                                      className="downline-main-boxes"
                                    >
                                      <div
                                        className="downline-accordion-title font-14 text-white"
                                        data-toggle="collapse"
                                        data-target={"#downline-drop" + index}
                                        onClick = {() => {
                                          getMemberDetailsbyId(item.id);
                                        }}
                                      >
                                        <span className="font-14 text-white font-semi mb-0">
                                          {item.username} {!stringIsNullOrEmpty(item.fullname) ? " - " + item.fullname : ""}
                                          <br />
                                          {!stringIsNullOrEmpty(
                                            item.nickname
                                          ) && (
                                            <small className="d-block">
                                              ({item.nickname})
                                            </small>
                                          )}
                                          {item.phoneNumber}
                                        </span>

                                        <div className="acc-arrows">
                                          <img
                                            src={require("../../assets/img/assets/next-page.png")}
                                            className="img-responsive img1"
                                            alt="accordion"
                                          />
                                          <img
                                            src={require("../../assets/img/assets/drop.png")}
                                            className="img-responsive img2"
                                            alt="accordion"
                                          />
                                        </div>
                                      </div>
                                      <div
                                        id={"downline-drop" + index}
                                        className="downline-accordion-wrapper collapse text-white mt-3"
                                      >
                                        <div className="downline-accordion-details">
                                          <span className="font-14 text-white font-semi">
                                            {t("CREATED_DATE")}
                                          </span>
                                          <div className="d-item font-14 text-white">
                                            <span>{item.createdDate}</span>
                                          </div>
                                        </div>
                                        <div className="downline-accordion-details">
                                          <span className="font-14 text-white font-semi">
                                            {t("COMM_RATE")}
                                          </span>
                                          <div className="d-item font-14 text-red font-semi">
                                            <Link
                                              to={{
                                                pathname:
                                                  WebUrl._URL_PROGRESS_COMM_RATE,
                                                state: { id: item.id },
                                              }}
                                              className="text-red"
                                            >
                                              {t("SEE_MORE")}
                                            </Link>
                                          </div>
                                        </div>
                                        <div className="downline-accordion-details">
                                          <span className="font-14 text-white font-semi">
                                            {t("POINTS")}
                                          </span>
                                          <div className="d-item font-14 text-white">
                                            <span>
                                              {
                                                "0"
                                              }
                                            </span>
                                          </div>
                                        </div>
                                        <div className="downline-accordion-details">
                                          <span className="font-14 text-white font-semi">
                                            {t("DIRECT_DOWNLINE")}
                                          </span>
                                          <div className="d-item font-14 text-white">
                                            <span>
                                              {downlineData["totalDirectDownlines"]}
                                            </span>
                                          </div>
                                        </div>
                                        <div className="downline-accordion-details">
                                          <span className="font-14 text-white font-semi">
                                            {t("TOTAL_DOWNLINES")}
                                          </span>
                                          <div className="d-item font-14 text-white">
                                            <span>
                                              {downlineData["totalDownlines"]}
                                            </span>
                                          </div>
                                        </div>
                                        <div className="downline-accordion-details">
                                          <Link
                                            to={{
                                              pathname:
                                                WebUrl._URL_EDIT_DOWNLINE,
                                              state: { id: item.id },
                                            }}
                                            className="d-edit-details font-14 text-gold cursor-pointer"
                                          >
                                            {t("EDIT_DOWNLINE_DETAILS")}
                                          </Link>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                }}
                              />
                            </>
                          )}
                          {item.tab_id == 1 && (
                            <>
                              {downlineGroups.map((downlineGroup, index) => {
                                return (
                                  <div key={index}>
                                    <div className="wallet-main-boxes downline-group">
                                      <div
                                        className="d-flex align-items-center"
                                        style={{
                                          whiteSpace: "nowrap",
                                          overflow: "hidden",
                                          textOverflow: "ellipsis",
                                        }}
                                      >
                                        {downlineGroup.label !== "Default" && (
                                          <i
                                            id="delGroup"
                                            className="fas fa-trash d-flex align-items-center"
                                            style={{ color: "white" }}
                                            onClick={() => {
                                              setSelectedGroupToDelete(
                                                downlineGroup
                                              );
                                              setConfirmDelete(true);
                                            }}
                                          />
                                        )}
                                        <strong
                                          className="d-block"
                                          style={{
                                            paddingLeft:
                                              downlineGroup.label !== "Default"
                                                ? "15px"
                                                : "25px",
                                            color: "white",
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            width: "125px",
                                          }}
                                        >
                                          {downlineGroup.label}
                                        </strong>
                                        <strong style={{ color: "white" }}>
                                          {" (" +
                                            t("TOTAL_MEMBER") +
                                            ": " +
                                            (downlineGroup.label !== "Default"
                                              ? downlineMemberData[index]
                                              : totalUngroupedMembers) +
                                            ")"}
                                        </strong>
                                      </div>
                                      <div className="group-row-right">
                                        <Link
                                          to={{
                                            pathname:
                                              WebUrl._URL_Edit_Downline_Group_Detail,
                                            state: {
                                              id: downlineGroup.value,
                                              groupName: downlineGroup.label,
                                            },
                                          }}
                                        >
                                          <button
                                            style={{
                                              whiteSpace: "nowrap",
                                              marginTop: "0",
                                              padding: "5px 15px",
                                              fontSize: "12px",
                                            }}
                                            className="custom-btn-style-1"
                                          >
                                            {t("EDIT_%")}
                                          </button>
                                        </Link>
                                        <Link
                                          to={{
                                            pathname:
                                              WebUrl._URL_DOWNLINE_GROUP_DETAIL,
                                            state: { id: downlineGroup.value },
                                          }}
                                        >
                                          <i
                                            className="fas fa-chevron-right "
                                            style={{
                                              fontSize: "20px",
                                              marginLeft: "50px",
                                              color: "white",
                                            }}
                                          ></i>
                                        </Link>
                                      </div>
                                    </div>
                                    {index === downlineGroups.length - 1 && (
                                      <div className="container create-group-btn">
                                        <button
                                          type="button"
                                          className="custom-btn-style-1"
                                          onClick={() => {
                                            _history.push({
                                              pathname:
                                                WebUrl._URL_DOWNLINE_GROUP_DETAIL,
                                            });
                                          }}
                                        >
                                          {t("CREATE")}
                                        </button>
                                      </div>
                                    )}
                                  </div>
                                );
                              })}
                              <SweetAlert
                                show={confirmDelete}
                                type="warning"
                                title={
                                  t("DELETE_GROUP") +
                                  selectedGroupToDelete.label +
                                  "?"
                                }
                                showCancel
                                cancelBtnText={t("CANCEL")}
                                confirmBtnText={t("CONFIRM")}
                                onConfirm={() => {
                                  deleteGroup(selectedGroupToDelete.value);
                                  setConfirmDelete(false);
                                }}
                                onCancel={() => {
                                  setConfirmDelete(false);
                                }}
                              />
                            </>
                          )}

                        </TabPane>
                      );
                    })}
                </TabContent>
              }
            </div>
          </div>
        </div> */}
      </section>
    </>
  );
};
export default withRouter(Downline);
