import React, { useState } from "react";
import PropTypes from "prop-types";
import Slider from "react-slick";
import { map } from "lodash";

const GameListBanner = ({ bannerList, categoryTitle }) => {
  const bannerSlider = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    dots: true,
    lazyLoad: true,
    autoplay: true
  };
  const [displayBanner, setDisplayBanner] = useState(false);
  var tempCategoryTitle = "";
  if (categoryTitle !== undefined) {
    tempCategoryTitle = categoryTitle.charAt(0).toUpperCase() + categoryTitle.slice(1);
  }
  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          {bannerList.length <= 0 && categoryTitle !== undefined ? <div className="category-banner">
            <img
              src={`/img/banner/${tempCategoryTitle}-banner.png`}
              style={displayBanner ? {} : { display: "none" }}
              className="img-100 banner-img-radius"
              alt="banner-main"
              onLoad={() => setDisplayBanner(true)}
            />
          </div>
            :
            <div className="banner-slider">
              <Slider {...bannerSlider}>
                {bannerList &&
                  bannerList.length &&
                  map(bannerList, (item, index) => {
                    return (
                      <div key={index} className="bs-item">
                        <img
                          src={item.image}
                          className="img-100 banner-img-radius"
                          alt="banner"
                          onClick={() => window.open(item.url)}
                        />
                      </div>
                    );
                  })}
              </Slider>
            </div>
          }
        </div>
      </div>
    </div>
  );
};

GameListBanner.propTypes = {
  bannerList: PropTypes.array.isRequired,
};

export default GameListBanner;
