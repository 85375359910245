import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import LiveChat from "react-livechat";
import querystring from "query-string";
import { useDispatch, useSelector } from "react-redux";
import { setBusy, setIdle } from "../../redux/AppAction";

/// <summary>
/// Author: -
/// </summary>
const LiveChatPage = props => {
  var _querystring = querystring;
  var _location = useLocation();
  var _dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const _activeTheme = useSelector(state => state.authState.userData.selectedTheme);

  const _LOAD_STATUS = {
    LOAD_PENDING: 0,
    LOAD_SUCCESS: 1,
    LOAD_FAIL: -2
  }

  const [livechat, setLiveChat] = useState();
  const [isLoaded, setIsLoaded] = useState(_LOAD_STATUS.LOAD_PENDING);

  useEffect(() => {
    _dispatch(setBusy());
    window.__lc = {};

    /// Validate the Username and password
    if (_location.search !== undefined) {
      const data = _querystring.parse(_location.search);
      window.__lc.license = parseInt(data.license);
    } else {
      setIsLoaded(_LOAD_STATUS.LOAD_FAIL);
    }
  }, []);

  useEffect(() => {
    if (isLoaded != _LOAD_STATUS.LOAD_PENDING) {
      _dispatch(setIdle());
    }
  }, [isLoaded]);

  useEffect(() => {
    if (livechat != undefined) {
      livechat.on_after_load = function () {
        livechat.open_chat_window();
        setIsLoaded(_LOAD_STATUS.LOAD_SUCCESS);
      }
      setTimeout(function () {
        if (!livechat.is_loaded()) {
          setIsLoaded(_LOAD_STATUS.LOAD_FAIL);
        }
      }, 5000);
    }

  }, [livechat]);

  return (
    <section id="livechat" className="livechat-section">
      <div className="container background-container background-div">
        <div>
          <div className="mb-3 title-4dresult">
            {t("LIVE_CHAT")}
          </div>
          <div class="mb-3 font-14 text-white font-semi">
            {t("LIVE_CHAT_LOADED")}
          </div>
        </div>
        <LiveChat license={0} onChatLoaded={ref => setLiveChat(ref)} />
      </div>
    </section>
  )
}

export default LiveChatPage;