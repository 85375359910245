import React, { useState, useEffect } from "react";
import { useHistory, Link, useLocation } from "react-router-dom";
import Icon from "@mdi/react";
import { mdiHistory } from "@mdi/js";
import { useSelector, useDispatch } from "react-redux";
import { ApiKey, ApiUrl, WebUrl, LanguageKey, SessionKey, TransactionType, PanelType, LanguageOption } from "../../util/Constant";
import { useTranslation } from "react-i18next";
import moment from "moment";
import SweetAlert from "react-bootstrap-sweetalert";
import ApiEngine from "../../util/ApiEngine.js";
import Breadcrumb from "components/custom/Breadcrumb";
import { createMultiPartFormBody, numberWithCurrencyFormat } from "../../util/Util";
import RightInformation from "../../components/custom/RightInformation";
import {
  showResponseMessage,
  setBusy,
  setIdle,
} from "../../redux/AppAction.js";
import ContentHeader from "../../components/content/header";
import Sidebar from "components/sidebar";
import ReactHtmlParser from "react-html-parser";
import {
  Modal,
  ModalBody,
  ModalHeader
} from "reactstrap";
import { redemptionHistoryFunction } from "../../redux/InformationAction.js";


const breadcrumb = [
  {
    value: 'HOME',
    active: false,
    link: WebUrl._URL_MAIN
  },
  {
    value: 'WALLET',
    active: false,
    link: WebUrl._URL_MAIN_WALLET
  },
  {
    value: 'GAME_POINTS',
    active: false,
    link: WebUrl._URL_LOYALTY_REDEMPTION
  },
  {

    value: 'GAMEPOINT_REDEMPTION',
    active: true,
  },
];


const LoyaltyVoucherDetails = (props) => {
  var _history = useHistory();
  var _location = useLocation();
  var _dispatch = useDispatch();
  var { t } = useTranslation(LanguageKey._PRIMARY);
  const userId = useSelector(
    (state) => state.authState.userData.userId
  );
  const languageCode = localStorage.getItem(SessionKey._LANGUAGE)
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [loyaltyData, setLoyaltyData] = useState([]);
  const [memberPoint, setMemberPoint] = useState(0);
  const [gameData, setGameData] = useState([]);
  const [redemptionHistoryList, setRedemptionHistoryList] = useState([]);

  useEffect(() => {
    init();
  }, [_location.state.id])

  useEffect(() => {
    init();
  }, [localStorage.getItem(SessionKey._LANGUAGE)]);

  const init = async () => {
    _dispatch(setBusy());
    if (_location.state.id) {
      let apiUrl = ApiUrl._API_GET_LOYALTY_ITEM_BY_ID + "?itemId=" + _location.state.id + "&languageCode=" + languageCode;
      let responseJson = await ApiEngine.get(apiUrl);

      if (responseJson[ApiKey._API_SUCCESS_KEY]) {
        let data = responseJson[ApiKey._API_DATA_KEY];
        setLoyaltyData(data);
      }
    }
    getMemberLoyaltyPoints();
    _dispatch(setIdle());


  }

  const handleRedemption = async (data) => {
    _dispatch(setBusy());

    data["transactionTypeId"] = TransactionType._VOUCHER;
    data["panel"] = PanelType._MEMBERSITE;
    data["amount"] = data["value"];

    if (data["productType"] === "Voucher") {
      let transactionJson = await ApiEngine.post(
        ApiUrl._API_CREATE_TRANSACTION,
        createMultiPartFormBody(data)
      );

      if (transactionJson[ApiKey._API_SUCCESS_KEY]) {
        _dispatch(
          showResponseMessage(
            transactionJson[ApiKey._API_SUCCESS_KEY],
            transactionJson[ApiKey._API_MESSAGE_KEY]
          )
        );
        init();
      }
      else {
        _dispatch(
          showResponseMessage(
            transactionJson[ApiKey._API_SUCCESS_KEY],
            transactionJson[ApiKey._API_MESSAGE_KEY]
          )
        );
      }
      _dispatch(redemptionHistoryFunction());
    }
    else {
      data["memberId"] = userId;
      let responseJson = await ApiEngine.post(
        ApiUrl._API_REDEEM_LOYALTY_ITEM,
        createMultiPartFormBody(data)
      );

      _dispatch(
        showResponseMessage(
          responseJson[ApiKey._API_SUCCESS_KEY],
          responseJson[ApiKey._API_MESSAGE_KEY]
        )
      );

      if (responseJson[ApiKey._API_SUCCESS_KEY]) {
        init();
      }
    }
    _dispatch(setIdle());
  };

  const getMemberLoyaltyPoints = async () => {
    let responseJson = await ApiEngine.get(ApiUrl._API_GET_MEMBER_LOYALTY_POINTS_BY_ID + "?id=" + userId);

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      setMemberPoint(responseJson[ApiKey._API_DATA_KEY]);
    }
  }

  function modalOnConfirm() {
    setShowConfirmation(false);
    handleRedemption(loyaltyData);
  }

  return (
    <div>
      {showConfirmation && (
        //<SweetAlert
        //    info
        //    showCancel
        //    confirmBtnText={t("CONFIRM")}
        //    cancelBtnText={t("CANCEL")}
        //    confirmBtnBsStyle="info"
        //    title={t("CONFIRM_REDEEM")}
        //    onConfirm={() => {
        //        setShowConfirmation(false);
        //        handleRedemption(loyaltyData);
        //    }}
        //    onCancel={() => {
        //        setShowConfirmation(false);
        //    }}
        //></SweetAlert>

        <Modal
          isOpen={true}
          id="loyalty-confirm-modal"
          fade={false}
          toggle={() => {
          }}
          centered
        >
          <ModalBody>
            <div className="text-center">
              <div className="loyalty-confirm-div">
                <div className="loyalty-title-container">
                  <div className="loyalty-confirm-modal-title">{ReactHtmlParser("Confirm to use <b>" + loyaltyData.pointsNeeded + " points</b> to redeem")}</div>
                  <div className="loyalty-confirm-modal-item"> {t(loyaltyData.name) + "?"}</div>
                </div>

                <div className="loyalty-confirm-btn-div">
                  <button type="submit" className="btn loyalty-custom-btn" style={{ background: '#FF334B' }} onClick={() => setShowConfirmation(false)} >{t("CANCEL")}</button>
                  <button type="submit" className="btn loyalty-custom-btn" onClick={() => modalOnConfirm()}>{t("REDEEM")}</button>
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>
      )}
      <section className="section-padding" id="loyalty-section">

        <Breadcrumb
          data={breadcrumb}

        />

        <div className="with-information-container">
          <div className="left">
            <div >
              <div style={{ marginBottom: "20px" }}>{t("GAMEPOINT_REDEMPTION")}</div>
              <div className="pb-5">
                <div className="gamepoint-voucher-img-box">
                  <img src={loyaltyData.image} alt="gamepoints-details" className="w-100" style={{ borderRadius: '8px' }} />
                </div>
                <div className="font16 text-center mt-4 gamepoint-voucher-title">
                  {loyaltyData.name}
                </div>
                <div className="gamepoint-voucher-point-box mt-2">
                  <div className="first">
                    {t("POINTS")} :
                  </div>
                  <div className="second">
                    {numberWithCurrencyFormat(loyaltyData.pointsNeeded)} {t("POINTS")}
                  </div>

                </div>
                <hr />
                <div className="loyalty-content-container title-with-blue-under mb-3">
                  {ReactHtmlParser(loyaltyData.desktopContent)}
                </div>

                <div className="btn-container mt-5">
                  {loyaltyData.redeemable === true && loyaltyData.stock > 0 && memberPoint >= loyaltyData.pointsNeeded ?
                    <button type="submit" className="btn btn-block loyalty-submit-button" onClick={() => setShowConfirmation(true)}>{t("REDEEM")}</button>
                    : loyaltyData.redeemable === false && loyaltyData.stock > 0 && (memberPoint > loyaltyData.pointsNeeded || memberPoint < loyaltyData.pointsNeeded) ? // Claim limit reached
                      <button type="submit" className="btn btn-block loyalty-submit-button" disabled>{t("REDEEMED")}</button>
                      : loyaltyData.redeemable === true && loyaltyData.stock > 0 && memberPoint < loyaltyData.pointsNeeded ? // Points not enough
                        <button type="submit" className="btn btn-block loyalty-submit-button" disabled>{t("REDEEM")}</button>
                        :
                        <button type="submit" className="btn btn-block loyalty-submit-button" disabled>{t("OUT_OF_STOCK")}</button>
                  }
                </div>
              </div>
            </div>
          </div>
          <div className="right">
            <RightInformation
              redemption={true}
              games={true}
            />
          </div>
        </div>

      </section>
    </div>
  )
}

export default LoyaltyVoucherDetails;
