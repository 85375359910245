import React, { useState, useEffect } from "react";
import "react-simple-keyboard/build/css/index.css";
import Sidebar from "components/sidebar";
import ContentHeader from "components/content/header";
import moment from "moment";
import { useTranslation } from "react-i18next";
import DateRangeFilter from "../../components/custom/DateRangeFilter";
import { stringIsNullOrEmpty, numberWithCurrencyFormat } from "../../util/Util";
import { ApiKey, ApiUrl, WebUrl } from "../../util/Constant";
import DynamicLoadTable from "../../components/custom/DynamicLoadTable";
import RightInformation from "../../components/custom/RightInformation";
import Breadcrumb from "components/custom/Breadcrumb";
import ApiEngine from "../../util/ApiEngine";
import { useDispatch } from "react-redux";
import {
  showResponseMessage,
  setBusy,
  setIdle,
} from "../../redux/AppAction.js";
const RewardHistory = (props) => {
  const { t, i18n } = useTranslation();
  var _dispatch = useDispatch();

  const [rewardUrl, setRewardUrl] = useState("");
  const [startDate, setStartDate] = useState(moment());
  const [endDate, setEndDate] = useState(moment());
  const [selectedDate, setSelectedDate] = useState("");
  const [rewardsData, setRewardData] = useState([]);
  const [grandTotalRewards, setGrandTotalRewards] = useState(0);

  useEffect(() => {
    getRewardHistory(startDate, endDate);
  }, []);

  async function getRewardHistory(start, end, selected = "") {

    let apiUrl =
      ApiUrl._API_GET_MEMBER_REWARD_HISTORY +
      "?StartDate=" +
      moment(start).startOf('day').format("YYYY-MM-DD") +
      "&EndDate=" +
      moment(end).endOf('day').format("YYYY-MM-DD") +
      "&v=" + moment(start).startOf('day').format("YYYY-MM-DD");

    if (!stringIsNullOrEmpty(selected)) {
      apiUrl += "&SelectedDate=" + moment(selected).format("YYYY-MM-DD");
    }
    setRewardUrl(apiUrl);
  }

  useEffect(() => {
    if (!stringIsNullOrEmpty(selectedDate)) {
      getRewardHistory(startDate, endDate, selectedDate);
    }
  }, [selectedDate]);

  const breadcrumb = [
    {
      value: 'REWARDS',
      active: false,
      link: WebUrl._URL_REWARDS
    },
    {
      value: 'REWARDS_RECORD',
      active: true,
      link: WebUrl._URL_REWARD_HISTORY
    },
  ];

  useEffect(() => {
    apiGetCategoryRewardRates();
  }, []);


  async function apiGetCategoryRewardRates() {
    let responseJson = await ApiEngine.get(
      ApiUrl._API_GET_CATEGORY_REWARD_RATES + "?all=true"
    );

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      let data = responseJson[ApiKey._API_DATA_KEY];
      let tempObj = {};
      let grandTotalReward = 0;
      let categoryTotal = {};
      data.map((rewardData, index) => {
        if (!tempObj[rewardData["date"]]) {
          tempObj[rewardData["date"]] = { totalAmount: 0, totalAmountClaimable: 0 };
        }
        if (!tempObj[rewardData["date"]][rewardData["categoryTitle"]]) {
          tempObj[rewardData["date"]][rewardData["categoryTitle"]] = { totalAmount: 0, totalAmountClaimable: 0 };
        }
        if (!tempObj[rewardData["date"]][rewardData["categoryTitle"]][rewardData["providerName"]]) {
          tempObj[rewardData["date"]][rewardData["categoryTitle"]][rewardData["providerName"]] = { productImage: rewardData["productImage"], rate: rewardData["rewardRate"] };
        }
        tempObj[rewardData["date"]].totalAmount += rewardData["amount"];
        tempObj[rewardData["date"]][rewardData["categoryTitle"]].totalAmount += rewardData["amount"];
        if (rewardData["claimed"] !== "True") {
          tempObj[rewardData["date"]].totalAmountClaimable += rewardData["amount"];
          tempObj[rewardData["date"]][rewardData["categoryTitle"]].totalAmountClaimable += rewardData["amount"];
          grandTotalReward += rewardData["amount"];

          if (!categoryTotal[rewardData["categoryTitle"]]) {
            categoryTotal[rewardData["categoryTitle"]] =
            {
              category: rewardData["categoryTitle"],
              totalAmount: rewardData["amount"]
            };
          }
          else if (categoryTotal[rewardData["categoryTitle"]]) {
            categoryTotal[rewardData["categoryTitle"]].totalAmount += rewardData["amount"];
          }
          else if (categoryTotal[rewardData["categoryTitle"]]) {
            categoryTotal[rewardData["categoryTitle"]] =
            {
              category: rewardData["categoryTitle"],
              totalAmount: rewardData["amount"]
            };
          }
        }

      });
      let temp = Object.keys(tempObj).sort();
      setSelectedDate(moment(temp[temp.length - 1]));

      setRewardData(tempObj);
      setGrandTotalRewards(numberWithCurrencyFormat(grandTotalReward, 3, true));

    } else {
      _dispatch(
        showResponseMessage(false, responseJson[ApiKey._API_MESSAGE_KEY])
      );
    }
  }

  const grandTotalData = [
    {
      label: 'TOTAL_EARNED',
      value: numberWithCurrencyFormat(Object.keys(rewardsData).length > 0 ? rewardsData[selectedDate.format('YYYY-MM-DD')]['totalAmount'] : 0, 3, true),
    },
    {
      label: 'TOTAL_CLAIMABLE',
      value: numberWithCurrencyFormat(Object.keys(rewardsData).length > 0 ? rewardsData[selectedDate.format('YYYY-MM-DD')]['totalAmountClaimable'] : 0, 3, true),
    },
  ];

  return (
    <>
      <section className="section-padding" id="reward-history-section">
        <Breadcrumb
          data={breadcrumb}
        // escButtonOnClick={() => window.history.go(-1)}
        />
        <div className="with-information-container">
          <div className="left">
            <DateRangeFilter
              startDate={startDate}
              endDate={endDate}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
              onSave={(start, end) => {
                setStartDate(moment(start));
                setEndDate(moment(end));
                getRewardHistory(start, end);
                setSelectedDate("");
              }}
              options={true}
            />

            <div className="thc-result-mainbox font-12">
              <table className="table reportTable">
                <thead>
                  <tr>
                    <th>{t("DATE")}</th>
                    <th>{t("PRODUCT_NAME")}</th>
                    <th>{t("CATEGORY")}</th>
                    <th>{t("COMMISSION_RATE")}</th>
                    <th>{t("VALID_TURNOVER")}</th>
                    <th>{t("AMOUNT")}</th>
                    <th>{t("CLAIMED_DATE")}</th>
                  </tr>
                </thead>
                <tbody>
                  <DynamicLoadTable
                    pageSize={20}
                    apiQuery={rewardUrl}
                    searchEnabled={true}
                    isTable={true}
                    render={(item, i) => {
                      return (
                        <tr key={"rewards_history" + i}>
                          <td>{item["date"]}</td>
                          <td>{item["productName"]}</td>
                          <td>{t(item["categoryName"])}</td>
                          <td>{numberWithCurrencyFormat(parseFloat(item["commissionPercent"]), 3)}</td>
                          <td>{numberWithCurrencyFormat(parseFloat(item["sales"]), 3)}</td>
                          <td>{numberWithCurrencyFormat(parseFloat(item["amount"]), 3)}</td>
                          <td className="">{item["claimedDate"]}</td>
                        </tr>
                      );
                    }}
                  />
                </tbody>
              </table>
            </div>
          </div>
          <div className="right">
            <RightInformation
              button={true}
              buttonText="REWARDS_TNC"
              grandTotal={true}
              grandTotalData={grandTotalData}
              grandTotalAmountTitle={grandTotalRewards}
            />
          </div>
        </div>

      </section>
    </>
  );
};
export default RewardHistory;
