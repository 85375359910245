import React, { useEffect, useState } from "react";
import { useDispatch,useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { ApiUrl, ApiKey, SessionKey, AlertTypes,} from "../../util/Constant";
import ApiEngine from '../../util/ApiEngine';
import moment from "moment";
import { showMessage} from "../../redux/AppAction";
import Draggable from 'react-draggable';
import classnames from "classnames";
import { Spinner, Nav, NavItem, NavLink,TabContent, TabPane} from "reactstrap";
import { isEmpty } from "lodash";

const CustomMiniGame = ({ parentGames93Connect, startGame }) => {
  const { t } = useTranslation();
  const { isLoggedIn } = useSelector((state) => state.authState);
  const carouselGameList = useSelector((state) => state.gameState.gameProviders);
  const _dispatch = useDispatch();

  const [games93Connect, setGames93Connect] = useState(parentGames93Connect);
  const [openMiniGameModal, setOpenMiniGameModal] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [currentActiveTab, setCurrentActiveTab] = useState("COINMINI");
  const [coinMiniGameUrl, setCoinMiniGameUrl] = useState("");
  const [kenoMiniGameUrl, setKenoMiniGameUrl] = useState("");
  const [taiXiuMiniGameUrl, setTaiXiuMiniGameUrl] = useState("");
  const [miniGamesFirstClicked, setMiniGamesFirstClicked] = useState(["COINMINI"]);
  const [showMiniGameTrigger, setShowMiniGameTrigger] = useState(false);
  const [controlledPosition, setControlledPosition] = useState({});
  const [startGameTime, setStartGameTime] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const delayTime = 4000;
  const onControlledDrag = (e, position) => {
    const { x, y } = position;
    setControlledPosition({ x, y });
  }
  const toggle = tab => {
    if (currentActiveTab !== tab) {
      if (startGameTime != "") {
        const withinSecond = new Date().getTime() - startGameTime < delayTime;
        if (miniGamesFirstClicked.indexOf(tab) == -1) {
          if (!withinSecond) {
            setCurrentActiveTab(tab);
            setIsLoading(true);
            startMiniGame(tab, 'd');
            setTimeout(function () {
              setMiniGamesFirstClicked([...miniGamesFirstClicked, tab]);
              setIsLoading(false);
            }, delayTime);
          }
        }
        else if (miniGamesFirstClicked.indexOf(currentActiveTab) != -1) {
          setCurrentActiveTab(tab);
        }
      }
    }
  }

  const [currentWindowWidth, setCurrentWindowWidth] = useState(
    Math.max(document.documentElement.clientWidth || 0,
      window.innerWidth || 0
    )
  );
  const [currentWindowHeight, setCurrentWindowHeight] = useState(
    Math.max(
      document.documentElement.clientHeight || 0,
      window.innerHeight || 0
    )
  );

  const elementHeader = document.getElementById("header-v3");
  const headerHeight = elementHeader.offsetHeight * -1;


  useEffect(() => {
    if (JSON.parse(localStorage.getItem(SessionKey._ISONLOAD)) === true) {
      localStorage.setItem(SessionKey._ISONLOAD, false);
    }
    else if (JSON.parse(localStorage.getItem(SessionKey._ISONLOAD)) === false) {
      localStorage.setItem(SessionKey._ISSHOWMINIGAMETRIGGER, true);
      setShowMiniGameTrigger(true);
    }
  }, []);

  useEffect(() => {
    setShowMiniGameTrigger(JSON.parse(localStorage.getItem(SessionKey._ISSHOWMINIGAMETRIGGER)));
  }, [isLoggedIn]);


  window.onbeforeunload = function () {
    localStorage.setItem(SessionKey._ISONLOAD, true);
  };

  async function startMiniGame(miniGameCode, device) {

    let languageCode = localStorage.getItem(SessionKey._LANGUAGE) && localStorage.getItem(SessionKey._LANGUAGE) !== 'undefined' ?
      localStorage.getItem(SessionKey._LANGUAGE) : 'en';

    // mini game have their own lang id
    let miniGameLangId = 0;

    if (languageCode === "en" || languageCode == "id") {
      miniGameLangId = 0;
    }
    else if (languageCode === "zh") {
      miniGameLangId = 1;
    }
    else if (languageCode === "ms") {
      miniGameLangId = 2;
    }
    else if (languageCode === "vi") {
      miniGameLangId = 3;
    }
    else if (languageCode === "th") {
      miniGameLangId = 4;
    }

    setStartGameTime("");
    var responseJson = await ApiEngine.get(ApiUrl._API_GET_MINI_GAME_URL +
      "?miniGameCode=" + miniGameCode +
      "&device=" + device +
      "&languageId=" + miniGameLangId
    );
    if (!responseJson[ApiKey._API_SUCCESS_KEY]) {
      throw responseJson[ApiKey._API_MESSAGE_KEY];
    }
    else {
      let data = responseJson[ApiKey._API_DATA_KEY];
      if (miniGameCode == "COINMINI") {
        setCoinMiniGameUrl(data);
        setStartGameTime(moment());
        setTimeout(function () {
          setIsLoading(false);
        }, 3000);
      }
      else if (miniGameCode == "KENOMINI") {
        setKenoMiniGameUrl(data);
        setStartGameTime(moment());
      }
      else {
        setTaiXiuMiniGameUrl(data);
        setStartGameTime(moment());
      }
    }
  }



  return (
    <>
       {openMiniGameModal && (
        <div className="cny-video">
          <div className="cny-video-box">
            <div className="mini-games-modal">
              <div className="mini-games-dialog">
                <div className="mini-games-header">
                  <img
                    src={"/img/mini-games/header/web_"
                      + (localStorage.getItem(SessionKey._LANGUAGE) && localStorage.getItem(SessionKey._LANGUAGE) !== 'undefined'
                        ? localStorage.getItem(SessionKey._LANGUAGE)
                        : 'en')
                      + ".png"} alt="mini game header"
                  />
                  <button className="mini-close-button"
                    onClick={() => {
                      setOpenMiniGameModal(false);
                      setCoinMiniGameUrl("");
                      setKenoMiniGameUrl("");
                      setTaiXiuMiniGameUrl("");
                      setCurrentActiveTab("COINMINI");
                      setMiniGamesFirstClicked(["COINMINI"]);
                      setIsLoading(true);
                    }}
                  ></button>
                </div>
                <div className="mini-games-body">
                  <Nav tabs className="mini-games-tab">
                    <NavItem>
                      <NavLink
                        className={
                          "mini-games-items " +
                          classnames({
                            selected:
                              currentActiveTab === "KENOMINI"
                          })}
                        onClick={() => {
                          toggle("KENOMINI");
                        }}
                      >
                        <img
                          src="/img/mini-games/gameIcon/KenoMini-Icon.png"
                          loading="lazy"
                          alt="KenoMini"
                        />
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={
                          "mini-games-items " +
                          classnames({
                            selected:
                              currentActiveTab === "COINMINI"
                          })}
                        onClick={() => {
                          toggle("COINMINI");
                        }}
                      >
                        <img
                          src="/img/mini-games/gameIcon/CoinMini.png"
                          loading="lazy"
                          alt="CoinMini"
                        />
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={
                          "mini-games-items " +
                          classnames({
                            selected:
                              currentActiveTab === 'TAIXIUMINI'
                          })}
                        onClick={() => {
                          toggle('TAIXIUMINI');
                        }}
                      >
                        <img
                          src="/img/mini-games/gameIcon/TaiXiumini.png"
                          loading="lazy"
                          alt="TaiXiuMini"
                        />
                      </NavLink>
                    </NavItem>
                  </Nav>

                  <TabContent
                    activeTab={currentActiveTab}
                    className="mini-games-section"
                  >
                    <TabPane
                      tabId="KENOMINI"
                      className="mini-games-content"
                      style={currentActiveTab === 'KENOMINI' ? { display: "block !important" } : { display: "none !important", opacity: 0 }
                      }>
                      <div id="KENOMINI" className="mini-games-content">
                        {
                          isLoading &&
                          <div className="loading">
                            <Spinner animation="border" role="status"></Spinner>
                          </div>
                        }
                        <iframe
                          className="iframe"
                          loading="lazy"
                          frameBorder="0"
                          width="248"
                          height="227"
                          title="Keno Mini"
                          src={kenoMiniGameUrl}
                        >
                        </iframe>
                      </div>
                    </TabPane>
                    <TabPane
                      tabId="COINMINI"
                      className="mini-games-content"
                      style={currentActiveTab === 'COINMINI' ? { display: "block !important" } : { display: "none !important", opacity: 0 }}>
                      <div id="COINMINI" className="mini-games-content">
                        {
                          isLoading &&
                          <div className="loading">
                            <Spinner animation="border" role="status"></Spinner>
                          </div>
                        }
                        <iframe
                          className="iframe"
                          loading="lazy"
                          width="248"
                          height="227"
                          title="Coin Mini"
                          src={coinMiniGameUrl}
                        >
                        </iframe>
                      </div>
                    </TabPane>
                    <TabPane
                      tabId="TAIXIUMINI"
                      className="mini-games-content"
                      style={currentActiveTab === 'TAIXIUMINI' ? { display: "block !important" } : { display: "none !important", opacity: 0 }}>
                      <div id="TAIXIUMINI" className="mini-games-content">
                        {
                          isLoading &&
                          <div className="loading">
                            <Spinner animation="border" role="status"></Spinner>
                          </div>
                        }
                        <iframe
                          className="iframe"
                          loading="lazy"
                          frameBorder="0"
                          width="248"
                          height="227"
                          title="TaiXiu Mini"
                          src={taiXiuMiniGameUrl}
                        >
                        </iframe>
                      </div>
                    </TabPane>
                  </TabContent>
                  <div className="more-game-img"
                    onClick={() => {
                      if (!isEmpty(games93Connect)) {
                        startGame(
                          games93Connect["id"],
                          games93Connect["hasOwnLobby"],
                          games93Connect["masterProductId"],
                          games93Connect["gameName"],
                          games93Connect["masterProductIsSeamless"],
                          games93Connect["isApp"],
                          games93Connect["appUrl"],
                          games93Connect["appDeepLink"],
                          games93Connect["isNewWindow"]
                        );
                      }
                    }}
                  >
                    <img
                      src={"/img/mini-games/moreGames/more_games_"
                        + (localStorage.getItem(SessionKey._LANGUAGE) && localStorage.getItem(SessionKey._LANGUAGE) !== 'undefined'
                          ? localStorage.getItem(SessionKey._LANGUAGE)
                          : 'en')
                        + ".png"} alt="more games"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {showMiniGameTrigger &&
        <>
          <Draggable
            bounds={{ left: 0, top: headerHeight + 50, right: 0, bottom: 200 }}
            onDrag={(e, position) => {
              onControlledDrag(e, position);
              setIsDragging(true);
            }}
            defaultPosition={{ x: 0, y: 0 }}
            onStop={() => {
              if (!isDragging) {
                if (isLoggedIn) {
                  setOpenMiniGameModal(true);
                  startMiniGame(currentActiveTab, "d");
                  setGames93Connect(carouselGameList.filter(x => x.gameName === "93Connect")[0]);
                }
                else if (!isLoggedIn) {
                  _dispatch(
                    showMessage({
                      type: AlertTypes._INFO,
                      content: t("PLEASE_LOGIN_FIRST"),
                    })
                  );
                }
              }
              else {
                setIsDragging(false);
              }
            }}
          >
            <div className="cny-pop-trigger">
              <img
                draggable="false"
                src={"/img/mini-games/Web_"
                  + (localStorage.getItem(SessionKey._LANGUAGE) && localStorage.getItem(SessionKey._LANGUAGE) !== 'undefined'
                    ? localStorage.getItem(SessionKey._LANGUAGE) === 'en' || localStorage.getItem(SessionKey._LANGUAGE) === 'zh' || localStorage.getItem(SessionKey._LANGUAGE) === 'ms'
                      ? localStorage.getItem(SessionKey._LANGUAGE)
                      : 'en'
                    : 'en')
                  + ".png"} alt="mini games" />
            </div>
          </Draggable>

          <Draggable
            bounds="body"
            position={controlledPosition}
            onStop={() => {
              setShowMiniGameTrigger(false);
              localStorage.setItem(SessionKey._ISSHOWMINIGAMETRIGGER, false);
            }}
          >
          <div className="cny-pop-trigger">
                            <div className={'cny-trigger-close'}>
                                <img
                                    draggable="false"
                                    src={"/img/Close_Icon.svg"} style={{ width: "20px" }}
                                    width="20"
                                    height="20"
                                    alt="close button"
                                />
                            </div>
                        </div>
          </Draggable>
        </>
      }
    </>
  )
}

export default CustomMiniGame;
