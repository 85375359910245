import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';
import { stringIsNullOrEmpty } from '../../util/Util';
import { ApiKey } from '../../util/Constant';

/// <summary>
/// Author: -
/// </summary>
const PhoneNumber = props => {
    const {
        containerClassName = "intl-tel-input",
        inputClassName = "form-control customInput",
        preferredCountries = ["my"],
        phoneContainerStyle,
        value = '',
        name = '',
        onChange,
        customPlaceHolder,
        label = 'MOBILE_NUMBER',
        mainContainerStyle = {},
        mainContainerClassName = 'form-group',
        errors,
        register,
        customErrorMessage,
        displayLabel
    } = props;

    var { t } = useTranslation();
    var _phoneRef = useRef(null);

    const [isInit, setIsInit] = useState(false);
    const [isValidNumber, setIsValidNumber] = useState(true);

    /// <summary>
    /// Author: - 
    /// </summary>
    useEffect(() => {
        if (register && name) {
            register({ name: name }//,
        //        {
        //            validate: () => isValidNumber || (customErrorMessage ?? 'INVALID_PHONE_NUMBER')
        //        }
            );
        }
    }, [isValidNumber]);

    /// <summary>
    /// Author: -
    /// </summary>
    function onChangePhoneNumber(isValid, currentEnterNumber, countryData, fullNumber) {
        var isReady = (_phoneRef.current && _phoneRef.current.preferredCountries[0]['iso2'] == countryData['iso2']) || isInit;

        if (isReady) {
            var newValue = currentEnterNumber.replace(/\D/g, '');
            var formattedValue = fullNumber.replace(/\D/g, '');

            var dialCodeWithPlus = "+" + countryData['dialCode'];
            var plainDialCode = countryData['dialCode'];

            if (!stringIsNullOrEmpty(newValue)) {
                var expectedPhoneFormat = formatPhoneNumber(formattedValue).replace(dialCodeWithPlus, '');

                if (expectedPhoneFormat.substring(0, dialCodeWithPlus['length']) == dialCodeWithPlus) {
                    expectedPhoneFormat = expectedPhoneFormat.substring(dialCodeWithPlus['length'], fullNumber['length']);
                }
                else if (expectedPhoneFormat.substring(0, plainDialCode['length']) == plainDialCode) {
                    expectedPhoneFormat = expectedPhoneFormat.substring(plainDialCode['length'], fullNumber['length']);
                }

                isValid = expectedPhoneFormat == newValue && isValid;
            }

            setIsValidNumber(isValid);
            if (onChange) {
                onChange(newValue, formattedValue, isValid);
            }
        }
        else {
            setIsInit(true);
        }
    }

    /// <summary>
    /// Author: -
    /// </summary>
    function formatPhoneNumber(value) {
        return !stringIsNullOrEmpty(value) ? (value.startsWith("+") ? value : ("+" + value)) : "";
    }

    return (
        <>
            <div id="phoneContainer" className={mainContainerClassName} style={mainContainerStyle}>
                {
                    displayLabel &&
                    <label><b>{t(label)}</b></label>
                }
                <IntlTelInput
                    ref={_phoneRef}
                    value={value}
                    separateDialCode
                    useMobileFullscreenDropdown={false}
                    fieldName={name}
                    preferredCountries={preferredCountries}
                    style={phoneContainerStyle ?? { display: 'block' }}
                    containerClassName={containerClassName}
                    inputClassName={inputClassName}
                    onPhoneNumberFocus={(validationResult, value, countryData, fullNumber) => {
                        onChangePhoneNumber(_phoneRef.current.isValidNumber(value), value, countryData, fullNumber);
                    }}
                    onPhoneNumberChange={(validationResult, value, countryData, fullNumber) => {
                        onChangePhoneNumber(_phoneRef.current.isValidNumber(value), value, countryData, fullNumber);
                    }}
                    onSelectFlag={(value, countryData, fullNumber) => {
                        onChangePhoneNumber(_phoneRef.current.isValidNumber(value), value, countryData, fullNumber);
                    }}
                    customPlaceholder={(placeholder) => {
                        return customPlaceHolder ?? placeholder.split(/[- ]+/).join('');
                    }} />
                {
                    errors && !isValidNumber && errors[name] && <div className="invalid-feedback">{t(errors[name][ApiKey._API_MESSAGE_KEY])}</div>
                }
            </div>
        </>
    );
}

export default PhoneNumber;