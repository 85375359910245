import React, { useState, useEffect, useRef, useMemo } from "react";
import "react-simple-keyboard/build/css/index.css";
import Sidebar from "components/sidebar";
import { map } from "lodash";
import QRCode from "qrcode.react";
import { ApiKey, ApiUrl, SessionKey, WebUrl } from "../../util/Constant";
import { stringIsNullOrEmpty } from "../../util/Util";
import { Link, useHistory, useLocation } from "react-router-dom";
import ApiEngine from "../../util/ApiEngine";
import moment from "moment";
import { Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Nav, NavItem, NavLink, TabContent, TabPane, Alert, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import classnames from "classnames";
import ContentHeader from "components/content/header";
import Icon from "@mdi/react";
import { mdiFileMultipleOutline } from "@mdi/js";
import { InlineShareButtons } from "sharethis-reactjs";
import RightInformation from "../../components/custom/RightInformation";
import { walletInformation, recentTransaction, latestGames } from "../../config/game-configs";
import Breadcrumb from "components/custom/Breadcrumb";

const ReferralCode = (props) => {
  const { t, i18n } = useTranslation();
  const _location = useLocation();
  const _history = useHistory();
  const [referralCodeDetail, setReferralCodeDetail] = useState([]);
  const [referralCodeId, setReferralCodeId] = useState();
  const [expiryDate, setExpiryDate] = useState(null);
  const [initCheck, setInitCheck] = useState(false);
  const [registerUrl, setRegisterUrl] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [showAlert1, setShowAlert1] = useState(false);
  const [showCodeAlert, setShowCodeAlert] = useState(false);
  const [categories, setCategories] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  const [currentActiveTab, setCurrentActiveTab] = useState(0);
  const [showShareModal, setShowShareModal] = useState(false);
  const _APPEND_REGISTER_URL = "/register?code=";
  let _copyRef = useRef();
  let _copyRef1 = useRef();
  const _ALERT_TIMEOUT = 2000;
  const [memberRegisterList, setMemberRegisterList] = useState([]);
  const [memberListCount, setMemberListCount] = useState(0);
  const [referralCodeCount, setReferralCodeCount] = useState(0);
  //const [latestGame, setLatestGame] = useState({});
  var selectedLanguage = localStorage.getItem(SessionKey._LANGUAGE) && localStorage.getItem(SessionKey._LANGUAGE) != 'undefined' ?
    localStorage.getItem(SessionKey._LANGUAGE) : 'en';
  const [informationList, setInformationList] = useState({});

  useEffect(() => {
    init();
    setInitCheck(true);
    //setTimeout(() => setInitCheck(true), 1000);
  }, [_location.state]);

  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  async function init() {
    let tempReferralCodeDetail = [];
    if (_location.state) {
      setReferralCodeId(_location.state.id);
      tempReferralCodeDetail = await getCodeDetail();
    }

    let responseJson = await ApiEngine.get(
      ApiUrl._API_GET_PRODUCT_CATEGORY_DESKTOP +
      "?selectedLanguage=" +
      selectedLanguage
    );
    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      let categoryData = responseJson[ApiKey._API_DATA_KEY];
      setActiveTab(categoryData[0]["id"]);
      let tempAvailableCategories = [];
      categoryData.map((category, index) => {
        if (
          tempReferralCodeDetail.memberReferralInfos.filter(
            (x) =>
              x.productCategory === category.originalTitle &&
              (x.commission > 0 || x.bonus > 0 || x.clubFee > 0)
          ).length > 0 ||
          category.originalTitle === "All"
        ) {
          tempAvailableCategories.push(category);
        }
      });
      setCategories(tempAvailableCategories);
      setInformationList({ id: _location.state.id, availableCategories: tempAvailableCategories, referralCodeDetail: tempReferralCodeDetail });
    }
    //}
  }

  async function getCodeDetail() {
    let apiUrl = ApiUrl._API_GET_MEMBER_REFERRAL_CODES;

    if (!stringIsNullOrEmpty(_location.state.id)) {
      apiUrl += "?id=" + _location.state.id;
    }
    let data = [];
    let responseJson = await ApiEngine.get(apiUrl);
    if (
      responseJson[ApiKey._API_SUCCESS_KEY] &&
      responseJson[ApiKey._API_DATA_KEY].length > 0
    ) {
      data = responseJson[ApiKey._API_DATA_KEY][0];
      setReferralCodeDetail(data);
      setExpiryDate(moment(data["expiryDate"]).format("YYYY-MM-DD"));

      //if(window.location.origin === "https://d.i8.live"){
      //  setRegisterUrl('https://i8.live'+ _APPEND_REGISTER_URL + data.code);
      //}
      //else{
      //  setRegisterUrl('https://staging.i8global.com'+ _APPEND_REGISTER_URL + data.code);
      //}
      setRegisterUrl(window.location.origin + _APPEND_REGISTER_URL + data.code);
    }
    return data;
  }

  function copyUrl() {
    //_copyRef.current.select();
    //document.execCommand("copy");
    //setShowAlert(true);
    //setTimeout(() => setShowAlert(false), [_ALERT_TIMEOUT]);
    navigator.clipboard.writeText(registerUrl);
    document.execCommand("copy");
    setShowCodeAlert(true);
  }

  /// <summary>
  /// Author: -
  /// </summary>
  function copyReferralCode() {
    navigator.clipboard.writeText(referralCodeDetail.code);
    document.execCommand("copy");
    setShowCodeAlert(true);
  }

  const ShareButton = useMemo(() => {
    if (!stringIsNullOrEmpty(registerUrl)) {
      return (
        <InlineShareButtons
          config={{
            alignment: 'center',
            color: 'social',
            enabled: true,
            language: 'en',
            networks: ['whatsapp', 'facebook', 'twitter', 'wechat', 'telegram'],
            padding: 12,
            radius: 35,
            size: 40,

            url: registerUrl,
          }}
        />
      )
    }
    else {
      return null;
    }

  }, [registerUrl])

  useEffect(() => {
    getMemberRegisterList();
  }, []);

  const getMemberRegisterList = async () => {
    let memberListJson = await ApiEngine.get(ApiUrl._API_GET_MEMBER_REGISTERED_UNDER_CODE + "?id=" + _location.state.id);
    if (memberListJson[ApiKey._API_SUCCESS_KEY]) {
      let memberListData = memberListJson[ApiKey._API_DATA_KEY];
      setMemberRegisterList(memberListData);
      setMemberListCount(memberListData.length);
    }
  }
  const breadcrumb = [
    {
      value: 'REFERRAL',
      active: false,
      link: WebUrl._URL_REFERRAL
    },
    {
      value: referralCodeDetail.code,
      active: true,
    },
  ];
  const tabHeadings = [
    {
      title: "REFERRAL_DETAIL",
      tab_id: 0,
    },
    {
      title: "ALL_MEMBER",
      tab_id: 1,
    }
  ];

  useEffect(() => {
    getReferralGroups();
    //getLatestGame();
  }, []);

  const getReferralGroups = async () => {
    var responseJson = await ApiEngine.get(
      ApiUrl._API_GET_MEMBER_REFERRAL_CODES
    );
    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      setReferralCodeCount((responseJson[ApiKey._API_DATA_KEY]).length);
    }
  }

  //const getLatestGame = async () => {
  //  var latestGameJson = await ApiEngine.get(ApiUrl._API_GET_LATEST_GAME);

  //  if (latestGameJson[ApiKey._API_SUCCESS_KEY]) {
  //    setLatestGame(latestGameJson[ApiKey._API_DATA_KEY]);
  //  }
  //}

  return (
    <div id="referral-code">
      <section className="section-padding" id="referral-code-section">
        <Breadcrumb data={breadcrumb} />
        <div className="with-information-container">
          <div className="left">
            <div className="font-16 color-main mb-4">
              {referralCodeDetail.code && (
                <>
                  {referralCodeDetail.code}
                </>
              )}
            </div>
            <div className="referral-qr-container">
              <div className="first">
                <div>
                  <div className="title">
                    {t("REFERRAL_CODE")}
                  </div>
                  <div className="referral-code-box">
                    <div>
                      {referralCodeDetail.code && (
                        <>
                          {referralCodeDetail.code}
                        </>
                      )}
                    </div>
                    <div className="cursor-global" onClick={() => copyReferralCode()}>
                      <img
                        className="cop-code"
                        src="/img/version3/copy.png"
                        alt="copy"
                      />
                    </div>
                  </div>
                </div>
                <div>
                  <div className="title">
                    {t("REMARK")}
                  </div>
                  <div className="referral-code-box">
                    <div>
                      {referralCodeDetail && referralCodeDetail.remark
                        ? referralCodeDetail.remark
                        : "--"}
                    </div>
                  </div>
                </div>
              </div>
              <div className="second">
                <QRCode
                  className="referral-qr-code-img"
                  value={registerUrl}
                />
              </div>
            </div>
            <div className="referral-qr-container-div">
              <div>
                <div className="title">
                  {t("REFERRAL_LINK")}
                </div>
                <div className="referral-code-box">
                  <div>
                    {registerUrl && (
                      <>
                        {registerUrl}
                      </>
                    )}
                  </div>
                  <div className="cursor-global" onClick={() => copyUrl()}>
                    <img
                      className="cop-code"
                      src="/img/version3/copy.png"
                      alt="copy"
                    />
                  </div>
                </div>
              </div>
            </div>
            <a className="rate-downline mt-4 d-block" onClick={() => setShowShareModal(true)}>{t("RATE_FOR_DOWNLINE")}</a>
            <div className="custom-two-header-tab-container mt-4">
              {tabHeadings &&
                tabHeadings.length &&
                map(tabHeadings, (item, index) => {
                  return (
                    <div
                      key={"downline-tab-" + index}
                      onClick={() => setCurrentActiveTab(item.tab_id)}
                      className={currentActiveTab == item.tab_id ? 'active' : ''}
                    >
                      {t(item.title, { value: memberListCount })}
                    </div>
                  );
                })
              }
            </div>
            {currentActiveTab == 0 && (
              <>
                {initCheck &&
                  categories.length > 0 &&
                  Object.keys(referralCodeDetail).length > 0 ? (
                  <div>
                    <Nav className="nav nav-tabs mt-4 mb-4" id="my-tabs">
                      {categories.length > 0 &&
                        categories.filter((x) => x.originalTitle !== "All")
                          .length > 1 &&
                        map(categories, (category, index) => {
                          if (
                            referralCodeDetail.memberReferralInfos.filter(
                              (x) =>
                                x.productCategory === category.originalTitle &&
                                (x.commission > 0 || x.bonus > 0 || x.clubFee > 0)
                            ).length > 0 ||
                            category.originalTitle === "All"
                          ) {
                            return (
                              <NavItem
                                key={index}
                                className={classnames({
                                  active: activeTab === category.id,
                                })}
                              >
                                <NavLink
                                  className={
                                    "font-14 " +
                                    classnames({
                                      active: activeTab === category.id,
                                    })
                                  }
                                  onClick={() => {
                                    toggleTab(category.id);
                                  }}
                                >
                                  {t(category.originalTitle)}
                                </NavLink>
                              </NavItem>
                            );
                          }
                        })}
                    </Nav>
                    {referralCodeDetail.memberReferralInfos.filter(
                      (x) => x.bonus > 0 || x.commission > 0 || x.clubFee > 0
                    ).length > 0 ? (
                      <TabContent activeTab={activeTab}>
                        {categories &&
                          map(categories, (category, index) => {
                            if (
                              referralCodeDetail.memberReferralInfos.filter(
                                (x) =>
                                  x.productCategory === category.originalTitle
                              ).length > 0 ||
                              category.originalTitle === "All"
                            ) {
                              return (
                                <TabPane key={index} tabId={category.id}>
                                  {referralCodeDetail.memberReferralInfos.map(
                                    (info, index) => {
                                      if (
                                        (info.commission > 0 ||
                                          info.bonus > 0 ||
                                          info.clubFee > 0) &&
                                        (info.productCategory ===
                                          category.originalTitle ||
                                          category.originalTitle === "All")
                                      ) {
                                        return (
                                          <div
                                            key={index}
                                            className="text-white mt-4 referral-list-box"
                                          >
                                            <h6>
                                              {info.productName + ' '}
                                              <span>
                                                {"(" +
                                                  t(
                                                    categories.find(
                                                      (x) =>
                                                        x.originalTitle ===
                                                        info.productCategory
                                                    ).originalTitle
                                                  ) +
                                                  ")"}
                                              </span>
                                            </h6>
                                            <div className="referral-game-details font-14 mt-2">
                                              {info.commission > 0 && (
                                                <div className="product-rate-row">
                                                  <div>
                                                    {t("COMMISSION_SHORT")}
                                                  </div>
                                                  <div>{info.commission}%</div>
                                                </div>
                                              )}
                                              {info.bonus > 0 && (
                                                <div className="product-rate-row">
                                                  <div>{t("BONUS")}</div>
                                                  <div>{info.bonus}%</div>
                                                </div>
                                              )}
                                              {info.productName === "D2 Poker" &&
                                                info.clubFee > 0 && (
                                                  <div className="product-rate-row">
                                                    <div>{t("CLUB_FEE")}</div>
                                                    <div>{info.clubFee}%</div>
                                                  </div>
                                                )}
                                            </div>
                                          </div>
                                        );
                                      }
                                    }
                                  )}
                                </TabPane>
                              );
                            }
                          })}
                      </TabContent>
                    ) : (
                      <div className="no-data-text">{t("NO_DATA_FOUND")}</div>
                    )}
                    <Link
                      to={{
                        pathname: WebUrl._URL_REFERRAL_EDIT,
                        state: {
                          id: referralCodeId,
                          availableCategories: categories,
                          referralCodeDetail: referralCodeDetail,
                        },
                      }}
                    >
                      <button className="custom-btn-style-1 mt-5">{t("EDIT")}</button>
                    </Link>
                  </div>
                ) : (
                  <div className="loading-spinner-wrapper">
                    <Spinner animation="border" role="status">
                      <span className="sr-only">Loading...</span>
                    </Spinner>
                  </div>
                )}
              </>
            )}

            <div className="referral-all-member-container mt-4">
              {currentActiveTab == 1 && (
                <>
                  {memberRegisterList &&
                    memberRegisterList.length ? (
                    map(memberRegisterList, (item, index) => {
                      return (
                        <div className="template-box-style-1">
                          <div className="first">
                            <div className="title">
                              {item.memberUsername}
                            </div>
                            <div className="details">
                              {item.phoneNumber}
                            </div>
                          </div>
                        </div>
                      );
                    })) : (
                    <>
                      <div style={{ textAlign: 'center' }}>{t("NO_DATA_FOUND")}</div>
                    </>
                  )
                  }
                </>
              )}
            </div>
          </div>
          <div className="right">
            <RightInformation
              buttonTwo={true}
              buttonTwoQuantity={referralCodeCount}
              buttonTwoText="REFERRAL_GROUPS"
              games={true}
              //gamesTitle="LATEST_GAME"
              //gamesData={latestGame}
              promotion={true}

            />
          </div>
        </div>




        {/* <div className="container">
          <div className="row">
            <div className="col-3" id="account-menu">
              <Sidebar activePage={"REFERRAL"} />
            </div>
            <div className="col-9">
              <ContentHeader enableBackButton title={t("REFERRAL_CODE")} />
              <hr className="right-title-hr" />
              {initCheck &&
              categories.length > 0 &&
              Object.keys(referralCodeDetail).length > 0 ? (
                <div className="template-box" id="payment-method-box">
                  <div className="deposit-qr mt-3">
                    <div className="display-flex justify-center">
                      <div className="text-white font-16 font-bold display-flex position-relative">
                        {referralCodeDetail.code} +
                        <div
                          className="referral-code-clipboard"
                          onClick={() => copyReferralCode()}
                        >
                          <Icon
                            path={mdiFileMultipleOutline}
                            size={0.8}
                            color="white"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="qr-bg-box">
                      <img
                        className="qr-code-background"
                        src={require("../../assets/img/referral_background.png")}
                      />
                      <QRCode
                        className="referral-qr-code"
                        value={registerUrl}
                      />
                    </div>
                    <p className="font-16 mt-3">
                      {t("REMARK")} :{" "}
                      {referralCodeDetail && referralCodeDetail.remark
                        ? referralCodeDetail.remark
                        : "--"}
                    </p>

                    <div className="referral-link-wrapper">
                      <input
                        className="font-12 custom-input-style-1"
                        style={{ borderRadius: "15px" }}
                        value={registerUrl}
                        ref={_copyRef}
                        readOnly
                      />
                      <div className="referral-copy">
                        <a onClick={() => copyUrl()}>
                          <img
                            src={require("../../assets/img/copy.png")}
                            className="img-responsive"
                            alt="close"
                          />
                        </a>
                      </div>
                      <div className="text-green text-bold">
                        {showAlert ? t("COPY_SUCCESS") : ""}
                      </div>
                    </div>

                    <div className="d-flex justify-content-between pt-3">
                      <p className="text-center mb-2 text-yellow pl-3">{t("RATE_FOR_DOWNLINE")}</p>
                        {ShareButton}
                    </div>
                  
                  </div>
                  <Nav className="nav nav-tabs mt-4" id="my-tabs">
                    {categories.length > 0 &&
                      categories.filter((x) => x.originalTitle !== "All")
                        .length > 1 &&
                      map(categories, (category, index) => {
                        if (
                          referralCodeDetail.memberReferralInfos.filter(
                            (x) =>
                              x.productCategory === category.originalTitle &&
                              (x.commission > 0 || x.bonus > 0 || x.clubFee > 0)
                          ).length > 0 ||
                          category.originalTitle === "All"
                        ) {
                          return (
                            <NavItem
                              key={index}
                              className={classnames({
                                active: activeTab === category.id,
                              })}
                            >
                              <NavLink
                                className={
                                  "font-14 " +
                                  classnames({
                                    active: activeTab === category.id,
                                  })
                                }
                                onClick={() => {
                                  toggleTab(category.id);
                                }}
                              >
                                {t(category.originalTitle)}
                              </NavLink>
                            </NavItem>
                          );
                        }
                      })}
                  </Nav>
                  {referralCodeDetail.memberReferralInfos.filter(
                    (x) => x.bonus > 0 || x.commission > 0 || x.clubFee > 0
                  ).length > 0 ? (
                    <TabContent activeTab={activeTab}>
                      {categories &&
                        map(categories, (category, index) => {
                          if (
                            referralCodeDetail.memberReferralInfos.filter(
                              (x) =>
                                x.productCategory === category.originalTitle
                            ).length > 0 ||
                            category.originalTitle === "All"
                          ) {
                            return (
                              <TabPane key={index} tabId={category.id}>
                                {referralCodeDetail.memberReferralInfos.map(
                                  (info, index) => {
                                    if (
                                      (info.commission > 0 ||
                                        info.bonus > 0 ||
                                        info.clubFee > 0) &&
                                      (info.productCategory ===
                                        category.originalTitle ||
                                        category.originalTitle === "All")
                                    ) {
                                      return (
                                        <div
                                          key={index}
                                          className="text-white mt-4 referral-list-box"
                                        >
                                          <h6>
                                            {info.productName + ' '}
                                            <span>
                                              {
                                                "(" +
                                                  t(
                                                    categories.find(
                                                      (x) =>
                                                        x.originalTitle ===
                                                        info.productCategory
                                                    ).originalTitle
                                                  ) +
                                                ") "
                                              }
                                            </span>
                                          </h6>
                                          <div className="referral-game-details font-14 mt-2">
                                            {info.commission > 0 && (
                                              <div className="product-rate-row">
                                                <div>
                                                  {t("COMMISSION_SHORT")}
                                                </div>
                                                <div>{info.commission}%</div>
                                              </div>
                                            )}
                                            {info.bonus > 0 && (
                                              <div className="product-rate-row">
                                                <div>{t("BONUS")}</div>
                                                <div>{info.bonus}%</div>
                                              </div>
                                            )}
                                            {info.productName === "D2 Poker" &&
                                              info.clubFee > 0 && (
                                                <div className="product-rate-row">
                                                  <div>{t("CLUB_FEE")}</div>
                                                  <div>{info.clubFee}%</div>
                                                </div>
                                              )}
                                          </div>
                                        </div>
                                      );
                                    }
                                  }
                                )}
                              </TabPane>
                            );
                          }
                        })}
                    </TabContent>
                  ) : (
                    <div className="no-data-text">{t("NO_DATA_FOUND")}</div>
                  )}
                  <Link
                    to={{
                      pathname: WebUrl._URL_REFERRAL_EDIT,
                      state: {
                        id: referralCodeId,
                        availableCategories: categories,
                        referralCodeDetail: referralCodeDetail,
                      },
                    }}
                  >
                    <button className="custom-btn-style-1 mt-5 mt-5">{t("EDIT")}</button>
                  </Link>
                </div>
              ) : (
                <div className="loading-spinner-wrapper">
                  <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                  </Spinner>
                </div>
              )}
            </div>
          </div>
        </div> */}
      </section>
      {/*{showCodeAlert && (*/}
      {/*  <div*/}
      {/*    className="content content-full-width"*/}
      {/*    style={{*/}
      {/*      position: "fixed",*/}
      {/*      top: "10px",*/}
      {/*      width: "100%",*/}
      {/*      zIndex: 9999,*/}
      {/*    }}*/}
      {/*  >*/}
      {/*    <div className="container">*/}
      {/*      <Alert color="green">*/}
      {/*        {t("CODE") +*/}
      {/*          ":" +*/}
      {/*          referralCodeDetail.code +*/}
      {/*          " + | " +*/}
      {/*          t("COPY_SUCCESS")}*/}
      {/*      </Alert>*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*)}*/}

      <Modal
        isOpen={showShareModal}
        id="modal-small-custom"
        fade={true}
        toggle={() => {
          setShowShareModal(false);
        }}
        centered
      >
        <ModalBody>
          <div className="text-center">
            <h3 className="mb-4">{t("SHARE_REFERRAL_CODE")}</h3>
            <div className="ref-share">
              {ShareButton}
            </div>
            <div className="mt-4">
              <button type="submit" className="btn custom-btn" onClick={() => setShowShareModal(false)}>{t("CLOSE")}</button>
            </div>
          </div>
        </ModalBody>
      </Modal>

      <Modal
        isOpen={showCodeAlert}
        id="modal-small-custom"
        fade={true}
        toggle={() => {
          setShowCodeAlert(false);
        }}
        centered
      >
        <ModalBody>
          <div className="text-center">
            <div className="confirm-div">
              <img
                className="cop-code"
                src="/img/version3/success.svg"
                alt="success"
              />
              <div>{t("COPY_SUCCESS")}</div>
              <button type="submit" className="btn custom-btn" style={{ width: "60% !important" }} onClick={() => setShowCodeAlert(false)}>{t("OK")}</button>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};
export default ReferralCode;
