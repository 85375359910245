import React, { useState, useEffect } from "react";
import "react-simple-keyboard/build/css/index.css";
import Sidebar from "components/sidebar";
import ApiEngine from "../../util/ApiEngine";
import { ApiKey, ApiUrl } from "../../util/Constant";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import moment from "moment";
import { stringIsNullOrEmpty } from "../../util/Util";
import ContentHeader from "components/content/header";

const NotificationDetail = (props) => {
  var { t } = useTranslation();
  var _history = useHistory();
  var _location = useLocation();

  const [announcement, setAnnouncement] = useState([]);

  const _SELECTIONS = {
    "Deposit SMS": "DEPOSIT",
    "Withdrawal SMS": "WITHDRAWAL",
    "Reset Password SMS": "RESET_PASSWORD",
    "Phone Verfication SMS": "PHONE_VERIFICATION",
    "PIN Reset": "RESET_PIN",
  };

  useEffect(() => {
    if (
      _location["state"] &&
      !stringIsNullOrEmpty(_location["state"]["data"])
    ) {
      var data = JSON.parse(_location["state"]["data"]);
      setAnnouncement(data);

      if (!data["isRead"]) {
        readAnnouncement(data["id"]);
      }
    } else {
      _history.goBack();
    }
  }, [_location["state"]]);

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  async function readAnnouncement(id) {
    var responseJson = await ApiEngine.post(
      `${ApiUrl._API_MARK_ANNOUNCEMENT_AS_READ}?announcementId=${id}`
    );

    if (!responseJson[ApiKey._API_SUCCESS_KEY]) {
      _history.goBack();
    }
  }

  return (
    <>
      <section className="section-padding" id="notification-section">
        <ContentHeader enableBackButton title={t("NOTIFICATION")} />
        <div className="news-detail template-box">
          <h3 className="news-title text-brand">
            {announcement["title"] == "Deposit SMS" ||
              announcement["title"] == "Withdrawal SMS"
              ? t(_SELECTIONS[announcement["title"]])
              : announcement["title"]}
          </h3>
          <p className="news-date text-brand mb-3">
            {moment(announcement["date"]).format("DD-MM-YYYY HH:mm:ss")}
          </p>
          <div
            className="news-content text-brand"
            dangerouslySetInnerHTML={{
              __html: announcement["description"],
            }}
          />
        </div>
      </section>
    </>
  );
};
export default NotificationDetail;
