import React, { useState, useEffect } from "react";
import "react-simple-keyboard/build/css/index.css";
import GameMenu from "components/game-list/game-menu";
import { SessionKey } from "../../util/Constant";
const DownloadApp = () => {
  const [displayGameMenu, setDisplayGameMenu] = useState(true);

  useEffect(() => {
    setDisplayGameMenu(false);
    setTimeout(() => setDisplayGameMenu(true), 1);
  }, [(localStorage.getItem(SessionKey._LANGUAGE) && localStorage.getItem(SessionKey._LANGUAGE) != 'undefined' ?
    localStorage.getItem(SessionKey._LANGUAGE) : 'en')]);

  return (
    <>
      {displayGameMenu && (
        <>
          <section className="category-section">
            <GameMenu />
          </section>
          <section className="section-padding" id="download-app-section">
            <div className="container">
              <div className="row">
                <div className="col-md-12 text-white">
                  <h3 className="text-center mb-4 pb-3">
                    That winner’s feeling starts here with i8 app!
                  </h3>
                  <div>
                    <img
                      src="/img/download-banner.png"
                      className="img-100"
                      alt="banner-main"
                    />
                  </div>
                  <div>
                    <h2 className="text-center text-gold mt-4 pt-3 mb-4 pb-2">
                      Download The i8 App Now!
                    </h2>
                  </div>
                  <div className="font-semi pr-5 pl-5">
                    <p>
                      We always strive to give you the best betting experience
                      and here comes the latest addition, the i8 app!
                    </p>
                    <p>
                      The best of fun, fast & fair betting will come to life in
                      an instant on your phone. Play sports, casino and more –
                      wherever and whenever you want.
                    </p>
                    <p>
                      After clicking the{" "}
                      <a href="" className="text-gold font-thin">
                        DOWNLOAD
                      </a>{" "}
                      button below (if currently using mobile) or scanning the
                      QR CODE, please follow the next instructions:{" "}
                    </p>
                  </div>
                  <div className="download-img-box">
                    <div>
                      <img
                        src="/img/download-qr.png"
                        className="img-responsive"
                        alt="banner-main"
                      />
                    </div>
                    <div>
                      <img
                        src="/img/or.png"
                        className="img-responsive"
                        alt="banner-main"
                      />
                    </div>
                    <div>
                      <div className="mb-4">
                        <a href="">
                          <img
                            src="/img/app-store.png"
                            className="img-responsive"
                            alt="banner-main"
                          />
                        </a>
                      </div>
                      <div>
                        <a href="">
                          <img
                            src="/img/google-play.png"
                            className="img-responsive"
                            alt="banner-main"
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                  <div>
                    <h3 className="text-center mt-4 pt-3 mb-4 pb-2">
                      Congratulations, process is completed!{" "}
                    </h3>
                    <h3 className="text-center">
                      Open the app and enjoy your{" "}
                      <span className="text-gold">Fun. Fast. Fair.</span>{" "}
                      experience with i8 !
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      )}
    </>
  );
};
export default DownloadApp;
