import React from "react";
import { PageSettings } from "./config/page-settings.js";
import Content from "./components/content";
import SweetAlert from "react-bootstrap-sweetalert";
import LoadingOverlay from "react-loading-overlay";
import { connect } from "react-redux";
import {
  updateLoginUser,
  resetLogin,
  performLogout,
  checkIsLoggedIn,
} from "./redux/AuthAction.js";
import { disposeMessage } from './redux/AppAction.js';
import classNames from "classnames";
import { isObjectEmpty, stringIsNullOrEmpty } from "./util/Util.js";
import { withTranslation } from "react-i18next";
import { Helmet } from 'react-helmet';
import ApiEngine from './util/ApiEngine.js';
import { ApiKey, ApiUrl } from "./util/Constant";

LoadingOverlay.propTypes = undefined
class App extends React.Component {
  constructor(props) {
    super(props);

    this.handleSetPageContent = (value) => {
      this.setState((state) => ({
        pageContent: value,
      }));
    };
    this.handleSetPageContentClass = (value) => {
      this.setState((state) => ({
        pageContentClass: value,
      }));
    };
    this.handleSetPageContentFullHeight = (value) => {
      this.setState((state) => ({
        pageContentFullHeight: value,
      }));
    };
    this.handleSetPageContentFullWidth = (value) => {
      this.setState((state) => ({
        pageContentFullWidth: value,
      }));
    };
    this.handleSetPageContentInverseMode = (value) => {
      this.setState((state) => ({
        pageContentInverseMode: value,
      }));
    };
    this.handleSetPageFooter = (value) => {
      this.setState((state) => ({
        pageFooter: value,
      }));
    };
    this.handleSetBodyWhiteBg = (value) => {
      if (value === true) {
        document.body.classList.add("bg-white");
      } else {
        document.body.classList.remove("bg-white");
      }
    };

    this.state = {
      pageContent: true,
      pageContentClass: "",
      pageContentFullHeight: false,
      pageContentFullWidth: true,
      pageContentInverseMode: false,
      handleSetPageContent: this.handleSetPageContent,
      handleSetPageContentClass: this.handleSetPageContentClass,
      handleSetPageContentFullHeight: this.handleSetPageContentFullHeight,
      handleSetPageContentFullWidth: this.handleSetPageContentFullWidth,
      handleSetPageContentInverseMode: this.handleSetPageContentInverseMode,

      pageFooter: false,
      handleSetPageFooter: this.handleSetPageFooter,
      error: null,
      errorInfo: null,

      handleSetBodyWhiteBg: this.handleSetBodyWhiteBg,
      handleSetPageBoxedLayout: this.handleSetPageBoxedLayout,

      interval1: null,
      location: window.location.href,
      language: this.props.i18n.language,
    };
  }

  /// <summary>
  /// Author: -
  /// </summary>
  componentDidMount() {
    clearInterval(this.state.interval1);
    this.state.interval1 = null;
    this.props.checkIsLoggedIn();
    this.state.interval1 = setInterval(() => this.props.checkIsLoggedIn(), 30000);
    this.getSeo(this.state.language);

  }

  componentWillUnmount() {
    clearInterval(this.state.interval1);
    this.state.interval1 = null;
  }

  /// <summary>
  /// Author: -
  /// </summary>
  componentDidUpdate(prevProps) {
    if (
      this.props.languageState.languageId != prevProps.languageState.languageId
    ) {
      this.props.i18n.changeLanguage(this.props.languageState.languageId);
    }

    if (this.state.interval1 == null) {
      this.state.interval1 = setInterval(() => this.props.checkIsLoggedIn(), 30000);
    }

    if (this.state.location != window.location.href || this.state.language != prevProps.i18n.language) {
      this.getSeo(this.props.i18n.language);
      this.state.location = window.location.href;
      this.state.language = this.props.i18n.language;
    }
  }

  /// <summary>
  /// Author: -
  /// </summary>
  componentDidCatch(error, errorInfo) {
    this.setState({
      error: error,
      errorInfo: errorInfo,
    });

    clearInterval(this.state.interval1);
  }

  async getSeo(language) {
    var responseJson = await ApiEngine.get(
      `${ApiUrl._API_GET_SEO_SCRIPTS_LANGUAGE}?LanguageCode=` + language
    );

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      var data = responseJson[ApiKey._API_DATA_KEY];

      var contentLength = data.length;
      var list = [];
      var newData = [];
      var newList = [];
      for (var x = 0; x < contentLength; x++) {
        var content = JSON.parse(data[x]["content"]);
        var contentLength1 = Object.keys(content).length;
        for (var i = 0; i < contentLength1; i++) {

          var jsonObj = JSON.parse(content["parameter_" + (i + 1)]);
          newData.push({
            name: "PARAMETER " + (i + 1),
            metaType: jsonObj["metaType"],
            content: jsonObj["content"]
          });
        }
        newList = {
          metaType: data[x]["metaDataType"],
          data: newData
        }
        newData = [];
        list.push(newList);
      }
      var titleList = [];
      var meta = [];
      var link = [];
      var script = [];
      var noscript = [];
      var base = [];
      var title = "";

      for (var i = 0; i < list.length; i++) {
        if (list[i].metaType == 'Meta') {
          for (var x = 0; x < list[i].data.length; x++) {
            var content = list[i].data[x].content;
            var jsonObject = JSON.parse(content);
            meta.push({
              page: list[i].data[x].metaType,
              content: jsonObject
            })
          }
        } else if (list[i].metaType == 'Title') {
          for (var x = 0; x < list[i].data.length; x++) {
            titleList.push({
              page: list[i].data[x].metaType,
              content: list[i].data[x].content
            })
          }

        }
        else if (list[i].metaType == 'Link') {
          for (var x = 0; x < list[i].data.length; x++) {
            var content = list[i].data[x].content;
            var jsonObject = JSON.parse(content);
            link.push({
              page: list[i].data[x].metaType,
              content: jsonObject
            })
          }

        }
        else if (list[i].metaType == 'script') {
          for (var x = 0; x < list[i].data.length; x++) {
            var page = list[i].data[x].metaType;
            var content = list[i].data[x].content;
            var jsonObject = JSON.parse(content);
            var code = jsonObject.code.replaceAll("'", `"`);
            jsonObject.code = code;
            script.push({
              page: page,
              content: jsonObject
            });

          }

        }
        else if (list[i].metaType == 'noscript') {
          for (var x = 0; x < list[i].data.length; x++) {
            var page = list[i].data[x].metaType;
            var content = list[i].data[x].content;
            var jsonObject = JSON.parse(content);
            noscript.push({
              page: page,
              content: jsonObject
            });
          }

        }
        else if (list[i].metaType == 'Base') {
          for (var x = 0; x < list[i].data.length; x++) {
            var page = list[i].data[x].metaType;
            var content = list[i].data[x].content;
            var jsonObject = JSON.parse(content);
            base.push({
              page: page,
              content: jsonObject
            });
          }

        }
      }

      var scriptList = [];
      var metaList = [];
      var linkList = [];
      var noscriptList = [];
      var baseList = [];

      if (titleList.length > 0) {
        titleList.filter(title => (title.page == window.location.pathname)).map(content => {
          if (!stringIsNullOrEmpty(content)) {
            title = content.content;
          }
        })

        if (stringIsNullOrEmpty(title)) {
          titleList.filter(title => (title.page == "*")).map(contentAll => {
            title = contentAll.content;
          })
        }
      }

      if (script.length > 0) {
        script.filter(scrips => ((scrips.page == window.location.pathname || scrips.page == "*"))).map(content => {
          if (!stringIsNullOrEmpty(content)) {
            scriptList.push(content.content);
          }
        })
      }

      if (meta.length > 0) {
        meta.filter(metas => (metas.page == "*" || metas.page == window.location.pathname)).map(content => {
          if (!stringIsNullOrEmpty(content)) {
            metaList.push(content.content);
          }
        })
        var list = [];
        var listPage = [];
        if (metaList.length > 0) {
          var filterList = meta.filter(function (obj) {
            if (obj.content.name === "description") {
              list.push(obj.content);
              listPage.push(obj.page);
              return obj.content;
            }
          })
          var i = 0;
          var x = 0;
          while (i < metaList.length) {

            if (metaList[i] === list[x]) {
              metaList.splice(i, 1);
              x++;
            } else {
              ++i;
            }
          }
        }

        listPage.includes(window.location.pathname) ?
          filterList.filter(a => a.page == window.location.pathname).map(content => {
            metaList.push(content.content);
          })
          :
          filterList.filter(a => a.page == "*").map(content => {
            metaList.push(content.content);
          })

      }

      if (link.length > 0) {
        link.filter(links => ((links.page == window.location.pathname || links.page == "*"))).map(content => {
          if (!stringIsNullOrEmpty(content)) {
            linkList.push(content.content);
          }
        })
      }

      if (noscript.length > 0) {
        noscript.filter(noscripts => ((noscripts.page == window.location.pathname || noscripts.page == "*"))).map(content => {
          if (!stringIsNullOrEmpty(content)) {
            noscriptList.push(content.content);
          }
        })
      }

      if (base.length > 0) {
        base.filter(bases => ((bases.page == window.location.pathname || bases.page == "*"))).map(content => {
          if (!stringIsNullOrEmpty(content)) {
            baseList.push(content.content);
          }
        })
      }

      this.setState({ baseList: baseList });
      this.setState({ noscriptList: noscriptList });
      this.setState({ scriptList: scriptList });
      this.setState({ linkList: linkList });
      this.setState({ title: title });
      this.setState({ metaList: metaList });
      this.setState({ seo: list });
    }
  }

  render() {
    const { seo, metaList, title, linkList, scriptList, noscriptList, baseList, location, language } = this.state;
    if (this.state.errorInfo) {
      return (
        <div className="error" style={{ padding: "20px" }}>
          <img
            src={require("./assets/img/error.png")}
            style={{
              maxWidth: "100px",
              objectFit: "contain",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          />
          <h3>Something went wrong, please contact admin for help</h3>
          <div className="m-b-30">{window.navigator.userAgent}</div>
        </div>
      );
    } else {
      return (
        <PageSettings.Provider value={this.state}>
          <Helmet
            title={title}
            meta={metaList}
            link={linkList}
          >
            <base href={location} />
          </Helmet>
          {scriptList &&
            scriptList.length > 0 &&
            scriptList.map(({ code, type, id, src }, index) => (
              <Helmet>
                <script key={index} src={src} id={id} type={type}>
                  {code}
                </script>
              </Helmet>
            ))
          }

          {noscriptList &&
            noscriptList.length > 0 &&
            noscriptList.map(({ code }, index) => (
              <Helmet>
                <noscript>
                  {code}
                </noscript>
              </Helmet>
            ))
          }
          <LoadingOverlay
            active={
              this.props.appState.isBusy ||
              !this.props.authState.loginStateInitialized
            }
            styles={{
              overlay: (base) => ({
                ...base,
                zIndex: "10000",
                position: "fixed",
              }),
            }}
            className={classNames("loading-overlay")}
              >
                  
                  <img src={require('../src/assets/img/loading_dice.gif')} style={{ width: "120px", height: "120px" }} />
                  {/*<div className="loader">
                      <img src={require("./assets/img/logo_blue.png")} />
                  </div>*/}
                  <b className="loader-font">{this.props.appState.message + "..."}</b>
          </LoadingOverlay>
          {!isObjectEmpty(this.props.appState.alert) && (
            <div className="sweet-alert-container">
              {/*console.log({
                ...this.props.appState.alert,
                onConfirm: this.props.appState.alert.onConfirm ?? this.props.disposeMessage()

              }) */}
              <SweetAlert {...this.props.appState.alert}>
                {!stringIsNullOrEmpty(this.props.appState.alert.content) &&
                  (this.props.appState.alert.html
                    ? this.props.appState.alert.content
                    : this.props.appState.alert.content
                        .split("\n")
                        .map((item, key) => {
                          return (
                            <span key={key} className="sweet-alert-content">
                              {item}
                              <br />
                            </span>
                          );
                        }))}
              </SweetAlert>
            </div>
          )}
          {this.props.authState.loginStateInitialized && (
            <div
              className={
                (this.state.hasCustomSkin === true
                  ? "custom-skin-background "
                  : "") +
                classNames("fade page-sidebar-fixed show page-container", {
                  "page-content-full-height": this.state.pageContentFullHeight,
                })
              }
              style={{
                minHeight: "100vh",
                height: "100%",
                backgroundSize:
                  this.props.authState.userData.selectedTheme === 1
                    ? "cover"
                    : "contain",
              }}
            >
              <div
                onClick={() => {
                  if (this.state.pageSidebarToggled) {
                    this.toggleMobileSidebar();
                  }
                }}
              >
                {this.state.pageContent && <Content />}
              </div>
            </div>
          )}
        </PageSettings.Provider>
      );
    }
  }
}

const mapStateToProps = (state) => {
  return ({
    ...state,
  })
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  var alert = {};
  if (Object.keys(stateProps.appState.alert).length > 0 && !stateProps.appState.alert.onConfirm) {
    alert = {
      ...stateProps.appState.alert,
      onConfirm: dispatchProps.disposeMessage
    }
    stateProps.appState.alert = { ...alert };
  }
  
  return ({ ...stateProps, ...dispatchProps, ...ownProps });
}

export default connect(mapStateToProps, {
  updateLoginUser: updateLoginUser,
  resetLogin: resetLogin,
  performLogout: performLogout,
  checkIsLoggedIn: checkIsLoggedIn,
  disposeMessage: disposeMessage
}, mergeProps)(withTranslation()(App));
