import { _SAVE_LANGUAGE_ID } from "./LanguageAction";

const _INITIALSTATE = {
  languageId: "",
  retain: false,
};

/// <summary>
/// Author : -
/// </summary>
export default (state = _INITIALSTATE, action) => {
  switch (action.type) {
    case _SAVE_LANGUAGE_ID:
      return {
        ...state,
        languageId: action.languageId,
        retain: action.retain,
      };
    default:
      return state;
  }
};
