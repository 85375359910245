import React, { useState, useEffect, useCallback } from "react";
import "react-simple-keyboard/build/css/index.css";
import { map, debounce, isNull } from "lodash";
import ApiCms from "util/ApiCms";
import { ApiKey, ApiUrl, WebUrl, SessionKey, LanguageOption } from "../../util/Constant";
import { useHistory } from "react-router-dom";
import GameMenu from "components/game-list/game-menu";
import {
  showResponseMessage,
  setBusy,
  setIdle,
} from "../../redux/AppAction.js";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import ApiEngine from "../../util/ApiEngine";
import ContentHeader from "components/content/header";
import Breadcrumb from "components/custom/Breadcrumb";
import RightInformation from "../../components/custom/RightInformation";
import Input from "components/custom/Input";
import {
  stringIsNullOrEmpty
} from "./../../util/Util.js";
import { helpCentreCategory, promotionFuntion } from "../../redux/InformationAction.js";

const HelpCentre = (props) => {
  const { t, i18n } = useTranslation();
  const [searchText, setSearchText] = useState("");
  const [breadcrumbs, updateBreadcrumbs] = useState([]);
  const [categories, setCategories] = useState([]);
  const [displayGameMenu, setDisplayGameMenu] = useState(true);
  const [channels, setChannels] = useState([]);
  let _history = useHistory();
  var _dispatch = useDispatch();

  const onClick = (breadcrumbItems) => {
    updateBreadcrumbs((arr) => [...arr, breadcrumbItems]);
  };

  useEffect(() => {
    fetchData();
    init();
    // setLang(
    //   LanguageOption.find(
    //     (x) => x.code === localStorage.getItem(SessionKey._LANGUAGE)
    //   ).code
    // );
  }, [
    // lang
    (localStorage.getItem(SessionKey._LANGUAGE) && localStorage.getItem(SessionKey._LANGUAGE) != 'undefined') ?
      (LanguageOption.find(
        (x) => x.code === localStorage.getItem(SessionKey._LANGUAGE)) != undefined ?
        LanguageOption.find(
          (x) => x.code === localStorage.getItem(SessionKey._LANGUAGE)
        ).code :
        LanguageOption[0].code
      ) :
      LanguageOption[0].code

    // LanguageOption.find(
    //   (x) => x.code === localStorage.getItem(SessionKey._LANGUAGE)
    // ).code,
  ]);

  useEffect(() => {
    setDisplayGameMenu(false);
    setTimeout(() => setDisplayGameMenu(true), 1);
  }, [(localStorage.getItem(SessionKey._LANGUAGE) && localStorage.getItem(SessionKey._LANGUAGE) != 'undefined' ?
    localStorage.getItem(SessionKey._LANGUAGE) : 'en')]);

  async function init() {
    _dispatch(setBusy());
    var responseJson = await ApiEngine.get(
      ApiUrl._API_GET_COMMUNICATION_CHANNELS + "?all=true&languageCode=" +
      (localStorage.getItem(SessionKey._LANGUAGE) && localStorage.getItem(SessionKey._LANGUAGE) != 'undefined' ?
        localStorage.getItem(SessionKey._LANGUAGE) : 'en')
    );

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      setChannelData(responseJson[ApiKey._API_DATA_KEY]);
    }

    _dispatch(setIdle());
  }

  async function fetchData() {
    _dispatch(setBusy());

    const { data } = await ApiCms.get( //get from category query
      "/categories/query" +
      "?locale=" +
      ((localStorage.getItem(SessionKey._LANGUAGE) && localStorage.getItem(SessionKey._LANGUAGE) != 'undefined') ?
        (LanguageOption.find(
          (x) => x.code === localStorage.getItem(SessionKey._LANGUAGE)) != undefined ?
          LanguageOption.find(
            (x) => x.code === localStorage.getItem(SessionKey._LANGUAGE)
          ).code :
          LanguageOption[0].code
        ) :
        LanguageOption[0].code)

      //LanguageOption.find(
      //  (x) => x.code === localStorage.getItem(SessionKey._LANGUAGE)
      //).code
    );

    const enData = await ApiCms.get("/categories/query" + "?locale=en");
    var dataArray = [];
    const data21 = data.data.filter((x) => !stringIsNullOrEmpty(x.category_id)).map((d, index) => {
      dataArray.push(d);
    });

    const data1 = [...dataArray].sort(function (a, b) {
      //if (a.category_id < b.category_id) {
      //  return -1;
      //}
      return a.category_id > b.category_id ? 1 : -1;
    });

    var data2 = [].concat({ data: data1 });

    const { data: categoriesList } = data2[0];

    setCategories(categoriesList);

    _dispatch(helpCentreCategory(categoriesList));
    _dispatch(setIdle());
  }

  async function GoToCategory(slug, category_id) {
    if (slug) {
      _history.push({
        pathname: WebUrl._URL_HELP_CENTRE_CATEGORY.replace(":category", slug),
        state: {
          category_id: category_id,
        },
      });
    }
  }

  async function GoToSubCategory(slug, sub_slug, category_id, sub_category_id) {
    if (slug) {
      _history.push({
        pathname: WebUrl._URL_HELP_CENTRE_SUB_CATEGORY
          .replace(":category", slug)
          .replace(":sub_category", sub_slug),
        state: {
          category_id: category_id,
          sub_category_id: sub_category_id,
        },
      });
    }
  }

  function setChannelData(data) {
    var channels = [];
    data.filter((i) => i.channelName.match(/Livechat/i)).map((d, index) => {
      var channel = {
        channelName: d.channelName,
        name: d.channelDescription,
        description: "",
        actionUrl: d.cta,
        image: d.channelImage,
        attachment: d.attachment,
      };

      channels.push(channel);
    });
    setChannels(channels);
  }

  const searchOnChange = useCallback(
    debounce(async (keyword) => {
      let url =
        "/categories/query" +
        "?locale=" + ((localStorage.getItem(SessionKey._LANGUAGE) && localStorage.getItem(SessionKey._LANGUAGE) != 'undefined') ?
          (LanguageOption.find(
            (x) => x.code === localStorage.getItem(SessionKey._LANGUAGE)) != undefined ?
            LanguageOption.find(
              (x) => x.code === localStorage.getItem(SessionKey._LANGUAGE)
            ).code :
            LanguageOption[0].code
          ) :
          LanguageOption[0].code)

      // LanguageOption.find(
      //   (x) => x.code === localStorage.getItem(SessionKey._LANGUAGE)
      // ).code;

      if (keyword.target.value.length) {
        keyword = keyword.target.value;
        url = url + "&search=" + keyword;
      }

      const { data } = await ApiCms.get(url);
      const { data: categoriesList } = data;

      setCategories(categoriesList);

      setSearchText(keyword);
    }, 500),
    []
  );

  const breadcrumb = [
    {
      value: 'SETTINGS',
      active: false,
      link: WebUrl._URL_SETTINGS
    },
    {
      value: 'HELP_CENTRE',
      active: true,
      link: WebUrl._URL_HELP_CENTRE
    },
  ];

  async function GoToFaq(
    slug,
    sub_slug,
    faq,
    category_id,
    sub_category_id,
    faq_id

  ) {
    if (sub_slug) {
      _history.push({
        pathname: WebUrl._URL_HELP_CENTRE_FAQ
          .replace(":category", slug)
          .replace(":sub_category", sub_slug)
          .replace(":faq", faq),
        state: {
          category_id: category_id,
          sub_category_id: sub_category_id,
          faq_id: faq_id,
        },
      });
    }
  }

  return (
    <div id="help-centre-group-section">
      <section className="section-padding" >
        <Breadcrumb
          data={breadcrumb}
        />

        <div className="with-information-container">
          <div className="left">
            <div className="search-container-help-centre">
              <div className="search-box-vt">
                <Input
                  id="header-search-help"
                  name="header-search-help"
                  placeholder={t("HOW_CAN_WE_HELP")}
                  onChange={searchOnChange}
                  inputGroupType="append"
                  inputGroupContent={<div className="header-search-icon-video-tutorial" > <img
                    src="/img/version3/search.png"
                    alt="search"
                  /> </div>}
                />
              </div>
            </div>
            {categories && categories.length === 0
              ? t("NO_DATA_FOUND")
              : categories.map((item, index) => {
                return (
                  !isNull(item.publishedAt) && ((!item.slug.includes("_")) || item.slug.match("_myr")) && (    //item : general Title, Eg Getting Started, filter by slug with myr if consist "_"
                    <div key={item.id} className="template-box-help-centre settings-accordion-item mb-4">
                      <div
                        className="settings-acc-title"
                        data-toggle="collapse"
                        data-target={"#security" + index}
                      >
                        <div>
                          {t(item.title)}
                        </div>
                        <div className="acc-arrows">
                          <img
                            src="/img/version3/arrow-down.png"
                            className="img-responsive img1"
                            alt="accordion"
                          />
                          <img
                            src="/img/version3/select-arrow-blue-up.png"
                            className="img-responsive img2"
                            alt="icon"
                          />
                        </div>
                      </div>
                      {item && (
                        <>
                          <div
                            id={"security" + index}
                            className="collapse settings-acc-details settings-form"
                          >
                            <div>
                              {item.sub_categories &&
                                item.sub_categories.length &&
                                item.sub_categories.map((item1, index1) => {
                                  return (
                                    !isNull(item1.publishedAt) && ((!item1.slug.includes("_")) || item1.slug.match("_myr")) &&  //item 1 : subcategory, eg How To Deposit, filter by myr if consist of "_"
                                    (
                                      <div className="cursor-global"
                                        key={item1.id}
                                        data-toggle="collapse"
                                        data-target={"#sub_security" + item1.slug}
                                      >
                                        {console.log(item1)}
                                        {
                                          (stringIsNullOrEmpty(item1.faqs)) ?
                                            <div onClick={() =>
                                              GoToSubCategory(
                                                item.slug,
                                                item1.slug,
                                                item.category_id,
                                                item1.category_id
                                              )
                                            }>
                                              <label className="cursor-global help-centre-div">{item1.Title}</label>
                                            </div>
                                            :
                                            <>
                                              {/* {item1.faqs.filter((x) => !stringIsNullOrEmpty(x.publishedAt)).length > 0 ? 
                                                <label className="mt-4 cursor-global">{item1.Title}</label>
                                                :
                                                <div onClick={() =>
                                                  GoToSubCategory(
                                                    item.slug,
                                                    item1.slug,
                                                    item.category_id,
                                                    item1.category_id
                                                  )
                                                }>
                                                  <label className="mt-4 cursor-global">{item1.Title}</label>
                                                </div>
                                              }*/}

                                              {item1 && (
                                                <>
                                                  {item1.faqs &&
                                                    item1.faqs.length ? (
                                                    <>
                                                      {
                                                        item1.faqs.map((item2, index2) => {
                                                          return (
                                                            !isNull(item2.publishedAt) && ((!item2.slug.includes("_")) || item2.slug.match("_myr")) && ( //item 2 : faq content
                                                              <div className="cursor-global"
                                                                key={item2.id}
                                                                data-toggle="collapse"
                                                                data-target={"#sub_security" + item1.slug}
                                                                onClick={() =>
                                                                  GoToFaq(
                                                                    item1.category.slug,
                                                                    item1.slug,
                                                                    item2.slug,
                                                                    item1.category.category_id,
                                                                    item1.sub_category_id,
                                                                    item2.faq_id
                                                                  )
                                                                }
                                                              >
                                                                <div>
                                                                  <label className="cursor-global help-centre-div">{item2.Title}</label>
                                                                </div>
                                                              </div>
                                                            )
                                                          )
                                                        })
                                                      }
                                                    </>
                                                  ) : (
                                                    <>
                                                    </>
                                                  )
                                                  }
                                                </>
                                              )}</>
                                        }

                                      </div>

                                    ));
                                })}
                            </div>
                          </div>
                        </>

                      )}
                    </div>
                  )
                )
              })}

          </div>
          <div className="right">
            <RightInformation
              promotion={true}
              helpCentre={true}
            />
          </div>
        </div>
      </section>
    </div>
  );
};
export default HelpCentre;
