import React from "react";
import Select from "react-select";

const SelectOption = (props) => {
  const { options, placeholder = "", value, onChange, ...otherProps } = props;
  const _SELECT_STYLES = {
    menu: (styles, state) => ({
      ...styles,
    }),
    control: (styles, state) => ({
      ...styles,
      background: "transparent",
      borderRadius: "10px",
      boxShadow: "0px 3px 17px 0px rgba(0,0,0,.1)",
      height: "50px",
      fontSize: "11px",
      background: 'linear-gradient(0deg, rgba(25, 35, 47, 0.1), rgba(25, 35, 47, 0.1)), linear-gradient(271.54deg, rgba(51, 105, 255, 0.12) -18.33%, rgba(27, 42, 84, 0.3) 100%)',
      boxShadow: '0px 4px 7px rgba(0, 0, 0, 0.3)',
      borderRadius: '8px',
      border: '0px'
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        color: "#000",
        fontSize: "11px",
      };
    },
    singleValue: (styles) => ({
      ...styles,
      paddingLeft: "24px",
      fontSize: "14px",
      color: "#FFFFFF",
      fontWeight: "600",
    }),
    placeholder: (styles) => ({
      ...styles,
      paddingLeft: "24px",
      fontSize: "14px",
      color: "#FFFFFF",
    }),
  };

  return (
    <Select
      options={options}
      isSearchable={false}
      styles={_SELECT_STYLES}
      placeholder={
        options.filter((option) => option.value == value)[0] !== undefined
          ? options.filter((option) => option.value == value)[0].label
          : placeholder
      }
      value={options.filter((option) => option.value == value)}
      onChange={(e) => {
        onChange(e);
      }}
      {...otherProps}
    />
  );
};

export default SelectOption;
