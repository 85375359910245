import React from "react";
import "react-simple-keyboard/build/css/index.css";
import { map } from "lodash";
import GameMenu from "components/game-list/game-menu";
import Slider from "react-slick";
import { popularGames } from "../../config/game-configs";
import Button from "components/button";
import Header from "components/header/HeaderDownload";

const Download = () => {
  const categorySlider = {
    infinite: true,
    slidesToShow: 6,
    slidesToScroll: 1,
    arrows: true,
    dots: false,
    responsive: [
      //     {
      //     breakpoint: 1500,
      //     settings: {
      //         slidesToShow: 5,
      //     },
      //   },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 3,
        },
      },
    ],
  };

  const CategoryList = [
    {
      image: "/img/download/cat1.png",
      text: "LOTTERY",
    },
    {
      image: "/img/download/cat2.png",
      text: "SLOT",
    },
    {
      image: "/img/download/cat3.png",
      text: "SPORT",
    },
    {
      image: "/img/download/cat4.png",
      text: "RNG",
    },
    {
      image: "/img/download/cat5.png",
      text: "ESPORT",
    },
    {
      image: "/img/download/cat6.png",
      text: "POKER",
    },
    {
      image: "/img/download/cat3.png",
      text: "TEST",
    },
  ];
  const FeatureList = [
    {
      image: "/img/download/feature1.png",
      text: "Fast Withdrawal",
    },
    {
      image: "/img/download/feature2.png",
      text: "24 Hours Phone Support",
    },
    {
      image: "/img/download/feature3.png",
      text: "Safety E-Wallet System",
    },
    {
      image: "/img/download/feature4.png",
      text: "Privacy Protection",
    },
    {
      image: "/img/download/feature5.png",
      text: "More than 500++ Online Game",
    },
    {
      image: "/img/download/feature6.png",
      text: "Fast and easy transaction",
    },
  ];
  return (
    <>
      <Header />
      <section id="download-banner-section">
        <div className="container">
          <div className="row">
            <div className="col text-white">
              <p className="font-18 font-semi mb-2">
                Make a Quick Deposit and Play
              </p>
              <h2>
                Read About Game
                <br />
                That You Enjoy It
              </h2>
              <div className="download-buttons">
                <div>
                  <a
                    href="#"
                    className="transaction-btn small-btn font-18 font-semi"
                  >
                    <div>
                      <img
                        src="/img/apple.png"
                        className="img-responsive"
                        alt="banner-main"
                      />
                    </div>
                    <div>App Store</div>
                  </a>
                </div>
                <div>
                  <a
                    href="#"
                    className="transaction-btn small-btn font-18 font-semi"
                  >
                    <div>
                      <img
                        src="/img/playstore.png"
                        className="img-responsive"
                        alt="banner-main"
                      />
                    </div>
                    <div>Google Play</div>
                  </a>
                </div>
              </div>
              <div className="download-banner-image">
                <img
                  src="/img/download/banner-mobile.png"
                  className="img-responsive"
                  alt=" Read About Game That You Enjoy It"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section-padding pt-0" id="download-category-section">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h2 className="text-center download-main-title">
                PLAY BY CATEGORY
              </h2>
              <div className="download-category-slider">
                <Slider {...categorySlider}>
                  {CategoryList &&
                    CategoryList.length &&
                    map(CategoryList, (item) => {
                      return (
                        <div className="download-category-item">
                          <div>
                            <img
                              src={item.image}
                              className="img-100"
                              alt={item.text}
                            />
                          </div>
                          <p className="font-18 mb-0 text-white font-semi mt-3">
                            {item.text}
                          </p>
                        </div>
                      );
                    })}
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section-padding pt-3" id="download-popular-section">
        <div className="container">
          <div className="col-12">
            <h2 className="text-center download-main-title">
              POPULAR GAMES
            </h2>
          </div>
          <div className="row">
            {popularGames &&
              popularGames.length &&
              popularGames.slice(0, 18).map((item) => {
                return (
                  <div className="col-lg-2 col-md-3 col-4">
                    <div className="game-box">
                      {item.tag ? (
                        <div className="tag">
                          <img
                            src={item.tag}
                            className="img-responsive"
                            alt="hot"
                          />
                        </div>
                      ) : null}
                      <img
                        src={item.image}
                        className="img-100"
                        alt={item.text}
                      />
                    </div>
                  </div>
                );
              })}
          </div>
          <div className="row">
            <div className="col mt-2">
              <Button
                text="Show all"
                size="small"
                type="ghost"
              // onClick={() => _history.push(WebUrl._URL_WITHDRAWAL)}
              />
            </div>
          </div>
        </div>
      </section>
      <section className="mb-5">
        <div className="container">
          <div className="row">
            <div className="col">
              <img
                src="/img/download/download-middle-banner.png"
                className="img-100"
                alt="WIN EXTRA MONEY IN A SECOND"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="section-padding" id="download-feature-section">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2 className="text-center download-main-title">
                POPULAR GAMES
              </h2>
            </div>
          </div>
          <div className="row">
            {FeatureList &&
              FeatureList.length &&
              map(FeatureList, (item) => {
                return (
                  <div className="col-md-4 col-6 text-center mb-5">
                    <div>
                      <img
                        src={item.image}
                        className="img-responsive"
                        alt={item.text}
                      />
                    </div>
                    <p className="font-18 mb-0 text-white font-semi mt-1">
                      {item.text}
                    </p>
                  </div>
                );
              })
            }
          </div>
        </div>
      </section>
      <section className="section-padding" id="download-bottom-section">
        <div className="container">
          <div className="row">
            <div className="col-12 position-relative">
              <img
                src="/img/download/bottom.png"
                className="img-responsive"
                alt="So, what are you waiting for download i8 app right now!!!"
              />
              <div className="text-white download-bottom-content">
                <h3>
                  So, what are you waiting for.
                </h3>
                <p>Let's play together with your friend, play more <br />and win more now!</p>
                <div className="download-buttons download-btn-white">
                  <div>
                    <a
                      href="#"
                      className="transaction-btn small-btn font-16 font-semi"
                    >
                      <div>
                        <img
                          src="/img/download/ap-blue.png"
                          className="img-responsive"
                          alt="banner-main"
                        />
                      </div>
                      <div>App Store</div>
                    </a>
                  </div>
                  <div>
                    <a
                      href="#"
                      className="transaction-btn small-btn font-16 font-semi"
                    >
                      <div>
                        <img
                          src="/img/download/pl-blue.png"
                          className="img-responsive"
                          alt="banner-main"
                        />
                      </div>
                      <div>Google Play</div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default Download;
