import React, { useState, useEffect, useRef } from "react";
import ContentHeader from "components/content/header";
import RightInformation from "../components/custom/RightInformation";
import { walletInformation, recentTransaction, latestGames } from "../config/game-configs";
import Breadcrumb from "components/custom/Breadcrumb";
import { map } from "lodash";
import Input from "../components/custom/Input";
import { useTranslation } from "react-i18next";
import Icon from "@mdi/react";

const Landing = (props) => {
    const [currentActiveTab, setCurrentActiveTab] = useState(0);
    const [redeemModalVisible, setRedeemModalVisible] = useState(true);
    var { t } = useTranslation();

    const menuData = [
        {
          image: "/img/version3/landing/1.png",
          title: 'Malaysia',
        },
        {
          image: "/img/version3/landing/2.png",
          title: 'Australia',
        },
        {
          image: "/img/version3/landing/3.png",
          title: 'Thailand',
        },
        {
          image: "/img/version3/landing/4.png",
          title: 'Indonesia',
        },
        {
          image: "/img/version3/landing/5.png",
          title: 'Vietnam',
        },
        {
          image: "/img/version3/landing/6.png",
          title: 'Global',
        },
      ];
    return (
        <>
            <section className="section-padding p-0" id="landing-section">
                <div></div>
                <div className="landing-container">
                    <div className="landing-image">
                        <img
                            className=""
                            src="/img/version3/landing_logo.png"
                            alt="i8-logo"
                        />
                    </div>
                    <div className="">
                        <p className="color-gray font-14 text-center">Choose your country</p>
                    </div>
                    <div className="custom-modal-label mt-4 mb-4">
                        <div>Welcome</div>
                    </div>
                    <div className="landing-language-container">
                        {menuData &&
                            menuData.length &&
                            map(menuData, (item, index) => {
                            return (
                                <div 
                                key={"landing-languange-" + index}
                                className={"landing-language-item " + (index == 0 ? 'active' : '')}
                                >
                                    <div className="image">
                                        <img src={item.image} alt="points" className="img-responsive"/>
                                    </div>
                                    <div className="title">
                                        {item.title}
                                    </div>
                                </div>
                            );
                            })
                        }
                    </div>
                </div>
                <div className="landing-version text-center pb-4 pt-4">
                    Version . 1.1.4224 
                </div>
            </section>
        </>
    );
};

export default Landing;