import {
  _WALLET_INFO,
  _LATEST_GAME,
  _NEW_DOWNLINE_MEMBER,
  _DOWNLINE_GROUP,
  _PROMOTION,
  _UNCLAIMED,
  _DOWNLINE_SALE,
  _HELP_CENTRE_CATEGORY,
  _REWARD_INFO,
  _REDEMPTION_HISTORY
} from "./InformationAction";

import {
  _LOGOUT,
} from "./AuthAction";

const _INITIAL_STATE = {
  walletInfo: {},
  latestGame: {},
  newDownlineMember: {},
  promotion: {},
  unclaimed: 0,
  downlineSale: {},
  helpCentreCategory: {},
  rewardInfo: {},
  redeptionHistory: {}
}

export default (state = _INITIAL_STATE, action) => {
  switch (action.type) {
    case _WALLET_INFO:
      return {
        ...state,
        walletInfo: action.data
      };
      break;
    case _LATEST_GAME:
      return {
        ...state,
        latestGame: action.data
      };
      break;
    case _NEW_DOWNLINE_MEMBER:
      return {
        ...state,
        newDownlineMember: action.data
      };
      break;
    case _PROMOTION:
      return {
        ...state,
        promotion: action.data
      };
      break;
    case _UNCLAIMED:
      return {
        ...state,
        unclaimed: action.data
      };
      break;
    case _DOWNLINE_SALE:
      return {
        ...state,
        downlineSale: action.data
      };
      break;
    case _HELP_CENTRE_CATEGORY:
      return {
        ...state,
        helpCentreCategory: action.data
      }
    case _REWARD_INFO:
      return {
        ...state,
        rewardInfo: action.data
      }
    case _REDEMPTION_HISTORY:
      return {
        ...state,
        redeptionHistory: action.data
      }
    case _LOGOUT:
      return {
        ...state,
        walletInfo: {},
        latestGame: {},
        newDownlineMember: {},
        unclaimed: 0,
        downlineSale: {},
        rewardInfo: {},
        redeptionHistory: {}
      }
      break;
    default:
      return state;
      break;
  }
}